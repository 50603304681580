import * as Types from '../@types/graphql.generated';

import { gql } from '@apollo/client';
export type AllStockStatusFieldsFragment = (
  { __typename?: 'stockStatus' }
  & Pick<Types.StockStatus, 'quantity' | 'incomingQuantity'>
  & { deliveries?: Types.Maybe<Array<(
    { __typename?: 'incomingDelivery' }
    & Pick<Types.IncomingDelivery, 'confirmed' | 'date' | 'incoming'>
  )>> }
);

export const AllStockStatusFieldsFragmentDoc = gql`
    fragment allStockStatusFields on stockStatus {
  quantity
  incomingQuantity
  deliveries {
    confirmed
    date
    incoming
  }
}
    `;