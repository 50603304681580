import React from "react"
import { GetMenuQuery } from "./operations.generated"
import { Flex, Text } from "@focus-nordic/ui-components"
import { SITE_MAX_WIDTH } from "../../constants"
import { Login } from "./Login"

interface DesktopSecondaryMenuProps
	extends Pick<GetMenuQuery, "mainSecondaryMenu"> {}

const DesktopSecondaryMenu: React.FC<DesktopSecondaryMenuProps> = props => {
	return (
		<Flex
			maxw={SITE_MAX_WIDTH}
			w={1}
			justifyContent="flex-end"
			py={1.25}
			px={5}
		>
			{props.mainSecondaryMenu && (
				<Flex as="ul">
					{props.mainSecondaryMenu.map((item, i) => (
						<li key={item?.id}>
							<Text.Link fontSize={1.75} to={item?.url || ""} mr={2} mb={0}>
								{item?.title}
							</Text.Link>
						</li>
					))}
					<li>
						<Login />
					</li>
				</Flex>
			)}
		</Flex>
	)
}

export { DesktopSecondaryMenu }
