import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type CardPromoBlockFieldsFragment = (
  { __typename?: 'cardPromoBlock' }
  & Pick<Types.CardPromoBlock, 'id' | 'type' | 'cardPromoTitle' | 'preamble'>
  & { blockTheme: (
    { __typename?: 'themeInfo' }
    & Pick<Types.ThemeInfo, 'backgroundColor' | 'textColor'>
  ), cards?: Types.Maybe<Array<(
    { __typename?: 'referenceInfo' }
    & Pick<Types.ReferenceInfo, 'title' | 'preamble' | 'isDarkTheme'>
    & { image?: Types.Maybe<(
      { __typename?: 'ImageBase' }
      & Pick<Types.ImageBase, 'fileName'>
      & { meta?: Types.Maybe<(
        { __typename?: 'ImageMeta' }
        & Pick<Types.ImageMeta, 'author' | 'alt'>
      )> }
    )>, ctaLink?: Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'alt' | 'target' | 'title' | 'url' | 'isExternal'>
    )> }
  )>> }
);

export const CardPromoBlockFieldsFragmentDoc = gql`
    fragment cardPromoBlockFields on cardPromoBlock {
  id
  type
  blockTheme {
    backgroundColor
    textColor
  }
  cardPromoTitle
  preamble
  cards {
    title
    preamble
    isDarkTheme
    image {
      fileName
      meta {
        author
        alt
      }
    }
    ctaLink {
      alt
      target
      title
      url
      isExternal
    }
  }
}
    `;