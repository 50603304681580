import TagManager from "react-gtm-module"
import { CustomerTypeEnum } from "../../@types/graphql.generated"

export const trackMainMenuClickEvent = (
	linkLabel: string,
	userType: CustomerTypeEnum
) => {
	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			event: "Main Menu",
			"Main Menu Category": linkLabel,
			type: userType
		}
	})
}

export const trackMegaMenuClickEvent = (
	categoryLabel = "none",
	userType: CustomerTypeEnum,
	parentLinkLabel = "none",
	childLinkLabel = "none"
) => {
	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			type: userType,
			event: "megaMenu",
			megaMenuCategory: categoryLabel,
			parentLinkText: parentLinkLabel,
			childLinkText: childLinkLabel
		}
	})
}
