"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Large = void 0;
var React = __importStar(require("react"));
var Flex_1 = require("../Flex");
var Image_1 = require("../Image");
var types_1 = require("./types");
var ProductCardLink_1 = require("./ProductCardLink");
var Label = __importStar(require("./Text"));
var ProductLabel_1 = require("../ProductLabel");
var Large = function (props) {
    var _a;
    return (React.createElement(Flex_1.Flex, { flexDirection: "column", flex: 1, pb: props.withoutBottomSeparator ? 0 : 2, mb: props.withoutBottomSeparator ? 0 : { _: 3, m: 5 }, position: "relative", justifyContent: "flex-start", borderBottom: !props.withoutBottomSeparator, borderColor: "grey-3" },
        React.createElement(Flex_1.Flex, { flexDirection: "column", position: "relative", flex: 1, zIndex: 0 },
            props.image && (React.createElement(Flex_1.Flex, { p: 2, mb: 1, display: "block", backgroundColor: "grey-1", position: "relative" },
                ((props.label && props.label !== "NONE") || (props.warranty && props.warranty > 0)) && (React.createElement(Flex_1.Flex, { position: "absolute", flexDirection: "column", alignItems: "flex-start", top: 0, left: 0, zIndex: 1, style: { gap: "2px 0" } },
                    props.label && props.label !== "NONE" && React.createElement(ProductLabel_1.ProductLabel, { label: props.label, locales: props.locales.productLabel }),
                    props.warranty && props.warranty > 0 && (React.createElement(ProductLabel_1.ProductLabel, { label: ProductLabel_1.ProductLabelKeys.WARRANTY, customLabelContent: (_a = props.locales.productLabel.productLabelWarrantyPlural) === null || _a === void 0 ? void 0 : _a.replace("{0}", props.warranty.toString()), locales: props.locales.productLabel })))),
                React.createElement(Image_1.Image, __assign({ ratio: 2 / 3 }, props.image)))),
            React.createElement(ProductCardLink_1.ProductCardLink, { to: props.to, onClick: props.onClick },
                props.brandName && React.createElement(Label.Brand, null, props.brandName),
                props.name && (React.createElement(Label.Name, __assign({}, (props.simpleView && {
                    fontSize: { _: 1.75, s: 2 },
                    lineHeight: { _: 2.25, s: 2.5 }
                })), props.name))),
            !props.simpleView && (React.createElement(React.Fragment, null,
                props.articleNumber && (React.createElement(Label.Data, { flexDirection: "row", title: props.locales.articleNumber, data: props.articleNumber })),
                props.ean && (React.createElement(Label.Data, { flexDirection: "row", title: props.locales.ean, data: props.ean })))),
            props.fee && (React.createElement(Label.Data, { flexDirection: "row", title: props.locales.fee, data: props.fee })),
            props.margin && (React.createElement(Label.Data, { flexDirection: "row", title: props.locales.margin, data: props.margin })),
            props.productDiscountedPrice && props.isLoggedIn && props.rsp ? (React.createElement(Label.Discount, { flexDirection: "row", title: props.locales.rsp, data: {
                    discountPrice: props.productDiscountedPrice,
                    comparisonPrice: props.productComparisonPrice,
                    startDate: props.startDate,
                    endDate: props.endDate,
                    tooltipId: props.articleNumber,
                    locales: {
                        campaignMessage: props.locales.campaignMessage,
                    }
                } })) : props.rsp && (React.createElement(Label.Data, { flexDirection: "row", title: props.locales.rsp, data: props.rsp })),
            props.stockStatus && React.createElement(Flex_1.Flex, null, props.stockStatus)),
        React.createElement(Flex_1.Flex, { justifyContent: "space-between", alignItems: "flex-end", flexWrap: "wrap", mt: 2 },
            props.productDiscountedPrice && props.productComparisonPrice && !props.isLoggedIn && props.buyable !== types_1.Buyable.Hidden ? (React.createElement(Flex_1.Flex, { flexDirection: "column", pr: 2 },
                React.createElement(Label.Price, { minw: 16, price: props.productComparisonPrice, my: 0, textColor: "grey-3", textDecoration: "line-through", fontSize: 1.75, lineHeight: 1.75 }),
                React.createElement(Label.Price, { minw: 16, price: props.productDiscountedPrice, textColor: "red-1" }))) : (props.price && React.createElement(Label.Price, { minw: 16, price: props.price, pr: 2, textColor: props.buyable === types_1.Buyable.Disabled || (props.isLoggedIn && !props.rsp) ? "grey-3" : "black" })),
            props.notifyMe ?
                React.createElement(Flex_1.Flex, { mt: { _: 0.5, s: 0 } }, props.notifyMe)
                : (props.addToCart ?
                    React.createElement(Flex_1.Flex, { mt: { _: 0.5, s: 0 } }, props.addToCart) :
                    null))));
};
exports.Large = Large;
