import * as React from "react"
import { SimpleModal, Button, Text, TextField, ButtonProps, Flex, Toast } from "@focus-nordic/ui-components"
import { useNotifyMeWhenProductBackInStockLazyQuery, useUnsubscribeFromNotifyMeWhenProductBackInStockLazyQuery } from './operations.generated'
import { EMAIL_VALIDATION_PATTERN, NOTIFY_ME_PRODUCTS_DATA } from "../../constants/index"
import { useLocales, translationNamespace } from "../../hooks"
import { useForm } from "react-hook-form"
import { setYearForward, getTopDomain } from "../../utils"
import { EventEmitter } from "fbemitter"
import Cookies from "js-cookie"

export interface NotifyMeProps extends ButtonProps {
    productId: string
    productName: string
}

interface INotifyMeProductsData {
    [key: string]: []
}

interface INotifyMeSubscriptionEventData {
    productId: string
    isSubscribed: boolean
}

const getNotifyMeProductsDataFromCookie = (cookieValue: string | undefined) => {
    try {
        if (!cookieValue) {
            return {}
        }

        const parsedProductsData = JSON.parse(cookieValue);
    
        if (parsedProductsData) {
          return parsedProductsData;
        }

        return {}
    } catch (error) {
        return {}
    }
}

const setNotifyMeProductsDataToCookie = (notifyMeProductsData: INotifyMeProductsData) => {
    Cookies.set(NOTIFY_ME_PRODUCTS_DATA, JSON.stringify(notifyMeProductsData), {
        domain: getTopDomain(window.location.hostname),
        expires: setYearForward()
    })
}

const emitter = new EventEmitter()
const NOTIFY_ME_SUBSCRIPTION_EVENT = "notifyMeSubscriptionEvent"

const NotifyMe: React.FC<NotifyMeProps> = ({ productId, productName, ...rest }) => {
    const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false)
    const [currentToastMessage, setCurrentToastMessage] = React.useState<string>("")
    const [isSubscribed, setSubscribed] = React.useState<boolean>(false)
    const [lastSubmittedEmail, setLastSubmittedEmail] = React.useState<string>("")
    const emailAddressInputRef = React.useRef() as React.MutableRefObject<HTMLInputElement | null>
    const locales = useLocales(translationNamespace.shared("NotifyMe"), [
		"notifyMeLabel",
        "notifyMeDescription",
        "notifyMeYourEmail",
        "notifyMeSubmitButtonLabel",
        "notifyMeConfirmationMessage",
        "dontNotifyMeLabel",
        "dontNotifyMeConfirmationMessage",
        "notifyMeEmailRequiredLabel",
        "notifyMeInvalidEmailFormatLabel"
	])

    React.useEffect(() => {
        const notifyMeProductsData = getNotifyMeProductsDataFromCookie(Cookies.get(NOTIFY_ME_PRODUCTS_DATA))
        const notifyMeSubscription = emitter.addListener(NOTIFY_ME_SUBSCRIPTION_EVENT, (data: INotifyMeSubscriptionEventData) => {
            if (data.productId === productId) {
                setSubscribed(data.isSubscribed);
            }
        });

        if (notifyMeProductsData?.[productId]) {
            setSubscribed(true)
        }

        return () => {
            notifyMeSubscription.remove();
        };
    }, [productId])

    React.useEffect(() => {
        setTimeout(() => {
            if (isModalVisible && emailAddressInputRef?.current) {
                emailAddressInputRef.current.focus()
            }
        }, 100)
    }, [isModalVisible, emailAddressInputRef])

    const { handleSubmit, register, formState } = useForm<
        {
            emailAddress: string
        }
	>({
        mode: "onChange",
		defaultValues: {
			emailAddress: ""
		}
	})

    const { errors } = formState

    const [notifyMeWhenProductBackInStockLazyQueryRequest, { loading }] = useNotifyMeWhenProductBackInStockLazyQuery({
		onCompleted: (result) => {
            if (result && result.notifyMeWhenProductBackInStock?.success) {
                const notifyMeProductsData = getNotifyMeProductsDataFromCookie(Cookies.get(NOTIFY_ME_PRODUCTS_DATA))

                if (notifyMeProductsData) {
                    notifyMeProductsData[productId] = lastSubmittedEmail
                    setNotifyMeProductsDataToCookie(notifyMeProductsData)
                }
                setLastSubmittedEmail("")
                setSubscribed(true)
                setCurrentToastMessage(locales.notifyMeConfirmationMessage)
                setIsModalVisible(false)
                emitter.emit(NOTIFY_ME_SUBSCRIPTION_EVENT, {
                    productId,
                    isSubscribed: true
                });
            } else {
                setCurrentToastMessage("")
            }
		},
	})

    const [
        unsubscribeFromNotifyMeWhenProductBackInStockLazyQueryRequest, 
        { loading: unsubscribeLoading }
    ] = useUnsubscribeFromNotifyMeWhenProductBackInStockLazyQuery({
        onCompleted: (result) => {
            if (result && result.unsubscribeFromNotifyMeWhenProductBackInStock?.success) {
                const notifyMeProductsData = getNotifyMeProductsDataFromCookie(Cookies.get(NOTIFY_ME_PRODUCTS_DATA))

                if (notifyMeProductsData) {
                    delete notifyMeProductsData[productId]
                    setNotifyMeProductsDataToCookie(notifyMeProductsData)
                }

                setSubscribed(false)
                setCurrentToastMessage(locales.dontNotifyMeConfirmationMessage)
                emitter.emit(NOTIFY_ME_SUBSCRIPTION_EVENT, {
                    productId,
                    isSubscribed: false
                });
            } else {
                setCurrentToastMessage("")
            }
        },
    })

    const submitSendingNotification = (data: any) => {
        setLastSubmittedEmail(data.emailAddress)
        notifyMeWhenProductBackInStockLazyQueryRequest({
            variables: {
                productId,
                emailAddress: data.emailAddress,
                productName,
            },
        })
    }

    const unsubscribeFromSendingNotification = () => {
        const notifyMeProductsData = getNotifyMeProductsDataFromCookie(Cookies.get(NOTIFY_ME_PRODUCTS_DATA))

        if (notifyMeProductsData?.[productId]) {
            unsubscribeFromNotifyMeWhenProductBackInStockLazyQueryRequest({
                variables: {
                    productId,
                    emailAddress: notifyMeProductsData?.[productId],
                    productName,
                },
            })
        }        
    }

    return (
        <>
            <Flex position="relative">
                {isSubscribed ? 
                    <Button 
                        size="small" 
                        icon="check-circle-outlined"
                        disabled={unsubscribeLoading}
                        {...rest}
                        onClick={unsubscribeFromSendingNotification}
                    >
                        {locales.dontNotifyMeLabel}
                    </Button> :
                    <Button 
                        size="small" 
                        icon="bell" 
                        {...rest}
                        onClick={() => setIsModalVisible(true)}
                    >
                        {locales.notifyMeLabel}
                    </Button>
                }
            </Flex>
            <SimpleModal
                isVisible={isModalVisible} 
                heading={locales.notifyMeLabel}
                maxw={{ _: "none", m: 59 }}
                onClose={() => setIsModalVisible(false)}
            >
                <Text mb={3}>
                    {locales.notifyMeDescription}
                </Text>
                <Flex
                    as="form"
                    onSubmit={handleSubmit(submitSendingNotification)}
                    flexDirection="column"
                    w={1}
                >
                    <TextField
                        placeholder={locales.notifyMeYourEmail}
                        type="email"
                        name="emailAddress"
                        fieldSize="small"
                        helperText={
                            errors.emailAddress ? errors.emailAddress.message : undefined
                        }
                        error={Boolean(errors.emailAddress)}
                        inputRef={(el) => {
                            register(el, {
                                required: locales.notifyMeEmailRequiredLabel,
                                pattern: {
                                    value: EMAIL_VALIDATION_PATTERN,
                                    message: locales.notifyMeInvalidEmailFormatLabel,
                                },
                            });
                            emailAddressInputRef.current = el;
                        }}
                    />
                    <Button type="submit" mt={2} stretch={true} disabled={loading}>
                        {locales.notifyMeSubmitButtonLabel}
                    </Button>
                </Flex>
            </SimpleModal>
            {currentToastMessage && (
                <Toast 
                    message={currentToastMessage}
                    onClose={() => setCurrentToastMessage("")}
                />
            )}
        </>
    )
}

export { NotifyMe }