import TagManager from "react-gtm-module"
import { CartItem, CustomerTypeEnum } from "../@types/graphql.generated"

export const trackFormSubmit = (
	formName: string,
	userType: CustomerTypeEnum,
	submitStatus?: "success" | "failure"
) => {
	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			event: "formSubmit",
			formName,
			status: submitStatus,
			type: userType
		}
	})
}

interface TrackPurchaseData {
	userType: CustomerTypeEnum
	orderId: string
	revenue: number
	cartItems: CartItem[]
	tax: number
	shipping: number
}

export const trackPurchase = ({
	userType,
	orderId,
	revenue,
	tax,
	shipping,
	cartItems
}: TrackPurchaseData) => {
	const products = cartItems.map(item => ({
		name: item.product?.name,
		id: item.product?.id,
		price: item.price?.netPrice,
		brand: item.product?.brandName,
		category: item.product?.categories?.join("/"),
		variant: item.product?.name,
		quantity: item.quantity
	}))

	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			ecommerce: null
		}
	})

	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			type: userType,
			event: "purchase",
			ecommerce: {
				currencyCode: cartItems[0]?.price?.currency,
				purchase: {
					actionField: {
						id: orderId,
						affiliation: userType,
						revenue,
						tax,
						shipping
					},
					products
				}
			}
		}
	})
}
