import { PriceInfo } from "../@types/graphql.generated"

export const formatPriceInfo = (
	priceInfo: PriceInfo,
	localeCode: string
): string => {
	const formatter = new Intl.NumberFormat(localeCode, {
		style: "currency",
		currency: priceInfo.currency,
		minimumFractionDigits: 2
	})
	return formatter.format(priceInfo.netPrice)
}

export const formatToCurrency = (priceInfo: PriceInfo, localeCode: string) => {
	const formatter = new Intl.NumberFormat(localeCode, {
		style: "currency",
		currency: priceInfo.currency
	})
	const numberFormat = formatter.formatToParts(priceInfo.netPrice)
	const currency = numberFormat.find(({ type }) => type === "currency")?.value
	return currency
}

export const formatPercentage = (val: number) => `${(val * 100).toFixed()}%`
