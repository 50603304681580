import { BreadcrumbsLink } from "@focus-nordic/ui-components"
import { Link } from "../../@types/graphql.generated"

export const dataToProps = (links: Link[]): BreadcrumbsLink[] =>
	links.map(link => {
		const { title, url } = link

		return {
			id: url,
			title,
			url
		}
	})
