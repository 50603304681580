import { BreadcrumbsLink, Flex, Text, UserControl, ProductCard, OrderStatuses } from "@focus-nordic/ui-components"
import React from "react"
import { useParams } from "react-router"
import { formatDate, formatPriceInfo } from "@focus-nordic/www-client/src/utils"
import { LocalesContext } from "@focus-nordic/www-client/src/context/LocalesContext"
import { useGetOrderOverviewQuery } from "./operations.generated"
import { useLocales, translationNamespace } from "../../../../hooks"
import { Panel } from "../../components"
import { Layout, LayoutProps } from "../../Layout"

interface OrderOverviewViewProps extends LayoutProps {}

type UrlParams = {
	orderId: string
}

export const OrderOverviewView: React.FC<OrderOverviewViewProps> = props => {
	const { localeCode } = React.useContext(LocalesContext)
	const userLocales = useLocales(translationNamespace.shared("User"), [
		"myPagesBillingAddressTitle",
		"myPagesDeliveryAddressTitle"
	]);
	const orderStatusLocales = useLocales(translationNamespace.shared("OrderStatus"), [
		"orderStatusFullyShipped",
		"orderStatusPartiallyShipped",
		"orderStatusInProgress",
		"orderStatusError"
	]);
	const locales = useLocales("myPages_orderOverview", [
		"orderOverviewOrderNumber",
		"orderOverviewOrderDate",
		"orderOverviewPaymentMethod",
		"orderOverviewTotalPrice",
		"orderOverviewVatExcluded",
		"orderOverviewProducts",
		"orderOverviewCopyingFee",
		"orderOverviewEan",
		"orderOverviewArticleNumber",
		"orderOverviewSupplierArticleNumber",
		"orderOverviewOrderedQuantity",
		"orderOverviewDeliveredQuantity",
		"orderOverviewFee",
		"orderOverviewPrice",
		"orderOverviewOrderDetailsTitle"
	]);

	const { orderId } = useParams<UrlParams>()
	const orderOverviewData = useGetOrderOverviewQuery({
		variables: { 
			orderNumber: orderId
		}
	});
	const orderOverview = orderOverviewData.data?.orderOverview;
	const { 
		orderDate, 
		orderNumber, 
		paymentMethod, 
		billingAddress, 
		deliveryAddress, 
		totalPrice, 
		productsPrice, 
		copyFee,
		products
	} = orderOverview || {}

	const getBreadcrumbLinks = React.useCallback(() => {
		return props.breadcrumbsLinks?.map((link: BreadcrumbsLink) => {
			return {
				...link,
				title: link.url.includes(':orderId') ? orderId : link.title
			}
		})
	}, [orderId, props.breadcrumbsLinks])

	return (
		<Layout {...props} breadcrumbsLinks={getBreadcrumbLinks()}>
            <Panel.Title fontWeight={{_: "normal", s: "bold"}} fontSize={{_: 2, s: 4}}>{orderId}</Panel.Title>
			<Flex flexDirection={{_: "column", s: "row"}}>
				<Flex w={{ _: 1, s: 2 / 3 }} pr={{ _: 0, s: 4 }}>
					<Flex p={{_: 2, s: 3}} backgroundColor="white" w={1} flexDirection={{_: "column", s: "row"}}>
						<Flex w={{_: 1, s: 1 / 2}} flexDirection="column" pr={{_: 0, s: 3.75}} mb={{_: 2, s: 0}} borderRight={{_: false, s: true}}>
							<Text display={{ _: "block", s: "none" }} mb={1} textColor="black">{locales.orderOverviewOrderDetailsTitle}</Text>
							<UserControl w={1} mb={{ _: 0, s: 2 }} justifyContent="space-between" flexDirection={{ _: "row", s: "column" }} label={locales.orderOverviewOrderNumber} text={orderNumber} borderBottom={{_: true, s: false}} />
							<UserControl w={1} mb={{ _: 0, s: 2 }} justifyContent="space-between" flexDirection={{ _: "row", s: "column" }} label={locales.orderOverviewOrderDate} text={formatDate(orderDate)} flex={1} />
							<UserControl w={1} mb={{_: 0, s: 2}} justifyContent="space-between" flexDirection={{_: "row", s: "column"}} label={locales.orderOverviewPaymentMethod} text={paymentMethod} />
						</Flex>
						<Flex w={{_: 1, s: 1 / 2}} pl={{_: 0, s: 3.75}} flexDirection="column">
							<UserControl w={1} mb={2} label={userLocales.myPagesBillingAddressTitle}>
								<Text textColor="black" mt={1} mb={0}>{billingAddress?.name}</Text>
								<Text textColor="black" mb={0}>{billingAddress?.addressOne}</Text>
								<Text textColor="black" mb={0}>{billingAddress?.postalCode} {billingAddress?.city} {billingAddress?.country}</Text>
							</UserControl>
							<UserControl w={1} mb={2} label={userLocales.myPagesDeliveryAddressTitle}>
								<Text textColor="black" mt={1} mb={0}>{deliveryAddress?.name}</Text>
								<Text textColor="black" mb={0}>{deliveryAddress?.addressOne}</Text>
								<Text textColor="black" mb={0}>{deliveryAddress?.postalCode} {deliveryAddress?.city} {deliveryAddress?.country}</Text>
							</UserControl>
						</Flex>
					</Flex>
				</Flex>
				<Flex w={{_: 1, s: 1 / 3}} p={{_: 2, s: 3}} mt={{_: 3, s: 0}} backgroundColor="white" flexDirection="column">
					<Flex flexDirection="column" w={1} borderBottom pb={1.25} mb={1.25}>
						<Flex justifyContent="space-between" w={1}>
							<Text textColor="black" fontSize={3} mb={0} mr={1} truncate="singleLine">{locales.orderOverviewTotalPrice}</Text>
							{totalPrice && (
								<Text textColor="black" fontSize={3} mb={0}>{formatPriceInfo(totalPrice, localeCode)}</Text>
							)}
						</Flex>
						<Text as="span" fontSize={1.5} mb={0} textAlign="right">{locales.orderOverviewVatExcluded}</Text>
					</Flex>
					{productsPrice && (
						<Flex justifyContent="space-between">
							<Text as="span" fontSize={1.5} mb={0} mr={2} truncate="singleLine">{locales.orderOverviewProducts}</Text>
							<Text as="span" textColor="black" fontSize={1.5} mb={0}>{formatPriceInfo(productsPrice, localeCode)}</Text>
						</Flex>
					)}
					{copyFee && (
						<Flex justifyContent="space-between">
							<Text as="span" fontSize={1.5} mb={0} mr={2} truncate="singleLine">{locales.orderOverviewCopyingFee}</Text>
							<Text as="span" textColor="black" fontSize={1.5} mb={0}>{formatPriceInfo(copyFee, localeCode)}</Text>
						</Flex>
					)}
				</Flex>
			</Flex>
			{products && products.length > 0 ? (
				<Flex p={{_: 2, s: 3}} mt={{_: 3, s: 4}} backgroundColor="white" flexDirection="column">
					{products.map((product, index) => (
						<Flex display="block" pb={2} mb={index === products.length - 1 ? 0 : 2} borderBottom>
							<ProductCard.OrderItem
								to={product.productPageUrl?.url}
								locales={{
									articleNumber: locales.orderOverviewArticleNumber,
									supplierArticleNumber: locales.orderOverviewSupplierArticleNumber,
									orderedQuantity: locales.orderOverviewOrderedQuantity,
									deliveredQuantity: locales.orderOverviewDeliveredQuantity,
									ean: locales.orderOverviewEan, 
									fee: locales.orderOverviewFee,
									price: locales.orderOverviewPrice,
									orderStatuses: {
										[OrderStatuses.FULLYSHIPPED]: orderStatusLocales.orderStatusFullyShipped,
										[OrderStatuses.PARTIALLYSHIPPED]: orderStatusLocales.orderStatusPartiallyShipped,
										[OrderStatuses.INPROGRESS]: orderStatusLocales.orderStatusInProgress,
										[OrderStatuses.ERROR]: orderStatusLocales.orderStatusError,
									}
								}}
								brandName={product.brandName}
								image={product.productImage}
								name={product.productName}
								articleNumber={product.articleNumber}
								supplierArticleNumber={product.vendorItemNumber}
								ean={product.ean}	
								fee={product.feePerUnit ? formatPriceInfo(product.feePerUnit, localeCode) : ""}
								pricePerUnit={product.pricePerUnit ? formatPriceInfo(product.pricePerUnit, localeCode) : ""}
								orderedQuantity={product.orderedQuantity}
								deliveredQuantity={product.deliveredQuantity}
								totalPrice={product.totalPrice ? formatPriceInfo(product.totalPrice, localeCode) : ""}
							/>
						</Flex>
					))}
				</Flex>
			) : null}
		</Layout>
	)
}
