import * as React from "react"
import { Flex } from "@focus-nordic/ui-components"
import { MegaMenuLevelOne } from "../Header/DektopPrimaryMenu"
import { SITE_MAX_WIDTH } from "../../constants"
import { ProductCategoriesBlock as IProductCategoriesBlock, Menu } from "../../@types/graphql.generated"

const ProductCategoriesBlock: React.FC<IProductCategoriesBlock> = props => {
	return (
		<Flex
			w={1}
			py={{ _: 4, m: 6, l: 8 }}
			px={{ _: 2, m: 6, l: 8 }}
			maxw={SITE_MAX_WIDTH}
			style={{ margin: "0 auto" }}
			flexDirection="column"
		>
			{props.categoryList && props.categoryList.length > 0 && (
				<Flex justifyContent="center">
					<Flex w={1} flexWrap="wrap" alignItems="flex-start">
						<MegaMenuLevelOne menu={props.categoryList as Menu[]} />
					</Flex>
				</Flex>
			)}
		</Flex>
	)
}

export { ProductCategoriesBlock }
