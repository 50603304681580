import { MY_PAGES_ROUTE } from "@focus-nordic/www-common/constants"
import { CheckoutView } from "./views/CheckoutView/CheckoutView"
import { OrderConfirmationView } from "./views/OrderConfirmationView"
import {
	CustomerInformationView,
	OrderHistoryView,
	BackordersListView,
	OrderOverviewView,
	OrderTrackingView,
	UsersOverviewView,
	CustomerLedgerView,
	InvoicesAndCreditMemosView
} from "./views"
import { Layout } from "./Layout"

export interface PageRoute {
	titleTranslationKey: string
	path: string
	component: typeof Layout
	subpages?: PageRoute[]
	useNavigation?: boolean
	excludedCultures?: string[]
}

export const CHECkOUT_PAGE_ROUTE = {
	titleTranslationKey: "checkout_checkoutTitle",
	path: "checkout",
	component: CheckoutView
}

export const ORDER_CONFIRMATION_PAGE_ROUTE = {
	path: "order-confirmation/:orderId",
	titleTranslationKey: "checkout_orderConfirmationPageTitle",
	component: OrderConfirmationView
}

// new myPages routes
export const CUSTOMER_INFORMATION_PAGE_ROUTE = {
	path: "customer-information",
	titleTranslationKey: "customerInformation_customerInformationPageTitle",
	component: CustomerInformationView,
	useNavigation: true
}

export const CUSTOMER_LEDGER_PAGE_ROUTE = {
	path: "customer-ledger",
	titleTranslationKey: "customerLedger_customerLedgerPageTitle",
	component: CustomerLedgerView,
	useNavigation: true
}

export const ORDER_HISTORY_PAGE_ROUTE = {
	path: "order-history",
	titleTranslationKey: "orderHistory_orderHistoryPageTitle",
	component: OrderHistoryView,
	useNavigation: true
}

export const ORDER_OVERVIEW_PAGE_ROUTE = {
	path: ":orderId",
	titleTranslationKey: "orderOverview_orderOverviewPageTitle",
	component: OrderOverviewView,
	useNavigation: true
}

export const BACKORDERS_LIST_PAGE_ROUTE = {
	path: "backorders-list",
	titleTranslationKey: "backordersList_backordersListPageTitle",
	component: BackordersListView,
	useNavigation: true
}

export const ORDER_TRACKING_PAGE_ROUTE = {
	path: "tracking",
	titleTranslationKey: "orderTracking_orderTrackingPageTitle",
	component: OrderTrackingView,
	useNavigation: true
}

export const USERS_OVERVIEW_PAGE_ROUTE = {
	path: "users",
	titleTranslationKey: "usersOverview_usersOverviewPageTitle",
	component: UsersOverviewView,
	useNavigation: true
}

export const INVOICES_AND_CREDIT_MEMOS_PAGE_ROUTE = {
	path: "invoices-and-credit-memos",
	titleTranslationKey: "invoicesAndCreditMemos_invoicesAndCreditMemosPageTitle",
	component: InvoicesAndCreditMemosView,
	useNavigation: true,
	excludedCultures: ["ro-ro"]
}

export const PAGE_ROUTES: PageRoute[] = [
	{
		...CHECkOUT_PAGE_ROUTE,
		subpages: [ORDER_CONFIRMATION_PAGE_ROUTE]
	},
	CUSTOMER_INFORMATION_PAGE_ROUTE,
	CUSTOMER_LEDGER_PAGE_ROUTE,
	{
		...ORDER_HISTORY_PAGE_ROUTE,
		subpages: [ORDER_OVERVIEW_PAGE_ROUTE]
	},
	USERS_OVERVIEW_PAGE_ROUTE,
	BACKORDERS_LIST_PAGE_ROUTE,
	ORDER_TRACKING_PAGE_ROUTE,
	INVOICES_AND_CREDIT_MEMOS_PAGE_ROUTE
]

const getPageTitleTranslationKeys = (
	routes: typeof PAGE_ROUTES,
	titles: string[] = []
): string[] =>
	routes.reduce<string[]>(
		(acc, route) => [
			...acc,
			route.titleTranslationKey,
			...(route.subpages
				? getPageTitleTranslationKeys(route.subpages, acc)
				: [])
		],
		[]
	)

export const PAGE_ROUTES_TITLES: string[] = getPageTitleTranslationKeys(
	PAGE_ROUTES
)

const getPageRoutes = (
	routes: typeof PAGE_ROUTES = PAGE_ROUTES,
	path: string = MY_PAGES_ROUTE
): string[] =>
	routes.reduce<string[]>((acc, route) => {
		const returnRoute = `${path}/${route.path}`
		return [
			...acc,
			returnRoute,
			...(route.subpages ? getPageRoutes(route.subpages, returnRoute) : [])
		]
	}, [])

const ROUTES = getPageRoutes()
export const getPageRoute = (path: string) =>
	ROUTES.find(route => route.endsWith(path))?.split("/:")[0]

// pre-aggregate my-pages page routes
export const MY_PAGES_ROUTES = {
	checkout: getPageRoute(CHECkOUT_PAGE_ROUTE.path)!,
	orderConfirmation: getPageRoute(ORDER_CONFIRMATION_PAGE_ROUTE.path)!
}

export const getLocaleFilteredRoutes = (
	routes: PageRoute[],
	localeCode: string
) => {
	return routes.filter(route => !route.excludedCultures?.includes(localeCode))
}
