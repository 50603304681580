import React from "react"
import { useHistory, useLocation } from "react-router-dom"
import queryString from "query-string"
import Downshift, { DownshiftState, StateChangeOptions } from "downshift"
import { INITIAL_TABLE_ROWS_COUNT } from "../constants"
import { QueryLazyOptions } from '@apollo/client'
import debounce from "lodash/debounce"

type QueryFunction = (
  options?: QueryLazyOptions<any> | undefined
) => void;

export const useSearchQuery  = (queryFunction: QueryFunction, includePagination: boolean) => {
  const history = useHistory()
  const location = useLocation()
  const { searchQuery } = queryString.parse(history.location.search)
  const [searchValue, setSearchValue] = React.useState(searchQuery ? searchQuery.toString() : "")
  const [isInitialRender, setIsInitialRender] = React.useState(true)

  React.useEffect(() => {
    queryFunction({
      variables: { 
          input: {
              ...(includePagination && {
                  pagination: {
                    skip: 0,
                    take: INITIAL_TABLE_ROWS_COUNT
                }
              }),
              ...(searchValue.length && { searchQuery: searchValue })
          }
      }
    })
    setIsInitialRender(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSearch = (searchValue: string) => {
    // Calling the React Router history.replace function causes the component to be remounted
    //history.replace({ search: `?searchQuery=${searchValue}` })

    const newUrl = `${location.pathname}?searchQuery=${encodeURIComponent(searchValue)}`;
    window.history.replaceState({}, document.title, newUrl);

    queryFunction({
      variables: { 
          input: {
            ...(includePagination && {
                pagination: {
                  skip: 0,
                  take: INITIAL_TABLE_ROWS_COUNT
              }
            }),
            searchQuery: searchValue
          }
      }
    })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  const debouncedOnChange = React.useRef<(val: any) => void>(
    debounce(onSearch, 750, { leading: false })
  );

  React.useEffect(() => {
    if (!isInitialRender) {
      debouncedOnChange.current(searchValue);
    }
  }, [searchValue]);

  const stateReducer = (
    state: DownshiftState<unknown>,
    actionAndChanges: StateChangeOptions<unknown>
  ) => {
    const { type } = actionAndChanges;
    switch (type) {
        case Downshift.stateChangeTypes.blurInput:
            return {
                ...state,
                inputValue: state.inputValue
            };
        default:
            return state;
    }
  }

  return { onSearch, onSubmit, stateReducer, searchValue, setSearchValue }
}