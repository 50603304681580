import * as React from "react"
import { Text, Flex, Icon, SimpleModal, SelectableList, SelectableListRefProps, SelectableListItem } from "@focus-nordic/ui-components"
import {
	Customer
} from "../../@types/graphql.generated"
import { useGetUserQuery } from "../../operations/user.generated"
import { useLocales, translationNamespace, useDidUpdateEffect } from "../../hooks"
import Cookies from "js-cookie"
import { COOKIE_CUSTOMER_NUMBER } from "../../constants"
import { setYearForward, getTopDomain } from "../../utils"
import { MY_PAGES_ROUTE } from "@focus-nordic/www-common/constants"

const ChangeCustomer: React.FC = () => {
    const locales = useLocales(translationNamespace.shared("User"), [
		"switchCustomer",
		"searchCustomer",
		"noCustomersFound"
	])

	const user = useGetUserQuery()
	const availableCustomers = user.data?.user?.availableCustomers as SelectableListItem[]
	const currentCustomer = user.data?.user?.currentCustomer as Customer

	const [submittedCustomerId, setSubmittedCustomerId] = React.useState("")
	const [selectedCustomerId, setSelectedCustomerId] = React.useState(currentCustomer?.identifier)
	const [isModalVisible, setIsModalVisible] = React.useState(false)
	const customerListRef = React.useRef<SelectableListRefProps>()

	useDidUpdateEffect(() => {
		const location = window.location

		if (submittedCustomerId) {
			Cookies.set(COOKIE_CUSTOMER_NUMBER, submittedCustomerId, {
				domain: getTopDomain(location.hostname),
				expires: setYearForward()
			})
			
			if (location.pathname.includes(MY_PAGES_ROUTE)) {
				location.replace(MY_PAGES_ROUTE);
			} else {
				location.reload()
			}
		}
	}, [submittedCustomerId])

	React.useEffect(() => {
		if (isModalVisible) {
			setTimeout(() => {
				if (customerListRef?.current?.setInputFocused) {
					customerListRef?.current?.setInputFocused();
				}
			}, 100)
		}
	}, [isModalVisible, customerListRef])

	const onCustomerChange = (customerId: string, submitSelection?: boolean) => {
		setSelectedCustomerId(customerId)
		submitSelection && setSubmittedCustomerId(customerId)
	}

	const openModal = () => {
		setIsModalVisible(true)
	}

	const closeModal = () => {
		setIsModalVisible(false)
		
		if (customerListRef?.current?.clearInputValue) {
			customerListRef?.current?.clearInputValue();
		}

		if (submittedCustomerId) {
			setSelectedCustomerId(submittedCustomerId)
		} else {
			setSelectedCustomerId(currentCustomer?.identifier)
		}
	}

	const submitCustomer = () => {
		setIsModalVisible(false)
		if (selectedCustomerId) {
			setSubmittedCustomerId(selectedCustomerId)
		}
	}

    return (
		<>
			<SimpleModal 
				isVisible={isModalVisible} 
				heading={locales.switchCustomer}
				maxw={{ _: "none", m: 70 }}
				submitButtonLabel="OK"
				closeOnClickOutside={false}
				onClose={closeModal}
				onSubmit={submitCustomer}
			>
				<SelectableList
					ref={customerListRef}
					items={availableCustomers}
					selectedItemId={selectedCustomerId}
					inputPlaceholder={locales.searchCustomer}
					emptyListText={locales.noCustomersFound}
					displayOptionId={true}
					h={30}
					onChange={onCustomerChange}
				/>
			</SimpleModal>
			<Flex
				alignItems="center"
				onClick={openModal}
			>
				<Text.Button 
					display="flex"
					alignItems="center"
					mb={0}
				>
					<Icon
						mb={0}
						size={1.5}
						color="blue-6"
						icon="arrows-switch"
						viewBox="0 0 12 10"
					/>
					<Text 
						display={{ _: "none", s: "block" }}
						as="span" 
						mb={0} 
						ml={0.75} 
						fontSize={1.75} 
						textColor="blue-6"
					>
						{locales.switchCustomer}
					</Text>
				</Text.Button >
			</Flex>
		</>
    )
}

export { ChangeCustomer }