import React from "react"
import { Button, Flex, Icon, Spinner, Text, TextField, colors } from "@focus-nordic/ui-components/dist"
import { translationNamespace, useCart, useIsLoggedIn, useLocales } from "../../hooks"
import { CustomerTypeEnum } from "../../@types/graphql.generated"
import { COOKIE_ACTIVATED_DISCOUNT_CODE } from "../../constants"
import Cookies from "js-cookie"

const DiscountCode: React.FC = () => {
    const isLoggedIn = useIsLoggedIn()
    const [isFormVisible, setFormVisible] = React.useState<Boolean>(false)
    const [discountCode, setDiscountCode] = React.useState<string>('')
    const [isActivatedDiscountCode, setIsActivatedDiscountCode] = React.useState<Boolean>(false)
    const [helperText, setHelperText] = React.useState<string>('')
    const [activationAttempts, setActivationAttempts] = React.useState(0)
    const [discountCodeCantBeApplied, setDiscountCodeCantBeApplied] = React.useState(false)
    const { cart, loading, updateCartWithDiscountCode, discountCodeValue } = useCart()
    const inputRef = React.useRef<HTMLInputElement>()

    const locales = useLocales(translationNamespace.shared("DiscountCode"), [
        "activateCode",
        "discountCodeTitle",
        "discountCodeActivated",
        "discountCodeInvalid",
        "discountCodeCannotBeUsed",
        "addDiscountCode",
        "tooManyAttempts",
    ])

    const commonLocales = useLocales(translationNamespace.shared("Common"), ["add", "close"])

	const appliedDiscountCode = cart.fullOrderDiscountApplied || (cart?.items && cart?.items.some(item => item.discount !== null))

    const handleButtonClick = () => {
        setFormVisible(!isFormVisible)
        if (discountCodeCantBeApplied) {
            setHelperText(locales.discountCodeCannotBeUsed)
            return
        }
        if (!appliedDiscountCode) {
            setDiscountCode('')
            setHelperText('')
            setIsActivatedDiscountCode(false)
        }
    }

    React.useEffect(() => {
        if (isFormVisible && inputRef.current) {
            inputRef.current.focus()
        }
    }, [inputRef, isFormVisible])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHelperText('')
        setIsActivatedDiscountCode(false)
        setDiscountCode(event.target.value.trim())
    }

    const handleActivateCode = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setHelperText('')
        setActivationAttempts(activationAttempts + 1)
        if (activationAttempts < 10 && updateCartWithDiscountCode && !discountCodeCantBeApplied) {
            updateCartWithDiscountCode(
                isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C,
                discountCode
            )
            setIsActivatedDiscountCode(true)
        } else {
            setHelperText(locales.tooManyAttempts)
        }
    }

    React.useEffect(() => {
        if (discountCodeValue && appliedDiscountCode) {
            setDiscountCode(discountCodeValue)
            setFormVisible(true)
        }
    }, [])

    React.useEffect(() => {
        if (!loading) {
            const text =  appliedDiscountCode ? locales.discountCodeActivated : locales.discountCodeInvalid
            !!discountCode && setHelperText(text)
        }
    }, [appliedDiscountCode, loading])

    React.useEffect(() => {
        const discountCodeCannotBeUsed = Boolean(cart?.items?.every(item => item?.product?.discount !== null))
        setDiscountCodeCantBeApplied(discountCodeCannotBeUsed)
        if (discountCodeCannotBeUsed) {
            setHelperText(locales.discountCodeCannotBeUsed)
            setDiscountCode('')
            removeDiscountCode()
        } else if (!discountCode) {
            setHelperText('')
        }
    }, [cart])

    const removeDiscountCode = () => {
        Cookies.remove(COOKIE_ACTIVATED_DISCOUNT_CODE)
    }

    const handleRemoveDiscountCode = () => {
        setDiscountCode('')
        removeDiscountCode()
    }

    return (
        <Flex border p={3} mb={1.25} flexDirection="column">
            <Flex flex={1} justifyContent="space-between">
                <Text
                    as="h6"
                    fontSize={2}
                    fontWeight="bold"
                    textColor="black"
                    mb={0}
                >
                        {locales.discountCodeTitle}
                </Text>
                <Button
                    variant="transparent"
                    p={0}
                    pl={2}
                    onClick={handleButtonClick}
                >
                    {isFormVisible ? commonLocales.close : commonLocales.add}
                </Button>
            </Flex>
            {isFormVisible && (
                <form onSubmit={handleActivateCode}>
                    <Flex flexDirection="column">
                        <Flex my={2} position="relative">
                            <TextField
                                name="discountCode"
                                value={discountCode}
                                error={isActivatedDiscountCode && !loading && (!appliedDiscountCode || activationAttempts > 10) && !!discountCode}
                                helperText={helperText}
                                isValid={!!appliedDiscountCode}
                                onChange={handleChange}
                                placeholder={locales.addDiscountCode}
                                disabled={discountCodeCantBeApplied || appliedDiscountCode}
                                inputRef={inputRef}
                                required
                            />
                            {isActivatedDiscountCode && (
                                <Flex position="absolute" top={15} right={12} backgroundColor="white" zIndex={2} pl={0.5}>
                                    {loading ? <Spinner strokeColor={colors["grey-3"]} /> :
                                        appliedDiscountCode && activationAttempts <= 10 ? 
                                            <Icon icon="check" color="green-2"/> :
                                            !!discountCode && <Icon icon="info-circle" color="red-2"/>
                                    }
                                </Flex>
                            )}
                        </Flex>
                        {appliedDiscountCode && !loading && !discountCodeCantBeApplied && (
                            <Flex mb={2} alignItems="center" border p={1} backgroundColor="grey-10" maxw={31.75} width="max-content">
                                <Flex flexShrink={0}>
                                    <Icon color="grey-4" icon="discount" size={2.5} />
                                </Flex>
                                <Flex ml={0.5} w={1} overflow="hidden">
                                    <Text pl={0.5} mb={0}>{discountCode}</Text>
                                </Flex>
                                <Button variant="transparentBlack" iconPosition="right" icon="close" p={0} onClick={handleRemoveDiscountCode}></Button>
                            </Flex>
                        )}
                        <Button
                            type="submit"
                            disabled={loading || activationAttempts > 10 || discountCodeCantBeApplied || appliedDiscountCode}
                        >
                            {locales.activateCode}
                        </Button>
                    </Flex>
                </form>
            )}
        </Flex>
    )
}

export { DiscountCode }