"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Text = exports.TextAnchorStyled = exports.TextStyled = exports.textStyles = void 0;
var React = __importStar(require("react"));
var styled_components_1 = __importStar(require("styled-components"));
var styled_system_1 = require("styled-system");
var typography_1 = require("../../styles/constants/typography");
var styled_system_2 = require("../../styles/styled-system");
var react_router_dom_1 = require("react-router-dom");
exports.textStyles = styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t", "\n\t", "\n\t", "\n\t", "\n\t", "\n"], ["\n\t", "\n\t", "\n\t", "\n\t", "\n\t",
    "\n"])), styled_system_2.typography, styled_system_1.display, styled_system_1.alignItems, styled_system_1.verticalAlign, styled_system_1.variant({
    prop: "truncate",
    variants: {
        singleLine: {
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        twoLine: {
            overflow: "hidden",
            display: "-webkit-box",
            "-webkit-line-clamp": "2",
            "-webkit-box-orient": "vertical",
            whiteSpace: "normal"
        },
        threeLine: {
            overflow: "hidden",
            display: "-webkit-box",
            "-webkit-line-clamp": "3",
            "-webkit-box-orient": "vertical",
            whiteSpace: "normal"
        },
        none: {
            overflow: "visisble",
            display: "inline",
            "-webkit-line-clamp": "none",
            "-webkit-box-orient": "initial",
            whiteSpace: "normal"
        }
    }
}));
var withConfig = {
    shouldForwardProp: function (prop, defaultValidatorFn) {
        return ![
            "lineHeight",
            "fontWeight",
            "fontSize",
            "display",
            "mr",
            "whitespace",
            "textColor",
            "variant",
            "textDecoration"
        ].includes(prop) && defaultValidatorFn(prop);
    }
};
/**
 * Text element
 */
exports.TextStyled = styled_components_1.default.p.withConfig(withConfig)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), exports.textStyles);
var Text = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? "paragraph-1" : _b, rest = __rest(_a, ["variant"]);
    return React.createElement(exports.TextStyled, __assign({}, typography_1.variants[variant], rest));
};
exports.Text = Text;
/**
 * Link element
 */
var TextLinkStyled = styled_components_1.default(react_router_dom_1.Link).withConfig(withConfig)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), exports.textStyles);
var TextLink = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? "paragraph-1" : _b, rest = __rest(_a, ["variant"]);
    return React.createElement(TextLinkStyled, __assign({}, typography_1.variants[variant], { as: react_router_dom_1.Link }, rest));
};
Text.Link = TextLink;
/**
 * Anchor element
 */
exports.TextAnchorStyled = styled_components_1.default.a(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), exports.textStyles);
var TextAnchor = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? "paragraph-1" : _b, rest = __rest(_a, ["variant"]);
    return React.createElement(exports.TextAnchorStyled, __assign({}, typography_1.variants[variant], { as: "a" }, rest));
};
Text.Anchor = TextAnchor;
/**
 * Button element
 */
var TextButtonStyled = styled_components_1.default.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), exports.textStyles);
var TextButton = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? "paragraph-1" : _b, rest = __rest(_a, ["variant"]);
    return React.createElement(TextButtonStyled, __assign({}, typography_1.variants[variant], { as: "button" }, rest));
};
Text.Button = TextButton;
/**
 * Label element
 */
var TextLabelStyled = styled_components_1.default.label(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), exports.textStyles);
var TextLabel = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? "paragraph-1" : _b, rest = __rest(_a, ["variant"]);
    return React.createElement(TextLabelStyled, __assign({}, typography_1.variants[variant], { as: "label" }, rest));
};
Text.Label = TextLabel;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
