import jwtDecode from "jwt-decode"
import { AccessToken, Tokens } from "@focus-nordic/www-client/src/links"
import { GetCookie, SetCookie, RemoveCookie } from "@focus-nordic/www-common/@types"
import { COOKIE_CUSTOMER_NUMBER } from "@focus-nordic/www-client/src/constants"

export const getTokenExpiration = (accessToken: string): number => {
	try {
		const decodedToken = jwtDecode<AccessToken>(accessToken)
		if (decodedToken.exp && decodedToken.nbf) {
			return decodedToken.exp * 1000 - decodedToken.nbf * 1000
		}
		return 0
	} catch (e) {
		return 0
	}
}

export const getTokensFromCookies = (getCookie: GetCookie) => () => ({
	refreshToken: getCookie("refresh_token"),
	accessToken: getCookie("access_token")
})

export const getCustomerNumberFromCookie = (getCookie: GetCookie, domain: string) => () => getCookie(COOKIE_CUSTOMER_NUMBER, {
	...(domain && { domain })
})

export const removeTokensFromCookies = (removeCookie: RemoveCookie) => () => {
	removeCookie("refresh_token")
	removeCookie("access_token")
}

export const updateTokenCookies = (setCookie: SetCookie) => (
	tokens: Tokens
) => {
	// expiraiton in milliseconds (minus 5min)
	const expiration = getTokenExpiration(tokens.accessToken) - 5 * 60 * 1000
	setCookie("access_token", tokens.accessToken, {
		expires: new Date(new Date().getTime() + expiration)
	})
	setCookie("refresh_token", tokens.refreshToken)
}
