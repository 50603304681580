import * as Types from '../@types/graphql.generated';

import { gql } from '@apollo/client';
export type MenuFieldsFragment = (
  { __typename?: 'menu' }
  & Pick<Types.Menu, 'id' | 'isExternalLink' | 'pageType' | 'title' | 'url'>
);

export const MenuFieldsFragmentDoc = gql`
    fragment menuFields on menu {
  id
  isExternalLink
  pageType
  title
  url
}
    `;