import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type MediaGridBlockFieldsFragment = (
  { __typename?: 'mediaGridBlock' }
  & Pick<Types.MediaGridBlock, 'id' | 'type' | 'title'>
  & { items?: Types.Maybe<Array<(
    { __typename?: 'youtubeEmbed' }
    & Pick<Types.YoutubeEmbed, 'ytId'>
  ) | (
    { __typename?: 'image' }
    & Pick<Types.Image, 'src' | 'alt' | 'height' | 'width'>
  )>> }
);

export const MediaGridBlockFieldsFragmentDoc = gql`
    fragment mediaGridBlockFields on mediaGridBlock {
  id
  type
  title
  items {
    ... on image {
      src
      alt
      height
      width
    }
    ... on youtubeEmbed {
      ytId
    }
  }
}
    `;