"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.border = void 0;
var styled_system_1 = require("styled-system");
var border_1 = require("../utils/border");
var config = {
    borderTop: {
        property: "borderTop",
        transform: border_1.border
    },
    borderRight: {
        property: "borderRight",
        transform: border_1.border
    },
    borderBottom: {
        property: "borderBottom",
        transform: border_1.border
    },
    borderLeft: {
        property: "borderLeft",
        transform: border_1.border
    },
    border: {
        properties: ["borderTop", "borderRight", "borderBottom", "borderLeft"],
        transform: border_1.border
    },
    borderColor: {
        property: "borderColor",
        scale: "borderColors"
    }
};
exports.border = styled_system_1.system(config);
