import React from "react"
import { Button, Flex, scale } from "@focus-nordic/ui-components"
import { MENU_HEIGHT } from "../../../../constants"
import { LocalesContext } from "../../../../context"
import {
	useCart,
	useCheckoutTracking,
	useIsLoggedIn,
	useIsSeller,
	useLocales
} from "../../../../hooks"
import { useGetUserQuery } from "../../../../operations/user.generated"
import { Cart } from "../../../Cart"
import { CartSummary } from "../../../CartSummary"
import { Panel } from "../../components"
import { Layout, LayoutProps } from "../../Layout"
import { useCreateOrderMutation } from "./operations.generated"
import { DeliveryInputs } from "./DeliveryInputs"
import { InvoiceInputs } from "./InvoiceInputs"
import { CheckoutProvider, useCheckout } from "./context/CheckoutProvider"
import { CheckoutFormValues } from "./types/CheckoutFormData"
import {
	cartItemToCartItemInput,
	containsOverpricedItems,
	formatPriceInfo,
	trackPurchase
} from "../../../../utils"
import { Redirect } from "react-router"
import { getPageRoute, ORDER_CONFIRMATION_PAGE_ROUTE } from "../../routes"
import {
	GetCartDocument,
	GetCartQuery
} from "../../../../operations/cart.generated"
import {
	CartItem,
	CheckoutInput,
	CustomerTypeEnum
} from "../../../../@types/graphql.generated"
import { netPriceSum } from "../../../Cart/utils"

interface CheckoutViewProps extends LayoutProps {}

export const Content: React.FC<CheckoutViewProps> = props => {
	const locales = useLocales("myPages_checkout", [
		"checkoutSubmitCta",
		"checkoutTitle",
		"orderTitle",
		"invoiceTitle",
		"deliveryTitle",
		"checkoutConsentTitle"
	])
	const { localeCode } = React.useContext(LocalesContext)
	const { cart } = useCart()
	const user = useGetUserQuery()
	const isSeller = useIsSeller()
	const isLoggedIn = useIsLoggedIn()
	const invoiceAddress = user.data?.user?.currentCustomer?.invoiceAddress
	const formRef = React.useRef() as React.RefObject<HTMLFormElement>
	const [hasFormConsent, setHasFormConsent] = React.useState(false)
	const [hasInvalidPrice, setHasInvalidPrice] = React.useState(false)
	const [
		createOrderMutation,
		createOrderMutationResult
	] = useCreateOrderMutation()
	const [trackingCartItems, setTrackingCartItems] = React.useState<CartItem[]>(
		[]
	)

	const { form, checkoutData, isDropShipAddress } = useCheckout()
	const { handleSubmit } = form!

	const onsSubmit = (data: CheckoutFormValues) => {
		const { selectedAddress, countryCode, ...rest } = data
		const selectedOrderAddress = checkoutData?.allOrderAddresses?.find(
			address => address.id === selectedAddress
		)

		const input: CheckoutInput = {
			...rest,
			...(!isDropShipAddress && {
				shippingAddress: {
					name: selectedOrderAddress?.name ?? "",
					addressOne: selectedOrderAddress?.address ?? "",
					postalCode: selectedOrderAddress?.postalCode ?? "",
					city: selectedOrderAddress?.city ?? "",
					country: selectedOrderAddress?.country ?? ""
				}
			}),
			...(cart.items && {
				cartItems: cart.items.map(cartItem => {
					const shouldAddCustomPrice =
						isSeller &&
						isLoggedIn &&
						cartItem.price?.netPrice !== cartItem.product?.price?.netPrice
					return cartItemToCartItemInput(cartItem, shouldAddCustomPrice)
				})
			})
		}

		if (cart.items) {
			setTrackingCartItems(cart.items)
		}

		createOrderMutation({
			variables: {
				input
			},
			update: cache => {
				const previousCart = cache.readQuery<GetCartQuery>({
					query: GetCartDocument
				})
				cache.writeQuery({
					query: GetCartDocument,
					data: {
						...previousCart,
						...(previousCart?.cart && {
							cart: { ...previousCart.cart, items: [] }
						})
					}
				})
			}
		})
	}

	useCheckoutTracking(CustomerTypeEnum.B2B, 1, cart.items)

	React.useEffect(() => {
		if (createOrderMutationResult.data?.createOrder?.__typename === "Order") {
			trackPurchase({
				userType: CustomerTypeEnum.B2B,
				orderId: createOrderMutationResult.data.createOrder.id ?? "",
				revenue: netPriceSum(trackingCartItems),
				cartItems: trackingCartItems,
				tax: 0,
				shipping: 0
			})
		}
	}, [createOrderMutationResult, trackingCartItems])

	React.useEffect(() => {
		setHasInvalidPrice(containsOverpricedItems(cart?.items ?? []))
	}, [cart.items])

	return (
		<Layout {...props}>
			<Flex w={1} flexDirection="column">
				{createOrderMutationResult.data?.createOrder?.__typename ===
					"Order" && (
					<Redirect
						to={`${getPageRoute(ORDER_CONFIRMATION_PAGE_ROUTE.path)}/${
							createOrderMutationResult.data.createOrder.id
						}`}
					/>
				)}
				<Panel.Title>{locales.checkoutTitle}</Panel.Title>
				<Flex
					w={1}
					alignItems="flex-start"
					flexDirection={{ _: "column", l: "row" }}
				>
					<Flex w={1} mr={3} flexDirection="column">
						<Panel flex={1}>
							<Cart.ProductList columnLayoutBreakpoint="xl" shouldInitCart={true} />
						</Panel>
						{Boolean(cart.items?.length) && (
							<>
								{checkoutData && user.data && (
									<Flex
										flexDirection="column"
										ref={formRef}
										as="form"
										onSubmit={handleSubmit(onsSubmit)}
									>
										<Panel.Title>{locales.orderTitle}</Panel.Title>
										<InvoiceInputs invoiceAddress={invoiceAddress} />
										<Panel.Title>{locales.deliveryTitle}</Panel.Title>
										<DeliveryInputs
											orderAddresses={checkoutData.allOrderAddresses}
										/>
									</Flex>
								)}
							</>
						)}
					</Flex>
					{Boolean(cart.items?.length) && (
						<Flex
							w={1}
							maxw={{ l: 38 }}
							position={{ l: "sticky" }}
							top={scale.px(MENU_HEIGHT.desktop + 3)}
						>
							<Panel flexDirection="column" flex={1}>
								<Cart.Summary />

								<CartSummary.PriceRow
									title={locales.invoiceTitle}
									price={formatPriceInfo(
										{ currency: cart.currency, netPrice: 0 },
										localeCode
									)}
								/>
								<Flex py={1.5}>
									<CartSummary.ConsentCheckbox
										onChange={() => setHasFormConsent(!hasFormConsent)}
									/>
									<CartSummary.ConsentLabel
										labelText={locales.checkoutConsentTitle}
									/>
								</Flex>

								<Button
									variant="green"
									type="button"
									disabled={
										!hasFormConsent || createOrderMutationResult.loading || hasInvalidPrice
									}
									onClick={() => {
										formRef.current?.dispatchEvent(
											new Event("submit", { cancelable: true })
										)
									}}
								>
									{locales.checkoutSubmitCta}
								</Button>
							</Panel>
						</Flex>
					)}
				</Flex>
			</Flex>
		</Layout>
	)
}

export const CheckoutView: React.FC<CheckoutViewProps> = props => {
	return (
		<CheckoutProvider>
			<Content {...props} />
		</CheckoutProvider>
	)
}
