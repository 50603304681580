import * as Types from '../../@types/graphql.generated';

import { CommonProductFieldsFragment, ProductRangeFieldsFragment } from '../../operations/product.generated';
import { gql } from '@apollo/client';
import { CommonProductFieldsFragmentDoc, ProductRangeFieldsFragmentDoc } from '../../operations/product.generated';
export type RelatedProductsBlockFieldsFragment = (
  { __typename?: 'relatedProductsBlock' }
  & Pick<Types.RelatedProductsBlock, 'id' | 'type' | 'title' | 'defaultLayout'>
  & { products?: Types.Maybe<Array<(
    { __typename?: 'product' }
    & CommonProductFieldsFragment
  )>> }
);

export const RelatedProductsBlockFieldsFragmentDoc = gql`
    fragment relatedProductsBlockFields on relatedProductsBlock {
  id
  type
  title
  defaultLayout
  products {
    ...commonProductFields
  }
}
    ${CommonProductFieldsFragmentDoc}`;