import * as React from "react"
import { Flex, Text, Icon } from "@focus-nordic/ui-components"
import { SITE_MAX_WIDTH } from "../../constants"
import {
	MediaAssetsBlock as IMediaAssetsBlock,
	BlockType
} from "../../@types/graphql.generated"
import { useLocales } from "../../hooks/useLocales"

const MediaAssetsBlock: React.FC<IMediaAssetsBlock> = props => {
	const locales = useLocales(`Blocks_${BlockType.Mediaassetsblock}`, [
		"download"
	])

	return (
		<Flex id={props.id} as="section" backgroundColor="grey-1" w={1}>
			<Flex
				flexDirection="column"
				w={1}
				py={{ _: 4, m: 8 }}
				px={{ _: 2, m: 8 }}
				maxw={SITE_MAX_WIDTH}
				style={{ margin: "0 auto" }}
			>
				{props.title && (
					<Text
						as="h2"
						mb={{ _: 4, m: 6 }}
						textColor="black"
						fontWeight="bold"
						fontSize={{ _: 3, m: 4 }}
						lineHeight={{ _: 4, m: 5 }}
					>
						{props.title}
					</Text>
				)}
				<Flex flexWrap="wrap" w={1}>
					{props.assets &&
						props.assets.map((a, i) => (
							<Flex flexDirection="column" w={{ _: 1, m: 4 / 12 }} key={i}>
								<Flex alignItems="center" mb={1}>
									<Icon color="black" icon="download" />
									<Text
										fontSize={2.5}
										lineHeight={3.5}
										fontWeight="bold"
										textColor="black"
										ml={1}
										mb={0}
									>
										{a.label}
									</Text>
								</Flex>
								<Text.Anchor href={a.src} textColor="blue-6" ml={4}>
									{locales.download}
								</Text.Anchor>
							</Flex>
						))}
				</Flex>
			</Flex>
		</Flex>
	)
}

export { MediaAssetsBlock }
