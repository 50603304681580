import * as React from "react"
import {
	Flex,
	Text,
	FullscreenImage,
	Image,
	IconLabel
} from "@focus-nordic/ui-components"
import { LinkAnchor } from "../LinkAnchor"
import { SITE_MAX_WIDTH } from "../../constants"
import { imagePath } from "../../utils"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { FullWidthImagePromoBlock as IFullWidthImagePromoBlock } from "../../@types/graphql.generated"

const FullWidthImagePromoBlock: React.FC<IFullWidthImagePromoBlock> = props => {
	const blockDescriptionExists = props.title || props.preamble
	const locales = useLocales(translationNamespace.shared("imageData"), [
		"photographer"
	])

	return (
		<Flex
			id={props.id}
			flexDirection="column"
			py={{
				_: blockDescriptionExists ? 4 : 0,
				m: blockDescriptionExists ? 8 : 0
			}}
			w={1}
			style={{
				backgroundColor: props.blockTheme?.backgroundColor
			}}
		>
			{blockDescriptionExists && (
				<Flex
					w={1}
					maxw={SITE_MAX_WIDTH}
					px={{ _: 2, m: 8 }}
					style={{ margin: "0 auto" }}
				>
					<Flex flexDirection="column" maxw={80} w={1} mb={{ _: 4, m: 6 }}>
						{props.title && (
							<Text variant="headline-4" as="h2" mb={{ _: 3, m: 4 }}>
								<span style={{ color: props.blockTheme?.textColor }}>
									{props.title}
								</span>
							</Text>
						)}
						{props.preamble && (
							<Text>
								<span style={{ color: props.blockTheme?.textColor }}>
									{props.preamble}
								</span>
							</Text>
						)}
					</Flex>
				</Flex>
			)}

			{props.cards &&
				props.cards.map((card, i) => {
					const useMirrorLayout =
						i % 2 === 0 ? props.isMirrorLayout : !props.isMirrorLayout

					return (
						<Flex
							flexDirection="column"
							mb={{
								_: 0,
								m: 4,
								l: 0
							}}
							key={i}
						>
							<FullscreenImage
								flexDirection={{ _: "column", l: "row" }}
								media={
									card.image ? (
										<Image
											src={imagePath(card.image.fileName, "3:2=384x256")}
											alt={
												card.image.meta && card.image.meta.alt
													? card.image.meta.alt
													: ""
											}
											srcSet={[
												`${imagePath(
													card.image.fileName,
													"3:2=384x256",
													true
												)}`,
												`${imagePath(card.image.fileName, "3:2=768x512", true)}`
											]}
											responsiveSrcSet={{
												s: [
													`${imagePath(
														card.image.fileName,
														"3:2=768x512",
														true
													)}`,
													`${imagePath(
														card.image.fileName,
														"3:2=1536x1024",
														true
													)}`
												],
												l: [
													`${imagePath(
														card.image.fileName,
														"3:2=1536x1024",
														true
													)}`,
													`${imagePath(
														card.image.fileName,
														"3:2=3072x2048",
														true
													)}`
												]
											}}
										/>
									) : null
								}
								caption={
									card.image?.meta?.author
										? `${locales.photographer} ${card.image?.meta?.author}`
										: undefined
								}
								h={{ _: "auto", l: 85, xl: 120 }}
								py={{ _: 0, l: 4 }}
							>
								<FullscreenImage.Panel
									justifyContent={useMirrorLayout ? "flex-end" : "flex-start"}
									darkTheme={card.isDarkTheme}
									mt={{ m: card.image ? -8 : 0 }}
								>
									{card.title && (
										<Text
											variant="headline-4"
											textColor={card.isDarkTheme ? "white" : "black"}
											mb={0.5}
										>
											{card.title}
										</Text>
									)}

									{card.ctaLink && card.ctaLink?.url && (
										<Flex mt={2.5}>
											{(() => {
												const IconLabelComp = () => (
													<IconLabel
														textColor={card.isDarkTheme ? "white" : "black"}
														iconColor="blue-6"
													>
														{card.ctaLink?.title}
													</IconLabel>
												)
												return card.ctaLink ? (
													<LinkAnchor {...card.ctaLink}>
														<IconLabelComp />
													</LinkAnchor>
												) : null
											})()}
										</Flex>
									)}
								</FullscreenImage.Panel>
							</FullscreenImage>
						</Flex>
					)
				})}
		</Flex>
	)
}

export { FullWidthImagePromoBlock }
