import React from "react"
import { CartContextBtbProvider } from "./CartContextBtb"
import { CartContextBtcProvider } from "./CartContextBtc"
// Todo: investigate why this import path breaks SSR
// import { useIsBtcEnabled, useIsLoggedIn } from "../../hooks"
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn"

export const CartContextProvider: React.FC = props => {
	const isLoggedIn = useIsLoggedIn()

	return isLoggedIn ? (
		<CartContextBtbProvider>{props.children}</CartContextBtbProvider>
	) : (
		<CartContextBtcProvider>{props.children}</CartContextBtcProvider>
	)
}
