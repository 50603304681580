import env from "@beam-australia/react-env"

let loadeApiPromise: Promise<typeof google>

export const loadGoogleMapsApi = (): typeof loadeApiPromise => {
	if (typeof loadeApiPromise === "undefined") {
		const script = document.createElement("script")
		script.type = "text/javascript"
		script.src = `https://maps.googleapis.com/maps/api/js?key=${env(
			"GOOGLE_API_KEY"
		)}&libraries=places`
		document.body.appendChild(script)

		loadeApiPromise = new Promise<typeof google>((resolve, reject) => {
			const errorHandler = () => reject("unable to fetch the Google maps API")

			script.addEventListener("load", e => {
				if (window.CookieControl) {
					resolve(window.google)
				} else {
					errorHandler()
				}
			})
			script.addEventListener("error", e => {
				errorHandler()
			})
		})
	}
	return loadeApiPromise
}
