"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.breakpoint = exports.breakpointMap = exports.breakpoints = void 0;
var styled_components_breakpoint_1 = require("styled-components-breakpoint");
var breakpoints = {
    xxs: 414,
    xs: 468,
    s: 640,
    m: 769,
    l: 968,
    xl: 1240,
    xxl: 1440,
    xxxl: 1600
};
exports.breakpoints = breakpoints;
var breakpoint = styled_components_breakpoint_1.createBreakpoint(__assign({ _: 0 }, breakpoints));
exports.breakpoint = breakpoint;
var breakpointMap = styled_components_breakpoint_1.createMap(breakpoints);
exports.breakpointMap = breakpointMap;
