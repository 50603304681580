import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type MenuFieldsFragment = (
  { __typename?: 'menu' }
  & Pick<Types.Menu, 'id' | 'isExternalLink' | 'pageType' | 'title' | 'url'>
);

export type GetMenuQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMenuQuery = (
  { __typename?: 'Query' }
  & { mainPrimaryMenu?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'menu' }
    & { children?: Types.Maybe<Array<(
      { __typename?: 'menu' }
      & { children?: Types.Maybe<Array<(
        { __typename?: 'menu' }
        & { children?: Types.Maybe<Array<(
          { __typename?: 'menu' }
          & MenuFieldsFragment
        )>> }
        & MenuFieldsFragment
      )>> }
      & MenuFieldsFragment
    )>> }
    & MenuFieldsFragment
  )>>>, mainSecondaryMenu?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'menu' }
    & MenuFieldsFragment
  )>>> }
);

export const MenuFieldsFragmentDoc = gql`
    fragment menuFields on menu {
  id
  isExternalLink
  pageType
  title
  url
}
    `;
export const GetMenuDocument = gql`
    query GetMenu {
  mainPrimaryMenu: menu(type: MAIN_PRIMARY) {
    ...menuFields
    children {
      ...menuFields
      children {
        ...menuFields
        children {
          ...menuFields
        }
      }
    }
  }
  mainSecondaryMenu: menu(type: MAIN_SECONDARY) {
    ...menuFields
  }
}
    ${MenuFieldsFragmentDoc}`;

/**
 * __useGetMenuQuery__
 *
 * To run a query within a React component, call `useGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMenuQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuQuery, GetMenuQueryVariables>) {
        return Apollo.useQuery<GetMenuQuery, GetMenuQueryVariables>(GetMenuDocument, baseOptions);
      }
export function useGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuQuery, GetMenuQueryVariables>) {
          return Apollo.useLazyQuery<GetMenuQuery, GetMenuQueryVariables>(GetMenuDocument, baseOptions);
        }
export type GetMenuQueryHookResult = ReturnType<typeof useGetMenuQuery>;
export type GetMenuLazyQueryHookResult = ReturnType<typeof useGetMenuLazyQuery>;
export type GetMenuQueryResult = Apollo.QueryResult<GetMenuQuery, GetMenuQueryVariables>;