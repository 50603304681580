"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.overflowWrap = void 0;
var styled_system_1 = require("styled-system");
var configs = {
    overflowWrap: {
        property: "overflowWrap"
    }
};
exports.overflowWrap = styled_system_1.system(configs);
