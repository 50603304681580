import React from "react"
import { IconNotification } from "@focus-nordic/ui-components"
import { useCart } from "../../hooks/useCart"

const Indicator = () => {
	const { cart } = useCart()

	const count = cart?.items?.reduce((acc, item) => acc + item.quantity, 0) ?? 0

	return <IconNotification icon="cart" count={count} />
}

export { Indicator }
