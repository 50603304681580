import * as React from "react"
import { Flex, Text, DescriptionList } from "@focus-nordic/ui-components"
import { SITE_MAX_WIDTH } from "../../constants"
import { DescriptionListBlock as IDescriptionListBlock } from "../../@types/graphql.generated"

const DescriptionListBlock: React.FC<IDescriptionListBlock> = props => {
	return (
		<Flex
			id={props.id}
			w={1}
			flexDirection="column"
			pt={{ _: 3, m: 5 }}
			pb={{ _: 3, m: 6 }}
			px={{ _: 2, m: 8 }}
			maxw={SITE_MAX_WIDTH}
			style={{ margin: "0 auto" }}
		>
			{props.title && (
				<Text variant="headline-4" as="h2" mb={{ _: 3, m: 6 }}>
					{props.title}
				</Text>
			)}
			{props.fields && (
				<DescriptionList>
					{props.fields.map((f, i) => (
						<DescriptionList.Item key={i} term={f.key} details={f.value} />
					))}
				</DescriptionList>
			)}
		</Flex>
	)
}

export { DescriptionListBlock }
