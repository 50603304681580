import { Product } from "../../@types/graphql.generated"
import { SelectOption } from "@focus-nordic/ui-components"

export const dataToProps = (variants: Product[]) =>
	variants.reduce(
		(acc, variants) => [
			{
				...(variants.link && { value: variants.link.url }),
				...(variants.name ? { label: variants.name } : { label: variants.id })
			},
			...acc
		],
		[] as SelectOption[]
	)
