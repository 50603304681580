export const SITE_MAX_WIDTH = 180
export const RTE_CONTENT_MAX_WIDTH = 108
export const RTE_CONTAINER_MAX_WIDTH = 146
export const MAIN_MIN_HEIGHT = "80vh"
export const INITIAL_TABLE_ROWS_COUNT = 24

export const Z_INDEX = {
	anchorLinkListBlock: 3,
	primaryMenuDropDown: 4,
	searchSuggestion: 4,
	primaryMenu: 4,
	ProductRangeFacets: 5,
	CookieController: 6,
	Modal: 7
}

// Cookie keyes
export const CUSTOMER_IDENTIFIER_DISPLAYED = "customerIdentifierDisplayed"
export const NOTIFY_ME_PRODUCTS_DATA = "notifyMeProductsData"

export const MENU_HEIGHT = {
	mobile: 6,
	desktop: 7.5
}

// Validation
export const EMAIL_VALIDATION_PATTERN = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/

export const PRODUCT_ANCHOR_LIST_HEIGHT = 80

// Custom events
export type CookiControlConsentEventDictionary = { consent: boolean }
export type CookieControlConsentEvent = CustomEventInit<
	CookiControlConsentEventDictionary
>

export const consentChange = (eventName: string, consent: boolean) =>
	new CustomEvent<CookiControlConsentEventDictionary>(eventName, {
		detail: { consent }
	})
export const COOKIE_CONTROL_MARKETING_CONSENT =
	"cookieControl:marketing:consent"

// Page Titles
export const TITLE_CLIENT_NAME = "Focus Nordic"
export const TITLE_CHECKOUT = "Checkout"
export const TITLE_MY_PAGES = "My Pages"
export const TITLE_MY_PAGES_CHECKOUT = "My Pages Checkout"

// Cookie keys
export const COOKIE_CUSTOMER_NUMBER = "customerNumber"
export const COOKIE_ACTIVATED_DISCOUNT_CODE = "activatedDiscountCode"

export const PAGE_TYPES = {
	categoryPage: "categoryPage",
	brandPage: "brandPage"
}
