import { SelectOption } from "@focus-nordic/ui-components/dist"
import { CustomerAddress } from "../../../../@types/graphql.generated"

export const dataToProps = (deliveryAddresses: CustomerAddress[]) => {
	return deliveryAddresses.map((deliveryAddress: CustomerAddress) => {
	    const { name, addressOne, addressTwo, postalCode, city, country } = deliveryAddress as CustomerAddress || {}

		return {
            label: `${name}, ${addressOne}, ${addressTwo ? addressTwo + "," : ""} ${postalCode}, ${city}, ${country?.toLowerCase()}`,
            value: JSON.stringify(deliveryAddress)
        }
	}) as SelectOption[]
}