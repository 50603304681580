import * as React from "react"
import { CardLayout } from "../@types/graphql.generated"

interface UiContextValue {
	primaryMobileMenuOpen: boolean
	togglePrimaryMobileMenuOpen: () => any
	activeProductRangeCardLayout?: CardLayout
	setActiveProductRangeCardLayout: React.Dispatch<
		React.SetStateAction<CardLayout | undefined>
	>
}

export const UiContext = React.createContext<UiContextValue>({
	primaryMobileMenuOpen: false,
	togglePrimaryMobileMenuOpen: () => {},
	activeProductRangeCardLayout: CardLayout.Large,
	setActiveProductRangeCardLayout: () => {}
})

export const UiContextProvider: React.FC = props => {
	const [primaryMobileMenuOpen, setPrimaryMobileMenuOpen] = React.useState(
		false
	)

	const [
		activeProductRangeCardLayout,
		setActiveProductRangeCardLayout
	] = React.useState<CardLayout>()

	const togglePrimaryMobileMenuOpen = () =>
		setPrimaryMobileMenuOpen(prev => !prev)

	return (
		<UiContext.Provider
			value={{
				primaryMobileMenuOpen,
				togglePrimaryMobileMenuOpen,
				activeProductRangeCardLayout,
				setActiveProductRangeCardLayout
			}}
		>
			{props.children}
		</UiContext.Provider>
	)
}
