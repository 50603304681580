import * as React from "react"
import {
	TextMessagesBlock as ITextMessagesBlock,
	Link as ILink
} from "../../@types/graphql.generated"
import {
	Button,
	Flex,
	TextMessage,
	scale,
	breakpoint,
	IconLabel,
	getColorKey,
	ColorKeys,
	ButtonVariant
} from "@focus-nordic/ui-components"
import styled from "styled-components"
import { getLinkTargetAttribute } from "../../utils"
import { LinkAnchor } from "../LinkAnchor"

export interface TextMessagesBlockProps extends ITextMessagesBlock {}

type ThemeStructure = {
	bg: ColorKeys
	title: ColorKeys
	description: ColorKeys
	buttonVariant: ButtonVariant
	linkArrow: ColorKeys
	linkBg: ColorKeys
	linkText: ColorKeys
}

const themeVariants: Partial<Record<ColorKeys, ThemeStructure>> = {
	white: {
		bg: "white",
		title: "black",
		description: "grey-4",
		buttonVariant: "blue",
		linkArrow: "blue-6",
		linkBg: "blue-1",
		linkText: "black"
	},
	"blue-1": {
		bg: "blue-1",
		title: "black",
		description: "grey-4",
		buttonVariant: "blue",
		linkArrow: "blue-6",
		linkBg: "white",
		linkText: "black"
	},
	"blue-3": {
		bg: "blue-3",
		title: "white",
		description: "blue-1",
		buttonVariant: "white",
		linkArrow: "blue-6",
		linkBg: "white",
		linkText: "white"
	}
}

const defaultTheme: ThemeStructure = {
	bg: "white",
	title: "black",
	description: "grey-4",
	buttonVariant: "blue",
	linkArrow: "blue-6",
	linkBg: "blue-1",
	linkText: "black"
}

const StyledFlex = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: ${scale.px(3)};
	width: 100%;
	max-width: ${scale.px(180)};
	padding: 0 ${scale.px(2)};

	${breakpoint("s")`
		flex-direction: row;
		gap: ${scale.px(4)};
		padding: 0 ${scale.px(5)};
	`};

	${breakpoint("xxl")`
		padding: 0 ${scale.px(8)};
	`};
`

type TextMessagesCTAButtonProps = {
	cta: ILink
	buttonCTA: boolean
	theme: ThemeStructure
}

const TextMessagesCTAButton: React.FC<TextMessagesCTAButtonProps> = React.memo(
	({ cta, buttonCTA, theme }) => {
		return buttonCTA ? (
			<Button.Anchor
				href={cta.url}
				target={getLinkTargetAttribute(cta.target)}
				variant={theme.buttonVariant}
			>
				{cta.title}
			</Button.Anchor>
		) : (
			<LinkAnchor {...cta}>
				<IconLabel
					iconColor={theme.linkArrow}
					textColor={theme.linkText}
					circleBg={theme.linkBg}
				>
					{cta.title}
				</IconLabel>
			</LinkAnchor>
		)
	}
)

const TextMessagesBlock: React.FC<TextMessagesBlockProps> = ({
	buttonCTA,
	blockTheme,
	items
}) => {
	if (!items || !items.length) return null

	const isJustOneItem = items.length === 1
	const colorKey = getColorKey(blockTheme?.backgroundColor) || "white"
	const selectedTheme = themeVariants[colorKey] ?? defaultTheme

	return (
		<Flex
			backgroundColor={selectedTheme?.bg}
			w={1}
			justifyContent="center"
			pt={isJustOneItem ? { _: 4, m: 6 } : { _: 3, m: 5 }}
			pb={{ _: 4, m: 6 }}
		>
			<StyledFlex>
				{items.map((item, i) => (
					<TextMessage
						key={i}
						title={item.title}
						description={item.description}
						titleColor={selectedTheme?.title}
						descriptionColor={selectedTheme?.description}
						size={isJustOneItem ? "large" : "normal"}
					>
						{item?.cta && (
							<TextMessagesCTAButton
								cta={item.cta}
								buttonCTA={buttonCTA}
								theme={selectedTheme}
							/>
						)}
					</TextMessage>
				))}
			</StyledFlex>
		</Flex>
	)
}

export { TextMessagesBlock }
