import * as React from "react"
import {
	GetPageQuery,
	GetPageQueryVariables
} from "../../../operations/page.generated"
import { QueryResult } from "@apollo/client"
import { ExternalScript } from "../../../@types/graphql.generated"

export const useExternalScriptsLoading = (
    pageQueryResult: QueryResult<GetPageQuery, GetPageQueryVariables>
) => {
	React.useEffect(() => {
        const externalScripts = pageQueryResult.data?.page?.externalScripts
        const externalScriptsExist = externalScripts && externalScripts.length > 0;

        if (externalScriptsExist) {
            externalScripts.forEach((script: ExternalScript) => {
                const isLoaded = Array.from(document.getElementsByTagName('script')).some(
                    scriptElement => scriptElement.src === script.scriptUrl
                );
    
                if (!isLoaded) {
                    const scriptElement = document.createElement('script');
                    scriptElement.type = 'text/javascript';
                    scriptElement.src = script.scriptUrl;
                    scriptElement.async = script.asyncScript;
    
                    const targetElement = script.renderInHead ? document.head : document.body
    
                    targetElement.appendChild(scriptElement);
                }
            });
        }
        
        return () => {
            if (externalScriptsExist) {
                externalScripts.forEach((script: ExternalScript) => {
                    const scriptElements = document.querySelectorAll(`script[src="${script.scriptUrl}"]`);
                    scriptElements.forEach(scriptElement => {
                        if (scriptElement instanceof HTMLScriptElement) {
                            scriptElement.remove();
                        }
                    });
                });
            }
        };
    }, [pageQueryResult]);
}
