import React from 'react'
import { Layout, LayoutProps } from '../../Layout'
import { Panel } from '../../components'
import { Flex, Icon, Table, Text,  LinkListDropdown, colors, breakpoint } from '@focus-nordic/ui-components'
import { useGetDocumentsQuery } from './operations.generated'
import { translationNamespace, useLocales } from '../../../../hooks'
import { DocumentModel, DocumentType, Pagination as PaginationProps } from '../../../../@types/graphql.generated'
import { Loader } from '../../../Loader'
import { TableColumn } from 'react-data-table-component'
import { formatDate, formatPriceInfo } from '../../../../utils'
import { Link } from 'react-router-dom'
import { cellStyled, cellWithLinkStyled } from '../../styles'
import { ORDER_HISTORY_PAGE_ROUTE } from '../../routes'
import { MY_PAGES_ROUTE } from '@focus-nordic/www-common/constants'
import styled from 'styled-components'
import { LocalesContext } from '../../../../context'
import { getInvoiceDocumentUrl } from "@focus-nordic/www-common/utils"
import { INITIAL_TABLE_ROWS_COUNT } from '../../../../constants'
import { Pagination } from '../../../Pagination'

interface InvoicesAndCreditMemosViewProps extends LayoutProps { }

type DataRow = Required<DocumentModel>

type ColumnsProps = Omit<DataRow, "__typename">

const ExportLink = styled.a`
	font-size: inherit;
	line-height: inherit;
	text-decoration: none;

	&:hover {
		color: ${colors["blue-6"]};
    text-decoration: underline;
	}
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  margin: 0 10px;
  background-color: white;

  
  ${breakpoint("s")`
      margin: 0;
	`}

  & > div {
    overflow: inherit;
  }
`

export const InvoicesAndCreditMemosView: React.FC<InvoicesAndCreditMemosViewProps> = props => {
  const { data, loading, fetchMore } = useGetDocumentsQuery({
    variables: {
      input: {
        pagination: {
          skip: 0,
          take: INITIAL_TABLE_ROWS_COUNT
        }
      }
    }
  })
  const documents = data?.documents?.documents as ColumnsProps[]
  const pagination = data?.documents?.pagination as PaginationProps
  
  const locales = useLocales("myPages_invoicesAndCreditMemos", [
    "invoicesAndCreditMemosPageTitle",
    "invoicesAndCreditMemosDocumentNumber",
    "invoicesAndCreditMemosOrderNumbers",
    "invoicesAndCreditMemosDocumentType",
    "invoicesAndCreditMemosInvoiceDate",
    "invoicesAndCreditMemosAmountIncludingFees",
    "invoicesAndCreditMemosDueDate",
    "invoicesAndCreditMemosCreditMemo",
    "invoicesAndCreditMemosInvoice",
    "invoicesAndCreditMemosOrders",
    "invoicesAndCreditMemosDataLimitMessage"
  ])

  const sharedLocales = useLocales(translationNamespace.shared("Common"), [
    "noRecordsMessage",
    "showing",
    "of"
  ])

  const { localeCode } = React.useContext(LocalesContext)

  const loadMore = () => {
    if (pagination) {
        fetchMore({
            variables: {
                input: {
                    pagination: {
                        skip: pagination.skip + pagination.take,
                        take: pagination.take,
                    }
                }
            },
        })
    }
  }

  const columns: TableColumn<ColumnsProps>[] = [
    {
      name: locales.invoicesAndCreditMemosDocumentNumber,
      cell: row => (
        <Flex alignItems="center">
          <Icon icon="file-download" color="blue-6" size={2} viewBox='0 0 16 16'/> 
          <Flex ml={1}>
            <Text textColor="blue-6" mb={0} fontSize={1.5}>
              <ExportLink
                href={getInvoiceDocumentUrl(row.documentNumber, row.documentType)}
                target="_blank"
                rel="noreferrer nofollow"
                download
              >
                {row.documentNumber}
              </ExportLink>
            </Text>
          </Flex>
        </Flex>
      ),
      style: cellStyled,
      minWidth: "135px",
      maxWidth: "148px"
    },
    {
      name: locales.invoicesAndCreditMemosOrderNumbers,
      cell: row => {
        if (row.singleOrderNumber) {
          return (
            <Link to={`${MY_PAGES_ROUTE}/${ORDER_HISTORY_PAGE_ROUTE.path}/${row.singleOrderNumber}`}>{row.singleOrderNumber}</Link>
          )
        } else if (row.orderNumbers) {
          const ordersList = row.orderNumbers.map(order => (
            <Link to={`${MY_PAGES_ROUTE}/${ORDER_HISTORY_PAGE_ROUTE.path}/${order}`} key={order}>{order}</Link>
          ))
          return (
            <LinkListDropdown
              text={`${row.orderNumbers.length} ${locales.invoicesAndCreditMemosOrders}`}
              list={ordersList}
            />
          )
        }
      },
      style: cellWithLinkStyled,
      minWidth: "130px",
      maxWidth: "163px"
    },
    {
      name: locales.invoicesAndCreditMemosDocumentType,
      selector: row => {
        const documentTypeLocales = {
          [DocumentType.CreditMemo]: locales.invoicesAndCreditMemosCreditMemo,
          [DocumentType.Invoice]: locales.invoicesAndCreditMemosInvoice
        }
        return documentTypeLocales[row.documentType]
      },
      minWidth: "155px",
      maxWidth: "226px"
    },
    {
      name: locales.invoicesAndCreditMemosInvoiceDate,
      selector: row => formatDate(row.invoiceDate),
      maxWidth: "135px"
    },
    {
      name: locales.invoicesAndCreditMemosAmountIncludingFees,
      selector: row => formatPriceInfo({ netPrice: row.amountIncludingFees, currency: row.currency }, localeCode),
      minWidth: "170px",
      maxWidth: "197px"
    },
    {
      name: locales.invoicesAndCreditMemosDueDate,
      selector: row => formatDate(row.dueDate),
      maxWidth: "123px"
    }
  ]

  return (
    <Layout {...props}>
        <Panel.Title>{locales.invoicesAndCreditMemosPageTitle}</Panel.Title>
        <Panel.Title variant="headline-6" textColor="grey-4" fontWeight="normal" mb={2}>{locales.invoicesAndCreditMemosDataLimitMessage}</Panel.Title>
        <TableWrapper>
          <Table
              columns={columns}
              data={documents}
              noRecordsMessage={sharedLocales.noRecordsMessage}
              progressPending={loading}
              progressComponent={<Loader minh={40}/>}
          />
        </TableWrapper>
        {pagination && (
          <Flex flexDirection="column" mt={7}>
            <Pagination 
                pagination={pagination}
                fetchMore={loadMore}
                disabled={loading}
                py={0}
                mb={2.25}
            />
            {documents.length > 0 &&
                <Text textAlign="center" lineHeight={2.5}>{sharedLocales.showing} {documents.length} {sharedLocales.of} {pagination.count}</Text>
            }
        </Flex>
        )}
    </Layout>
  )
}
