import * as React from "react"
import { Breadcrumbs, Flex } from "@focus-nordic/ui-components"
import { BreadcrumbsBlock as IBreadcrumbsBlock } from "../../@types/graphql.generated"
import { dataToProps } from "./utils"
import { SITE_MAX_WIDTH } from "../../constants"

const BreadcrumbsBlock: React.FC<IBreadcrumbsBlock> = props => (
	<Flex w={1} maxw={SITE_MAX_WIDTH} py={{ _: 1.5 }} px={2}>
		{props.links && <Breadcrumbs links={dataToProps(props.links)} />}
	</Flex>
)

export { BreadcrumbsBlock }
