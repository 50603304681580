import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type BreadcrumbsBlockFieldsFragment = (
  { __typename?: 'breadcrumbsBlock' }
  & Pick<Types.BreadcrumbsBlock, 'id' | 'type'>
  & { links?: Types.Maybe<Array<(
    { __typename?: 'link' }
    & Pick<Types.Link, 'alt' | 'target' | 'title' | 'url'>
  )>> }
);

export const BreadcrumbsBlockFieldsFragmentDoc = gql`
    fragment breadcrumbsBlockFields on breadcrumbsBlock {
  id
  type
  links {
    alt
    target
    title
    url
  }
}
    `;