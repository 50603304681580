import React from "react"
import {
	Flex,
	TextField,
	Text,
	TextFieldProps
} from "@focus-nordic/ui-components/dist"
import { useForm } from "react-hook-form"
import { GeneralInfoFeildsConfig } from "../../utils/fieldsConfig"

// used to extract the return type of useForm register
const useRegisterReturnType = () => useForm()

type Register = ReturnType<typeof useRegisterReturnType>

export interface VatNumberFieldProps extends TextFieldProps {
	register: Register["register"]
	trigger: Register["trigger"]
	getValues: Register["getValues"]
	vatNumberProps: GeneralInfoFeildsConfig['vatNumber'];
	name: string
	prefix?: string
}

const VatNumberField: React.FC<VatNumberFieldProps> = ({
	register,
	trigger,
	getValues,
	vatNumberProps,
	...rest
}) => {
	return (
		<Flex flex={1}>
			{vatNumberProps.props.prefix && <Text mt={2} mb={0} mr={1.5} fontSize={2.25}>{vatNumberProps.props.prefix}</Text>}
			<TextField
				inputRef={register(vatNumberProps.registerOptions)}
				{...vatNumberProps.props}
				{...rest}
			/>
			{vatNumberProps.props.suffix && <Text mt={2} mb={0} ml={1.5} fontSize={2.25}>{vatNumberProps.props.suffix}</Text>}
		</Flex>
	) 
}

export default VatNumberField
