import TagManager from "react-gtm-module"
import { CustomerTypeEnum, Product } from "../../@types/graphql.generated"

export interface TrackData {
	userType: CustomerTypeEnum
	listType: string
	product: Product
	position: number
}

export const trackProductClick = ({
	userType,
	listType,
	product,
	position
}: TrackData) => {
	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			ecommerce: null
		}
	})

	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			event: "productClick",
			type: userType,
			ecommerce: {
				click: {
					actionField: { list: listType },
					products: [
						{
							name: product.name,
							id: product.id,
							price: product.price?.netPrice,
							brand: product.brandName,
							category: product.categories?.join("/"),
							variant: product.name,
							position
						}
					]
				}
			}
		}
	})
}
