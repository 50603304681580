import React from "react"
import { DesktopHeader } from "./DesktopHeader"
import { MobileHeader } from "./MobileHeader"
import { Menu } from "../../@types/graphql.generated"
import { useGetMenuQuery } from "./operations.generated"
import { useGetUserQuery } from "../../operations/user.generated"

const Header: React.FC = () => {
	const query = useGetMenuQuery()
	const user = useGetUserQuery()

	return (
		<>
			{query.loading && user.loading
				? "loading"
				: query.data?.mainPrimaryMenu &&
				  query.data?.mainSecondaryMenu && (
						<header>
							<DesktopHeader
								mainPrimaryMenu={query.data.mainPrimaryMenu}
								mainSecondaryMenu={query.data.mainSecondaryMenu}
								user={user.data?.user}
							/>
							<MobileHeader
								primaryMenu={query.data.mainPrimaryMenu as Menu[]}
								secondaryMenu={query.data.mainSecondaryMenu as Menu[]}
								user={user.data?.user}
							/>
						</header>
				  )}
		</>
	)
}

export { Header }
