import React from "react"
import { Flex, Text, TextField, TextArea } from "@focus-nordic/ui-components"
import { Panel } from "../../components"
import { useLocales } from "../../../../hooks"
import { CustomerAddress } from "../../../../@types/graphql.generated"
import { RteContent } from "../../.."
import { useCheckout } from "./context/CheckoutProvider"
import { useGetUserQuery } from "../../../../operations/user.generated"
import { MAX_LENGTH_INPUT, MAX_LENGTH_TEXT_AREA } from "../../consts"

interface InvoiceInputsProps {
	invoiceAddress?: Pick<
		CustomerAddress,
		"name" | "addressOne" | "addressTwo" | "postalCode" | "city" | "country"
	>
}

const InvoiceInputs: React.FC<InvoiceInputsProps> = props => {
	const { form } = useCheckout()
	const { register, errors } = form!
	const user = useGetUserQuery()
	const invoiceAddress = user.data?.user?.currentCustomer?.invoiceAddress

	const locales = useLocales("myPages_checkout", [
		"invoiceTitle",
		"companyNameTitle",
		"invoiceAddressTitle",
		"invoiceZipCodeTitle",
		"invoiceCityTitle",
		"invoiceCountryTitle",
		"invoiceAddressInfo",
		"orderDetailsTitle",
		"clientReferenceNumberTitle",
		"clientReferencePerson",
		"orderProcessorMessageTitle",
		"sellerMessageTitle"
	])
	return (
		<Panel
			alignItems="flex-start"
			flexDirection={{ _: "column", xl: "row" }}
			flex={1}
			w={1}
		>
			<Flex flexDirection={"column"} flex={1} mr={{ xl: 4 }}>
				<Text variant="headline-6" mb={2}>
					{locales.invoiceTitle}
				</Text>
				<Flex mb={2}>
					<TextField
						label={locales.companyNameTitle}
						disabled
						name="billingAddress.name"
						value={invoiceAddress?.name}
					/>
				</Flex>
				<Flex mb={2}>
					<TextField
						label={locales.invoiceAddressTitle}
						disabled
						name="billingAddress.address"
						value={invoiceAddress?.addressOne}
					/>
				</Flex>
				<Flex flex={1} w={1} flexDirection={{ _: "column", s: "row" }}>
					<Flex mb={2} flex={1} pr={{ s: 2 }}>
						<TextField
							label={locales.invoiceZipCodeTitle}
							disabled
							name="billingAddress.postalCode"
							value={invoiceAddress?.postalCode}
						/>
					</Flex>
					<Flex mb={2} flex={2}>
						<TextField
							label={locales.invoiceCityTitle}
							disabled
							name="billingAddress.city"
							value={invoiceAddress?.city}
						/>
					</Flex>
				</Flex>
				<Flex mb={2}>
					<TextField
						label={locales.invoiceCountryTitle}
						disabled
						name="billingAddress.city"
						value={invoiceAddress?.country}
					/>
				</Flex>
				<Text mb={2}>
					<RteContent content={locales.invoiceAddressInfo} />
				</Text>
			</Flex>
			<Flex flexDirection={"column"} flex={1} justifyContent="start" w={1}>
				<Text variant="headline-6" mb={2}>
					{locales.orderDetailsTitle}
				</Text>
				<Flex mb={2}>
					<TextField
						label={locales.clientReferenceNumberTitle}
						name="externalReferenceNumber"
						error={!!errors.externalReferenceNumber}
						inputRef={register()}
						maxLength={MAX_LENGTH_INPUT}
					/>
				</Flex>
				<Flex mb={2}>
					<TextField
						label={locales.clientReferencePerson}
						name="clientReferencePerson"
						error={!!errors.clientReferencePerson}
						inputRef={register()}
						maxLength={MAX_LENGTH_INPUT}
					/>
				</Flex>
				<Flex mb={2}>
					<TextArea
						label={locales.orderProcessorMessageTitle}
						rows={5}
						name="orderProcessorMessage"
						error={!!errors.orderProcessorMessage}
						maxLength={MAX_LENGTH_TEXT_AREA}
						inputRef={register()}
					/>
				</Flex>
				{user.data?.user?.isSeller && (
					<Flex>
						<TextArea
							label={locales.sellerMessageTitle}
							rows={5}
							name="sellerMessage"
							error={!!errors.sellerMessage}
							maxLength={MAX_LENGTH_TEXT_AREA}
							inputRef={register()}
						/>
					</Flex>
				)}
			</Flex>
		</Panel>
	)
}

export { InvoiceInputs }
