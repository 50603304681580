import * as Types from '../@types/graphql.generated';

import { gql } from '@apollo/client';
export type AllLinkFieldsFragment = (
  { __typename?: 'link' }
  & Pick<Types.Link, 'alt' | 'target' | 'title' | 'url' | 'isExternal'>
);

export const AllLinkFieldsFragmentDoc = gql`
    fragment allLinkFields on link {
  alt
  target
  title
  url
  isExternal
}
    `;