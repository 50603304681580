import * as React from "react"
import { Text, Flex, Symbol, Select } from "@focus-nordic/ui-components"
import { Link } from "react-router-dom"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import {
	dataToProps,
	detectSelectedMarket,
	trackFooterLinkClick
} from "./utils"
import { CustomerTypeEnum, Menu } from "../../@types/graphql.generated"
import { useGetMenuPrimaryQuery, useGetLayoutSettingQuery } from "./operations.generated"
import { FooterSecondary } from "../FooterSecondary"
import { NewsletterForm } from "./NewsletterForm"
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn"

const marketMenuSelectId = "marketMenu-select"

const Footer: React.FC = () => {
	const isLoggedIn = useIsLoggedIn()
	const query = useGetMenuPrimaryQuery()
	//const layoutSettingsQuery = useGetLayoutSettingQuery()
	const [selectedMarket, setSelectedMarket] = React.useState<string>()

	React.useEffect(() => {
		if (query.data) {
			setSelectedMarket(
				detectSelectedMarket(dataToProps(query.data?.marketMenu as Menu[]))
					?.value
			)
		}
	}, [query.data])

	const locales = useLocales(translationNamespace.shared("Footer"), [
		"address",
		"findOnMap",
		"findOnMapLink",
		"orgName",
		"selectRegion"
	])

	const onMarketChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedMarket = e.target?.value
		if (selectedMarket) {
			window.location.href = selectedMarket
		}
	}

	return (
		<>
			<Flex
				backgroundColor="black"
				pt={{ _: 8, m: 11 }}
				pb={{ _: 5, m: 6 }}
			>
				<Flex
					w={1}
					maxw={180}
					px={{ _: 2, s: 5, l: 8 }}
					flexDirection="column"
					style={{ margin: "0 auto" }}
				>
					<Flex flexDirection={{ _: "column", m: "row" }}>
						<Flex
							flexDirection="column"
							w={{ _: 1, m: 2 / 7 }}
							mb={{ _: 5, m: 0 }}
							alignItems={{ _: "center", m: "flex-start" }}
						>
							<Flex mb={3}>
								<Link to="/">
									<Symbol.Logo w={16.75} h={5} palette="white" />
								</Link>
							</Flex>
							<Flex flexDirection="column" mb={3}>
								<Text textColor="white" mb={0} whiteSpace="pre-wrap">
									{locales.address}
								</Text>
							</Flex>

							<Text textColor="grey-3">
								<a
									href={locales.findOnMapLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									{locales.findOnMap}
								</a>
							</Text>
						</Flex>
						<Flex flex="1" flexWrap="wrap" mx={{ _: -2, m: 0 }}>
							{query.data?.footerPrimaryMenu &&
								query.data?.footerPrimaryMenu.map((item, i) => (
									<Flex
										as="ul"
										flexDirection="column"
										w={{ _: 6 / 12, xl: 3 / 12 }}
										mb={{ _: 4, m: 6 }}
										px={{ _: 2, m: 4 }}
										key={i}
									>
										{item?.title && (
											<Flex as="li">
												<Text
													textColor="white"
													variant="headline-5"
													mb={3.5}
												>
													{item?.title}
												</Text>
											</Flex>
										)}
										{item?.children && (
											<Flex as="ul" flexDirection="column">
												{item?.children?.map(child =>
													(() => {
														const isExternalLink = child?.isExternalLink

														return (
															<Flex as="li" key={child.id}>
																{child.url ? (
																	isExternalLink ? (
																		<Text.Anchor
																			href={child.url}
																			rel="noopener noreferrer"
																			textColor="grey-3"
																			target="_blank"
																			mb={1}
																			onClick={() =>
																				trackFooterLinkClick(
																					item.title,
																					child.title,
																					isLoggedIn
																						? CustomerTypeEnum.B2B
																						: CustomerTypeEnum.B2C
																				)
																			}
																		>
																			{child.title}
																		</Text.Anchor>
																	) : (
																		<Text.Link
																			to={child.url}
																			textColor="grey-3"
																			mb={1}
																			onClick={() =>
																				trackFooterLinkClick(
																					item.title,
																					child.title,
																					isLoggedIn
																						? CustomerTypeEnum.B2B
																						: CustomerTypeEnum.B2C
																				)
																			}
																		>
																			{child.title}
																		</Text.Link>
																	)
																) : (
																	<Text mb={1} textColor="grey-3">
																		{child.title}
																	</Text>
																)}
															</Flex>
														)
													})()
												)}
											</Flex>
										)}
									</Flex>
								))}
						</Flex>
					</Flex>
					<Flex
						flexDirection={{ _: "column", m: "row" }}
						alignItems="flex-end"
                        justifyContent="space-between"
                        my={5}
						// justifyContent="space-between"
						// mt={2}
					>
						<Flex flex={1} w={1} maxw={{ m: 7 / 12 }} mb={{ _: 4, m: 0 }}>
							<NewsletterForm />
							{/* {layoutSettingsQuery.data?.layoutSetting?.subscribeForm && (
								<iframe 
									src={layoutSettingsQuery.data.layoutSetting.subscribeForm.formSrc} 
									title={layoutSettingsQuery.data.layoutSetting.subscribeForm.title} 
									style={{ height: layoutSettingsQuery.data.layoutSetting.subscribeForm.formHeight, width: '100%' }}
								></iframe>
							)} */}
						</Flex>
						{query.data?.marketMenu && query.data?.marketMenu.length > 0 && (
							<Flex
								alignItems={{ m: "flex-start" }}
								justifyContent={{ _: "center", m: "space-between" }}
								flexDirection={{ _: "column", m: "row" }}
								flex={1}
								w={1}
								maxw={{ m: 4 / 12 }}
							>
								<Flex flexDirection="column">
									<Text.Label
										textColor="white"
										mb={1}
										whiteSpace="nowrap"
										htmlFor={marketMenuSelectId}
									>
										{locales.selectRegion}
									</Text.Label>
									<Flex w={1}>
										<Select
											variant="small"
											id={marketMenuSelectId}
											value={selectedMarket}
											options={dataToProps(
												query.data?.marketMenu as Menu[]
											)}
											onChange={onMarketChange}
										/>
									</Flex>
								</Flex>
							</Flex>
						)}
					</Flex>
				</Flex>
			</Flex>
			<FooterSecondary />
		</>
	)
}

export { Footer }
