import * as Types from '../../@types/graphql.generated';

import { MenuFieldsFragment } from '../../operations/menu.generated';
import { gql } from '@apollo/client';
import { MenuFieldsFragmentDoc } from '../../operations/menu.generated';
import * as Apollo from '@apollo/client';
export type GetMenuPrimaryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMenuPrimaryQuery = (
  { __typename?: 'Query' }
  & { footerPrimaryMenu?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'menu' }
    & { children?: Types.Maybe<Array<(
      { __typename?: 'menu' }
      & MenuFieldsFragment
    )>> }
    & MenuFieldsFragment
  )>>>, marketMenu?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'menu' }
    & MenuFieldsFragment
  )>>> }
);

export type CreateNewsletterSubscriberMutationVariables = Types.Exact<{
  input: Types.CreateNewsletterSubscriberInput;
}>;


export type CreateNewsletterSubscriberMutation = (
  { __typename?: 'Mutation' }
  & { subscriber?: Types.Maybe<(
    { __typename: 'NewsletterSubscriber' }
    & Pick<Types.NewsletterSubscriber, 'email'>
  ) | (
    { __typename: 'CreateNewsletterSubscriberError' }
    & Pick<Types.CreateNewsletterSubscriberError, 'code' | 'message'>
  )> }
);

export type GetLayoutSettingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLayoutSettingQuery = (
  { __typename?: 'Query' }
  & { layoutSetting?: Types.Maybe<(
    { __typename?: 'layoutSettings' }
    & { subscribeForm?: Types.Maybe<(
      { __typename?: 'iFrameForm' }
      & Pick<Types.IFrameForm, 'title' | 'formSrc' | 'formHeight'>
    )> }
  )> }
);


export const GetMenuPrimaryDocument = gql`
    query GetMenuPrimary {
  footerPrimaryMenu: menu(type: FOOTER_PRIMARY) {
    ...menuFields
    children {
      ...menuFields
    }
  }
  marketMenu: menu(type: REGION) {
    ...menuFields
  }
}
    ${MenuFieldsFragmentDoc}`;

/**
 * __useGetMenuPrimaryQuery__
 *
 * To run a query within a React component, call `useGetMenuPrimaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuPrimaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuPrimaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMenuPrimaryQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuPrimaryQuery, GetMenuPrimaryQueryVariables>) {
        return Apollo.useQuery<GetMenuPrimaryQuery, GetMenuPrimaryQueryVariables>(GetMenuPrimaryDocument, baseOptions);
      }
export function useGetMenuPrimaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuPrimaryQuery, GetMenuPrimaryQueryVariables>) {
          return Apollo.useLazyQuery<GetMenuPrimaryQuery, GetMenuPrimaryQueryVariables>(GetMenuPrimaryDocument, baseOptions);
        }
export type GetMenuPrimaryQueryHookResult = ReturnType<typeof useGetMenuPrimaryQuery>;
export type GetMenuPrimaryLazyQueryHookResult = ReturnType<typeof useGetMenuPrimaryLazyQuery>;
export type GetMenuPrimaryQueryResult = Apollo.QueryResult<GetMenuPrimaryQuery, GetMenuPrimaryQueryVariables>;
export const CreateNewsletterSubscriberDocument = gql`
    mutation CreateNewsletterSubscriber($input: CreateNewsletterSubscriberInput!) {
  subscriber: createNewsletterSubscriber(input: $input) {
    __typename
    ... on NewsletterSubscriber {
      email
    }
    ... on CreateNewsletterSubscriberError {
      code
      message
    }
  }
}
    `;
export type CreateNewsletterSubscriberMutationFn = Apollo.MutationFunction<CreateNewsletterSubscriberMutation, CreateNewsletterSubscriberMutationVariables>;

/**
 * __useCreateNewsletterSubscriberMutation__
 *
 * To run a mutation, you first call `useCreateNewsletterSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsletterSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsletterSubscriberMutation, { data, loading, error }] = useCreateNewsletterSubscriberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewsletterSubscriberMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewsletterSubscriberMutation, CreateNewsletterSubscriberMutationVariables>) {
        return Apollo.useMutation<CreateNewsletterSubscriberMutation, CreateNewsletterSubscriberMutationVariables>(CreateNewsletterSubscriberDocument, baseOptions);
      }
export type CreateNewsletterSubscriberMutationHookResult = ReturnType<typeof useCreateNewsletterSubscriberMutation>;
export type CreateNewsletterSubscriberMutationResult = Apollo.MutationResult<CreateNewsletterSubscriberMutation>;
export type CreateNewsletterSubscriberMutationOptions = Apollo.BaseMutationOptions<CreateNewsletterSubscriberMutation, CreateNewsletterSubscriberMutationVariables>;
export const GetLayoutSettingDocument = gql`
    query GetLayoutSetting {
  layoutSetting {
    subscribeForm {
      title
      formSrc
      formHeight
    }
  }
}
    `;

/**
 * __useGetLayoutSettingQuery__
 *
 * To run a query within a React component, call `useGetLayoutSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLayoutSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLayoutSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLayoutSettingQuery(baseOptions?: Apollo.QueryHookOptions<GetLayoutSettingQuery, GetLayoutSettingQueryVariables>) {
        return Apollo.useQuery<GetLayoutSettingQuery, GetLayoutSettingQueryVariables>(GetLayoutSettingDocument, baseOptions);
      }
export function useGetLayoutSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLayoutSettingQuery, GetLayoutSettingQueryVariables>) {
          return Apollo.useLazyQuery<GetLayoutSettingQuery, GetLayoutSettingQueryVariables>(GetLayoutSettingDocument, baseOptions);
        }
export type GetLayoutSettingQueryHookResult = ReturnType<typeof useGetLayoutSettingQuery>;
export type GetLayoutSettingLazyQueryHookResult = ReturnType<typeof useGetLayoutSettingLazyQuery>;
export type GetLayoutSettingQueryResult = Apollo.QueryResult<GetLayoutSettingQuery, GetLayoutSettingQueryVariables>;