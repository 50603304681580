"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.space = exports.padding = exports.margin = void 0;
var styled_system_1 = require("styled-system");
var calc_1 = require("../utils/calc");
var configs = {};
var transform = function (val) {
    return typeof val === "number" ? calc_1.scale.px(val) : val;
};
configs.margin = {
    m: {
        property: "margin",
        transform: transform
    },
    mt: {
        property: "marginTop",
        transform: transform
    },
    mr: {
        property: "marginRight",
        transform: transform
    },
    mb: {
        property: "marginBottom",
        transform: transform
    },
    ml: {
        property: "marginLeft",
        transform: transform
    },
    mx: {
        properties: ["marginLeft", "marginRight"],
        transform: transform
    },
    my: {
        properties: ["marginTop", "marginBottom"],
        transform: transform
    }
};
configs.padding = {
    p: {
        property: "padding",
        transform: transform
    },
    pt: {
        property: "paddingTop",
        transform: transform
    },
    pr: {
        property: "paddingRight",
        transform: transform
    },
    pb: {
        property: "paddingBottom",
        transform: transform
    },
    pl: {
        property: "paddingLeft",
        transform: transform
    },
    px: {
        properties: ["paddingLeft", "paddingRight"],
        transform: transform
    },
    py: {
        properties: ["paddingTop", "paddingBottom"],
        transform: transform
    }
};
exports.margin = styled_system_1.system(configs.margin);
exports.padding = styled_system_1.system(configs.padding);
exports.space = styled_system_1.compose(exports.margin, exports.padding);
