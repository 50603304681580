import Cookies from "js-cookie"
import * as utils from "@focus-nordic/www-common/utils/tokenCookies"
import { getTopDomain } from "./urlHelpers" 

export const getTokensFromCookies = utils.getTokensFromCookies(Cookies.get)

export const getCustomerNumberFromCookie = (domain: string) => utils.getCustomerNumberFromCookie(Cookies.get, getTopDomain(domain))

export const removeTokensFromCookies = utils.removeTokensFromCookies(
	Cookies.remove
)

export const updateTokenCookies = utils.updateTokenCookies(Cookies.set)
