import * as React from "react"
import { Flex, Spinner, colors, LayoutProps } from "@focus-nordic/ui-components"

const Loader: React.FC<LayoutProps> = props => (
	<Flex w={1} justifyContent="center" alignItems="center" {...props}>
		<Spinner size={5} strokeColor={colors["blue-6"]} />
	</Flex>
)

export { Loader }
