import * as React from "react"
import {
	BlockType,
	CustomerTypeEnum,
	Pages
} from "../../@types/graphql.generated"
import {
	SearchBlockFieldsFragment,
	GetSearchQueryResult
} from "./operations.generated"
import { PageRange } from "../PageRange"
import { useGetSearchQuery } from "./operations.generated"
import { Flex, Tabs, Text, LayoutProps } from "@focus-nordic/ui-components"
import { SITE_MAX_WIDTH } from "../../constants"
import { useLocales, translationNamespace } from "../../hooks"
import { useHistory } from "react-router-dom"
import queryString from "query-string"
import { Loader } from "../Loader"
import { ProductRange } from "../ProductRange"
import { useBlockQueryParams } from "../../hooks"
import { SearchSuggestion } from "../SearchSuggestion"
import { MAIN_MIN_HEIGHT } from "../../constants"
import { trackSearchResults } from "./utils"
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn"

interface SearchBlockProps extends SearchBlockFieldsFragment {}

const TabContainer: React.FC<LayoutProps> = ({ children, ...rest }) => (
	<Flex
		w={1}
		alignItems="center"
		flexDirection="column"
		py={{ _: 4, m: 10 }}
		px={2}
		{...rest}
	>
		<Flex w={1} maxw={SITE_MAX_WIDTH} justifyContent="center">
			{children}
		</Flex>
	</Flex>
)

const SearchResult: React.FC<{
	result: GetSearchQueryResult
	searchTerm: string
}> = props => {
	const isLoggedIn = useIsLoggedIn()
	// add searchResults to array
	const searchResults = [
		...(props.result.data?.productRange
			? [props.result.data?.productRange]
			: []),
		...(props.result.data?.pageRange ? [props.result.data?.pageRange] : [])
	]
	const locales = useLocales(
		translationNamespace.block(BlockType.Searchblock),
		["productRangeTitle", "contentRangeTitle"]
	)

	// This event will fire every time a user gets to the search result page - not optimal but the only option for now
	React.useEffect(() => {
		const count =
			(props.result.data?.productRange?.pagination.count ?? 0) +
			(props.result.data?.pageRange?.pagination.count ?? 0)
		trackSearchResults({
			searchTerm: props.searchTerm,
			searchResultCount: count,
			userType: isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C
		})
	}, [props.result, props.searchTerm, isLoggedIn])

	return (
		<>
			{searchResults.length > 0 ? (
				<Tabs activeTab={searchResults[0]?.__typename}>
					<Flex pb={{ _: 4, m: 6 }}>
						{/* Render tabs */}
						{searchResults.map(
							(range, i) =>
								({
									products: (
										<Flex
											mr={i === searchResults.length - 1 ? 0 : 3}
											key={`tab-products-${props.searchTerm}`}
										>
											<Tabs.Tab id={range.__typename!}>
												<Tabs.Label>
													{locales.productRangeTitle}
													<Tabs.Label fontWeight="normal">
														{` (${props.result.data?.productRange?.pagination.count})`}
													</Tabs.Label>
												</Tabs.Label>
											</Tabs.Tab>
										</Flex>
									),
									pages: (
										<Flex
											mr={i === searchResults.length - 1 ? 0 : 3}
											key={`tab-pages-${props.searchTerm}`}
										>
											<Tabs.Tab id={range.__typename!}>
												<Tabs.Label>
													{locales.contentRangeTitle}
													<Tabs.Label fontWeight="normal">
														{` (${props.result.data?.pageRange?.pagination.count})`}
													</Tabs.Label>
												</Tabs.Label>
											</Tabs.Tab>
										</Flex>
									)
								}[range.__typename!])
						)}
					</Flex>
					{/* Render tab panels */}
					{searchResults.map(
						result =>
							({
								products: (
									<Tabs.Panel
										id={result.__typename!}
										w={1}
										key={`tab-panel-${props.searchTerm}`}
									>
										<TabContainer borderTop>
											<ProductRange
												hasPriceListExport={false}
												productRange={props.result.data!.productRange!}
												queryProductRangeArgs={{
													searchInput: {
														searchTerm: props.searchTerm
													}
												}}
											/>
										</TabContainer>
									</Tabs.Panel>
								),
								pages: (
									<Tabs.Panel
										id={result.__typename!}
										key={`tab-panel-page-${props.searchTerm}`}
										w={1}
										flexDirection="column"
									>
										<Flex
											w={1}
											minh={MAIN_MIN_HEIGHT}
											justifyContent="center"
											py={{ _: 4, m: 10 }}
											px={2}
											backgroundColor="blue-1"
										>
											<Flex
												w={1}
												maxw={136}
												alignItems="center"
												flexDirection="column"
											>
												<PageRange
													pages={result as Pages}
													disabled={props.result.loading}
													fetchMore={() => {
														const pagination =
															props.result.data?.pageRange?.pagination

														if (pagination) {
															props.result.fetchMore({
																variables: {
																	withProductRange: false,
																	skip: pagination.take + pagination.skip,
																	take: pagination.take
																}
															})
														}
													}}
												/>
											</Flex>
										</Flex>
									</Tabs.Panel>
								)
							}[result.__typename!])
					)}
				</Tabs>
			) : null}
		</>
	)
}

const Search: React.FC<{ searchTerm: string }> = props => {
	const productListBlockQueryParams = useBlockQueryParams()
	const searchResult = useGetSearchQuery({
		variables: {
			searchInput: { searchTerm: decodeURI(props.searchTerm) },
			facetsInput: productListBlockQueryParams.facetsInput
		},
		notifyOnNetworkStatusChange: true
	})

	// iterate available search results
	return (
		<Flex w={1} alignItems="center" flexDirection="column">
			{searchResult.loading && !searchResult.data ? (
				<Loader mb={25} />
			) : (
				<SearchResult result={searchResult} searchTerm={props.searchTerm} />
			)}
		</Flex>
	)
}

const SearchBlock: React.FC<SearchBlockProps> = props => {
	const { location } = useHistory()
	const searchTerm = (queryString.parse(location.search).q as string) || ""

	const locales = useLocales(
		translationNamespace.block(BlockType.Searchblock),
		["searchTitle"]
	)

	return (
		<Flex w={1} alignItems="center" flexDirection="column">
			<Flex
				w={1}
				pt={{ _: 4, m: 6 }}
				flexDirection="column"
				alignItems="center"
			>
				<Text>{locales.searchTitle}</Text>
				<Flex w={1} maxw={60} mb={4} px={2}>
					<SearchSuggestion key={searchTerm} initialValue={searchTerm} />
				</Flex>
				{searchTerm.trim().length > 0 && <Search searchTerm={searchTerm} />}
			</Flex>
		</Flex>
	)
}

export { SearchBlock }
