import { CartContext, CartContextValue } from "../context"
import {
	// useUpdateCartItemMutation,´
	UpdateCartItemMutationVariables
} from "../operations/cart.generated"
import React from "react"

type UpdateCartItem = (options: UpdateCartItemMutationVariables) => any

interface UseCartReturnType extends CartContextValue {
	// updateCartItem: UpdateCartItem
}

/**
 * Retutns a Record of translations by a tranlation lookup key
 * If no mathing translation is found the lookup key is returned as a placeholder
 * @param namespace translation key namespace
 * @param lookup array of lookup keys
 */
export function useCart(): UseCartReturnType {
	const context = React.useContext(CartContext)

	return {
		...context
	}
}
