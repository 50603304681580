import * as Types from '../@types/graphql.generated';

import { gql } from '@apollo/client';
export type AllPaginationFieldsFragment = (
  { __typename?: 'pagination' }
  & Pick<Types.Pagination, 'take' | 'skip' | 'count'>
);

export const AllPaginationFieldsFragmentDoc = gql`
    fragment allPaginationFields on pagination {
  take
  skip
  count
}
    `;