"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Image = exports.ImageImg = exports.ImagePicture = exports.ImageFigure = void 0;
var React = __importStar(require("react"));
var styled_components_1 = __importStar(require("styled-components"));
var styles_1 = require("../../styles");
var styled_system_1 = require("../../styles/styled-system");
exports.ImageFigure = styled_components_1.default.figure(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t",
    "\n"])), function (props) {
    return typeof props.ratio !== "undefined" && styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\theight: 0;\n\t\t\tposition: relative;\n\t\t\t", "\n\t\t"], ["\n\t\t\theight: 0;\n\t\t\tposition: relative;\n\t\t\t", "\n\t\t"])), styled_system_1.ratio);
});
exports.ImagePicture = styled_components_1.default.picture(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", "\n"], ["\n\t",
    "\n"])), function (props) {
    return typeof props.ratio !== "undefined" && styled_components_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\t\tposition: absolute;\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: center;\n\t\t\talign-items: center;\n\t\t\ttop: 0;\n\t\t\tright: 0;\n\t\t\tbottom: 0;\n\t\t\tleft: 0;\n\t\t"], ["\n\t\t\tposition: absolute;\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: center;\n\t\t\talign-items: center;\n\t\t\ttop: 0;\n\t\t\tright: 0;\n\t\t\tbottom: 0;\n\t\t\tleft: 0;\n\t\t"])));
});
exports.ImageImg = styled_components_1.default.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\tdisplay: block;\n\n\t", "\n\t", "\n"], ["\n\tdisplay: block;\n\n\t",
    "\n\t",
    "\n"])), function (props) {
    return typeof props.ratio !== "undefined" && styled_components_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\t\t\theight: auto;\n\t\t\twidth: auto;\n\t\t\tmax-height: 100%;\n\t\t\tmax-width: 100%;\n\t\t\t", "\n\t\t\tposition: absolute;\n\t\t"], ["\n\t\t\theight: auto;\n\t\t\twidth: auto;\n\t\t\tmax-height: 100%;\n\t\t\tmax-width: 100%;\n\t\t\t",
        "\n\t\t\tposition: absolute;\n\t\t"])), function () {
        if (props.height && props.width && props.ratio) {
            var realRatio = props.height / props.width;
            if (realRatio > props.ratio) {
                return "height: 100%;";
            }
            else if (realRatio < props.ratio) {
                return "width: 100%;";
            }
            else {
                return "height: 100%; width: 100%;";
            }
        }
    });
}, function (props) {
    return typeof props.cover !== "undefined" && styled_components_1.css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\t\t\tobject-fit: cover;\n\t\t\twidth: 100%;\n\t\t\theight: 100%;\n\t\t"], ["\n\t\t\tobject-fit: cover;\n\t\t\twidth: 100%;\n\t\t\theight: 100%;\n\t\t"])));
});
var Image = function (_a) {
    var srcSet = _a.srcSet, responsiveSrcSet = _a.responsiveSrcSet, ratio = _a.ratio, _b = _a.loading, loading = _b === void 0 ? "lazy" : _b, rest = __rest(_a, ["srcSet", "responsiveSrcSet", "ratio", "loading"]);
    var dynaamicAspectRatio = rest.height && rest.width && rest.height / rest.width;
    var activeRatio = ratio || dynaamicAspectRatio;
    return (React.createElement(exports.ImageFigure, { ratio: activeRatio },
        React.createElement(exports.ImagePicture, { ratio: activeRatio },
            responsiveSrcSet &&
                Object.keys(responsiveSrcSet)
                    .reverse()
                    .map(function (key, i) { return (React.createElement("source", { key: i, media: "(min-width: " + styles_1.breakpoints[key] + "px)", srcSet: responsiveSrcSet[key].join(", ") })); }),
            srcSet && React.createElement("source", { srcSet: srcSet.join(", ") }),
            React.createElement(exports.ImageImg, __assign({}, rest, { loading: loading, ratio: activeRatio })))));
};
exports.Image = Image;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
