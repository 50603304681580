export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  /** The name scalar represents a valid GraphQL name as specified in the spec and can be used to refer to fields or types. */
  Name: any;
};

export enum AlignmentType {
  Center = 'CENTER',
  Right = 'RIGHT',
  Left = 'LEFT'
}

export type AnchorLinkBlock = Block & {
  __typename?: 'anchorLinkBlock';
  id: Scalars['String'];
  links?: Maybe<Array<Link>>;
  type: BlockType;
};

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER'
}

export type ArticleIntroBlock = Block & {
  __typename?: 'articleIntroBlock';
  articleTitle: Scalars['String'];
  id: Scalars['String'];
  image?: Maybe<ImageBase>;
  preamble?: Maybe<Scalars['String']>;
  publishDate: Scalars['Date'];
  type: BlockType;
};

export type BackorderEntry = {
  __typename?: 'BackorderEntry';
  orderDate: Scalars['DateTime'];
  orderNumber: Scalars['String'];
  productName: Scalars['String'];
  articleNumber: Scalars['String'];
  quantity: Scalars['Int'];
  pricePerUnit: PriceInfo;
  totalPrice: PriceInfo;
  totalFees?: Maybe<PriceInfo>;
  isExpired: Scalars['Boolean'];
  onDemand: Scalars['Boolean'];
  productPageUrl: Link;
  stockStatus?: Maybe<StockStatus>;
};

export type Block = {
  id: Scalars['String'];
  type: BlockType;
};

export type Blocks = TextEditorBlock | ProductListBlock | CategoryLinksBlock | CategoryLinkListBlock | UnknownBlock | BreadcrumbsBlock | CategoryHeroBlock | ProductOverviewBlock | AnchorLinkBlock | DescriptionListBlock | MediaAssetsBlock | MediaGridBlock | RelatedProductsBlock | ArticleIntroBlock | InspirationHeroBlock | HeroBlock | CardPromoBlock | RteBlock | MediaContentBlock | BrandLinkListBlock | FullWidthImagePromoBlock | SearchBlock | CustomerApplicationBlock | FeaturedProductsBlock | GalleryBlock | ProductCategoriesBlock | VideoBlock | TextMessagesBlock;

export enum BlockType {
  Unknown = 'UNKNOWN',
  Texteditorblock = 'TEXTEDITORBLOCK',
  Productlistblock = 'PRODUCTLISTBLOCK',
  Categorylinksblock = 'CATEGORYLINKSBLOCK',
  Categorylinklistblock = 'CATEGORYLINKLISTBLOCK',
  Breadcrumbsblock = 'BREADCRUMBSBLOCK',
  Categoryheroblock = 'CATEGORYHEROBLOCK',
  Mediaassetsblock = 'MEDIAASSETSBLOCK',
  Relatedproductsblock = 'RELATEDPRODUCTSBLOCK',
  Mediagridblock = 'MEDIAGRIDBLOCK',
  Productoverviewblock = 'PRODUCTOVERVIEWBLOCK',
  Anchorlinkblock = 'ANCHORLINKBLOCK',
  Descriptionlistblock = 'DESCRIPTIONLISTBLOCK',
  Heroblock = 'HEROBLOCK',
  Inspirationheroblock = 'INSPIRATIONHEROBLOCK',
  Articleintroblock = 'ARTICLEINTROBLOCK',
  Cardpromoblock = 'CARDPROMOBLOCK',
  Rteblock = 'RTEBLOCK',
  Mediacontentblock = 'MEDIACONTENTBLOCK',
  Fullwidthimagepromoblock = 'FULLWIDTHIMAGEPROMOBLOCK',
  Brandlinklistblock = 'BRANDLINKLISTBLOCK',
  Searchblock = 'SEARCHBLOCK',
  Customerapplicationblock = 'CUSTOMERAPPLICATIONBLOCK',
  Featuredproductsblock = 'FEATUREDPRODUCTSBLOCK',
  Galleryblock = 'GALLERYBLOCK',
  Productcategoriesblock = 'PRODUCTCATEGORIESBLOCK',
  Videoblock = 'VIDEOBLOCK',
  Textmessagesblock = 'TEXTMESSAGESBLOCK'
}

export type Brand = {
  __typename?: 'brand';
  name: Scalars['String'];
  brandId?: Maybe<Scalars['String']>;
  link: Link;
  logosrc: Scalars['String'];
};

export type BrandLinkListBlock = Block & {
  __typename?: 'brandLinkListBlock';
  brandItems?: Maybe<Array<Menu>>;
  id: Scalars['String'];
  type: BlockType;
};

export type BreadcrumbsBlock = Block & {
  __typename?: 'breadcrumbsBlock';
  id: Scalars['String'];
  links?: Maybe<Array<Link>>;
  type: BlockType;
};

export enum Buyable {
  Allowed = 'ALLOWED',
  Disabled = 'DISABLED',
  Hidden = 'HIDDEN'
}

export enum CardLayout {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE'
}

export type CardPromoBlock = Block & {
  __typename?: 'cardPromoBlock';
  blockTheme: ThemeInfo;
  cardPromoTitle: Scalars['String'];
  cards?: Maybe<Array<ReferenceInfo>>;
  id: Scalars['String'];
  preamble?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type Cart = {
  __typename?: 'cart';
  currency: Scalars['String'];
  customerNumber: Scalars['String'];
  id: Scalars['String'];
  items?: Maybe<Array<CartItem>>;
  userId: Scalars['String'];
  freightCost?: Maybe<Scalars['String']>;
};

export type CartItem = {
  __typename?: 'cartItem';
  /** @deprecated Field should not be used , use cart.currency instead */
  currency?: Maybe<Scalars['String']>;
  discount?: Maybe<DiscountModel>;
  /** @deprecated Field sould not be used , use product.fee instead */
  fee?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  lastActiveProductPrice?: Maybe<PriceInfo>;
  margin?: Maybe<Scalars['Float']>;
  price?: Maybe<PriceInfo>;
  priceHasChanged?: Maybe<Scalars['Boolean']>;
  quantity: Scalars['Int'];
  rsp?: Maybe<PriceInfo>;
  sortOrder?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
};

export type CartItemCustomPrice = {
  productIdentifier: Scalars['String'];
  customPrice?: Maybe<Scalars['Decimal']>;
  priceHasChanged?: Maybe<Scalars['Boolean']>;
};

export type CartItemInput = {
  articleNumber: Scalars['String'];
  quantity: Scalars['Int'];
  price?: Maybe<Scalars['Decimal']>;
};

export type CartItemQuantity = {
  articleNumber: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
};

export type CartSummary = {
  __typename?: 'cartSummary';
  currency: Scalars['String'];
  fullOrderDiscountAmount?: Maybe<PriceInfo>;
  fullOrderDiscountApplied: Scalars['Boolean'];
  id: Scalars['String'];
  items?: Maybe<Array<CartItem>>;
  itemsPriceSum?: Maybe<PriceInfo>;
  /** @deprecated Use itemsPriceSum instead. (price can be without VAT) */
  itemsPriceSumIncludingVAT?: Maybe<PriceInfo>;
  vatRate: Scalars['Float'];
};

export type CategoryHeroBlock = Block & {
  __typename?: 'categoryHeroBlock';
  caption?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<ImageBase>;
  preamble?: Maybe<Scalars['String']>;
  readMoreAnchor?: Maybe<Link>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type CategoryItem = CategorySelection | CategoryRootItem;

export type CategoryLinkListBlock = Block & {
  __typename?: 'categoryLinkListBlock';
  categories?: Maybe<Array<CategoryTree>>;
  id: Scalars['String'];
  title: Scalars['String'];
  type: BlockType;
};

export type CategoryLinksBlock = Block & {
  __typename?: 'categoryLinksBlock';
  id: Scalars['String'];
  links?: Maybe<Array<Link>>;
  type: BlockType;
};

export type CategoryRoot = {
  __typename?: 'categoryRoot';
  key: Scalars['String'];
  label: Scalars['String'];
  type: FacetType;
  values: Array<CategoryItem>;
};

export type CategoryRootItem = {
  __typename?: 'categoryRootItem';
  key: Scalars['String'];
  label: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  values: Array<CategorySelection>;
};

export type CategorySelection = {
  __typename?: 'categorySelection';
  count: Scalars['Int'];
  key: Scalars['String'];
  label: Scalars['String'];
  link?: Maybe<Scalars['String']>;
};

export type CategoryTree = {
  __typename?: 'categoryTree';
  alt: Scalars['String'];
  subcategories?: Maybe<Array<CategoryTree>>;
  target: LinkTarget;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type Checkout_CartItemInput = {
  articleNumber: Scalars['String'];
  quantity: Scalars['Int'];
};

export type CheckoutInput = {
  /** Reference number from external system, defined by customer */
  externalReferenceNumber?: Maybe<Scalars['String']>;
  /** Reference person in customer company for the order */
  clientReferencePerson?: Maybe<Scalars['String']>;
  /** Message to order handler from sales person */
  orderProcessorMessage?: Maybe<Scalars['String']>;
  /** Message from the seller */
  sellerMessage?: Maybe<Scalars['String']>;
  /** Indicating free freight */
  freeFreight?: Maybe<Scalars['Boolean']>;
  /** Snapshot of the displayed items */
  cartItems?: Maybe<Array<CartItemInput>>;
  /** Shipping address of order */
  shippingAddress?: Maybe<OrderAddressModelInput>;
  /** Phone notification */
  smsNotification?: Maybe<PhoneNotificationInput>;
  /** Defines full or partial shipping */
  deliveryOption?: Maybe<DeliveryOptionEnum>;
};

export type ChooseCustomer = {
  __typename?: 'ChooseCustomer';
  identifier?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CompanyInformationInput = {
  companyName: Scalars['String'];
  vatNumber: Scalars['String'];
  orgNumber: Scalars['String'];
  customerReferenceNumber?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'country';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type CreateNewsletterSubscriberError = Error & {
  __typename?: 'CreateNewsletterSubscriberError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type CreateNewsletterSubscriberInput = {
  email: Scalars['String'];
  newsletterRecipient?: Maybe<NewsletterRecipient>;
};

export type CreateNewsletterSubscriberResult = NewsletterSubscriber | CreateNewsletterSubscriberError;

export type CreateOrderError = Error & {
  __typename?: 'CreateOrderError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type CreateOrderResultGraphType = CreateOrderError | Order;

export type Customer = {
  __typename?: 'Customer';
  identifier?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  usingDropship: Scalars['Boolean'];
  invoiceAddress?: Maybe<CustomerAddress>;
  deliveryAddresses?: Maybe<Array<Maybe<CustomerAddress>>>;
};

export type CustomerAddress = {
  __typename?: 'CustomerAddress';
  name?: Maybe<Scalars['String']>;
  addressOne?: Maybe<Scalars['String']>;
  addressTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type CustomerApplicationAddressInput = {
  name: Scalars['String'];
  contact: Scalars['String'];
  addressOne: Scalars['String'];
  addressTwo?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
};

export type CustomerApplicationBlock = Block & {
  __typename?: 'customerApplicationBlock';
  countries?: Maybe<Array<Country>>;
  id: Scalars['String'];
  type: BlockType;
};

export type CustomerApplicationInput = {
  companyName: Scalars['String'];
  registrationNumber: Scalars['String'];
  vatRegistrationNumber: Scalars['String'];
  url: Scalars['String'];
  typeOfCompany: Scalars['String'];
  turnoverCurrentYear: Scalars['Decimal'];
  turnoverLastYear: Scalars['Decimal'];
  invoiceAddress: CustomerApplicationAddressInput;
  deliveryAddressSameAsInvoiceAddress: Scalars['Boolean'];
  deliveryAddress?: Maybe<CustomerApplicationAddressInput>;
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  authorizedSignatory: Scalars['String'];
  invoiceEmailAddress: Scalars['String'];
};

export type CustomerApplicationResult = {
  __typename?: 'customerApplicationResult';
  successDescription: Scalars['String'];
  successTitle: Scalars['String'];
};

export type CustomerStatisticsModel = {
  __typename?: 'CustomerStatisticsModel';
  currency?: Maybe<Scalars['String']>;
  customerIdentifier: Scalars['String'];
  currentYearsTurnover: Scalars['Decimal'];
  previousYearsTurnover: Scalars['Decimal'];
  nextPreviousYearsTurnover: Scalars['Decimal'];
  invoicedAmount: Scalars['Decimal'];
  invoicedAmountDue: Scalars['Decimal'];
};

export enum CustomerTypeEnum {
  B2B = 'B2B',
  B2C = 'B2C',
  B2E = 'B2E'
}




export type DeliveryOption = {
  __typename?: 'deliveryOption';
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  price: PriceInfo;
  servicePoints?: Maybe<Array<ServicePoint>>;
};

export enum DeliveryOptionEnum {
  Full = 'FULL',
  Partial = 'PARTIAL'
}

export type DescriptionListBlock = Block & {
  __typename?: 'descriptionListBlock';
  fields?: Maybe<Array<KeyValue>>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type DiscountModel = {
  __typename?: 'DiscountModel';
  comparisonPrice: PriceInfo;
  discountedPrice: PriceInfo;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type DocumentModel = {
  __typename?: 'DocumentModel';
  documentNumber: Scalars['String'];
  singleOrderNumber?: Maybe<Scalars['String']>;
  orderNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  documentType: DocumentType;
  invoiceDate: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  amountIncludingFees: Scalars['Float'];
  currency?: Maybe<Scalars['String']>;
};

export enum DocumentType {
  Invoice = 'INVOICE',
  CreditMemo = 'CREDIT_MEMO'
}

export type Error = {
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ExternalScript = {
  __typename?: 'externalScript';
  asyncScript: Scalars['Boolean'];
  renderInHead: Scalars['Boolean'];
  scriptUrl: Scalars['String'];
};

export type Facet = CategoryRoot | Range | Selection;

export enum FacetType {
  Range = 'RANGE',
  Selection = 'SELECTION',
  Category = 'CATEGORY'
}

export type FeaturedProductsBlock = Block & {
  __typename?: 'featuredProductsBlock';
  featuredProductIds?: Maybe<Scalars['String']>;
  featuredProductsDescription?: Maybe<Scalars['String']>;
  featuredProductsTitle?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  type: BlockType;
  featuredProducts?: Maybe<Array<Product>>;
};

export type File = {
  __typename?: 'file';
  label?: Maybe<Scalars['String']>;
  src: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type FullWidthImagePromoBlock = Block & {
  __typename?: 'fullWidthImagePromoBlock';
  blockTheme: ThemeInfo;
  cards?: Maybe<Array<ReferenceInfo>>;
  id: Scalars['String'];
  isMirrorLayout: Scalars['Boolean'];
  preamble?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type GalleryBlock = Block & {
  __typename?: 'galleryBlock';
  alternativeLayout: Scalars['Boolean'];
  galleryCards?: Maybe<Array<GalleryCard>>;
  id: Scalars['String'];
  type: BlockType;
};

export type GalleryCard = {
  __typename?: 'galleryCard';
  alignmentType: AlignmentType;
  ctaButton?: Maybe<Link>;
  image?: Maybe<ImageBase>;
  imageOpacity?: Maybe<Scalars['Float']>;
  isDarkTheme: Scalars['Boolean'];
  preamble?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type GetBackordersInput = {
  searchQuery?: Maybe<Scalars['String']>;
  pagination?: Maybe<SimplePaginationInput>;
};

export type GetDocumentsInput = {
  searchQuery?: Maybe<Scalars['String']>;
  pagination: SimplePaginationInput;
};

export type GetDocumentsResult = {
  __typename?: 'GetDocumentsResult';
  documents?: Maybe<Array<DocumentModel>>;
  pagination: Pagination;
};

export type GetOrderHistoryInput = {
  searchQuery?: Maybe<Scalars['String']>;
  sorting?: Maybe<OrderHistorySortingInput>;
  filtering?: Maybe<OrderHistoryFilterInput>;
  pagination: SimplePaginationInput;
};

export type GetOrderHistoryResult = {
  __typename?: 'GetOrderHistoryResult';
  orders?: Maybe<Array<OrderHistoryEntry>>;
  filterParameters: OrderHistoryFilterParameters;
  totalOrders: Scalars['Int'];
  pagination: Pagination;
};

export type HeroBlock = Block & {
  __typename?: 'heroBlock';
  alternativeLayout: Scalars['Boolean'];
  /** @deprecated Obsolete property */
  ctaButton?: Maybe<Link>;
  galleryMode: Scalars['Boolean'];
  heroCards?: Maybe<Array<GalleryCard>>;
  /** @deprecated Obsolete property */
  heroTitle: Scalars['String'];
  id: Scalars['String'];
  /** @deprecated Obsolete property */
  image?: Maybe<ImageBase>;
  isDarkTheme: Scalars['Boolean'];
  /** @deprecated Obsolete property */
  isRightAllignment: Scalars['Boolean'];
  /** @deprecated Obsolete property */
  preamble?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type IFrameForm = {
  __typename?: 'iFrameForm';
  formHeight: Scalars['Int'];
  formSrc: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'image';
  alt: Scalars['String'];
  height: Scalars['Int'];
  src: Scalars['String'];
  width: Scalars['Int'];
};

export type ImageBase = {
  __typename?: 'ImageBase';
  fileName: Scalars['String'];
  meta?: Maybe<ImageMeta>;
};

export type ImageMeta = {
  __typename?: 'ImageMeta';
  alt?: Maybe<Scalars['String']>;
  author: Scalars['String'];
};

export type IncomingDelivery = {
  __typename?: 'incomingDelivery';
  incoming?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  confirmed: Scalars['Boolean'];
};

export type InspirationHeroBlock = Block & {
  __typename?: 'inspirationHeroBlock';
  id: Scalars['String'];
  image?: Maybe<ImageBase>;
  imageOpacity?: Maybe<Scalars['Float']>;
  inspirationTitle: Scalars['String'];
  preamble?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type LayoutSettings = {
  __typename?: 'layoutSettings';
  subscribeForm?: Maybe<IFrameForm>;
};

export type Link = {
  __typename?: 'link';
  /** @deprecated Should not be implemented in first palace */
  alt: LinkTarget;
  isExternal: Scalars['Boolean'];
  target: LinkTarget;
  title: Scalars['String'];
  url: Scalars['String'];
};

export enum LinkTarget {
  Self = 'SELF',
  Blank = 'BLANK',
  NoLink = 'NO_LINK'
}

export type MediaAssetsBlock = Block & {
  __typename?: 'mediaAssetsBlock';
  assets?: Maybe<Array<File>>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type MediaContentBlock = Block & {
  __typename?: 'mediaContentBlock';
  blockTheme: ThemeInfo;
  cards?: Maybe<Array<ReferenceInfo>>;
  id: Scalars['String'];
  isMirrorLayout: Scalars['Boolean'];
  preamble?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type MediaGridBlock = Block & {
  __typename?: 'mediaGridBlock';
  id: Scalars['String'];
  items?: Maybe<Array<MediaItem>>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type MediaItem = YoutubeEmbed | Image;

export type Menu = {
  __typename?: 'menu';
  children?: Maybe<Array<Menu>>;
  id: Scalars['Int'];
  isExternalLink: Scalars['Boolean'];
  pageType: Scalars['String'];
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export enum MenuType {
  MainPrimary = 'MAIN_PRIMARY',
  MainSecondary = 'MAIN_SECONDARY',
  FooterPrimary = 'FOOTER_PRIMARY',
  FooterSecondary = 'FOOTER_SECONDARY',
  Region = 'REGION'
}

export type Meta = {
  __typename?: 'meta';
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Subscribing for email newsletters */
  createNewsletterSubscriber?: Maybe<CreateNewsletterSubscriberResult>;
  /** Sends application for becomming a customer */
  customerApplication?: Maybe<CustomerApplicationResult>;
  /**
   * Updates the given item with quantity in the cart, removes if quantity is null,
   * adds if item does not yet exist in the cart.
   */
  updateCartItem?: Maybe<Cart>;
  /** Updates custom price for an existed cart item. Removes custom price if it's null */
  updateCartItemCustomPrice?: Maybe<Cart>;
  /**
   * Updates multiple items with quantity in the cart, removes if quantity is null,
   * adds if item does not yet exist in the cart.
   */
  updateMultipleCartItems?: Maybe<Cart>;
  /** Creates a B2B order */
  createOrder?: Maybe<CreateOrderResultGraphType>;
};


export type MutationCreateNewsletterSubscriberArgs = {
  input: CreateNewsletterSubscriberInput;
};


export type MutationCustomerApplicationArgs = {
  applicationForm: CustomerApplicationInput;
};


export type MutationUpdateCartItemArgs = {
  articleNumber: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
};


export type MutationUpdateCartItemCustomPriceArgs = {
  items: Array<CartItemCustomPrice>;
};


export type MutationUpdateMultipleCartItemsArgs = {
  cartItems: Array<Maybe<CartItemQuantity>>;
};


export type MutationCreateOrderArgs = {
  input: CheckoutInput;
};


export enum NewsletterRecipient {
  Btb = 'BTB',
  Btc = 'BTC'
}

export type NewsletterSubscriber = {
  __typename?: 'NewsletterSubscriber';
  email: Scalars['String'];
  message: Scalars['String'];
};

export type NotifyMeResult = {
  __typename?: 'NotifyMeResult';
  success: Scalars['Boolean'];
};

export type Order = {
  __typename?: 'Order';
  id?: Maybe<Scalars['String']>;
  sent: Scalars['Boolean'];
};

export type Order_CartItem = {
  __typename?: 'order_cartItem';
  id: Scalars['String'];
  price?: Maybe<PriceInfo>;
  quantity: Scalars['Int'];
  sortOrder: Scalars['Int'];
  discount?: Maybe<Product_DiscountModel>;
  /** @deprecated Field should not be used , use cart.currency instead */
  currency?: Maybe<Scalars['String']>;
  /** @deprecated Field sould not be used , use product.fee instead */
  fee?: Maybe<Scalars['Float']>;
  /** @deprecated B2B margin. Ignored for B2C */
  margin?: Maybe<Scalars['Float']>;
  /** @deprecated B2B rsp price. Ignored for B2C */
  rsp?: Maybe<PriceInfo>;
};

export type Order_CartSummary = {
  __typename?: 'order_cartSummary';
  items?: Maybe<Array<Order_CartItem>>;
  id?: Maybe<Scalars['String']>;
  /** @deprecated Use itemsPriceSum instead. (price can be without VAT) */
  itemsPriceSumIncludingVAT?: Maybe<PriceInfo>;
  itemsPriceSum?: Maybe<PriceInfo>;
  fullOrderDiscountAmount?: Maybe<PriceInfo>;
  fullOrderDiscountApplied: Scalars['Boolean'];
  vatRate: Scalars['Float'];
  currency: Scalars['String'];
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  name: Scalars['String'];
  postalCode: Scalars['String'];
  id: Scalars['String'];
};

export type OrderAddressModelInput = {
  name: Scalars['String'];
  addressOne: Scalars['String'];
  addressTwo?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
};

export type OrderAddressNullableModel = {
  __typename?: 'OrderAddressNullableModel';
  name?: Maybe<Scalars['String']>;
  addressOne?: Maybe<Scalars['String']>;
  addressTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type OrderHistoryEntry = {
  __typename?: 'OrderHistoryEntry';
  id: Scalars['String'];
  orderDate: Scalars['DateTime'];
  totalAmount: PriceInfo;
  orderStatus: OrderStatus;
  customerReference?: Maybe<Scalars['String']>;
  customerOrderNumber?: Maybe<Scalars['String']>;
};

export type OrderHistoryFilterInput = {
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  customerReferences?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<OrderStatus>>;
};

export type OrderHistoryFilterParameters = {
  __typename?: 'OrderHistoryFilterParameters';
  customerReferences?: Maybe<Array<Scalars['String']>>;
  orderStatus?: Maybe<Array<OrderStatus>>;
};

export enum OrderHistorySortableBy {
  Date = 'DATE',
  Reference = 'REFERENCE',
  Status = 'STATUS'
}

export type OrderHistorySortingInput = {
  ascending: Scalars['Boolean'];
  sortBy: OrderHistorySortableBy;
};

export type OrderOvervewModel = {
  __typename?: 'OrderOvervewModel';
  orderNumber: Scalars['String'];
  orderDate: Scalars['DateTime'];
  paymentMethod: Scalars['String'];
  billingAddress: OrderAddressNullableModel;
  deliveryAddress: OrderAddressNullableModel;
  totalPrice: PriceInfo;
  productsPrice?: Maybe<PriceInfo>;
  recycleFee?: Maybe<PriceInfo>;
  copyFee?: Maybe<PriceInfo>;
  chemicalFee?: Maybe<PriceInfo>;
  products?: Maybe<Array<OrderProductOverview>>;
};

export type OrderProductOverview = {
  __typename?: 'OrderProductOverview';
  articleNumber: Scalars['String'];
  vendorItemNumber?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  productName: Scalars['String'];
  feePerUnit?: Maybe<PriceInfo>;
  pricePerUnit: PriceInfo;
  orderedQuantity: Scalars['Int'];
  deliveredQuantity: Scalars['Int'];
  totalPrice: PriceInfo;
  isExpired: Scalars['Boolean'];
  onDemand: Scalars['Boolean'];
  productImage?: Maybe<Image>;
  productPageUrl: Link;
};

export enum OrderStatus {
  Inprogress = 'INPROGRESS',
  Partiallyshipped = 'PARTIALLYSHIPPED',
  Fullyshipped = 'FULLYSHIPPED',
  Error = 'ERROR'
}

export type OrderTrackingEntry = {
  __typename?: 'OrderTrackingEntry';
  deliveryDate: Scalars['DateTime'];
  orderNumber: Scalars['String'];
  customerOrderNumber?: Maybe<Scalars['String']>;
  shipper: Scalars['String'];
  trackingNumber: Scalars['String'];
  trackingUrl: Scalars['String'];
};

export type Page = {
  __typename?: 'page';
  blocks?: Maybe<Array<Blocks>>;
  canonicalUrl?: Maybe<Scalars['String']>;
  externalScripts?: Maybe<Array<ExternalScript>>;
  httpStatus: Scalars['Int'];
  /** @deprecated Use httpStatus instead. */
  httpStatusCode: StatusCode;
  id: Scalars['String'];
  meta?: Maybe<Meta>;
  pageHierarchy?: Maybe<Array<PageHierarchy>>;
  redirectUrl?: Maybe<Scalars['String']>;
  uniquePageId: Scalars['String'];
};

export type Pageable = {
  id: Scalars['String'];
  pagination: Pagination;
};

export type PageHierarchy = {
  __typename?: 'pageHierarchy';
  id: Scalars['Int'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type Pages = Pageable & Searchable & {
  __typename?: 'pages';
  id: Scalars['String'];
  pagination: Pagination;
  range?: Maybe<Array<ReferenceInfo>>;
  searchTerm?: Maybe<Scalars['String']>;
};

export type Pagination = {
  __typename?: 'pagination';
  count: Scalars['Int'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type PaginationInput = {
  page?: Maybe<Scalars['Int']>;
  maxSize?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type Payment = {
  __typename?: 'payment';
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
};

export type PaymentaddressInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  addressOne: Scalars['String'];
  postalCode: Scalars['String'];
  city: Scalars['String'];
  mail: Scalars['String'];
  phone?: Maybe<PrefixPhoneInput>;
  addressTwo?: Maybe<Scalars['String']>;
};

export type PaymentInput = {
  cartItems?: Maybe<Array<CartItemInput>>;
  address: PaymentaddressInput;
  invoiceAddress?: Maybe<PaymentaddressInput>;
  paymentSuccessUrl: Scalars['String'];
  paymentFailureUrl: Scalars['String'];
  deliveryOptionId: Scalars['String'];
  zipCode: Scalars['String'];
  servicePointId?: Maybe<Scalars['String']>;
  companyInformation?: Maybe<CompanyInformationInput>;
  customerType?: Maybe<CustomerTypeEnum>;
};

export type PhoneNotificationInput = {
  phone: PrefixPhoneInput;
};

export type PrefixPhoneInput = {
  prefix: Scalars['String'];
  number: Scalars['String'];
};

export type PriceInfo = {
  __typename?: 'priceInfo';
  currency: Scalars['String'];
  netPrice: Scalars['Float'];
};

export type Product = {
  __typename?: 'product';
  accessories?: Maybe<Array<Product>>;
  similarProducts?: Maybe<Array<Product>>;
  variants?: Maybe<Array<Product>>;
  id: Scalars['String'];
  articleNumber: Scalars['String'];
  vendorArticleNumber?: Maybe<Scalars['String']>;
  ean?: Maybe<Scalars['String']>;
  stockStatus?: Maybe<StockStatus>;
  brandName?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  packageSize?: Maybe<Scalars['Int']>;
  forcedPackageSize?: Maybe<Scalars['Boolean']>;
  buyable?: Maybe<Buyable>;
  onDemand?: Maybe<Scalars['Boolean']>;
  label: ProductLabel;
  price?: Maybe<PriceInfo>;
  fee?: Maybe<PriceInfo>;
  discount?: Maybe<Product_DiscountModel>;
  rsp?: Maybe<PriceInfo>;
  margin?: Maybe<Scalars['Float']>;
  availableItemsCount?: Maybe<Scalars['Int']>;
  warranty?: Maybe<Scalars['Int']>;
  showPreviousPrice?: Maybe<Scalars['Boolean']>;
  link: Link;
  images?: Maybe<Array<Image>>;
  mainImage?: Maybe<Image>;
  mainImageSmall?: Maybe<Image>;
  last30DaysLowestPrice?: Maybe<PriceInfo>;
  productPreviousPrice?: Maybe<PriceInfo>;
};

export type Product_DiscountModel = {
  __typename?: 'product_DiscountModel';
  discountedPrice: PriceInfo;
  comparisonPrice: PriceInfo;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type ProductCategoriesBlock = Block & {
  __typename?: 'productCategoriesBlock';
  categoryList?: Maybe<Array<Maybe<Menu>>>;
  id: Scalars['String'];
  type: BlockType;
};

export type ProductCategory = {
  __typename?: 'productCategory';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type ProductHit = QuickLink & {
  __typename?: 'ProductHit';
  link?: Maybe<Link>;
};

export enum ProductLabel {
  None = 'NONE',
  Comingsoon = 'COMINGSOON',
  New = 'NEW',
  Kit = 'KIT',
  Outlet = 'OUTLET',
  Campaign = 'CAMPAIGN',
  Campaignblackfriday = 'CAMPAIGNBLACKFRIDAY',
  Campaigncybermonday = 'CAMPAIGNCYBERMONDAY'
}

export type ProductListBlock = Block & {
  __typename?: 'productListBlock';
  /** @deprecated Not used anymore, remove from frontend before delete */
  brandCategories?: Maybe<Array<ProductCategory>>;
  /** @deprecated Not used anymore, remove from frontend before delete */
  brandId: Scalars['String'];
  categoryName: Scalars['String'];
  featuredProductIds?: Maybe<Scalars['String']>;
  featuredProductsDescription?: Maybe<Scalars['String']>;
  featuredProductsTitle?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  pageType?: Maybe<Scalars['String']>;
  preamble?: Maybe<Scalars['String']>;
  productCategoryId: Scalars['String'];
  productRange?: Maybe<Products>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
  featuredProducts?: Maybe<Array<Product>>;
};


export type ProductListBlockProductRangeArgs = {
  productsInput?: Maybe<ProductsInput>;
  facetsInput?: Maybe<ProductsFacetsInput>;
  paginationInput?: Maybe<PaginationInput>;
  sortInput?: Maybe<SortInput>;
};

export type ProductOverviewBlock = Block & {
  __typename?: 'productOverviewBlock';
  id: Scalars['String'];
  productId?: Maybe<Scalars['String']>;
  readMoreAnchor?: Maybe<Link>;
  type: BlockType;
  variantsAnchor?: Maybe<Link>;
  product: Product;
};

export type Products = Searchable & Pageable & {
  __typename?: 'products';
  brandId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  currentSort: ProductSortOptions;
  defaultCardLayout: CardLayout;
  facets?: Maybe<Array<Facet>>;
  id: Scalars['String'];
  pagination: Pagination;
  productIds?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortOptions: Array<ProductSortOptions>;
  products?: Maybe<Array<Product>>;
};

export type ProductsFacetsInput = {
  selectFacetsInput?: Maybe<Array<SelectFacetInput>>;
  rangeFacetsInput?: Maybe<Array<RangeFacetInput>>;
  categoryFacetsInput?: Maybe<RangeFacetInput>;
};

export type ProductsInput = {
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  campaignListId?: Maybe<Scalars['String']>;
};

export type ProductSortOptions = {
  __typename?: 'productSortOptions';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  cart?: Maybe<Cart>;
  /** B2C cart summary */
  cartSummary?: Maybe<CartSummary>;
  errorpage?: Maybe<Page>;
  /** layout Setting */
  layoutSetting?: Maybe<LayoutSettings>;
  menu?: Maybe<Array<Maybe<Menu>>>;
  page?: Maybe<Page>;
  pageRange?: Maybe<Pages>;
  productRange?: Maybe<Products>;
  searchSuggestions?: Maybe<SearchSuggestions>;
  /** Translations from Umbraco dictionary */
  translations?: Maybe<Array<Translation>>;
  /** Translations for B2C checkout */
  checkoutTranslations?: Maybe<Array<Translation>>;
  /** Translations for B2B MyPages section */
  b2BMyPagesTranslations?: Maybe<Array<Translation>>;
  linkToProductPage?: Maybe<Link>;
  linkToBrandPage?: Maybe<Link>;
  products?: Maybe<Array<Product>>;
  product?: Maybe<Product>;
  relatedProducts?: Maybe<Array<Product>>;
  productStockStatus?: Maybe<StockStatus>;
  productOnDemandAvailability: Scalars['Boolean'];
  brandImage: Scalars['String'];
  images?: Maybe<Array<Image>>;
  mainImage?: Maybe<Image>;
  mainImageSmall?: Maybe<Image>;
  /** Invoices and credit memos of current customer */
  documents?: Maybe<GetDocumentsResult>;
  /** Address for customer which order is can be delivered to */
  allOrderAddresses?: Maybe<Array<OrderAddress>>;
  /** Freight cost of customer */
  freightCost?: Maybe<Scalars['String']>;
  /** Current customer statistics */
  customerStatistics?: Maybe<CustomerStatisticsModel>;
  /** Current customer data */
  customer?: Maybe<Customer>;
  /** Available customers to choose */
  availableCustomers?: Maybe<Array<Maybe<ChooseCustomer>>>;
  /** Information about current user */
  user?: Maybe<User>;
  /** User who have accesst to current customer */
  customerUsers?: Maybe<Array<Maybe<UserRoleModel>>>;
  /**
   * Create an order and initialize payment (Original)
   * @deprecated No longer supported.
   */
  payment?: Maybe<Payment>;
  /** Create B2C order and initialize payment (New, with discount code) */
  b2CPayment?: Maybe<Payment>;
  orderHistory?: Maybe<GetOrderHistoryResult>;
  backorders?: Maybe<Array<BackorderEntry>>;
  orderOverview?: Maybe<OrderOvervewModel>;
  orderTracking?: Maybe<Array<OrderTrackingEntry>>;
  calculateB2CCart?: Maybe<CartSummary>;
  /** Get delivery options from LogTrade */
  deliveryOptions?: Maybe<Array<DeliveryOption>>;
  notifyMeWhenProductBackInStock: NotifyMeResult;
  unsubscribeFromNotifyMeWhenProductBackInStock: UnsubscribeResult;
  /** Get previous price */
  productPreviousPrice?: Maybe<PriceInfo>;
  /**
   * Get last 30 days lowest product price
   * @deprecated Only for Backward compatibility. Delete with next release
   */
  last30DaysLowestPrice?: Maybe<PriceInfo>;
};


export type QueryCartSummaryArgs = {
  customerType?: Maybe<CustomerTypeEnum>;
  cartItems: Array<Maybe<CartItemInput>>;
};


export type QueryErrorpageArgs = {
  code: Scalars['String'];
};


export type QueryMenuArgs = {
  type?: Maybe<MenuType>;
};


export type QueryPageArgs = {
  path: Scalars['String'];
};


export type QueryPageRangeArgs = {
  searchInput?: Maybe<SearchInput>;
  paginationInput?: Maybe<PaginationInput>;
};


export type QueryProductRangeArgs = {
  productsInput?: Maybe<ProductsInput>;
  searchInput?: Maybe<SearchInput>;
  facetsInput?: Maybe<ProductsFacetsInput>;
  paginationInput?: Maybe<PaginationInput>;
  sortInput?: Maybe<SortInput>;
  showCategoryFacetLinks?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchSuggestionsArgs = {
  searchInput: SearchInput;
};


export type QueryLinkToProductPageArgs = {
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
};


export type QueryLinkToBrandPageArgs = {
  brandId?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
};


export type QueryProductsArgs = {
  productIds?: Maybe<Scalars['String']>;
};


export type QueryProductArgs = {
  productId?: Maybe<Scalars['String']>;
};


export type QueryRelatedProductsArgs = {
  productId?: Maybe<Scalars['String']>;
  relatedProductType: Scalars['Int'];
};


export type QueryProductStockStatusArgs = {
  productId?: Maybe<Scalars['String']>;
};


export type QueryProductOnDemandAvailabilityArgs = {
  productId?: Maybe<Scalars['String']>;
};


export type QueryBrandImageArgs = {
  brandId?: Maybe<Scalars['String']>;
};


export type QueryImagesArgs = {
  productId?: Maybe<Scalars['String']>;
};


export type QueryMainImageArgs = {
  productId?: Maybe<Scalars['String']>;
};


export type QueryMainImageSmallArgs = {
  productId?: Maybe<Scalars['String']>;
};


export type QueryDocumentsArgs = {
  input?: Maybe<GetDocumentsInput>;
};


export type QueryPaymentArgs = {
  paymentInput: PaymentInput;
  checkoutUrlOverwrite?: Maybe<Scalars['String']>;
};


export type QueryB2CPaymentArgs = {
  paymentInput: PaymentInput;
  checkoutUrlOverwrite?: Maybe<Scalars['String']>;
  discountCode?: Maybe<Scalars['String']>;
};


export type QueryOrderHistoryArgs = {
  input?: Maybe<GetOrderHistoryInput>;
};


export type QueryBackordersArgs = {
  input?: Maybe<GetBackordersInput>;
};


export type QueryOrderOverviewArgs = {
  orderNumber: Scalars['String'];
};


export type QueryOrderTrackingArgs = {
  numberOfMonths?: Scalars['Int'];
};


export type QueryCalculateB2CCartArgs = {
  cartItems: Array<Maybe<CartItemInput>>;
  customerType: CustomerTypeEnum;
  discountCode?: Maybe<Scalars['String']>;
};


export type QueryDeliveryOptionsArgs = {
  cartItems?: Maybe<Array<CartItemInput>>;
  zipCode: Scalars['String'];
  customerType?: CustomerTypeEnum;
};


export type QueryNotifyMeWhenProductBackInStockArgs = {
  productId: Scalars['String'];
  emailAddress: Scalars['String'];
  productName: Scalars['String'];
};


export type QueryUnsubscribeFromNotifyMeWhenProductBackInStockArgs = {
  productId: Scalars['String'];
  emailAddress: Scalars['String'];
  productName: Scalars['String'];
};


export type QueryProductPreviousPriceArgs = {
  productId: Scalars['String'];
  calculateProductPreviousPrice: Scalars['Boolean'];
};


export type QueryLast30DaysLowestPriceArgs = {
  productId: Scalars['String'];
};

export type QuickLink = {
  link?: Maybe<Link>;
};

export type Range = {
  __typename?: 'range';
  key: Scalars['String'];
  label: Scalars['String'];
  max: Scalars['Decimal'];
  min: Scalars['Decimal'];
  type: FacetType;
};

export type RangeFacetInput = {
  key: Scalars['String'];
  min?: Maybe<Scalars['String']>;
  max?: Maybe<Scalars['String']>;
};

export type ReferenceInfo = {
  __typename?: 'referenceInfo';
  ctaLink?: Maybe<Link>;
  image?: Maybe<ImageBase>;
  isDarkTheme: Scalars['Boolean'];
  preamble?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type RelatedProductsBlock = Block & {
  __typename?: 'relatedProductsBlock';
  defaultLayout: CardLayout;
  id: Scalars['String'];
  productId?: Maybe<Scalars['String']>;
  relatedProductType?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
  products?: Maybe<Array<Product>>;
};

export type RteBlock = Block & {
  __typename?: 'rteBlock';
  content: Scalars['String'];
  id: Scalars['String'];
  type: BlockType;
};

export type Searchable = {
  searchTerm?: Maybe<Scalars['String']>;
};

export type SearchBlock = Block & {
  __typename?: 'searchBlock';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type SearchInput = {
  searchTerm: Scalars['String'];
};

export type SearchSuggestion = QuickLink & {
  __typename?: 'SearchSuggestion';
  link?: Maybe<Link>;
};

export type SearchSuggestions = {
  __typename?: 'searchSuggestions';
  searchPagePath: Scalars['String'];
  suggestions?: Maybe<Array<QuickLink>>;
};

export type SelectFacetInput = {
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type Selection = {
  __typename?: 'selection';
  isBrandFacet: Scalars['Boolean'];
  key: Scalars['String'];
  label: Scalars['String'];
  type: FacetType;
  values: Array<SelectionValue>;
};

export type SelectionValue = {
  __typename?: 'selectionValue';
  count: Scalars['Int'];
  value: Scalars['String'];
};

export type ServicePoint = {
  __typename?: 'servicePoint';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SimplePaginationInput = {
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type SortInput = {
  sortValue: Scalars['String'];
};

export enum StatusCode {
  Ok = 'OK',
  Permanentredirect = 'PERMANENTREDIRECT',
  Notfound = 'NOTFOUND',
  Servererror = 'SERVERERROR'
}

export type StockStatus = {
  __typename?: 'stockStatus';
  quantity?: Maybe<Scalars['String']>;
  incomingQuantity?: Maybe<Scalars['String']>;
  deliveries?: Maybe<Array<IncomingDelivery>>;
};

export type TextEditorBlock = Block & {
  __typename?: 'textEditorBlock';
  collapsible?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  type: BlockType;
};

export type TextMessage = {
  __typename?: 'textMessage';
  cta?: Maybe<Link>;
  description: Scalars['String'];
  title: Scalars['String'];
};

export type TextMessagesBlock = Block & {
  __typename?: 'textMessagesBlock';
  blockTheme: ThemeInfo;
  buttonCTA: Scalars['Boolean'];
  id: Scalars['String'];
  items?: Maybe<Array<TextMessage>>;
  type: BlockType;
};

export type ThemeInfo = {
  __typename?: 'themeInfo';
  backgroundColor: Scalars['String'];
  textColor: Scalars['String'];
};

export type Translation = {
  __typename?: 'Translation';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type UnknownBlock = Block & {
  __typename?: 'unknownBlock';
  id: Scalars['String'];
  type: BlockType;
};

export type UnsubscribeResult = {
  __typename?: 'UnsubscribeResult';
  success: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  isSeller: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  currentCustomer?: Maybe<Customer>;
  availableCustomers?: Maybe<Array<ChooseCustomer>>;
};

export type UserRoleModel = {
  __typename?: 'UserRoleModel';
  name?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported. */
  role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type VideoBlock = Block & {
  __typename?: 'videoBlock';
  ctaButton?: Maybe<Link>;
  externalVideoUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Primary videoUrl source. If it's empty - externalVideoUrl should be used */
  localVideoUrl?: Maybe<Scalars['String']>;
  thumbnailImage?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type YoutubeEmbed = {
  __typename?: 'youtubeEmbed';
  ytId: Scalars['String'];
};
