import React from "react"
import { Link } from "react-router-dom"
import { Flex, Text, Icon, TextProps, InlineListSeparator } from "@focus-nordic/ui-components"
import { ChangeCustomer } from "../ChangeCustomer"
import { GetUserQuery } from "../../operations/user.generated"
import { useLocales, translationNamespace } from "../../hooks"

interface UserProps extends Partial<GetUserQuery> {}

const Title: React.FC<TextProps> = props => (
	<Text.Anchor
		textColor="blue-6"
		fontSize={1.75}
		lineHeight={1.75}
		fontWeight="bold"
		mb={0}
		whiteSpace="nowrap"
		as="span"
		{...props}
	/>
)

const User: React.FC<UserProps> = props => {
	const locales = useLocales(translationNamespace.shared("User"), [
		"accountUrl",
		"accountDetailsUrl"
	])

	return (
		<>
			{props.user && (
				<Flex alignItems="center">
					<Flex>
						<ChangeCustomer />
					</Flex>
					{props.user.name && (
						<>
							<InlineListSeparator />
							<Flex>
								<Link to={locales.accountUrl}>
									<Flex alignItems="center" as="span">
										<Flex display="inline" as="span">
											<Icon icon="user" color="blue-6" />
										</Flex>
										<Title
											display={{ _: "none", s: "block" }}
											verticalAlign="super"
											ml={1}
										>
											{props.user.name}
										</Title>
									</Flex>
								</Link>
							</Flex>
						</>
					)}
					{props.user.currentCustomer && (
						<>
							<InlineListSeparator />
							<Flex>
								<Link to={locales.accountDetailsUrl}>
									<Title>{props.user.currentCustomer.name} </Title>
									<Title fontWeight="normal" truncate="singleLine">
										({props.user.currentCustomer.identifier})
									</Title>
								</Link>
							</Flex>
						</>
					)}
				</Flex>
			)}
		</>
	)
}
export { User }
