import * as React from "react"
import { Flex, Text } from "@focus-nordic/ui-components"

import { SITE_MAX_WIDTH } from "../../constants"
import { BrandLinkListBlock as IBrandLinkListBlock } from "../../@types/graphql.generated"

const BrandLinkListBlock: React.FC<IBrandLinkListBlock> = props => {
	return (
		<Flex
			w={1}
			py={{ _: 4, m: 8 }}
			px={{ _: 2, m: 8 }}
			maxw={SITE_MAX_WIDTH}
			style={{ margin: "0 auto" }}
		>
			{props.brandItems && (
				<Flex flexWrap="wrap" w={1} mx={{ _: -2, m: -4 }}>
					{props.brandItems.map(brandItem => (
						<React.Fragment key={brandItem.id}>
							<Flex
								as="ul"
								flexDirection="column"
								w={{ _: 1, s: 6 / 12, l: 2 / 12 }}
								mb={{ _: 4, m: 8 }}
								px={{ _: 2, m: 4 }}
							>
								<Flex as="li">
									<Text textColor="black" variant="headline-5">
										{brandItem.title}
									</Text>
								</Flex>
								{brandItem.children && (
									<Flex as="ul" flexDirection="column">
										{brandItem.children.map(
											item =>
												item &&
												item.url && (
													<React.Fragment key={item.id}>
														<Flex as="li">
															<Text.Link
																to={item.url}
																target={`_${
																	item.isExternalLink ? "blank" : "self"
																}`}
																mb={1}
															>
																{item.title}
															</Text.Link>
														</Flex>
													</React.Fragment>
												)
										)}
									</Flex>
								)}
							</Flex>
						</React.Fragment>
					))}
				</Flex>
			)}
		</Flex>
	)
}

export { BrandLinkListBlock }
