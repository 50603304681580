import { INVOICE_DOCUMENT_ROUTE, CUSTOMER_ENDPOINT } from "@focus-nordic/www-common/constants"
import { DocumentType } from "@focus-nordic/www-client/src/@types/graphql.generated"
import queryString from "query-string"

const documentTypeDictionary = {
	[DocumentType.CreditMemo]: "creditMemo",
	[DocumentType.Invoice]: "invoice",
}

export const getInvoiceDocumentUrl = (
	documentName: string,
	documentType: DocumentType
): string => {
	const invoiceQueryString = queryString.stringify({
		...(documentName && {
			documentName
		}),
		...(documentType && {
			customerDocumentType: documentTypeDictionary[documentType] ? documentTypeDictionary[documentType] : documentType
		}),
	})

	return `/${CUSTOMER_ENDPOINT}/${INVOICE_DOCUMENT_ROUTE}?${invoiceQueryString}`
}