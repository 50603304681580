import * as Types from '../../../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetCheckoutQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCheckoutQuery = (
  { __typename?: 'Query' }
  & { allOrderAddresses?: Types.Maybe<Array<(
    { __typename?: 'OrderAddress' }
    & Pick<Types.OrderAddress, 'id' | 'name' | 'address' | 'postalCode' | 'city' | 'country'>
  )>> }
);

export type CreateOrderMutationVariables = Types.Exact<{
  input: Types.CheckoutInput;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder?: Types.Maybe<(
    { __typename?: 'CreateOrderError' }
    & Pick<Types.CreateOrderError, 'code' | 'message'>
  ) | (
    { __typename?: 'Order' }
    & Pick<Types.Order, 'id'>
  )> }
);


export const GetCheckoutDocument = gql`
    query GetCheckout {
  allOrderAddresses {
    id
    name
    address
    postalCode
    city
    country
  }
}
    `;

/**
 * __useGetCheckoutQuery__
 *
 * To run a query within a React component, call `useGetCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCheckoutQuery(baseOptions?: Apollo.QueryHookOptions<GetCheckoutQuery, GetCheckoutQueryVariables>) {
        return Apollo.useQuery<GetCheckoutQuery, GetCheckoutQueryVariables>(GetCheckoutDocument, baseOptions);
      }
export function useGetCheckoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckoutQuery, GetCheckoutQueryVariables>) {
          return Apollo.useLazyQuery<GetCheckoutQuery, GetCheckoutQueryVariables>(GetCheckoutDocument, baseOptions);
        }
export type GetCheckoutQueryHookResult = ReturnType<typeof useGetCheckoutQuery>;
export type GetCheckoutLazyQueryHookResult = ReturnType<typeof useGetCheckoutLazyQuery>;
export type GetCheckoutQueryResult = Apollo.QueryResult<GetCheckoutQuery, GetCheckoutQueryVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($input: CheckoutInput!) {
  createOrder(input: $input) {
    ... on Order {
      id
    }
    ... on Error {
      code
      message
    }
    ... on CreateOrderError {
      message
      code
    }
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, baseOptions);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;