import React from "react"
import { FacetType } from "../../@types/graphql.generated"

export interface FacetValue {
	type: FacetType
	key: string
	value: string
}

type AddAction = {
	type: "ADD_FACET"
	payload: FacetValue
}

type UpdateAction = {
	type: "UPDATE_FACET"
	payload: FacetValue
}

type RemoveAction = {
	type: "REMOVE_FACET"
	payload: FacetValue
}

type ClearAction = {
	type: "CLEAR_FACETS"
}

type Action = AddAction | RemoveAction | ClearAction | UpdateAction

type FacetReducer = (state: FacetsState, action: Action) => FacetsState

export interface FacetsState {
	[id: string]: FacetValue
}

export const getFacetStateKey = (facetValue: FacetValue): string =>
	({
		[FacetType.Selection]: `${facetValue.key}-${facetValue.value}`,
		[FacetType.Range]: facetValue.key,
		[FacetType.Category]: facetValue.key,
	}[facetValue.type])

const addFacet = (state: FacetsState, action: AddAction) => {
	const payloadFacetKey = getFacetStateKey(action.payload)
	return {
		...state,
		[payloadFacetKey]: action.payload
	}
}

const removeFacet = (state: FacetsState, action: RemoveAction) => {
	const payloadFacetKey = getFacetStateKey(action.payload)
	const { [payloadFacetKey]: targetFacet, ...stateOmittedTarget } = state
	return stateOmittedTarget
}

const updateFacet = (state: FacetsState, action: UpdateAction) => {
	const payloadFacetKey = action.payload.type === FacetType.Category ? FacetType.Category.toLowerCase() : getFacetStateKey(action.payload)

	const updatedState = Object.keys(state).reduce((acc, key) => {
		const facet = state[key]
		if (facet.type !== FacetType.Category) {
			acc[key] = facet
		}
		return acc
	}, {})

	updatedState[payloadFacetKey] = action.payload

	return updatedState
}

const facetReducer: FacetReducer = (state, action) => {
	switch (action.type) {
		case "ADD_FACET":
			return addFacet(state, action)
		case "REMOVE_FACET":
			return removeFacet(state, action)
		case "UPDATE_FACET":
			return updateFacet(state, action)
		case "CLEAR_FACETS":
			return {}
		default:
			throw new Error()
	}
}

export const useFacetsReducer = (initialState: FacetsState) => {
	const useReducer = React.useReducer(facetReducer, initialState, arg => arg)

	return useReducer
}
