import React from "react"
import {
	LocalesContextProvider,
	LocalesContextProviderProps,
	UiContextProvider,
	FeatureFlagsProviderProps,
	FeatureFlagsProvider
} from "@focus-nordic/www-client/src/context"
import { Modal, theme } from "@focus-nordic/ui-components"
import { ThemeProvider } from "styled-components"

export interface AppContextProviderCommonProps {
	localesContextProviderProps: LocalesContextProviderProps
	featureFlagsProviderProps: FeatureFlagsProviderProps
}

export const AppContextProviderCommon: React.FC<AppContextProviderCommonProps> = props => {
	return (
		<LocalesContextProvider {...props.localesContextProviderProps}>
			<FeatureFlagsProvider {...props.featureFlagsProviderProps}>
				<ThemeProvider theme={theme}>
					<Modal.Provider>
						<UiContextProvider>{props.children}</UiContextProvider>
					</Modal.Provider>
				</ThemeProvider>
			</FeatureFlagsProvider>
		</LocalesContextProvider>
	)
}
