"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartItem = void 0;
var React = __importStar(require("react"));
var Flex_1 = require("../Flex");
var Label = __importStar(require("./Text"));
var Image_1 = require("../Image");
var ProductCardLink_1 = require("./ProductCardLink");
var Icon_1 = require("../Icon");
var Text_1 = require("../Text");
var Alert_1 = require("../Alert");
var CartItem = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
    var _4, _5, _6, _7, _8;
    return (React.createElement(Flex_1.Flex, { w: 1, position: "relative", flexDirection: "column" },
        React.createElement(Flex_1.Flex, { py: 2, alignItems: "flex-start", borderBottom: true, borderColor: "grey-3", flex: 1 },
            props.image && (React.createElement(Flex_1.Flex, { flexDirection: "column", justifyContent: "space-between", h: 1 },
                React.createElement(Flex_1.Flex, { mr: 2, mb: 1, w: (_a = { _: 10 }, _a[props.columnLayoutBreakpoint] = 12, _a), display: "block", p: 1.5, backgroundColor: "grey-1" },
                    React.createElement(Image_1.Image, __assign({ ratio: 1 }, props.image))))),
            React.createElement(Flex_1.Flex, { flexDirection: "column", flex: 1, h: 1 },
                React.createElement(Flex_1.Flex, { justifyContent: "space-between", mb: { _: 0, l: 1 } },
                    React.createElement(ProductCardLink_1.ProductCardLink, { to: props.to, onClick: props.onClick },
                        props.brandName && React.createElement(Label.Brand, null, props.brandName),
                        props.name && (React.createElement(Label.Name, { mb: (_b = { _: 1 }, _b[props.columnLayoutBreakpoint] = 0, _b) }, props.name))),
                    React.createElement(Flex_1.Flex, { h: 4, zIndex: 2, alignItems: "center" },
                        React.createElement("button", { onClick: props.onDelete },
                            React.createElement(Icon_1.Icon, { icon: "bin" })))),
                React.createElement(Flex_1.Flex, { flex: 1, flexDirection: (_c = {
                            _: "column"
                        },
                        _c[props.columnLayoutBreakpoint] = "row",
                        _c), justifyContent: "space-between" },
                    props.articleNumber && (React.createElement(Flex_1.Flex, { pr: (_d = { _: 0 }, _d[props.columnLayoutBreakpoint] = 2, _d), flex: "1" },
                        React.createElement(Label.Data, { flexDirection: (_e = {
                                    _: "row"
                                },
                                _e[props.columnLayoutBreakpoint] = "column",
                                _e), title: props.locales.articleNumber, data: props.articleNumber }))),
                    props.stockStatus && (React.createElement(Flex_1.Flex, { pr: (_f = { _: 0 }, _f[props.columnLayoutBreakpoint] = 2, _f), flex: "1" },
                        React.createElement(Label.Data, { alignItems: "flex-start", flexDirection: (_g = {
                                    _: "row"
                                },
                                _g[props.columnLayoutBreakpoint] = "column",
                                _g), title: props.locales.stockStatus, data: props.stockStatus }))),
                    props.fee && (React.createElement(Flex_1.Flex, { pr: (_h = { _: 0 }, _h[props.columnLayoutBreakpoint] = 2, _h), flex: "1" },
                        React.createElement(Label.Data, { flexDirection: (_j = {
                                    _: "row"
                                },
                                _j[props.columnLayoutBreakpoint] = "column",
                                _j), title: props.locales.fee, data: props.fee }))),
                    props.margin && (React.createElement(Flex_1.Flex, { pr: (_k = { _: 0 }, _k[props.columnLayoutBreakpoint] = 2, _k), flex: "1", display: (_l = {
                                _: "none"
                            },
                            _l[props.columnLayoutBreakpoint] = "flex",
                            _l) },
                        React.createElement(Label.Data, { flexDirection: (_m = {
                                    _: "row"
                                },
                                _m[props.columnLayoutBreakpoint] = "column",
                                _m), title: props.locales.margin, data: props.margin }))),
                    (props.productDiscountedPrice || props.cartItemDiscountedPrice) && !props.customPrice ? (React.createElement(Flex_1.Flex, { pr: (_o = { _: 0 }, _o[props.columnLayoutBreakpoint] = 2, _o), flex: "1", flexDirection: "column" },
                        React.createElement(Label.Discount, { flexDirection: (_p = {
                                    _: "row"
                                },
                                _p[props.columnLayoutBreakpoint] = "column",
                                _p), title: props.locales.price, data: {
                                discountPrice: (_4 = props.productDiscountedPrice) !== null && _4 !== void 0 ? _4 : props.cartItemDiscountedPrice,
                                price: (_5 = props.productComparisonPrice) !== null && _5 !== void 0 ? _5 : props.cartItemComparisonPrice
                            } }),
                        props.cartItemDiscountedPrice && (React.createElement(Flex_1.Flex, { alignItems: "center", zIndex: 2 },
                            React.createElement(Icon_1.Icon, { icon: "sell", color: "grey-4", size: 1.5, viewBox: "0 0 12 12" }),
                            React.createElement(Text_1.Text, { as: "span", fontSize: 1.5, ml: 0.5, mb: 0 }, props.locales.discountApplied))))) : props.price && (React.createElement(Flex_1.Flex, { pr: (_q = { _: 0 }, _q[props.columnLayoutBreakpoint] = 2, _q), flex: "1" }, props.customPrice ? (props.customPrice) : (React.createElement(Label.Data, { flexDirection: (_r = {
                                _: "row"
                            },
                            _r[props.columnLayoutBreakpoint] = "column",
                            _r), title: props.locales.price, data: props.price })))),
                    props.productDiscountedPrice && props.customPrice ? (React.createElement(Flex_1.Flex, { pr: (_s = { _: 0 }, _s[props.columnLayoutBreakpoint] = 2, _s), flex: "1", display: (_t = {
                                _: "none"
                            },
                            _t[props.columnLayoutBreakpoint] = "flex",
                            _t) },
                        React.createElement(Label.Discount, { flexDirection: (_u = {
                                    _: "row"
                                },
                                _u[props.columnLayoutBreakpoint] = "column",
                                _u), title: props.locales.rsp, data: {
                                discountPrice: props.productDiscountedPrice,
                                price: props.productComparisonPrice
                            } }))) : props.rsp && (React.createElement(Flex_1.Flex, { pr: (_v = { _: 0 }, _v[props.columnLayoutBreakpoint] = 2, _v), flex: "1", display: (_w = {
                                _: "none"
                            },
                            _w[props.columnLayoutBreakpoint] = "flex",
                            _w) },
                        React.createElement(Label.Data, { flexDirection: (_x = {
                                    _: "row"
                                },
                                _x[props.columnLayoutBreakpoint] = "column",
                                _x), title: props.locales.rsp, data: props.rsp }))),
                    React.createElement(Flex_1.Flex, { my: (_y = { _: 2 }, _y[props.columnLayoutBreakpoint] = 0, _y), pr: (_z = { _: 0 }, _z[props.columnLayoutBreakpoint] = 2, _z), flex: "1", alignSelf: "flex-start", pt: { _: 0, l: 3.25 } }, (props.productDiscountedPriceTotal || props.cartItemDiscountedPriceTotal) && !props.customPrice ?
                        React.createElement(Label.Price, { price: (_6 = props.productDiscountedPriceTotal) !== null && _6 !== void 0 ? _6 : props.cartItemDiscountedPriceTotal, my: 0, lineHeight: 3 }) :
                        props.priceTotal && React.createElement(Label.Price, { price: props.priceTotal, my: 0, lineHeight: 3 })),
                    props.addToCart && (React.createElement(Flex_1.Flex, { alignSelf: "flex-start", pt: { _: 0, l: 2.75 } }, props.addToCart)),
                    React.createElement(Flex_1.Flex, { mt: 1, display: (_0 = {
                                _: "flex"
                            },
                            _0[props.columnLayoutBreakpoint] = "none",
                            _0) },
                        props.margin && (React.createElement(Flex_1.Flex, { pr: 1 },
                            React.createElement(Label.Data, { flexDirection: (_1 = {
                                        _: "row"
                                    },
                                    _1[props.columnLayoutBreakpoint] = "column",
                                    _1), title: props.locales.margin, data: props.margin }))),
                        props.productDiscountedPrice && props.customPrice ? (React.createElement(Flex_1.Flex, null,
                            React.createElement(Label.Discount, { flexDirection: (_2 = {
                                        _: "row"
                                    },
                                    _2[props.columnLayoutBreakpoint] = "column",
                                    _2), title: props.locales.rsp, data: {
                                    discountPrice: props.productDiscountedPrice,
                                    price: props.productComparisonPrice
                                } }))) : props.rsp && (React.createElement(Flex_1.Flex, null,
                            React.createElement(Label.Data, { flexDirection: (_3 = {
                                        _: "row"
                                    },
                                    _3[props.columnLayoutBreakpoint] = "column",
                                    _3), title: props.locales.rsp, data: props.rsp }))))),
                props.displayAvailableItemsAlert &&
                    props.availableItemsCount !== undefined &&
                    props.availableItemsCount === 0 && (React.createElement(Alert_1.Alert, { mt: 3 },
                    React.createElement(Flex_1.Flex, { flexWrap: "wrap" },
                        React.createElement("div", { dangerouslySetInnerHTML: {
                                __html: (_8 = (_7 = props.locales) === null || _7 === void 0 ? void 0 : _7.cartProductZeroAvailabilityMessage) !== null && _8 !== void 0 ? _8 : ""
                            } }),
                        props.notifyMe && props.notifyMe)))))));
};
exports.CartItem = CartItem;
exports.CartItem.defaultProps = {
    columnLayoutBreakpoint: "l"
};
