import * as React from "react"
import { Flex, Text } from "@focus-nordic/ui-components"
import { ProductCardList, ProcuctListToggle, useCardLayout } from "../"
import { RelatedProductsBlockFieldsFragment } from "./operations.generated"
import { SITE_MAX_WIDTH } from "../../constants/index"
import { useLocales } from "../../hooks"
import { BlockType, CardLayout } from "../../@types/graphql.generated"

interface RelatedProductsBlockProps
	extends RelatedProductsBlockFieldsFragment {}

const RelatedProductsBlock: React.FC<RelatedProductsBlockProps> = props => {
	const [cardLayout, setCardLayout] = useCardLayout(props.defaultLayout)
	const locales = useLocales(BlockType.Productlistblock, ["showAs"])

	return (
		<Flex
			id={props.id}
			maxw={SITE_MAX_WIDTH}
			pt={{ _: 3, m: 5 }}
			pb={{ _: 3, m: 6 }}
			mb={{ _: -3, m: -6}}
			flexDirection="column"
			w={1}
		>
			<Flex
				px={{ _: 2, m: 8 }}
				mb={{ _: 2, m: 5 }}
				justifyContent="space-between"
			>
				{props.title && (
					<Text variant="headline-4" mb={0}>
						{props.title}
					</Text>
				)}
				<Flex alignItems="center">
					<Text as="span" mb={0.5} mr={0.5} textColor="black">
						{locales.showAs}
					</Text>
					<ProcuctListToggle
						currentListType={cardLayout}
						onToggle={cardLayout => setCardLayout(cardLayout)}
					/>
				</Flex>
			</Flex>
			<Flex
				flexWrap="wrap"
				px={{
					_: cardLayout === CardLayout.Large ? 1 : 2,
					m: cardLayout === CardLayout.Large ? 6 : 8
				}}
			>
				{props.products && (
					<ProductCardList
						columnWidth={{ _: 1 / 2, xl: 1 / 4 }}
						products={props.products as any}
						cardLayout={cardLayout}
					/>
				)}
			</Flex>
		</Flex>
	)
}

export { RelatedProductsBlock }
