import { ApolloLink, FetchResult } from "@apollo/client"
import { cartItemsDbConnect } from "../indexedDb"
import { GetCalculateB2CCartQuery } from "../operations/cart.generated"

export const persistBtcCartLink = new ApolloLink((operation, forward) => {
	return forward(operation).map((data: FetchResult<GetCalculateB2CCartQuery>) => {
		const persistCartItems = async () => {
			// Called after server responds
			if (operation.operationName === "GetCalculateB2CCart") {
				const items = data.data?.calculateB2CCart?.items

				if (items) {
					const idb = await cartItemsDbConnect()
					idb.saveCartItems(items)
				}
			}
		}
		persistCartItems()
		return data
	})
})
