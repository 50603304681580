import { RegisterOptions } from 'react-hook-form';
import {
	TextFieldProps
} from "@focus-nordic/ui-components/dist"
import queryString from "query-string"
import env from "@beam-australia/react-env"

export type GeneralInfoFeildsConfig = {
    orgNumber: {
        props: TextFieldProps,
        registerOptions: RegisterOptions
    };
    vatNumber: {
        props: TextFieldProps & {
            prefix?: string;
            suffix?: string;
        },
        registerOptions: RegisterOptions 
    };
};

export const exactFieldLengthValidator = (value: string, length: number, message: string) => {
    return value.trim().length === length || message?.replace("{0}", length.toString());
}

export const allowSpecificCharsOnlyHandler = (event: React.ChangeEvent<HTMLInputElement>, regex: RegExp) => {
    event.preventDefault();
    const inputElement = event.target;
    const inputValue = inputElement.value;
    const validInput = inputValue.replace(regex, '');
    inputElement.value = validInput;
}

export const allowDigitsOnlyHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    allowSpecificCharsOnlyHandler(event, /[^0-9]/g);
}

export const allowDigitsOrDashOnlyHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    allowSpecificCharsOnlyHandler(event, /[^0-9-]/g);
}

type vatNumberFieldValidatorOptions = {
    value: string; 
    minLength?: number;
    maxLength: number;
    locales: Record<string, string>;
};

const vatNumberFieldValidator = async (options: vatNumberFieldValidatorOptions) => {
    const { value, minLength, maxLength, locales } = options;
    const countryCode = window.__HEADERS__.xSite;

    if (minLength && minLength > 0 && value.length < minLength) {
        return value.trim().length === minLength || locales?.minLengthRuleText?.replace("{0}", minLength.toString());
    } else if (value.length !== maxLength && !minLength) {
    	return exactFieldLengthValidator(value, maxLength, locales?.exactFieldLengthRuleText);
    } else {
        try {
            const response = await fetch(
                `${env("API_BASE_URL")}/vatNumber/check?${queryString.stringify({
                    countryCode,
                    vatNumber: value
                })}`
            )
    
            const responseJson = await response.json();
                
            return responseJson?.success || locales?.vatValidationErrorText
    
        } catch (e) {
            console.error(e)
            return true
        }
    }
}

export const getFieldRulesConfig: (localeCode: string, locales: Record<string, string>) => GeneralInfoFeildsConfig = (
    localeCode: string,
    locales: Record<string, string>
) => {
    // Org number rules
    let orgNumberProps: Partial<{ maxLength: number, onInput: (event: React.ChangeEvent<HTMLInputElement>) => void }> = {
        onInput: allowDigitsOnlyHandler
    };
    let orgNumberRegisterOptions: RegisterOptions = {
        required: true
    };

    // VAT number rules
    let vatNumberProps: Partial<{ 
        maxLength: number,
        minLength?: number, 
        prefix?: string,
        suffix?: string
    }> = {};
    let vatNumberRegisterOptions: RegisterOptions = {
        required: true,
        pattern: {
            value: /^[0-9]+$/,
            message: locales?.digitsOnlyFieldFormatText,
        }
    };
  
    switch (localeCode) {
        case 'sv-se':      
            orgNumberProps.maxLength = 11;
            orgNumberProps.onInput = allowDigitsOrDashOnlyHandler;
            orgNumberRegisterOptions.pattern = {
                value: /^\d{6}-\d{4}$/,
                message: locales?.exactFieldFormatText?.replace("{0}", "XXXXXX-XXXX")
            }
            vatNumberProps.maxLength = 12;
            vatNumberProps.prefix = "SE";
            vatNumberRegisterOptions.validate = (value: string) => vatNumberFieldValidator({ value, maxLength: 12, locales });
            break;
        case 'fi-fi':
            orgNumberProps.maxLength = 9;
            orgNumberProps.onInput = allowDigitsOrDashOnlyHandler;
            orgNumberRegisterOptions.pattern = {
                value: /^\d{7}-\d{1}$/,
                message: locales?.exactFieldFormatText?.replace("{0}", "XXXXXXX-X")
            }
            vatNumberProps.maxLength = 8;
            vatNumberProps.prefix = "FI";   
            vatNumberRegisterOptions.validate = (value: string) => vatNumberFieldValidator({ value, maxLength: 8, locales }); 
            break;
        case 'nb-no':
            orgNumberProps.maxLength = 9;
            orgNumberRegisterOptions.validate = (value: string) => exactFieldLengthValidator(value, 9, locales?.exactFieldLengthRuleText);
            vatNumberProps.maxLength = 9;
            vatNumberProps.suffix = "MVA";
            vatNumberRegisterOptions.validate = (value: string) => exactFieldLengthValidator(value, 9, locales?.exactFieldLengthRuleText);
            break
        case 'lt-lt':
            orgNumberProps.maxLength = 9;
            orgNumberRegisterOptions.validate = (value: string) => exactFieldLengthValidator(value, 9, locales?.exactFieldLengthRuleText);
            vatNumberProps.maxLength = 12;
            vatNumberProps.prefix = "LT";
            vatNumberRegisterOptions.validate = (value: string) => vatNumberFieldValidator({ value, maxLength: 12, locales });
            break;
        case 'pl-pl':
            orgNumberProps.maxLength = 9;
            orgNumberRegisterOptions.validate = (value: string) => exactFieldLengthValidator(value, 9, locales?.exactFieldLengthRuleText);
            vatNumberProps.maxLength = 10;
            vatNumberRegisterOptions.validate = (value: string) => vatNumberFieldValidator({ value, maxLength: 10, locales });
            break;
        case 'da-dk':
            orgNumberProps.maxLength = 8;
            orgNumberRegisterOptions.validate = (value: string) => exactFieldLengthValidator(value, 8, locales?.exactFieldLengthRuleText);
            vatNumberProps.maxLength = 8;
            vatNumberProps.prefix = "DK";
            vatNumberRegisterOptions.validate = (value: string) => vatNumberFieldValidator({ value, maxLength: 8, locales });
            break;
        case 'et-ee':
            orgNumberProps.maxLength = 8;
            orgNumberRegisterOptions.validate = (value: string) => exactFieldLengthValidator(value, 8, locales?.exactFieldLengthRuleText);
            vatNumberProps.maxLength = 9;
            vatNumberProps.prefix = "EE";
            vatNumberRegisterOptions.validate = (value: string) => vatNumberFieldValidator({ value, maxLength: 9, locales });
            break;
        case 'sk-sk':
            orgNumberProps.maxLength = 8;
            orgNumberRegisterOptions.validate = (value: string) => exactFieldLengthValidator(value, 8, locales?.exactFieldLengthRuleText);
            vatNumberProps.maxLength = 10;
            vatNumberProps.prefix = "SK";
            vatNumberRegisterOptions.validate = (value: string) => vatNumberFieldValidator({ value, maxLength: 10, locales });
            break;
        case 'lv-lv':
            orgNumberProps.maxLength = 11;
            orgNumberRegisterOptions.validate = (value: string) => exactFieldLengthValidator(value, 11, locales?.exactFieldLengthRuleText);
            vatNumberProps.maxLength = 11;
            vatNumberProps.prefix = "LV";
            vatNumberRegisterOptions.validate = (value: string) => vatNumberFieldValidator({ value, maxLength: 11, locales });
            break;
        case 'cs-cz':
            orgNumberProps.maxLength = 8;
            orgNumberRegisterOptions.validate = (value: string) => exactFieldLengthValidator(value, 8, locales?.exactFieldLengthRuleText);
            vatNumberProps.minLength = 8;
            vatNumberProps.maxLength = 10;
            vatNumberProps.prefix = "CZ";
            vatNumberRegisterOptions.validate = (value: string) => vatNumberFieldValidator({ value, minLength: 8, maxLength: 10, locales });
            break;
        default:
            break;
    }

    return {
        orgNumber: {
            props: orgNumberProps,
            registerOptions: orgNumberRegisterOptions
        },
        vatNumber: {
            props: vatNumberProps,
            registerOptions: vatNumberRegisterOptions
        },
    };
};

export const isNonEmptyString = (value: string) => !!value.trim()
