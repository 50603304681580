import isObject from "isobject"

// Removes key value pairs from an object based on a test function
export function objectDeepOmit<T>(
	object: T,
	omitTest: (val: any) => boolean
): T {
	return Object.keys(object).reduce<any>(
		(acc, key) => ({
			...acc,
			...(isObject(object[key])
				? // call function recursively for nested objects
				  { [key]: objectDeepOmit(object[key], omitTest) }
				: omitTest(object[key]) && { [key]: object[key] })
		}),
		{}
	)
}
