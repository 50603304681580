export interface LoadScriptArgs {
	scriptUrl: string
	windowNamespace: string
}

export const loadeScript = <PromiseResolveValue>(
	callContext: { promise: Promise<PromiseResolveValue> | undefined },
	args: LoadScriptArgs
): Promise<PromiseResolveValue> => {
	if (typeof callContext.promise === "undefined") {
		const script = document.createElement("script")
		script.type = "text/javascript"
		script.src = args.scriptUrl
		document.body.appendChild(script)
		callContext.promise = new Promise<PromiseResolveValue>(
			(resolve, reject) => {
				const errorHandler = () =>
					reject(`unable to load script for namespace: ${args.windowNamespace}`)

				script.addEventListener("load", e => {
					if (window[args.windowNamespace]) {
						resolve(window[args.windowNamespace])
					} else {
						errorHandler()
					}
				})
				script.addEventListener("error", e => {
					callContext.promise = undefined
					errorHandler()
				})
			}
		)
	}
	return callContext.promise
}
