import * as React from "react"
import { ProductCard, Flex, ProductCardText, ProductLabels } from "@focus-nordic/ui-components"
import {
	Product,
	CardLayout,
	CustomerTypeEnum,
	Buyable,
	Pagination
} from "../../@types/graphql.generated"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { LocalesContext } from "../../context/LocalesContext"
import { ResponsiveValue } from "styled-system"
import { AddToCartButton } from "../AddToCartButton"
import { NotifyMe } from "../NotifyMe"
import { productDataToProductCardProps } from "../../utils"
import { StockStatusType } from "../StockStatus"
import { useLocation } from "react-router"
import { useIsLoggedIn } from "../../hooks"
import { getSearchParam, trackSearchResultClick } from "../SearchBlock"
import { trackProductClick } from "./utils"
import { useTrackProductListImpressions } from "./useTrackProductListImpressions"
import { ListTypes } from "./types"

interface ProductListProps {
	products: Product[]
	columnWidth?: ResponsiveValue<number>;
	columnFlex?: ResponsiveValue<number | string> | string;
	pagination?: Pick<Pagination, "skip" | "take" | "count">
	withoutBottomSeparator?: boolean
	simpleView?: boolean
}

// Compound component interface
interface IProductCardList {
	Medium: React.FC<ProductListProps>
	Large: React.FC<ProductListProps>
}

interface ProductCardListTypeProps {
	cardLayout?: CardLayout
}

/**
 * Product card list of optional size, large by default
 */
const ProductCardList: React.FC<ProductListProps & ProductCardListTypeProps> &
	IProductCardList = props =>
	({
		[CardLayout.Small]: <ProductCardList.Medium {...props} />,
		[CardLayout.Medium]: <ProductCardList.Medium {...props} />,
		[CardLayout.Large]: <ProductCardList.Large {...props} />
	}[props.cardLayout!])

ProductCardList.defaultProps = {
	cardLayout: CardLayout.Large,
	withoutBottomSeparator: false
}

/**
 * List of medium sized product cards
 */
ProductCardList.Medium = function(props) {
	const location = useLocation()
	const isLoggedIn = useIsLoggedIn()
	const { localeCode } = React.useContext(LocalesContext)
	const productLocales = useLocales(translationNamespace.shared("Product"), [
		"articleNumber",
		"ean",
		"rsp",
		"margin",
		"addToCart",
		"fee",
		"price",
		"stockStatus",
		"campaignMessage"
	])

	const productLabelLocales = useLocales(translationNamespace.shared("ProductLabel"), [
		"productLabelCampaign",
		"productLabelComingSoon",
		"productLabelKit",
		"productLabelNew",
		"productLabelOutlet",
		"productLabelCampaignBlackFriday",
		"productLabelWarrantyPlural"
    ])

	const cartLocales = useLocales(translationNamespace.shared("Cart"), [
		"cartProductNoMoreItemsMessage"
	])

	const locales = {
		...productLocales,
		...{
			productLabel: {
				[ProductLabels.CAMPAIGN]: productLabelLocales.productLabelCampaign,
				[ProductLabels.COMINGSOON]: productLabelLocales.productLabelComingSoon,
				[ProductLabels.KIT]: productLabelLocales.productLabelKit,
				[ProductLabels.NEW]: productLabelLocales.productLabelNew,
				[ProductLabels.OUTLET]: productLabelLocales.productLabelOutlet,
				[ProductLabels.CAMPAIGNBLACKFRIDAY]: productLabelLocales.productLabelCampaignBlackFriday,
				productLabelWarrantyPlural: productLabelLocales.productLabelWarrantyPlural
			}
		}
	}

	useTrackProductListImpressions({
		userType: isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C,
		products: props.products,
		listType: location.search ? ListTypes.search : ListTypes.default,
		...(props.pagination && { pagination: props.pagination })
	})

	const handleClick = (product: Product, index: number) => {
		const customerType = isLoggedIn
			? CustomerTypeEnum.B2B
			: CustomerTypeEnum.B2C
		const isSearchResultList = location.search

		trackProductClick({
			userType: customerType,
			listType: isSearchResultList ? ListTypes.search : ListTypes.default,
			product,
			position: index + 1
		})

		if (isSearchResultList) {
			trackSearchResultClick({
				searchTerm: getSearchParam(location.search) ?? "",
				targetUrl: product.link.url,
				userType: customerType
			})
		}
	}

	return (
		<>
			{props.products.map((product, index) => (
				<ProductCard.Medium
					key={product.id}
					{...productDataToProductCardProps(product, localeCode)}
					locales={locales}
					stockStatus={
						<StockStatusType
							textComponent={ProductCardText.Label}
							{...product}
						/>
					}
					addToCart={(
						<AddToCartButton
							parentContext="product-list"
							product={product}
							incrementButtonDisabledTooltipText={cartLocales.cartProductNoMoreItemsMessage}
							disableButtonOnMaxAvailabilityReach={!isLoggedIn} 
							size="small" 
						/>
					)}
					notifyMe={
						!isLoggedIn && product.availableItemsCount === 0 && product.buyable !== Buyable.Hidden ? <NotifyMe 
							variant="white"
							border							
							productId={product?.id}
							productName={product?.name ?? ""}
						/> : null
					}
					onClick={() => handleClick(product, index)}
					isLoggedIn={isLoggedIn}
					buyable={product.buyable}
				/>
			))}
		</>
	)
} as React.FC<ProductListProps>

/**
 * List of large sized product cards
 */
ProductCardList.Large = function(props) {
	const location = useLocation()
	const isLoggedIn = useIsLoggedIn()
	const { localeCode } = React.useContext(LocalesContext)
	const productLocales = useLocales(translationNamespace.shared("Product"), [
		"articleNumber",
		"ean",
		"rsp",
		"margin",
		"addToCart",
		"fee",
		"price",
		"stockStatus",
		"campaignMessage",
	])

	const productLabelLocales = useLocales(translationNamespace.shared("ProductLabel"), [
		"productLabelCampaign",
		"productLabelComingSoon",
		"productLabelKit",
		"productLabelNew",
		"productLabelOutlet",
		"productLabelCampaignBlackFriday",
		"productLabelWarrantyPlural"
    ])

	const cartLocales = useLocales(translationNamespace.shared("Cart"), [
		"cartProductNoMoreItemsMessage"
	])

	const locales = {
		...productLocales,
		...{
			productLabel: {
				[ProductLabels.CAMPAIGN]: productLabelLocales.productLabelCampaign,
				[ProductLabels.COMINGSOON]: productLabelLocales.productLabelComingSoon,
				[ProductLabels.KIT]: productLabelLocales.productLabelKit,
				[ProductLabels.NEW]: productLabelLocales.productLabelNew,
				[ProductLabels.OUTLET]: productLabelLocales.productLabelOutlet,
				[ProductLabels.CAMPAIGNBLACKFRIDAY]: productLabelLocales.productLabelCampaignBlackFriday,
				productLabelWarrantyPlural: productLabelLocales.productLabelWarrantyPlural
			}
		}
	}

	useTrackProductListImpressions({
		userType: isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C,
		products: props.products,
		listType: location.search ? ListTypes.search : ListTypes.default,
		...(props.pagination && { pagination: props.pagination })
	})

	const productsData = React.useMemo(() => {
		return props.products.map(product =>
			productDataToProductCardProps(product, localeCode)
		)
	}, [props.products, localeCode])

	const handleClick = (product: Product, index: number) => {
		const customerType = isLoggedIn
			? CustomerTypeEnum.B2B
			: CustomerTypeEnum.B2C
		const isSearchResultList = location.search

		trackProductClick({
			userType: customerType,
			listType: isSearchResultList ? ListTypes.search : ListTypes.default,
			product,
			position: index + 1
		})

		if (isSearchResultList) {
			trackSearchResultClick({
				searchTerm: getSearchParam(location.search) ?? "",
				targetUrl: product.link.url,
				userType: customerType
			})
		}
	}

	return (
		<>
			{productsData.map((productData, index) => {
				const product = props.products[index]
				return (
					productData && (
						<Flex
							w={props.columnWidth!}
							flex={props.columnFlex}
							px={{ _: 1, xl: 2 }}
							key={productData.articleNumber}
						>
							<ProductCard.Large
								{...productData}
								locales={locales}
								stockStatus={
									<StockStatusType
										textComponent={ProductCardText.Label}
										{...product}
									/>
								}
								addToCart={(
									<AddToCartButton
										parentContext="product-list"
										product={product}
										incrementButtonDisabledTooltipText={cartLocales.cartProductNoMoreItemsMessage}
										disableButtonOnMaxAvailabilityReach={!isLoggedIn} 
										size="small" 
									/>
								)}
								notifyMe={
									!isLoggedIn && product.availableItemsCount === 0 && product.buyable !== Buyable.Hidden ? <NotifyMe 
										variant="white"
										border
										productId={product?.id}
										productName={product?.name ?? ""}
									/> : null
								}
								onClick={() => handleClick(product, index)}
								isLoggedIn={isLoggedIn}
								buyable={product.buyable}
								withoutBottomSeparator={props.withoutBottomSeparator}
								simpleView={props.simpleView}
							/>
						</Flex>
					)
				)
			})}
		</>
	)
} as React.FC<ProductListProps>

ProductCardList.Large.defaultProps = {
	columnWidth: { _: 1 / 2, m: 1 / 3, l: 1 / 4 }
}

export { ProductCardList }
