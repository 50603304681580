import {
	Checkbox,
	Flex,
	Radio,
	Select,
	Text
} from "@focus-nordic/ui-components"
import React from "react"
import { DeliveryOptionEnum } from "../../../../@types/graphql.generated"
import { useGoogleMapsApi, useLocales } from "../../../../hooks"
import { useGetUserQuery } from "../../../../operations/user.generated"
import { Loader } from "../../../Loader"
import { Panel } from "../../components"
import { useCheckout } from "./context/CheckoutProvider"
import { GetCheckoutQuery } from "./operations.generated"
import { TempDropShipAddressForm } from "./TempDropShipAddressForm"

const PhoneNumberField = React.lazy(() =>
	import(/* webpackChunkName: 'PhoneNumberField' */ "../../../PhoneNumberField")
)
interface DeliveryProps {
	orderAddresses: GetCheckoutQuery["allOrderAddresses"]
}

const DeliveryInputs: React.FC<DeliveryProps> = props => {
	const { form, isDropShipAddress, setIsDropShipAddress } = useCheckout()
	const { register, trigger, errors, getValues, setValue } = form!
	const user = useGetUserQuery()
	const [isSmsNotification, setIsSmsNotification] = React.useState<boolean>(
		false
	)

	// start prefetching the google maps api
	// TODO: when google creds are in place, uncomment below
	// useGoogleMapsApi()

	React.useEffect(() => {
		isDropShipAddress
			? setValue("deliveryOption", DeliveryOptionEnum.Full)
			: setValue("deliveryOption", DeliveryOptionEnum.Partial)

		setIsSmsNotification(isDropShipAddress)
	}, [isDropShipAddress])

	const locales = useLocales("myPages_checkout", [
		"deliveryTitle",
		"dropshipAddressTitle",
		"deliveryOptionsTitle",
		"partialDeliveryTitle",
		"fullDeliveryTitle",
		"freeFreightTitle",
		"notificationsTitle",
		"smsNotificationTitle",
		"phoneTitle",
		"phoneErrorMessage",
		"zipCodeErrorMessage"
	])

	return (
		<Panel flex={1}>
			<Flex flexDirection={"column"} flex={1}>
				<Text variant="headline-6" mb={2}>
					{locales.deliveryTitle}
				</Text>
				{user.data?.user?.currentCustomer?.usingDropship && (
					<Flex mb={2}>
						<Checkbox
							label={locales.dropshipAddressTitle}
							onChange={() => setIsDropShipAddress(prev => !prev)}
						/>
					</Flex>
				)}
				{isDropShipAddress && (
					<Flex flexDirection={"column"}>
						<Flex mb={2}>
							{/* TODO: When google creds are in place, use FindAddressForm instead */}
							<TempDropShipAddressForm />
						</Flex>
					</Flex>
				)}
				{!isDropShipAddress && (
					<Flex mb={4}>
						{props.orderAddresses && (
							<Select
								name="selectedAddress"
								error={!!errors.selectedAddress}
								inputRef={register({
									required: true
								})}
								options={props.orderAddresses?.map(address => ({
									label: `${address.name}, ${address.address}, ${address.postalCode}, ${address.country}`,
									value: address.id
								}))}
							/>
						)}
					</Flex>
				)}
				<Text variant="headline-6" mb={2}>
					{locales.deliveryOptionsTitle}
				</Text>
				<Flex borderBottom borderColor="grey-1" pb={2} mb={4}>
					<Flex
						flex={1}
						flexDirection={{
							_: "column",
							s: "row"
						}}
						maxw={60}
						justifyContent="space-between"
					>
						<Flex
							flexDirection={{
								_: "column",
								s: "row"
							}}
						>
							{!isDropShipAddress && (
								<Flex
									pr={{
										s: 2
									}}
									mb={2}
								>
									<Radio
										label={locales.partialDeliveryTitle}
										name="deliveryOption"
										value={DeliveryOptionEnum.Partial}
										inputRef={register({
											required: true
										})}
									/>
								</Flex>
							)}
							<Flex mb={2}>
								<Radio
									label={locales.fullDeliveryTitle}
									name="deliveryOption"
									value={DeliveryOptionEnum.Full}
									inputRef={register({
										required: true
									})}
								/>
							</Flex>
						</Flex>
						<Flex
							mb={2}
							flexDirection={{
								_: "column",
								s: "row"
							}}
						>
							{user.data?.user?.isSeller && (
								<Checkbox
									label={locales.freeFreightTitle}
									name="freeFreight"
									inputRef={register()}
								/>
							)}
						</Flex>
					</Flex>
				</Flex>
				<Text variant="headline-6" mb={2}>
					{locales.notificationsTitle}
				</Text>
				<Flex mb={2}>
					<Checkbox
						label={locales.smsNotificationTitle}
						checked={isSmsNotification}
						onChange={() => {
							if (isDropShipAddress) return
							setIsSmsNotification(prev => !prev)
						}}
					/>
				</Flex>
				{isSmsNotification && (
					<Flex flex={1} w={1} maxw={60}>
						<React.Suspense fallback={<Loader />}>
							<PhoneNumberField
								name="smsNotification.phone"
								register={register}
								trigger={trigger}
								getValues={getValues}
								label={locales.phoneTitle}
								helperText={
									errors.smsNotification?.phone && locales.phoneErrorMessage
								}
								error={!!errors.smsNotification?.phone}
							/>
						</React.Suspense>
					</Flex>
				)}
			</Flex>
		</Panel>
	)
}

export { DeliveryInputs }
