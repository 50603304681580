import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type CategoryLinksBlockFieldsFragment = (
  { __typename?: 'categoryLinksBlock' }
  & Pick<Types.CategoryLinksBlock, 'id' | 'type'>
  & { links?: Types.Maybe<Array<(
    { __typename?: 'link' }
    & Pick<Types.Link, 'alt' | 'title' | 'url' | 'target'>
  )>> }
);

export const CategoryLinksBlockFieldsFragmentDoc = gql`
    fragment categoryLinksBlockFields on categoryLinksBlock {
  id
  type
  links {
    alt
    title
    url
    target
  }
}
    `;