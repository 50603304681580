import * as React from "react"
import {
	Flex,
	StackNavigation,
	StackNavigationView,
	StackNavigationContext,
	Icon,
	Drawer,
	Symbol,
	PrimaryMenu
} from "@focus-nordic/ui-components"
import { CustomerTypeEnum, Menu } from "../../@types/graphql.generated"
import { Z_INDEX, SITE_MAX_WIDTH, MENU_HEIGHT } from "../../constants/index"
import { Link } from "react-router-dom"
import { UiContext } from "../../context"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { trackMainMenuClickEvent } from "./utils"
import { CartDropDown } from "./CartDropDown"
import { SearchDropDown } from "./SearchDropDown"
import { Login } from "./Login"
import { User } from "./User"
import { GetUserQuery } from "../../operations/user.generated"
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn"
interface MobileHeaderProps extends Partial<GetUserQuery> {
	primaryMenu: Menu[]
	secondaryMenu: Menu[]
}

type Locales = {
	allIn: string
	logIn: string
}

const mapMenu = (
	toggleMenu: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
	trackMainMenuClickEvent: (
		linkLabel: string,
		userType: CustomerTypeEnum
	) => void,
	menu: Menu[],
	secondaryMenu: Menu[] = [],
	locales: Locales,
	isLoggedIn: boolean,
	previousMenu?: Menu
): StackNavigationView[] => {
	const view = {
		id: String(menu[0]?.id),
		title: previousMenu?.title,
		component: () => (
			<>
				{previousMenu?.url ? (
					<StackNavigation.Link to={previousMenu.url!} onClick={toggleMenu}>
						{locales.allIn} {previousMenu.title}
					</StackNavigation.Link>
				) : null}
				{menu.map((item, i) =>
					item.children && item.children.length > 0 ? (
						<StackNavigation.Nav to={String(item.children[0].id)} key={i}>
							{item.title}
						</StackNavigation.Nav>
					) : (
						item.url && (
							<StackNavigation.Link
								to={item.url}
								onClick={e => {
									toggleMenu(e)
									trackMainMenuClickEvent(
										item.title,
										isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C
									)
								}}
								key={i}
							>
								{item.title}
							</StackNavigation.Link>
						)
					)
				)}
				{secondaryMenu.map((item, i) => (
					<StackNavigation.Link
						to={item.url || ""}
						onClick={toggleMenu}
						key={i}
					>
						{item.title}
					</StackNavigation.Link>
				))}
			</>
		)
	}
	return [
		{ ...view },
		...menu.reduce<StackNavigationView[]>(
			(acc, item) =>
				item.children
					? [
							...acc,
							...mapMenu(
								toggleMenu,
								trackMainMenuClickEvent,
								[...item.children],
								[],
								locales,
								isLoggedIn,
								item
							)
					  ]
					: acc,
			[]
		)
	]
}

const MobileHeader: React.FC<MobileHeaderProps> = props => {
	const isLoggedIn = useIsLoggedIn()
	const {
		primaryMobileMenuOpen,
		togglePrimaryMobileMenuOpen
	} = React.useContext(UiContext)

	const locales: Locales = useLocales(translationNamespace.shared("Header"), [
		"logIn",
		"allIn"
	])

	return (
		<Flex w={1} display={{ _: "flex", l: "none" }} flexDirection="column">
			<PrimaryMenu>
				<Flex w={1} h={MENU_HEIGHT.mobile}>
					<Flex
						w={1}
						h={MENU_HEIGHT.mobile}
						maxw={SITE_MAX_WIDTH}
						px={{ _: 2, l: 5 }}
						py={1.25}
						justifyContent="space-between"
						alignItems="center"
						borderBottom
						borderColor="grey-2"
						position="fixed"
						zIndex={Z_INDEX.primaryMenu}
						backgroundColor="white"
					>
						<Flex flex={1} justifyContent="space-between">
							<Flex>
								<button onClick={togglePrimaryMobileMenuOpen}>
									<Icon icon="menu" color="black" mr={3} />
								</button>
								<Link to="/">
									<Symbol.Logo w={10} h={3} />
								</Link>
							</Flex>
							<Flex>
								<Flex mr={2}>
									<SearchDropDown />
								</Flex>
								<Flex mr={2.5}>
									<CartDropDown />
								</Flex>
								<Login />
							</Flex>
						</Flex>
						<Drawer
							isOpened={primaryMobileMenuOpen}
							onClose={togglePrimaryMobileMenuOpen}
						>
							<StackNavigation
								initialViewId={String(props.primaryMenu[0]?.id)}
								views={mapMenu(
									togglePrimaryMobileMenuOpen,
									trackMainMenuClickEvent,
									props.primaryMenu,
									props.secondaryMenu,
									locales,
									isLoggedIn
								)}
							>
								<Drawer.Header>
									<StackNavigationContext.Consumer>
										{({ history, goBack, currentView }) => (
											<>
												<Flex>
													{history.length > 1 && (
														<button onClick={goBack}>
															<Icon color="black" icon="chevron-left" />
														</button>
													)}
												</Flex>
												<Flex>
													{history.length > 1 && (
														<Drawer.Title>{currentView?.title}</Drawer.Title>
													)}
												</Flex>
												<Flex>
													<Drawer.CloseButton />
												</Flex>
											</>
										)}
									</StackNavigationContext.Consumer>
								</Drawer.Header>
								<Drawer.Content>
									<StackNavigation.Views />
								</Drawer.Content>
							</StackNavigation>
						</Drawer>
					</Flex>
				</Flex>
			</PrimaryMenu>
			{props.user && (
				<Flex w={1} py={1} px={2} borderBottom>
					<User user={props.user} />
				</Flex>
			)}
		</Flex>
	)
}

export { MobileHeader }
