import React from "react"

export const usePreviousNonNullish = <T>(value: T): T => {
	const [_value, setValue] = React.useState<T>(value)
	React.useEffect(() => {
		if (value !== null && value !== undefined) {
			setValue(value)
		}
	}, [value])
	return _value
}
