import { CartItem } from "../@types/graphql.generated"
import { idb } from "./index"

export async function cartItemsDbConnect() {
	const db = await idb
	async function saveCartItems(cartItems: CartItem[]) {
		const tx = db.transaction("cartItems", "readwrite")
		const store = tx.objectStore("cartItems")
		// empty store
		await store.clear()
		// insert new cart items
		await Promise.all(
			cartItems.map(item => {
				return store.put(item)
			})
		)
		await tx.done
	}

	async function getCartItems() {
		const tx = db.transaction("cartItems", "readwrite")
		const store = tx.objectStore("cartItems")
		const cartItems = await store.getAll()
		await tx.done
		return cartItems
	}

	return {
		saveCartItems,
		getCartItems,
	}
}
