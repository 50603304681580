import React from 'react'
import { TableColumn, ConditionalStyles } from "react-data-table-component"
import { Layout, LayoutProps } from '../../Layout'
import { useGetCustomerStatisticsQuery } from './operations.generated'
import { PriceInfo } from '../../../../@types/graphql.generated'
import { translationNamespace, useLocales } from '../../../../hooks'
import { Panel } from '../../components'
import { Loader } from '../../../Loader'
import { Flex, Table, Text, colors } from '@focus-nordic/ui-components'
import { formatPriceInfo } from '../../../../utils'
import { LocalesContext } from '../../../../context'

enum CustomerStatisticsKeys {
    CURRENTYEARSTURNOVER ="currentYearsTurnover",
    PREVIOUSYEARSTURNOVER = "previousYearsTurnover",
    NEXTPREVIOUSYEARSTURNOVER = "nextPreviousYearsTurnover",
    INVOICEDAMOUNT = "invoicedAmount",
    INVOICEDAMOUNTDUE = "invoicedAmountDue"
}

interface CustomerLedgerViewProps extends LayoutProps { }

interface ColumnsProps {
    id: number
    title: string
    value: PriceInfo
}

export const CustomerLedgerView: React.FC<CustomerLedgerViewProps> = props => {
    const customerStatisticsData = useGetCustomerStatisticsQuery()
    const customerStatistics = customerStatisticsData?.data?.customerStatistics
    const data = customerStatistics ? Object.entries(customerStatistics)
        .filter(([key]) => !["__typename", "customerIdentifier", "currency"].includes(key))
        .map(([key, value], index) => ({
            id: index,
            title: key, 
            value: {
                netPrice: value,
                currency: customerStatistics.currency
            }
        })) as ColumnsProps[] : []

    const { localeCode } = React.useContext(LocalesContext)

    const locales = useLocales("myPages_customerLedger", [
        "customerLedgerPageTitle",
        "customerLedgerTurnover",
        "customerLedgerBalance",
        "customerLedgerBalanceDue",
    ])

    const sharedLocales = useLocales(translationNamespace.shared("Common"), ["noRecordsMessage"])

    const cartLocales = useLocales(translationNamespace.shared("Cart"), ["grossPrice", "netPrice"])

    const columns: TableColumn<ColumnsProps>[] = [
        {
            cell: row => {
                let title= "", isIncludesVAT = false;
                switch (row.title) {
                    case CustomerStatisticsKeys.CURRENTYEARSTURNOVER:
                        title = `${locales.customerLedgerTurnover} ${new Date().getFullYear()}`
                        break;
                    case CustomerStatisticsKeys.PREVIOUSYEARSTURNOVER:
                        title = `${locales.customerLedgerTurnover} ${(new Date().getFullYear() - 1)}`
                        break;
                    case CustomerStatisticsKeys.NEXTPREVIOUSYEARSTURNOVER:
                        title = `${locales.customerLedgerTurnover} ${(new Date().getFullYear() - 2)}`
                        break;
                    case CustomerStatisticsKeys.INVOICEDAMOUNT:
                        title = locales.customerLedgerBalance
                        isIncludesVAT = true
                        break;
                    case CustomerStatisticsKeys.INVOICEDAMOUNTDUE:
                        title = locales.customerLedgerBalanceDue
                        isIncludesVAT = true
                        break;
                    default:
                        break;
                }
                return (
                    <Flex flexDirection="column">
                        <Text textColor="black" mb={0} fontSize={2}>{title}</Text>
                        <Text as="span" fontSize={1.5} lineHeight={2} mb={0}>
                            {isIncludesVAT ? cartLocales.grossPrice : cartLocales.netPrice}
                        </Text>
                    </Flex>
                )
            },
            maxWidth: "325px",
        },
        {
            cell: row => (
                <Flex flex={2} size={2} alignItems="center" justifyContent={{ _: "flex-end", l: "flex-start" }}>
                    <Text fontSize={2} mb={0}>
                        {row.value ? (row.value.netPrice === 0 ? row.value.netPrice : formatPriceInfo(row.value, localeCode)) : ""}
                    </Text>
                </Flex>
            ),
            grow: 2
        }
    ]

    const conditionalRowStyles: ConditionalStyles<ColumnsProps>[] = [
        {
            when: (row: ColumnsProps) => row.id % 2 === 0,
            style: {
                backgroundColor: colors["white"]
            },
        },
        {
            when: (row: ColumnsProps) => row.id % 2 !== 0,
            style: {
                backgroundColor: colors["grey-7"]
            },
        },
    ]

    return (
        <Layout {...props}>
            <Panel.Title>{locales.customerLedgerPageTitle}</Panel.Title>
            <Flex flexDirection="column" py={1} px={2} mx={{_: 1.25, s: 0}} backgroundColor="white">
                <Table
                    columns={columns}
                    data={data}
                    noRecordsMessage={sharedLocales.noRecordsMessage}
                    progressPending={customerStatisticsData.loading}
                    progressComponent={<Loader minh={40}/>}
                    noTableHead={true}
                    conditionalRowStyles={conditionalRowStyles}
                />
			</Flex>
        </Layout> 
    )
}
