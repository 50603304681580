import * as React from "react"
import {
	PrimaryMenu,
	PrimaryMenuContext,
	Icon
} from "@focus-nordic/ui-components"
import { DropDownContainer } from "./DropDownContainer"
import { SearchSuggestion } from "../SearchSuggestion"

const SearchDropDown = () => {
	return (
		<>
			<PrimaryMenu.ItemDropDown
				dropDownId="search"
				component={<Icon icon="search" />}
			/>
			<PrimaryMenu.DropDown id="search">
				<PrimaryMenuContext.Consumer>
					{({ closeAllDropDowns }) => (
						<DropDownContainer overflow="visible">
							<SearchSuggestion clearOnSubmit onSubmit={closeAllDropDowns} />
						</DropDownContainer>
					)}
				</PrimaryMenuContext.Consumer>
			</PrimaryMenu.DropDown>
		</>
	)
}

export { SearchDropDown }
