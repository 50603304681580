import React from "react"
import { Flex, Select, Text, TextField } from "@focus-nordic/ui-components"
import { useLocales } from "../../../../hooks"
import { useCheckout } from "./context/CheckoutProvider"
import { countries } from "../../countryCodes"
import {
	MAX_LENGTH_CITY_NAME,
	MAX_LENGTH_INPUT,
	MAX_LENGTH_POSTAL_CODE
} from "../../consts"
import { isNonEmptyString } from "../../../../utils/fieldsConfig"

interface TempDropShipAddressFormProps {}

export const TempDropShipAddressForm: React.FC<TempDropShipAddressFormProps> = props => {
	const { form } = useCheckout()
	const { register, errors } = form!

	const locales = useLocales("myPages_checkout", [
		"deliveryNameTitle",
		"deliveryAddressTitle",
		"deliveryAddressTitleTwo",
		"deliveryZipCodeTitle",
		"deliveryCityTitle",
		"dropshipCompleteAddressTitle",
		"deliveryCountryTitle"
	])

	return (
		<Flex flexDirection="column" flex={1}>
			<Text variant="headline-6" mb={2}>
				{locales.dropshipCompleteAddressTitle}
			</Text>
			<Flex mb={2}>
				<TextField
					label={locales.deliveryNameTitle}
					name="shippingAddress.name"
					error={!!errors.shippingAddress?.name}
					inputRef={register({
						required: true,
						validate: isNonEmptyString
					})}
					maxLength={MAX_LENGTH_INPUT}
				/>
			</Flex>
			<Flex mb={2}>
				<TextField
					label={locales.deliveryAddressTitle}
					name="shippingAddress.addressOne"
					error={!!errors.shippingAddress?.addressOne}
					inputRef={register({
						required: true,
						validate: isNonEmptyString
					})}
					maxLength={MAX_LENGTH_INPUT}
				/>
			</Flex>
			<Flex mb={2}>
				<TextField
					label={locales.deliveryAddressTitleTwo}
					name="shippingAddress.addressTwo"
					inputRef={register()}
					maxLength={MAX_LENGTH_INPUT}
				/>
			</Flex>
			<Flex flex={1} w={1} flexDirection={{ _: "column", s: "row" }}>
				<Flex mb={2} flex={1} pr={{ s: 2 }}>
					<TextField
						label={locales.deliveryZipCodeTitle}
						name="shippingAddress.postalCode"
						error={!!errors.shippingAddress?.postalCode}
						inputRef={register({
							required: true,
							validate: isNonEmptyString
						})}
						maxLength={MAX_LENGTH_POSTAL_CODE}
					/>
				</Flex>
				<Flex mb={2} flex={2}>
					<TextField
						label={locales.deliveryCityTitle}
						name="shippingAddress.city"
						error={!!errors.shippingAddress?.city}
						inputRef={register({
							required: true,
							validate: isNonEmptyString
						})}
						maxLength={MAX_LENGTH_CITY_NAME}
					/>
				</Flex>
			</Flex>
			<Flex mb={4}>
				<Select
					label={locales.deliveryCountryTitle}
					options={countries}
					name="shippingAddress.country"
					error={!!errors.shippingAddress?.country}
					inputRef={register({
						required: true,
						validate: isNonEmptyString
					})}
				/>
			</Flex>
		</Flex>
	)
}
