import React from "react"
import { Spinner as Loader, Flex, colors } from "@focus-nordic/ui-components"

const Spinner: React.FC = () => (
	<Flex flexDirection="column" alignItems="center">
		<Loader size={4} strokeColor={colors["grey-3"]} />
	</Flex>
)

export { Spinner }
