import { GalleryCard } from "../@types/graphql.generated"
import { imagePath } from "./"
import { GalleryBannerItem } from "@focus-nordic/ui-components/dist/components/GalleryBanner/types"

const generateSrcSet = (cardIndex: number, imageName: string, cardsCount: number) => {
    let mediumOrLargeItem = false;
    let srcSet = []
    const responsiveSrcSet = [`${imagePath(imageName, "16:9=768x432", true)}`]

    switch (cardsCount) {
        case 3:
            if (cardIndex === 0) {
                mediumOrLargeItem = true
                responsiveSrcSet.push(`${imagePath(imageName, "16:9=1536x864", true)}`)
            }
            break;
        case 4:
            if (cardIndex === 0 || cardIndex === 1) {
                mediumOrLargeItem = true
                responsiveSrcSet.push(`${imagePath(imageName, "16:9=1536x864", true)}`)
            }
            break;
        case 5:
            if (cardIndex === 0) {
                mediumOrLargeItem = true
                responsiveSrcSet.push(`${imagePath(imageName, "8:3=1536x576", true)}`)
            }
            break;
        default:
            responsiveSrcSet.push(`${imagePath(imageName, "16:9=1536x864", true)}`)
            break;
    }

    srcSet = mediumOrLargeItem ? [
        `${imagePath(imageName, "4:5=384x480", true)}`,
        `${imagePath(imageName, "4:5=768x960", true)}`
    ] : [
        `${imagePath(imageName, "16:9=768x432", true)}`
    ]

    return {
        srcSet,
        responsiveSrcSet
    };
}

export const galleryBannerDataToProps = (cards: GalleryCard[] | undefined) => {
    return cards && cards.length > 0 ? 
        cards.map((card: GalleryCard, index: number) => {
            const { image, ...props } = card

            return {
                ...props,
                ...(image && {
                    image: {
                        src: imagePath(image.fileName, "4:5=384x480"),
                        meta: image.meta,
                        srcSet: generateSrcSet(index, image.fileName, cards.length).srcSet,
                        responsiveSrcSet: {
                            s: generateSrcSet(index, image.fileName, cards.length).responsiveSrcSet
                        }
                    }
                })
            }
        }) as GalleryBannerItem[] : 
    []
}