import * as Types from '../../../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetCustomerStatisticsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomerStatisticsQuery = (
  { __typename?: 'Query' }
  & { customerStatistics?: Types.Maybe<(
    { __typename?: 'CustomerStatisticsModel' }
    & Pick<Types.CustomerStatisticsModel, 'currency' | 'customerIdentifier' | 'currentYearsTurnover' | 'previousYearsTurnover' | 'nextPreviousYearsTurnover' | 'invoicedAmount' | 'invoicedAmountDue'>
  )> }
);


export const GetCustomerStatisticsDocument = gql`
    query GetCustomerStatistics {
  customerStatistics {
    currency
    customerIdentifier
    currentYearsTurnover
    previousYearsTurnover
    nextPreviousYearsTurnover
    invoicedAmount
    invoicedAmountDue
  }
}
    `;

/**
 * __useGetCustomerStatisticsQuery__
 *
 * To run a query within a React component, call `useGetCustomerStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerStatisticsQuery, GetCustomerStatisticsQueryVariables>) {
        return Apollo.useQuery<GetCustomerStatisticsQuery, GetCustomerStatisticsQueryVariables>(GetCustomerStatisticsDocument, baseOptions);
      }
export function useGetCustomerStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerStatisticsQuery, GetCustomerStatisticsQueryVariables>) {
          return Apollo.useLazyQuery<GetCustomerStatisticsQuery, GetCustomerStatisticsQueryVariables>(GetCustomerStatisticsDocument, baseOptions);
        }
export type GetCustomerStatisticsQueryHookResult = ReturnType<typeof useGetCustomerStatisticsQuery>;
export type GetCustomerStatisticsLazyQueryHookResult = ReturnType<typeof useGetCustomerStatisticsLazyQuery>;
export type GetCustomerStatisticsQueryResult = Apollo.QueryResult<GetCustomerStatisticsQuery, GetCustomerStatisticsQueryVariables>;