import * as React from "react"
import { TextEditorBlock as ITextEditorBlock } from "../../@types/graphql.generated"
import { Flex, ShowMore } from "@focus-nordic/ui-components"
import { RTE_CONTENT_MAX_WIDTH, RTE_CONTAINER_MAX_WIDTH } from "../../constants"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { RteContent } from '../RteContent';

export interface TextEditorBlockProps extends ITextEditorBlock {}

const TextEditorBlock: React.FC<TextEditorBlockProps> = props => {
	const locales = useLocales(translationNamespace.shared("Common"), [
		"readMore"
	])

	return props.content ? (
		<Flex
			w={1}
			pt={{ _: 3, m: 5 }}
			pb={{ _: 4, m: 6 }}
			px={{ _: 2.25, m: 5 }}
			maxw={RTE_CONTAINER_MAX_WIDTH}
			id={props.id}
			flexDirection="column"
		>
			<Flex maxw={RTE_CONTENT_MAX_WIDTH}>
				{props.collapsible ? (
					<ShowMore cta={locales.readMore} height={35}>
						<RteContent content={props.content} />
					</ShowMore>
				) : (
					<RteContent content={props.content} />
				)}
			</Flex>
		</Flex>
	) : null
}

export { TextEditorBlock }
