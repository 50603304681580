import { useForm } from "react-hook-form"
import { Country } from "../../@types/graphql.generated"
import { EMAIL_VALIDATION_PATTERN } from "../../constants"
import {
	CustomerApplicationInput,
	CustomerApplicationAddressInput
} from "../../@types/graphql.generated"
import {
	TextFieldProps
} from "@focus-nordic/ui-components/dist"
import { getFieldRulesConfig } from "../../utils/fieldsConfig"

// WrapperUseForm is only used to extract RetrunType of the generic function useForm
const WrapperUseForm = () => useForm<CustomerApplicationInput>()
type UseForm = ReturnType<typeof WrapperUseForm>

export type AddressFieldKeys = keyof CustomerApplicationAddressInput

export type FieldConfig = {
	fieldType: "text" | "select" | "vatRegistrationNumber"
	inputType: "text" | "number" | "email"
	translationKey: string
	registerConfig: Omit<Parameters<UseForm["register"]>[0], "name">
	fieldProps?: TextFieldProps
	options?: Country[]
}

export type AddressFeildsConfig = Record<AddressFieldKeys, FieldConfig>

export const getAddressFeildsConfig = (): Omit<AddressFeildsConfig, "country"> => {
    return {
        name: {
            registerConfig: { required: true },
            fieldType: "text",
            inputType: "text",
            translationKey: "name"
        },
        contact: {
            registerConfig: { required: true },
            fieldType: "text",
            inputType: "text",
            translationKey: "contact"
        },
        addressOne: {
            registerConfig: { required: true },
            fieldType: "text",
            inputType: "text",
            translationKey: "addressOne"
        },
        addressTwo: {
            registerConfig: { required: false },
            fieldType: "text",
            inputType: "text",
            translationKey: "addressTwo"
        },
        postalCode: {
            registerConfig: { required: true },
            fieldType: "text",
            inputType: "text",
            translationKey: "postalCode"
        },
        city: {
            registerConfig: { required: true },
            fieldType: "text",
            inputType: "text",
            translationKey: "city"
        }
    }
}

export const countryField: FieldConfig = {
	registerConfig: { required: true },
	fieldType: "select",
	inputType: "text",
	translationKey: "country"
}

export type GeneralInfoFieldKeys = keyof Omit<
	CustomerApplicationInput,
	"invoiceAddress" | "deliveryAddress" | "deliveryAddressSameAsInvoiceAddress"
>

export type GeneralInfoFieldsConfig = Record<GeneralInfoFieldKeys, FieldConfig>

export const getGeneralInfoFieldsConfig = (locales: Record<string, string>, localeCode: string): GeneralInfoFieldsConfig => {
	const registrationNumberConfig = getFieldRulesConfig(localeCode, locales).orgNumber

    return {
        companyName: {
            registerConfig: { required: true },
            fieldType: "text",
            inputType: "text",
            translationKey: "companyName"
        },
        registrationNumber: {
            registerConfig: registrationNumberConfig.registerOptions,
            fieldProps: registrationNumberConfig.props,
            fieldType: "text",
            inputType: "text",
            translationKey: "registrationNumber"
        },
        vatRegistrationNumber: {
            registerConfig: { required: true },
            fieldType: "vatRegistrationNumber",
            inputType: "text",
            translationKey: "vatRegistrationNumber",
        },
        url: {
            registerConfig: { required: true },
            fieldType: "text",
            inputType: "text",
            translationKey: "url"
        },
        typeOfCompany: {
            registerConfig: { required: true },
            fieldType: "text",
            inputType: "text",
            translationKey: "typeOfCompany"
        },
        turnoverCurrentYear: {
            registerConfig: { required: true, valueAsNumber: true },
            fieldType: "text",
            inputType: "number",
            translationKey: "turnoverCurrentYear"
        },
        turnoverLastYear: {
            registerConfig: { required: true, valueAsNumber: true },
            fieldType: "text",
            inputType: "number",
            translationKey: "turnoverLastYear"
        },
        name: {
            registerConfig: { required: true },
            fieldType: "text",
            inputType: "text",
            translationKey: "name"
        },
        phone: {
            registerConfig: { required: true },
            fieldType: "text",
            inputType: "text",
            translationKey: "phone"
        },
        email: {
            registerConfig: {
                required: true,
                pattern: {
                    value: EMAIL_VALIDATION_PATTERN,
                    message: locales.incorrectEmailFormat,
                }
            },
            fieldType: "text",
            inputType: "email",
            translationKey: "email"
        },
        authorizedSignatory: {
            registerConfig: { required: true },
            fieldType: "text",
            inputType: "text",
            translationKey: "authorizedSignatory"
        },
        invoiceEmailAddress: {
            registerConfig: {
                required: true,
                pattern: {
                    value: EMAIL_VALIDATION_PATTERN,
                    message: locales.incorrectEmailFormat,
                }
            },
            fieldType: "text",
            inputType: "email",
            translationKey: "invoiceEmailAddress"
        }
    };
};
