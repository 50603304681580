import * as React from "react"
import { Rte } from "@focus-nordic/ui-components"
import { createMarkup } from "../../utils"

export const RteContent: React.FC<{
	content: string
}> = props => (
	<Rte>
		<div dangerouslySetInnerHTML={createMarkup(props.content)} />
	</Rte>
)