import * as React from "react"
import { Flex, Text } from "@focus-nordic/ui-components"
import { FeaturedProductsBlockFieldsFragment } from "./operations.generated"
import { CardLayout } from "../../@types/graphql.generated"
import { ProductCardList } from ".."
import { SITE_MAX_WIDTH } from "../../constants/index"

export interface FeaturedProductsBlockProps extends FeaturedProductsBlockFieldsFragment {}

const FeaturedProductsBlock: React.FC<FeaturedProductsBlockProps> = props => (
	props.featuredProducts && props.featuredProducts.length > 0 ? (
		<Flex 	
			maxw={SITE_MAX_WIDTH}
			mt={{ _: 3, m: 5 }}
			mb={{ _: 4, m: 6 }}
			flexDirection="column"
			w={1}
		>
			{(props.featuredProductsTitle || props.featuredProductsDescription) && (
				<Flex 
					flexDirection="column" 				
					px={{ _: 2, m: 6, xl: 8 }}	
					mb={{ _: 2, m: 4 }}
				>
					{props.featuredProductsTitle && (
						<Text 
							variant="headline-3"
							as="h2"
							fontSize={{ _: 2, m: 4 }}
							lineHeight={{ _: 2.5, m: 4 }}
							mb={{ _: 1, m: 1.5 }} 
						>
							{props.featuredProductsTitle}
						</Text>
					)}
					{props.featuredProductsDescription && (
						<Text 
							variant="paragraph-1"
							fontSize={{ _: 1.75, m: 2 }}
							lineHeight={{ _: 2.5, m: 3 }}
							mb={0}
						>
							{props.featuredProductsDescription}
						</Text>
					)}
				</Flex>
			)}
			<Flex
				px={{ _: 1, m: 5, xl: 6 }}
				overflow="auto"
			>
				<ProductCardList
					cardLayout={CardLayout.Large}
					products={props.featuredProducts}
					columnWidth={{ m: 1 / 5 }}
					columnFlex={{ _: "0 0 166px", m: "none"}}
					withoutBottomSeparator={true}
					simpleView={true}
				/>
			</Flex>
			<Flex 
				borderBottom 
				ml={{ _: 2, m: 6, xl: 8 }} 
				mr={{ _: 2, m: 6, xl: 8 }} 
				mt={{ _: 2, xl: 4 }} 
			>
			</Flex>
		</Flex>
	) : null
)

export { FeaturedProductsBlock }
