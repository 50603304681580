import * as React from "react"
import { Translation } from "../@types/graphql.generated"
import { useGetTranslationsQuery } from "./operations.generated"
import { translationsArrayToRecord } from "../utils"

export type TranslationsRecord = Record<string, string>

export interface LocalesContextProviderProps {
	localeCode: string
	translations?: Record<string, string>
}
export interface LocalesContextValue extends LocalesContextProviderProps {
	translations: Record<string, string>
}

export const LocalesContext = React.createContext<LocalesContextValue>({
	translations: {},
	localeCode: ""
})

export const LocalesContextProvider: React.FC<LocalesContextProviderProps> = props => {
	const translationsQuery = useGetTranslationsQuery()

	return (
		<LocalesContext.Provider
			value={{
				localeCode: props.localeCode,

				translations: {
					...(translationsQuery.data?.translations
						? translationsArrayToRecord(
								translationsQuery.data.translations as Translation[]
						  )
						: {}),
					...props.translations
				}
			}}
		>
			{props.children}
		</LocalesContext.Provider>
	)
}
