import { Flex, Text } from "@focus-nordic/ui-components"
import React from "react"
import { useParams } from "react-router"
import { RteContent } from "../.."
import { useLocales } from "../../../hooks"
import { Panel } from "../components"
import { Layout, LayoutProps } from "../Layout"

interface OrderConfirmationViewProps extends LayoutProps {}

type UrlParams = {
	orderId: string
}

export const OrderConfirmationView: React.FC<OrderConfirmationViewProps> = props => {
	const locales = useLocales("myPages_checkout", [
		"orderConfirmationTitle",
		"orderNumberTitle",
		"orderConfirmationText"
	])
	const { orderId } = useParams<UrlParams>()

	return (
		<Layout {...props}>
			<Flex w={1} flexDirection="column" alignItems="center">
				<Panel w={0.75} flexDirection={"column"} p={8}>
					<Text variant="headline-4">{locales.orderConfirmationTitle}</Text>
					<Text
						variant="headline-5"
						mb={2}
					>{`${locales.orderNumberTitle}: ${orderId}`}</Text>
					<RteContent content={locales.orderConfirmationText} />
				</Panel>
			</Flex>
		</Layout>
	)
}
