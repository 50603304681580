import * as Types from '../../@types/graphql.generated';

import { CommonProductFieldsFragment, ProductRangeFieldsFragment } from '../../operations/product.generated';
import { gql } from '@apollo/client';
import { CommonProductFieldsFragmentDoc, ProductRangeFieldsFragmentDoc } from '../../operations/product.generated';
export type FeaturedProductsBlockFieldsFragment = (
  { __typename?: 'featuredProductsBlock' }
  & Pick<Types.FeaturedProductsBlock, 'id' | 'type' | 'featuredProductsTitle' | 'featuredProductsDescription'>
  & { featuredProducts?: Types.Maybe<Array<(
    { __typename?: 'product' }
    & CommonProductFieldsFragment
  )>> }
);

export const FeaturedProductsBlockFieldsFragmentDoc = gql`
    fragment featuredProductsBlockFields on featuredProductsBlock {
  id
  type
  featuredProductsTitle
  featuredProductsDescription
  featuredProducts {
    ...commonProductFields
  }
}
    ${CommonProductFieldsFragmentDoc}`;