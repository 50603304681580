import React from "react"

export interface ISHellProps {
	head?: React.FC
	main?: React.FC
	footer?: React.FC
}

export const AppShell: React.FC<ISHellProps> = props => {
	const { head: Head, main: Main, footer: Footer } = props
	return (
		<>
			{Head && <Head />}
			{Main && <Main />}
			{Footer && <Footer />}
		</>
	)
}
