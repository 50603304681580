"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStatuses = void 0;
var OrderStatuses;
(function (OrderStatuses) {
    OrderStatuses["FULLYSHIPPED"] = "FULLYSHIPPED";
    OrderStatuses["PARTIALLYSHIPPED"] = "PARTIALLYSHIPPED";
    OrderStatuses["INPROGRESS"] = "INPROGRESS";
    OrderStatuses["ERROR"] = "ERROR";
})(OrderStatuses = exports.OrderStatuses || (exports.OrderStatuses = {}));
