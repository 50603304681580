import React from "react"
import {
	TextField,
	Text,
	Flex,
	Select,
	Button,
	Radio
} from "@focus-nordic/ui-components"
import { useForm } from "react-hook-form"
import { Panel } from "../../components"
import { useLocales, useCart } from "../../../../hooks"
import {
	GetDeliveryOptionsQueryVariables,
	useGetDeliveryOptionsLazyQuery
} from "../../operations.generated"
import { DeliveryOptions } from "./DeliveryOptions"
import { useCheckout } from "../../context/CheckoutProvider"
import { cartItemToCartItemInput } from "../../../../utils"
import {
	CustomerTypeEnum,
	DeliveryOption
} from "../../../../@types/graphql.generated"

export interface DeliveryStepProps {}

const DeliveryStep: React.FC<DeliveryStepProps> = props => {
	const locales = useLocales("checkout", [
		"deliveryTitle",
		"customerTypeConsumerLabel",
		"customerTypeCompanyLabel",
		"deliveryDescription",
		"countryPlaceholder",
		"postalCodePlaceholder",
		"postalCodeDescription",
		"viewFreightAlternativesCtaText"
	])

	const checkout = useCheckout()
	const { cart, initCart } = useCart()

	const postalCodeForm = useForm<GetDeliveryOptionsQueryVariables>()

	React.useEffect(() => {
		postalCodeForm.setValue("customerType", checkout.customerType)
	}, [checkout, postalCodeForm])

	const [getDeliveryOptions, deliveryOptions] = useGetDeliveryOptionsLazyQuery({
		fetchPolicy: "cache-and-network"
	})

	const [_deliveryOptions, setDeliveryOptions] = React.useState<
		DeliveryOption[]
	>([])

	React.useEffect(() => {
		if (!!deliveryOptions.data?.deliveryOptions?.length) {
			setDeliveryOptions(deliveryOptions.data.deliveryOptions)
		}
	}, [deliveryOptions])

	const onGetDeliveryOptionsSubmit = (
		data: GetDeliveryOptionsQueryVariables
	) => {
		checkout.setPaymentInput(prev => ({
			...prev,
			zipCode: data.zipCode,
			customerType: data.customerType
		}))

		getDeliveryOptions({
			variables: {
				...data,
				cartItems: cart.items?.map(cartItem =>
					cartItemToCartItemInput(cartItem)
				)
			}
		})
	}

	const customerTypeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		checkout.setCustomerType(event.target.value as CustomerTypeEnum) // set selected customer type in cartContext
		setDeliveryOptions([]) // reset fetched options on change
		checkout.setSelectedFreight({}) // reset selected delivery option on change -> otherwise sum will include previous selected freight option
		initCart(event.target.value as CustomerTypeEnum) // init cart with customer type to get new cart summary
	}

	const hasDeliveryOptions =
		!deliveryOptions.loading &&
		Boolean(deliveryOptions.data?.deliveryOptions?.length)

	return (
		<>
			<Panel mb={0} pb={hasDeliveryOptions ? 3 : { _: 2, m: 6 }}>
				<Panel.Title>{locales.deliveryTitle}</Panel.Title>
				<Flex
					as="form"
					onSubmit={postalCodeForm.handleSubmit(onGetDeliveryOptionsSubmit)}
					flexDirection="column"
				>
					<Flex mb={2} flexDirection={{ _: "column", m: "row" }}>
						<Flex mr={3} mb={2}>
							<Radio
								label={locales.customerTypeConsumerLabel}
								name="customerType"
								onChange={customerTypeHandler}
								value={CustomerTypeEnum.B2C}
								inputRef={postalCodeForm.register({
									required: true
								})}
							/>
						</Flex>
						<Flex mb={2}>
							<Radio
								label={locales.customerTypeCompanyLabel}
								name="customerType"
								onChange={customerTypeHandler}
								value={CustomerTypeEnum.B2E}
								inputRef={postalCodeForm.register({
									required: true
								})}
							/>
						</Flex>
					</Flex>
					<Text mb={1}>{locales.deliveryDescription}</Text>
					<Flex mb={2}>
						<Select
							options={[
								{
									value: locales.countryPlaceholder,
									label: locales.countryPlaceholder
								}
							]}
							disabled
						/>
					</Flex>
					<Flex mb={2}>
						<TextField
							label={locales.postalCodePlaceholder}
							helperText={locales.postalCodeDescription}
							name="zipCode"
							inputRef={postalCodeForm.register({
								required: true
							})}
						/>
					</Flex>
					<Button type="submit" disabled={deliveryOptions.loading}>
						{locales.viewFreightAlternativesCtaText}
					</Button>
				</Flex>
			</Panel>
			<DeliveryOptions
				loading={deliveryOptions.loading}
				deliveryOptions={_deliveryOptions}
			/>
		</>
	)
}

export { DeliveryStep }
