import { ProductCardProps } from "@focus-nordic/ui-components"
import { Product } from "../@types/graphql.generated"
import { formatPriceInfo, formatPercentage } from "./formatters"
import { formatDate } from "./dateHelpers"

export const productDataToProductCardProps = (
	product: Product,
	localeCode: string
): Omit<ProductCardProps, "locales"> => ({
	...(product.link.url ? { to: product.link.url } : { to: "" }),
	...(product.brandName && { brandName: product.brandName }),
	...(product.name && { name: product.name }),
	...(product.articleNumber && {
		articleNumber: product.articleNumber
	}),
	...(product.ean && { ean: product.ean }),
	...(product.rsp && { rsp: formatPriceInfo(product.rsp, localeCode) }),
	...(product.margin && { margin: formatPercentage(product.margin) }),
	...(product.fee && { fee: formatPriceInfo(product.fee, localeCode) }),
	...(product.stockStatus?.quantity && {
		quantity: Number(product.stockStatus.quantity)
	}),
	...(product.price?.netPrice && {
		price: formatPriceInfo(product.price, localeCode)
	}),
	...(product.mainImageSmall && {
		image: {
			src: product.mainImageSmall.src,
			width: Number(product.mainImageSmall.width),
			height: Number(product.mainImageSmall.height)
		}
	}),
	...(product.discount && {
		productDiscountedPrice: formatPriceInfo(product.discount.discountedPrice, localeCode),
	}),
	...(product.discount && product.productPreviousPrice && {
		productComparisonPrice: formatPriceInfo(product.productPreviousPrice, localeCode)
	}),
	...(product.discount?.startDate && {
		startDate: formatDate(product.discount.startDate),
		endDate: formatDate(product.discount.endDate)
	}),
	...(product.label && {
		label: product.label
	}),
	...(product.warranty !== undefined && {
		warranty: product.warranty
	}),
	...(product.availableItemsCount !== undefined && { availableItemsCount: product.availableItemsCount }),
})
