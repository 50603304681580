import * as React from "react"
import {
	TextField,
	Flex,
	Text,
	Checkbox,
	Select,
	Button,
	scale,
	LayoutProps
} from "@focus-nordic/ui-components"
import {
	CustomerApplicationBlock as ICustomerApplicationBlock,
	CustomerApplicationInput,
	BlockType,
	Country,
	CustomerTypeEnum
} from "../../@types/graphql.generated"
import {
	useCustomerApplicationMutation,
	CustomerApplicationMutationHookResult,
	CustomerApplicationMutation
} from "./operations.generated"
import { RTE_CONTENT_MAX_WIDTH } from "../../constants"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { useForm } from "react-hook-form"
import {
	countryField,
	FieldConfig,
	getGeneralInfoFieldsConfig,
	getAddressFeildsConfig,
	AddressFieldKeys
} from "./fieldsConfig"
import { objectDeepOmit } from "../../utils"
import { LocalesContext } from "../../context"
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn"
import { trackFormSubmit } from "../../utils/tracking"
import { getFieldRulesConfig } from "../../utils/fieldsConfig"
import VatNumberField from "../VatNumberField"

// WrapperUseForm is only used to extract RetrunType of the generic function useForm
const WrapperUseForm = () => useForm<CustomerApplicationInput>()
type UseForm = ReturnType<typeof WrapperUseForm>

// generalInfoFields translations
export const generalInfoFields = [
	"companyName",
	"registrationNumber", // 'organisation number' in form
	"vatRegistrationNumber",
	"url", // 'company url' in form
	"turnoverCurrentYear",
	"turnoverLastYear",
	"typeOfCompany",
	"name", // 'contact' in form
	"phone",
	"email",
	"authorizedSignatory", // 'company draftsman' in form,
	"invoiceEmailAddress"
]

// addressFields translations
export const addressFields: AddressFieldKeys[] = [
	"name",
	"contact",
	"addressOne",
	"addressTwo",
	"postalCode",
	"city",
	"country"
]

interface FormFieldsProps {
	useForm: UseForm
	loading?: boolean
	namespace?: keyof Pick<
		CustomerApplicationInput,
		"invoiceAddress" | "deliveryAddress"
	>
	locales: Record<string, string>
	commonLocales?: Record<string, string>
	formFields: Record<string, FieldConfig>
	countries?: Country[]
}

const Fieldset: React.FC<LayoutProps> = props => (
	<Flex
		border
		borderColor="grey-1"
		px={{ _: 1, s: 2 }}
		pt={{ _: 1, s: 2 }}
		justifyContent="space-between"
		flexDirection={{ _: "column", s: "row" }}
		flexWrap="wrap"
		{...props}
	/>
)

const FormFields: React.FC<FormFieldsProps> = props => {
	const { errors, register, getValues, trigger } = props.useForm
	const { localeCode } = React.useContext(LocalesContext)

	const locales = useLocales(
		translationNamespace.block(BlockType.Customerapplicationblock),
		["requiredField"]
	)
	const commonLocales = useLocales(translationNamespace.shared("Common"), [
		"exactFieldFormatText",
		"digitsOnlyFieldFormatText",
		"exactFieldLengthRuleText",
		"minLengthRuleText",
		"vatValidationErrorText"
	])

	const fieldRulesConfig = React.useMemo(() => {
		return getFieldRulesConfig(localeCode, commonLocales);
	}, [localeCode, commonLocales]);

	return (
		<Fieldset>
			{Object.keys(props.formFields).map(key => {
				const label = props.locales[props.formFields[key].translationKey]
				const fieldName = props.namespace ? `${props.namespace}.${key}` : key
				const registerConfig = props.formFields[key].registerConfig
				const fieldType = props.formFields[key].fieldType
				const inputType = props.formFields[key].inputType
				const fieldProps = props.formFields[key].fieldProps
				const error = props.namespace
					? errors[props.namespace]?.[key]
					: errors[key]

				return (
					<Flex
						key={`${props.namespace}-${key}`}
						flex={{ _: '0 0 100%', m: `0 0 calc(50% - ${scale.px(5)})`} }
						mb={{ _: 4, s: 5 }}
					>
						{{
							text: (
								<TextField
									inputRef={register(registerConfig)}
									error={error}
									{...(fieldProps && fieldProps)}
									helperText={error ? (error?.message || locales.requiredField) : undefined}
									name={fieldName}
									id={fieldName}
									label={label}
									disabled={props.loading}
									type={inputType}
								/>
							),
							select: (
								<Select
									placeholder={label}
									inputRef={register({
										...registerConfig,
										// check that value does not equal placeholder
										validate: val => val !== label
									})}
									id={fieldName}
									name={fieldName}
									error={error}
									options={props.formFields[key].options || []}
									helperText={error && locales.requiredField}
									disabled={props.loading}
									type={inputType}
								/>
							),
							vatRegistrationNumber: (
								<VatNumberField
									name="vatRegistrationNumber"
									register={register}
									trigger={trigger}
									getValues={getValues}
									vatNumberProps={fieldRulesConfig.vatNumber}
									error={!!errors.vatRegistrationNumber}
									helperText={error ? (errors.vatRegistrationNumber?.message || locales.requiredField) : undefined}
									label={label}
								/>
							)
						}[fieldType] || null}
					</Flex>
				)
			})}
		</Fieldset>
	)
}

interface FormProps extends ICustomerApplicationBlock {
	customerApplicationMutationHookResult: CustomerApplicationMutationHookResult
}

const Form: React.FC<FormProps> = props => {
	const isLoggedIn = useIsLoggedIn()
	const locales = useLocales(
		translationNamespace.block(BlockType.Customerapplicationblock),
		[
			"generalInfo",
			"invoiceAddress",
			"deliveryAddress",
			"requiredField",
			"send",
			"sameAsInvoiceAddress"
		]
	)
	const commonLocales = useLocales(translationNamespace.shared("Common"), [
		"exactFieldFormatText",
		"digitsOnlyFieldFormatText",
		"exactFieldLengthRuleText",
		"minLengthRuleText",
		"vatValidationErrorText",
		"incorrectEmailFormat"
	])

	const generalInfoFieldsLocales = useLocales(
		translationNamespace.block(BlockType.Customerapplicationblock),
		generalInfoFields
	)

	const addressFieldsLocales = useLocales(
		translationNamespace.block(BlockType.Customerapplicationblock),
		addressFields
	)
	const { localeCode } = React.useContext(LocalesContext)

	// Toggle for delivery address form
	const [
		deliveryAddressSameAsInvoiceAddress,
		setDeliveryAddressSameAsInvoiceAddress
	] = React.useState(true)

	// Submit mutation
	const [
		customerApplicationMutation,
		{ loading }
	] = props.customerApplicationMutationHookResult

	// handle country field special case
	const country = { ...countryField, options: props.countries }

	// https://react-hook-form.com/get-started
	const form = useForm<CustomerApplicationInput>({ mode: "onChange" })
	const { handleSubmit } = form

	const generalInfoFieldsConfig = React.useMemo(() => {
		return getGeneralInfoFieldsConfig(commonLocales, localeCode);
	}, [localeCode, commonLocales]);

	const generalFieldRulesConfig = React.useMemo(() => {
		return getFieldRulesConfig(localeCode, commonLocales);
	}, [localeCode, commonLocales]);

	const addressFieldsConfig = React.useMemo(() => {
		return getAddressFeildsConfig();
	}, []);

	const onSubmit = (data: CustomerApplicationInput) => {
		data.vatRegistrationNumber = 
			`${generalFieldRulesConfig?.vatNumber?.props?.prefix ?? ""}${data.vatRegistrationNumber}${generalFieldRulesConfig?.vatNumber?.props?.suffix ?? ""}`;

		trackFormSubmit(
			"customerApplication",
			isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C,
			"success"
		)

		customerApplicationMutation({
			variables: {
				input: {
					// omit emtpy values
					...objectDeepOmit(
						data,
						val => typeof val !== "undefined" && val !== ""
					),
					deliveryAddressSameAsInvoiceAddress
				}
			}
		})
	}

	const onError = () => {
		trackFormSubmit(
			"customerApplication",
			isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C,
			"failure"
		)
	}

	return (
		<Flex flexDirection="column" w={1}>
			<Flex
				as="form"
				onSubmit={handleSubmit(onSubmit)}
				onError={onError}
				maxw={RTE_CONTENT_MAX_WIDTH}
				flexDirection="column"
				noValidate
			>
				<Flex w={1} flexDirection="column" mb={6}>
					<Text variant="headline-4">{locales.generalInfo}</Text>
					<FormFields
						countries={props.countries}
						useForm={form}
						formFields={generalInfoFieldsConfig}
						locales={generalInfoFieldsLocales}
						commonLocales={commonLocales}
					/>
				</Flex>
				<Flex w={1} flexDirection="column" mb={6}>
					<Text variant="headline-4">{locales.invoiceAddress}</Text>
					<FormFields
						namespace="invoiceAddress"
						countries={props.countries}
						useForm={form}
						formFields={{ ...addressFieldsConfig, country }}
						locales={addressFieldsLocales}
						commonLocales={commonLocales}
					/>
				</Flex>
				<Flex w={1} flexDirection="column" mb={6}>
					<Flex alignItems="center">
						<Text variant="headline-4" mr={3}>
							{locales.deliveryAddress}
						</Text>
						<Checkbox
							checked={deliveryAddressSameAsInvoiceAddress}
							label={locales.sameAsInvoiceAddress}
							name="deliveryAddressSameAsInvoiceAddress"
							onChange={() =>
								setDeliveryAddressSameAsInvoiceAddress(
									!deliveryAddressSameAsInvoiceAddress
								)
							}
						/>
					</Flex>

					{!deliveryAddressSameAsInvoiceAddress && (
						<FormFields
							namespace="deliveryAddress"
							countries={props.countries}
							useForm={form}
							formFields={{ 
								name: addressFieldsConfig.name,
								contact: addressFieldsConfig.contact,
								addressOne: addressFieldsConfig.addressOne,
								addressTwo: addressFieldsConfig.addressTwo,
								postalCode: addressFieldsConfig.postalCode,
								city: addressFieldsConfig.city,
								country
							 }}
							locales={addressFieldsLocales}
						/>
					)}
				</Flex>
				<Flex w={1} maxw={33} m="0 auto">
					<Button type="submit" stretch={true} disabled={loading}>
						{locales.send}
					</Button>
				</Flex>
			</Flex>
		</Flex>
	)
}

interface SuccessProps {
	data: CustomerApplicationMutation
}

const Success: React.FC<SuccessProps> = props => {
	return (
		<Flex flexDirection="column" py={10}>
			<Text variant="headline-4" as="h1">
				{props.data.customerApplication?.successTitle}
			</Text>
			<Text>{props.data.customerApplication?.successDescription}</Text>
		</Flex>
	)
}

const CustomerApplicationBlock: React.FC<ICustomerApplicationBlock> = props => {
	const isLoggedIn = useIsLoggedIn()
	// Submit mutation
	const customerApplicationMutationHookResult = useCustomerApplicationMutation({
		errorPolicy: "all",
		onCompleted: () => {
			var srollTarget = document.getElementById(
				BlockType.Customerapplicationblock
			)
			srollTarget?.scrollIntoView()
			trackFormSubmit(
				"customerApplication",
				isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C,
				"success"
			)
		},
		onError: () => {
			trackFormSubmit(
				"customerApplication",
				isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C,
				"failure"
			)
		}
	})

	const [
		customerApplicationMutationHookMutation,
		{ data }
	] = customerApplicationMutationHookResult

	return (
		<Flex
			id={BlockType.Customerapplicationblock}
			flexDirection="column"
			w={1}
			alignItems="center"
			py={{ _: 4, m: 8 }}
			px={{ _: 2.25, m: 5 }}
		>
			<Flex w={1} maxw={RTE_CONTENT_MAX_WIDTH} flexDirection="column">
				{data ? (
					<Success data={data} />
				) : (
					<Form
						customerApplicationMutationHookResult={
							customerApplicationMutationHookResult
						}
						{...props}
					/>
				)}
			</Flex>
		</Flex>
	)
}

export { CustomerApplicationBlock }
