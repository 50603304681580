import React from "react"
import {
	Flex,
	Collapse,
	Stepper,
	Icon,
	Button
} from "@focus-nordic/ui-components"
import { FooterSecondary } from ".."
import { Header } from "./components"
import { SITE_MAX_WIDTH } from "../../constants"
import { useCheckout } from "./context/CheckoutProvider"
import { useLocales } from "../../hooks"

export interface LayoutProps {
	hasStepper?: boolean
}

const Layout: React.FC<LayoutProps> = props => {
	const checkout = useCheckout()
	const locales = useLocales("checkout", ["backToShoppingCtaText"])

	const stepTitlesLocales = useLocales("checkout", [
		"deliveryStepTitle",
		"userInfoStepTitle",
		"summaryStepTitle",
		"paymentStepTitle",
		"confirmationStepTitle"
	])

	return (
		<Flex
			minh="100vh"
			flexDirection="column"
			justifyContent="space-between"
			backgroundColor="grey-1"
		>
			<Header />
			<Flex flex={1} alignItems="flex-start">
				<Collapse isOpened>
					<Flex justifyContent="center" alignItems="flex-start" flex={1} py={7}>
						<Flex
							w={1}
							maxw={SITE_MAX_WIDTH}
							flexDirection="column"
							alignItems="center"
						>
							{props.hasStepper && (
								<Flex w={1} maxw={66} pb={1} px={2}>
									<Stepper activeStep={checkout.currentStepIndex}>
										{Object.keys(stepTitlesLocales).map((key, index) => (
											<Stepper.Step
												key={`step-${key}`}
												label={stepTitlesLocales[key]}
												circleContent={
													index === checkout.stepIds.length - 1 ? (
														<Icon
															color="white"
															icon={
																checkout.status === "failure"
																	? "close"
																	: "check"
															}
														/>
													) : (
														index + 1
													)
												}
												stepIndex={index}
											>
												{stepTitlesLocales[key]}
											</Stepper.Step>
										))}
									</Stepper>
								</Flex>
							)}
							{props.children}
							<Button.Link
								mt={3}
								variant="transparent"
								icon="arrow-left-big"
								to="/"
							>
								{locales.backToShoppingCtaText}
							</Button.Link>
						</Flex>
					</Flex>
				</Collapse>
			</Flex>
			<FooterSecondary />
		</Flex>
	)
}

Layout.defaultProps = {
	hasStepper: true
}

export { Layout }
