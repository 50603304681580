import { useLocation } from "react-router"
import queryString from "query-string"

const usePaymentIdQueryString = () => {
	const { search } = useLocation()
	const query = queryString.parse(search)

	return query.paymentId as string | undefined
}

export { usePaymentIdQueryString }
