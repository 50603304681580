import React from "react"
import { Text, ProductCard } from "@focus-nordic/ui-components"
import { utils } from "../../Cart"
import { LocalesContext } from "../../../context"
import { translationNamespace, useLocales, useCart, useIsLoggedIn } from "../../../hooks"
import { Spinner } from "."
import { useCheckout } from "../context/CheckoutProvider"
import { CustomerTypeEnum } from "../../../@types/graphql.generated"

const ItemsSummary: React.FC = () => {
	const isLoggedIn = useIsLoggedIn()
	const locales = useLocales("checkout", [
		"summaryTitle",
		"nextCtaText",
		"shippingAdressTitle",
		"deliveryOptionTitle",
		"orderItemsTitle"
	])

	const { cart, initCart, loading } = useCart()

	const { localeCode } = React.useContext(LocalesContext)

	const summaryItemLocales = useLocales(
		translationNamespace.shared("Product"),
		["articleNumber", "discountApplied"]
	)

	React.useEffect(() => {
		initCart(isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C)
	}, [])

	// Filter out items with 0 quantity
	const filteredCartItems = cart.items ? cart.items.filter(item => item.quantity !== 0) : null

	return (
		<>
			<Text variant="headline-6">
				{locales.orderItemsTitle} ({/* calculate cart total items */}
				{filteredCartItems?.reduce((acc, item) => acc + item.quantity, 0)})
			</Text>
			{loading ? (
				<Spinner />
			) : (
				filteredCartItems?.map(item => (
					<React.Fragment key={item.id}>
						<Text mb={0}>x{item.quantity}</Text>
						<ProductCard.Summary
							locales={summaryItemLocales}
							{...utils.cartItemDataToCartItemProps(
								item,
								localeCode,
								cart.currency
							)}
						/>
					</React.Fragment>
				))
			)}
		</>
	)
}
export { ItemsSummary }
