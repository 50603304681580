"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductCard = void 0;
var Medium_1 = require("./Medium");
var Large_1 = require("./Large");
var CartItem_1 = require("./CartItem");
var Summary_1 = require("./Summary");
var OrderItem_1 = require("./OrderItem");
exports.ProductCard = {
    Large: Large_1.Large,
    Medium: Medium_1.Medium,
    CartItem: CartItem_1.CartItem,
    Summary: Summary_1.Summary,
    OrderItem: OrderItem_1.OrderItem
};
