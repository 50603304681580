import React from "react"
import { Link, useRouteMatch } from "react-router-dom"
import { TableColumn } from "react-data-table-component"
import { Flex, Table, OrderStatus, OrderStatuses, Text, SearchInput } from "@focus-nordic/ui-components"
import { LocalesContext } from "@focus-nordic/www-client/src/context/LocalesContext"
import { Panel } from "../../components"
import { Layout, LayoutProps } from "../../Layout"
import { useGetOrderHistoryLazyQuery } from "./operations.generated"
import { OrderHistoryEntry, Pagination as PaginationProps } from "../../../../@types/graphql.generated"
import { useLocales, translationNamespace } from "../../../../hooks"
import { cellStyled } from "../../styles"
import { Pagination } from "../../../Pagination"
import { Loader } from "../../../Loader"
import { formatPriceInfo } from "../../../../utils"
import { useSearchQuery } from "../../../../hooks/useSearchQuery"

interface OrderHistoryViewProps extends LayoutProps { }

type DataRow = Required<OrderHistoryEntry>

type ColumnsProps = Omit<DataRow, "__typename">

export const OrderHistoryView: React.FC<OrderHistoryViewProps> = props => {
    const [pagination, setPagination] = React.useState<PaginationProps>()
    const [orders, setOrders] = React.useState<ColumnsProps[] | []>([])
    const { url: currentUrl } = useRouteMatch()    
	  const { localeCode } = React.useContext(LocalesContext)
    const searchInputRef = React.useRef() as React.MutableRefObject<HTMLDivElement>

    const [getOrderHistory, { loading, fetchMore }] = useGetOrderHistoryLazyQuery({
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setOrders(data?.orderHistory?.orders as ColumnsProps[])
            setPagination(data?.orderHistory?.pagination)
        },
    })

    const { searchValue, setSearchValue, stateReducer, onSubmit } = useSearchQuery(getOrderHistory, true)
    
    const locales = useLocales("myPages_orderHistory", [
        "orderHistoryPageTitle",
        "orderHistoryDate",
        "orderHistoryOrderNumber",
        "orderHistoryCustomerReference",
        "orderHistoryCustomerOrderNumber",
        "orderHistoryAmount",
        "orderHistoryStatus",
        "orderHistorySearchOnOrder",
    ])

    const orderStatusLocales = useLocales(translationNamespace.shared("OrderStatus"), [
        "orderStatusFullyShipped",
        "orderStatusPartiallyShipped",
        "orderStatusInProgress",
		    "orderStatusError"
    ])
    
    const sharedLocales = useLocales(translationNamespace.shared("Common"), [
        "noRecordsMessage",
        "showing",
        "of"
    ])

    const columns: TableColumn<ColumnsProps>[] = [
        {
            name: locales.orderHistoryDate,
            selector: row => row.orderDate.slice(0, row.orderDate.indexOf("T")),
            maxWidth: "160px",
        },
        {
            name: locales.orderHistoryOrderNumber,
            cell: row => <Link to={`${currentUrl}/${row.id}`}>{row.id}</Link>,
            style: cellStyled,
            minWidth: "115px",
            maxWidth: "180px",
        },
        {
            name: locales.orderHistoryCustomerReference,
            selector: row => row.customerReference,
            minWidth: "145px",
            maxWidth: "180px",
        },
        {
            name: locales.orderHistoryCustomerOrderNumber,
            selector: row => row.customerOrderNumber,
            minWidth: "145px",
            maxWidth: "176px",
        },
        {
            name: locales.orderHistoryAmount,
            cell: row => row.totalAmount ? formatPriceInfo(row.totalAmount, localeCode) : "",
            maxWidth: "165px",
        },
        {
            name: locales.orderHistoryStatus,
            cell: (row) => (
                <OrderStatus 
                    status={row.orderStatus}
                    compactMode={true}
                    locales={{
                        [OrderStatuses.FULLYSHIPPED]: orderStatusLocales.orderStatusFullyShipped,
                        [OrderStatuses.PARTIALLYSHIPPED]: orderStatusLocales.orderStatusPartiallyShipped,
                        [OrderStatuses.INPROGRESS]: orderStatusLocales.orderStatusInProgress,
                        [OrderStatuses.ERROR]: orderStatusLocales.orderStatusError,
                    }}
                />
            ),
            minWidth: "130px",
            maxWidth: "145px",
        }
    ];

    const loadMore = () => {
        if (pagination && fetchMore) {
            fetchMore({
                variables: {
                    input: {
                        pagination: {
                            skip: pagination.skip + pagination.take,
                            take: pagination.take,
                        },
                        ...(searchValue.length && { searchQuery: searchValue })
                    },
                }
            })
        }
    }

	return (
		<Layout {...props}>
            <Flex flexDirection={{ _: "column", s: "row" }} justifyContent="space-between">
                <Flex alignSelf={{ _: "flex-start", s: "center", l: "flex-start" }}>
                    <Panel.Title>{locales.orderHistoryPageTitle}</Panel.Title>
                </Flex>
                <Flex mb={{ _: 1, s: 1.5  }} mx={{ _: 2, l: 0 }}>
                    <SearchInput
                        inputFieldProps={{
                            placeholder: locales.orderHistorySearchOnOrder,
                            ref: searchInputRef
                        }}
                        stateReducer={stateReducer}
                        inputValue={searchValue}
                        lightTheme={true}
                        onInputValueChange={setSearchValue}
                        onSubmit={onSubmit}
                    />
                </Flex>
            </Flex>
            <Flex flexDirection="column" py={1} px={2} mx={{_: 1.25, s: 0}} backgroundColor="white">
                <Table
                    columns={columns}
                    data={orders}
                    noRecordsMessage={sharedLocales.noRecordsMessage}
                    progressPending={loading}
                    progressComponent={<Loader minh={40} />}
                />
            </Flex>
            {pagination && (
                <Flex flexDirection="column" mt={7}>
                    <Pagination 
                        pagination={pagination}
                        fetchMore={loadMore}
                        disabled={loading}
                        py={0}
                        mb={2.25}
                    />
                    {orders.length > 0 &&
                        <Text textAlign="center" lineHeight={2.5}>{sharedLocales.showing} {orders.length} {sharedLocales.of} {pagination.count}</Text>
                    }
                </Flex>
            )}
        </Layout> 
	)
}