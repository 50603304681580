import React from "react"
import { Flex, Select, Text, UserControl } from "@focus-nordic/ui-components"
import { useLocales, translationNamespace } from "../../../../hooks"
import { Panel } from "../../components"
import { Layout, LayoutProps } from "../../Layout"
import { useGetUserQuery } from "../../../../operations/user.generated"
import { Customer, CustomerAddress } from "../../../../@types/graphql.generated"
import { dataToProps } from "./utils"

interface CustomerInformationViewProps extends LayoutProps { }

export const CustomerInformationView: React.FC<CustomerInformationViewProps> = props => {
	const user = useGetUserQuery();
	const currentCustomer = user.data?.user?.currentCustomer;
	const [deliveryAddress, setDeliveryAddress] = React.useState<CustomerAddress>(currentCustomer?.deliveryAddresses?.[0] || {})
	const { name, registrationNumber, email, contact, phoneNumber, identifier, invoiceAddress, deliveryAddresses } = currentCustomer as Customer || {};
	const locales = useLocales(translationNamespace.shared("User"), [
		"myPagesInfoTitle",
		"myPagesBillingAddressTitle",
		"myPagesCustomer",
		"myPagesRegistrationNumber",
		"myPagesEmail",
		"myPagesCustomerNumber",
		"myPagesContact",
		"myPagesPhoneNumber",
		"myPagesAddress",
		"myPagesPostalCode",
		"myPagesCountry",
		"myPagesCity",
		"myPagesTextChangeBillingAddress",
		"myPagesDeliveryAddressTitle"
	]);

	const onDeliveryAddressChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		setDeliveryAddress(JSON.parse(evt.target.value))
	}

	return (
		<Layout {...props}>
			<Panel.Title>{locales.myPagesInfoTitle}</Panel.Title>
			{currentCustomer && (
				<Flex flexDirection="column" backgroundColor="white" p={4}>
					<Flex flexDirection={{_: "column", s: "row"}}>
						<Flex flexDirection="column" flex={1} mr={{_: 0, s: 4}}>
							<UserControl minh={6} mb={2} label={locales.myPagesCustomer} text={name} />
							<UserControl minh={6} mb={2} label={locales.myPagesRegistrationNumber} text={registrationNumber} />
							<UserControl minh={6} mb={2} label={locales.myPagesEmail} text={email} />
						</Flex>
						<Flex flexDirection="column" flex={1}>
							<UserControl minh={6} mb={2} label={locales.myPagesCustomerNumber} text={identifier} />
							<UserControl minh={6} mb={2} label={locales.myPagesContact} text={contact} />
							<UserControl minh={6} mb={2} label={locales.myPagesPhoneNumber} text={phoneNumber} />
						</Flex>
					</Flex>
					<Flex mt={{_: 3.5, s: 5}} flexDirection={{_: "column", s: "row"}}>
						<Flex flexDirection="column" flex={1} mr={{_: 0, s: 4}}>
							<Text mb={1} textColor="black" fontWeight="bold" fontSize={2.25}>{locales.myPagesBillingAddressTitle}</Text>
							{invoiceAddress && (
								<Flex flexDirection={{_: "column", s: "row"}} mb={{ _: 1.5, s: 2 }} borderBottom>
									<Flex flexDirection="column" flex={1} mr={{_: 0, s: 2}}>
										<UserControl minh={6} mb={2} label={locales.myPagesAddress} text={invoiceAddress.addressOne}/>
										<UserControl minh={6} mb={2} label={locales.myPagesPostalCode} text={invoiceAddress.postalCode} />
										<UserControl minh={6} mb={2} label={locales.myPagesCountry} text={invoiceAddress.country} />
									</Flex>
									<Flex flexDirection="column" flex={1}>
										<UserControl minh={6} mb={2} label={locales.myPagesAddress + " 2"} text={invoiceAddress.addressTwo}/>
										<UserControl minh={6} mb={2} label={locales.myPagesCity} text={invoiceAddress.city} />
									</Flex>
								</Flex>
							)}
							<Text>{locales.myPagesTextChangeBillingAddress}&nbsp;(+46 (0) 31 - 316 23 00)
							</Text>
						</Flex>
						<Flex flexDirection="column" flex={1} mt={{_: 3.25, s: 0}}>
							<Text mb={1} textColor="black" fontWeight="bold" fontSize={2.25}>{locales.myPagesDeliveryAddressTitle}</Text>
							{deliveryAddresses && deliveryAddresses.length > 0 && (
								<Flex flexDirection="column" mb={{ _: 1.5, s: 2 }}>
									{deliveryAddresses.length > 1 && (
										<Select
											color="grey-5"
											mb={2}
											options={dataToProps(deliveryAddresses as CustomerAddress[])}
											onChange={onDeliveryAddressChange}
										/>
									)}
									<Flex flexDirection={{_: "column", s: "row"}}>
										<Flex flexDirection="column" flex={1} mr={{_: 0, s: 2}}>
											<UserControl minh={6} mb={2} label={locales.myPagesAddress} text={deliveryAddress.addressOne}/>
											<UserControl minh={6} mb={2} label={locales.myPagesPostalCode} text={deliveryAddress.postalCode} />
											<UserControl minh={6} mb={2} label={locales.myPagesCountry} text={deliveryAddress.country} />
										</Flex>
										<Flex flexDirection="column" flex={1}>
											<UserControl minh={6} mb={2} label={locales.myPagesAddress + " 2"} text={deliveryAddress.addressTwo}/>
											<UserControl minh={6} mb={2} label={locales.myPagesCity} text={deliveryAddress.city} />
										</Flex>
									</Flex>
								</Flex>
							)}
						</Flex>
					</Flex>
				</Flex>
			)}
		</Layout>
	)
}