import React from "react"
import { Z_INDEX } from "@focus-nordic/www-client/src/constants"
import {
	GlobalStyles as UIGlobalStyles,
	Modal
} from "@focus-nordic/ui-components"
import { GlobalStyles } from "../styles/GlobalStyles"
import { Routes } from "@focus-nordic/www-common"

export const Layout = () => (
	<>
		<GlobalStyles />
		<UIGlobalStyles />
		<Modal zIndex={Z_INDEX.Modal} />
		<Routes />
	</>
)
