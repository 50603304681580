"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextMessage = void 0;
var React = __importStar(require("react"));
var __1 = require("..");
var titleStylesVariants = {
    large: {
        mb: { _: 1, l: 2 },
        fontSize: { _: 3, l: 5 },
        lineHeight: { _: 4, l: 6 }
    },
    normal: {
        mb: 1,
        fontSize: { _: 2.25, l: 3 },
        lineHeight: { _: 3, l: 4 }
    }
};
var TextMessage = function (_a) {
    var title = _a.title, description = _a.description, _b = _a.titleColor, titleColor = _b === void 0 ? "black" : _b, _c = _a.descriptionColor, descriptionColor = _c === void 0 ? "grey-4" : _c, _d = _a.size, size = _d === void 0 ? "normal" : _d, children = _a.children;
    var isLarge = size === "large";
    var textAlignParam = isLarge ? "center" : "left";
    var buttonMarginTop = isLarge ? { _: 2, l: 3 } : { _: 2, l: 4 };
    var buttonPosition = isLarge ? "center" : "start";
    var titleStyles = titleStylesVariants[size];
    return (React.createElement(__1.Flex, { flexDirection: "column", flex: 1, justifyContent: "space-between", maxw: { _: 1, m: 80 } },
        React.createElement(__1.Flex, { flexDirection: "column" },
            title && (React.createElement(__1.Text, { textColor: titleColor, mb: titleStyles.mb, fontSize: titleStyles.fontSize, lineHeight: titleStyles.lineHeight, textAlign: textAlignParam, fontWeight: "bold" }, title)),
            description && (React.createElement(__1.Text, { mb: 0, fontSize: { _: 2, l: 2.25 }, lineHeight: 3, textColor: descriptionColor, textAlign: textAlignParam }, description))),
        children && (React.createElement(__1.Flex, { mt: buttonMarginTop, justifyContent: buttonPosition }, children))));
};
exports.TextMessage = TextMessage;
