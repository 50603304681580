import React from "react"
import {
	Flex,
	Button,
	Counter,
	CounterProps,
	Tooltip,
	Text,
	Icon,
	IconProps
} from "@focus-nordic/ui-components"
import { useLocales, translationNamespace, useIsLoggedIn } from "../../hooks"
import { Buyable, CustomerTypeEnum } from "../../@types/graphql.generated"
import debounce from "lodash/debounce"
import { useCart } from "../../hooks/useCart"
import { CommonProductFieldsFragment } from "../../operations/product.generated"
import {
	trackAddToCartClick,
	TrackData,
	trackRemoveFromCartClick
} from "./utils"

interface AddToCartButtonProps extends Pick<CounterProps, "size"> {
	product: CommonProductFieldsFragment
	parentContext?: string
	disableButtonOnMaxAvailabilityReach?: boolean
	incrementButtonDisabledTooltipText?: string
}

const ToolTipLabel: React.FC<Pick<IconProps, "icon" | "color">> = props => (
	<Flex alignItems="center">
		<Icon icon={props.icon} color={props.color} mr={0.5} size={2} />
		<Text mb={0} fontSize={1.75} textColor="white">
			{props.children}
		</Text>
	</Flex>
)

ToolTipLabel.defaultProps = {
	color: "white"
}

const AddToCartButton: React.FC<AddToCartButtonProps> = ({
	product,
	parentContext,
	disableButtonOnMaxAvailabilityReach,
	incrementButtonDisabledTooltipText,
	...rest
}) => {
	const locales = useLocales(translationNamespace.shared("Cart"), [
		"addToCart",
		"packageSize",
		"forcedPackageSize",
		"optionalPackageSize"
	])
	const toolTipId = `package-tool-tip-${product.id}`

	const { updateCartItem, cart, loading } = useCart()
	const isLoggedIn = useIsLoggedIn()
	const debouncedUpdateCartItem = React.useRef(
		debounce(updateCartItem, 350, {
			leading: false
		})
	)

	// check if product exist in cart
	const cartItem = cart?.items?.find(
		item => item.product?.articleNumber === product.articleNumber
	)
	// set inital quantity value to value from cart
	const [quantity, setQuantity] = React.useState(cartItem?.quantity ?? 0)

	// set incrementBy based on forced package size and cart type
	const incrementBy =
		product.forcedPackageSize && isLoggedIn ? product.packageSize ?? 1 : 1

	const updateCount = (count: number, incDec = true, manualUpdate = false) => {
		setQuantity(prev => {
			let newQuantity = incDec ? prev + count : count
			// round value to closest valid package size
			newQuantity = Math.ceil(newQuantity / incrementBy) * incrementBy

			if (product.availableItemsCount !== undefined && disableButtonOnMaxAvailabilityReach && 
				newQuantity > product.availableItemsCount) {
				newQuantity = product.availableItemsCount
			}

			if (newQuantity === 0 || newQuantity < prev) trackRemove()

			debouncedUpdateCartItem.current({
				product,
				quantity: newQuantity
			})
			return manualUpdate ? newQuantity : prev
		})
	}

	const debuncedUpdateCount = React.useRef(debounce(updateCount, 450))

	const onIncrement = () => {
		updateCount(incrementBy)
		trackAdd()
	}

	const onDecrement = () => {
		updateCount(-incrementBy)
	}

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setQuantity(Number(e.target.value))
		debuncedUpdateCount.current(Number(e.target.value), false, true)
	}

	const onAddToCartClick = () => {
		updateCount(incrementBy)
		trackAdd()
	}

	const trackData: TrackData = {
		userType: isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C,
		product,
		quantity: incrementBy
	}

	const trackAdd = () => {
		trackAddToCartClick(trackData)
	}

	const trackRemove = () => {
		trackRemoveFromCartClick(trackData)
	}

	// Update quantity state if the cache is updated
	React.useEffect(() => {
		if (cartItem?.quantity) {
			setQuantity(() => cartItem.quantity)
		} else {
			setQuantity(() => 0)
		}
	}, [cartItem])

	const addToCartButtonDisabled = 
		(disableButtonOnMaxAvailabilityReach && product.availableItemsCount === 0) || 
		product.buyable === Buyable.Disabled || 
		loading

	const incrementButtonDisabled = 
		(disableButtonOnMaxAvailabilityReach && product.availableItemsCount !== undefined) ?
		quantity >= product.availableItemsCount : false

	return (
		<>
			{product.buyable !== Buyable.Hidden && (
				<>
					{product.packageSize &&
						product.packageSize > 1 &&
						product.buyable !== Buyable.Disabled &&
						isLoggedIn && (
							<Tooltip id={toolTipId} place="left">
								<ToolTipLabel icon="box">{`${locales.packageSize}: ${product.packageSize}`}</ToolTipLabel>
								<ToolTipLabel
									icon={product.forcedPackageSize ? "info-circle" : "info"}
									color={product.forcedPackageSize ? "orange" : "white"}
								>
									{product.forcedPackageSize
										? locales.forcedPackageSize
										: locales.optionalPackageSize}
								</ToolTipLabel>
							</Tooltip>
						)}
					<Flex position="relative" w={1} data-tip data-for={toolTipId}>
						<Flex
							position="absolute"
							width={1}
							display={quantity !== 0 ? "flex" : "none"}
						>
							<Counter
								id={product.id}
								zIndex={1}
								value={quantity}
								onIncrement={onIncrement}
								onDecrement={onDecrement}
								onInputChange={onInputChange}
								disabled={loading}
								incrementDisabled={incrementButtonDisabled}
								incrementDisabledTooltipText={incrementButtonDisabled ? incrementButtonDisabledTooltipText : ""}
								variant="green"
								parentContext={parentContext}
								incrementBy={incrementBy}
								{...rest}
							/>
						</Flex>
						<Flex
							flexDirection="column"
							flex={1}
							minw={9}
							visibility={quantity !== 0 ? "hidden" : "visible"}
						>
							<Button
								icon="cart"
								variant="green"
								zIndex={1}
								disabled={addToCartButtonDisabled}
								onClick={onAddToCartClick}
								whiteSpace="nowrap"
								{...rest}
							>
								{locales.addToCart}
							</Button>
						</Flex>
					</Flex>
				</>
			)}
		</>
	)
}

AddToCartButton.defaultProps = {
	disableButtonOnMaxAvailabilityReach: false
}

export { AddToCartButton }
