"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStatus = void 0;
var React = __importStar(require("react"));
var Icon_1 = require("../Icon");
var Flex_1 = require("../Flex");
var Text_1 = require("../Text");
var styles_1 = require("../../styles");
var types_1 = require("./types");
var OrderStatus = function (_a) {
    var _b, _c;
    var status = _a.status, locales = _a.locales, compactMode = _a.compactMode, rest = __rest(_a, ["status", "locales", "compactMode"]);
    var commonLabelProps = {
        as: "span",
        fontSize: compactMode ? 1.5 : 1.75,
        lineHeight: compactMode ? 2 : 2.5,
        mb: 0,
        ml: 0.5
    };
    var commonIconProps = {
        size: compactMode ? 1.5 : 2
    };
    var label = (_b = {},
        _b[types_1.OrderStatuses.FULLYSHIPPED] = (React.createElement(Text_1.Text, __assign({}, commonLabelProps), locales[types_1.OrderStatuses.FULLYSHIPPED])),
        _b[types_1.OrderStatuses.PARTIALLYSHIPPED] = (React.createElement(Text_1.Text, __assign({}, commonLabelProps), locales[types_1.OrderStatuses.PARTIALLYSHIPPED])),
        _b[types_1.OrderStatuses.INPROGRESS] = (React.createElement(Text_1.Text, __assign({}, commonLabelProps), locales[types_1.OrderStatuses.INPROGRESS])),
        _b[types_1.OrderStatuses.ERROR] = (React.createElement(Text_1.Text, __assign({}, commonLabelProps), locales[types_1.OrderStatuses.ERROR])),
        _b)[status] || null;
    var icon = (_c = {},
        _c[types_1.OrderStatuses.FULLYSHIPPED] = (React.createElement(Icon_1.Icon, __assign({ icon: "check-circle", color: "green-1" }, commonIconProps))),
        _c[types_1.OrderStatuses.PARTIALLYSHIPPED] = (React.createElement(Icon_1.Icon, __assign({ icon: "arrow-right-cycle", color: "orange" }, commonIconProps))),
        _c[types_1.OrderStatuses.INPROGRESS] = (React.createElement(Icon_1.Icon, __assign({ icon: "pending", color: "blue-6" }, commonIconProps))),
        _c[types_1.OrderStatuses.ERROR] = (React.createElement(Icon_1.Icon, __assign({ icon: "close-circle", color: "red-1" }, commonIconProps))),
        _c)[status] || null;
    return (React.createElement(Flex_1.Flex, __assign({ alignItems: "center" }, rest),
        React.createElement(Flex_1.Flex, { flex: "0 0 " + styles_1.scale(compactMode ? 1.5 : 2) }, icon),
        label));
};
exports.OrderStatus = OrderStatus;
OrderStatus.defaultProps = {
    compactMode: false
};
