"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.borderKeys = exports.borderColors = void 0;
var colors_1 = require("./colors");
exports.borderColors = {
    "grey-5": colors_1.colors["grey-5"],
    "grey-4": colors_1.colors["grey-4"],
    "grey-3": colors_1.colors["grey-3"],
    "grey-2": colors_1.colors["grey-2"],
    "grey-1": colors_1.colors["grey-1"]
};
exports.borderKeys = Object.keys(exports.borderColors);
