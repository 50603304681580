import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type CategoryHeroBlockFieldsFragment = (
  { __typename?: 'categoryHeroBlock' }
  & Pick<Types.CategoryHeroBlock, 'id' | 'type' | 'title' | 'preamble'>
  & { image?: Types.Maybe<(
    { __typename?: 'ImageBase' }
    & Pick<Types.ImageBase, 'fileName'>
    & { meta?: Types.Maybe<(
      { __typename?: 'ImageMeta' }
      & Pick<Types.ImageMeta, 'author' | 'alt'>
    )> }
  )>, readMoreAnchor?: Types.Maybe<(
    { __typename?: 'link' }
    & Pick<Types.Link, 'alt' | 'target' | 'title' | 'url'>
  )> }
);

export const CategoryHeroBlockFieldsFragmentDoc = gql`
    fragment categoryHeroBlockFields on categoryHeroBlock {
  id
  type
  title
  preamble
  image {
    fileName
    meta {
      author
      alt
    }
  }
  readMoreAnchor {
    alt
    target
    title
    url
  }
}
    `;