import * as Types from '../@types/graphql.generated';

import { gql } from '@apollo/client';
export type AllImageBaseFieldsFragment = (
  { __typename?: 'ImageBase' }
  & Pick<Types.ImageBase, 'fileName'>
  & { meta?: Types.Maybe<(
    { __typename?: 'ImageMeta' }
    & Pick<Types.ImageMeta, 'alt' | 'author'>
  )> }
);

export const AllImageBaseFieldsFragmentDoc = gql`
    fragment allImageBaseFields on ImageBase {
  fileName
  meta {
    alt
    author
  }
}
    `;