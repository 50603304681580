import React from "react"
import { useForm } from "react-hook-form"
import { DeliveryOptionEnum } from "../../../../../@types/graphql.generated"
import { LocalesContext } from "../../../../../context"
import { localeCodeToCountryCode } from "../../../../../utils"
import { GetCheckoutQuery, useGetCheckoutQuery } from "../operations.generated"
import { CheckoutFormValues } from "../types/CheckoutFormData"

// WrapperUseForm is only used to extract RetrunType of the generic function useForm
const WrapperUseForm = () => useForm<CheckoutFormValues>()
export type Form = ReturnType<typeof WrapperUseForm>

interface CheckoutContextValue {
	form?: Form
	checkoutData?: GetCheckoutQuery
	setIsDropShipAddress: React.Dispatch<React.SetStateAction<boolean>>
	isDropShipAddress: boolean
}

const CheckoutContext = React.createContext<CheckoutContextValue>({
	setIsDropShipAddress: () => {},
	isDropShipAddress: false
})

export const CheckoutProvider: React.FC = props => {
	const { localeCode } = React.useContext(LocalesContext)
	const { data: checkoutData } = useGetCheckoutQuery()
	const [isDropShipAddress, setIsDropShipAddress] = React.useState<boolean>(
		false
	)

	const form = useForm<CheckoutFormValues>({
		defaultValues: {
			deliveryOption: DeliveryOptionEnum.Partial,
			countryCode: localeCodeToCountryCode(
				localeCode
			).toUpperCase() as CheckoutFormValues["countryCode"],
			shippingAddress: {
				country: localeCodeToCountryCode(
					localeCode
				).toUpperCase() as CheckoutFormValues["countryCode"]
			},
			selectedAddress: checkoutData?.allOrderAddresses
				? checkoutData?.allOrderAddresses[0].id
				: undefined
		}
	})

	return (
		<CheckoutContext.Provider
			value={{
				checkoutData,
				form,
				isDropShipAddress,
				setIsDropShipAddress
			}}
			{...props}
		/>
	)
}

export const useCheckout = () => {
	return React.useContext(CheckoutContext)
}
