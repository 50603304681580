import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type TextEditorBlockFieldsFragment = (
  { __typename?: 'textEditorBlock' }
  & Pick<Types.TextEditorBlock, 'id' | 'type' | 'content' | 'collapsible'>
);

export const TextEditorBlockFieldsFragmentDoc = gql`
    fragment textEditorBlockFields on textEditorBlock {
  id
  type
  content
  collapsible
}
    `;