"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ratio = void 0;
var styled_system_1 = require("styled-system");
var configs = {
    ratio: {
        property: "paddingBottom",
        transform: function (ratio) {
            return typeof ratio === "string" ? ratio : ratio * 100 + "%";
        }
    }
};
exports.ratio = styled_system_1.system(configs);
