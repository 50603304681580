import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type TextMessagesBlockFieldsFragment = (
  { __typename?: 'textMessagesBlock' }
  & Pick<Types.TextMessagesBlock, 'type' | 'id' | 'buttonCTA'>
  & { blockTheme: (
    { __typename?: 'themeInfo' }
    & Pick<Types.ThemeInfo, 'backgroundColor' | 'textColor'>
  ), items?: Types.Maybe<Array<(
    { __typename?: 'textMessage' }
    & Pick<Types.TextMessage, 'title' | 'description'>
    & { cta?: Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'title' | 'url' | 'isExternal' | 'target'>
    )> }
  )>> }
);

export const TextMessagesBlockFieldsFragmentDoc = gql`
    fragment textMessagesBlockFields on textMessagesBlock {
  type
  id
  buttonCTA
  blockTheme {
    backgroundColor
    textColor
  }
  items {
    title
    description
    cta {
      title
      url
      isExternal
      target
    }
  }
}
    `;