import React from "react"
import { Link } from "react-router-dom"
import { TableColumn } from "react-data-table-component"
import { Table, Flex, Text, TextProps, SearchInput } from "@focus-nordic/ui-components"
import { LocalesContext } from "@focus-nordic/www-client/src/context/LocalesContext"
import { Panel } from "../../components"
import { Layout, LayoutProps } from "../../Layout"
import { useGetBackordersLazyQuery } from "./operations.generated"
import { BackorderEntry } from "../../../../@types/graphql.generated"
import { translationNamespace, useLocales } from "../../../../hooks"
import { cellStyled } from "../../styles"
import { MY_PAGES_ROUTE } from "@focus-nordic/www-common/constants"
import { ORDER_HISTORY_PAGE_ROUTE } from "../../routes"
import { Loader } from "../../../Loader"
import { formatPriceInfo } from "../../../../utils"
import { StockStatus } from "../../../StockStatus"
import { useSearchQuery } from "../../../../hooks/useSearchQuery"

interface BackordersListViewProps extends LayoutProps { }

type DataRow = Required<BackorderEntry>

type ColumnsProps = Omit<DataRow, "__typename">

const TooltipDataDescription: React.FC<TextProps> = props => (
    <Text fontSize={1.5} truncate="twoLine" textAlign="end" lineHeight={2} {...props} />
)

export const BackordersListView: React.FC<BackordersListViewProps> = props => {
  const searchInputRef = React.useRef() as React.MutableRefObject<HTMLDivElement>
	const { localeCode } = React.useContext(LocalesContext)
	const [backorders, setBackorders] = React.useState<ColumnsProps[] | []>([])
	const [ getBackorders, { loading } ] = useGetBackordersLazyQuery({
		fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
        if (data.backorders) {
          setBackorders(data.backorders as ColumnsProps[])
        }
    },
	})
  const { onSubmit, stateReducer, searchValue, setSearchValue } = useSearchQuery(getBackorders, false)

	const locales = useLocales("myPages_backordersList", [
		"backordersListPageTitle",
		"backordersListOrderNumber",
		"backordersListDate",
		"backordersListArticleNumber",
		"backordersListProductName",
		"backordersListQuantity",
		"backordersListFees",
		"backordersListPricePerUnit",
		"backordersListTotalPrice",
		"backordersListStock",
		"backordersListOnDemand",
		"backordersListSearchOnBackorder",
	])

	const sharedLocales = useLocales(translationNamespace.shared("Common"), ["noRecordsMessage"])

	const columns: TableColumn<ColumnsProps>[]  = [
		{
			name: locales.backordersListOrderNumber,
			cell: row => (
                <Link to={`${MY_PAGES_ROUTE}/${ORDER_HISTORY_PAGE_ROUTE.path}/${row.orderNumber}`}>{row.orderNumber}</Link>
            ),
			style: cellStyled,
			minWidth: "90px",
			maxWidth: "95px",
		},
		{
			name: locales.backordersListDate,
			selector: row => row.orderDate.slice(0, row.orderDate.indexOf("T")),
			minWidth: "80px",
			maxWidth: "110px",
		},
		{
			name: locales.backordersListArticleNumber,
			selector: row => row.articleNumber,
			minWidth: "90px",
			maxWidth: "91px",
		},
		{
			name: locales.backordersListProductName,
			cell: row => {
				return (
					<Flex alignItems="center">
						<Link to={row.productPageUrl.url}>{row.productName}</Link>
					</Flex>
				)
			},

			style: {
				minHeight: "58px",
				paddingRight: "10px",
				...cellStyled
			},
			minWidth: "180px",
			maxWidth: "188px",
		},
		{
			name: locales.backordersListQuantity,
			selector: row => row.quantity,
			minWidth: "90px",
			maxWidth: "92px",
		},
		{
			name: locales.backordersListFees,
			cell: row => row.totalFees ? formatPriceInfo(row.totalFees, localeCode) : "",
			minWidth: "90px",
			maxWidth: "92px",
		},
		{
			name: locales.backordersListPricePerUnit,
			cell: row => row.pricePerUnit ? formatPriceInfo(row.pricePerUnit, localeCode) : "",
			maxWidth: "133px",
		},
		{
			name: locales.backordersListTotalPrice,
			cell: row => row.totalPrice ? formatPriceInfo(row.totalPrice, localeCode) : "",
			maxWidth: "144px",
		},
		{
			name: locales.backordersListStock,
			cell: (row) => (
				<StockStatus
					id={row.articleNumber + "_" + row.orderNumber}
					stockStatus={row.stockStatus}
					onDemand={row.onDemand}
					isExpired={row.isExpired}
					textComponent={row => <TooltipDataDescription mb={0} {...row} />}
					compactMode={true}
					place="left"
				/>
			),
			right: true,
			minWidth: "130px",
			maxWidth: "135px"
		}
	]

	return (
		<Layout {...props}>
			<Flex flexDirection={{ _: "column", s: "row" }} justifyContent="space-between">
          <Flex alignSelf={{ _: "flex-start", s: "center", l: "flex-start" }}>
              <Panel.Title>{locales.backordersListPageTitle}</Panel.Title>
          </Flex>
          <Flex mb={{ _: 1, s: 1.5  }} mx={{ _: 2, l: 0 }}>
              <SearchInput
                  inputFieldProps={{
                      placeholder: locales.backordersListSearchOnBackorder,
                      ref: searchInputRef
                  }}
                  stateReducer={stateReducer}
                  inputValue={searchValue}
                  lightTheme={true}
                  onInputValueChange={setSearchValue}
                  onSubmit={onSubmit}
              />
          </Flex>
      </Flex>
			<Flex flexDirection="column" py={1} px={2} mx={{_: 1.25, s: 0}} backgroundColor="white">
				<Table
					columns={columns}
					data={backorders}
					noRecordsMessage={sharedLocales.noRecordsMessage}
					progressPending={loading}
          progressComponent={<Loader minh={40}/>}
				/>
			</Flex>
		</Layout>
	)
}