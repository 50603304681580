import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type DescriptionListBlockFieldsFragment = (
  { __typename?: 'descriptionListBlock' }
  & Pick<Types.DescriptionListBlock, 'id' | 'type' | 'title'>
  & { fields?: Types.Maybe<Array<(
    { __typename?: 'KeyValue' }
    & Pick<Types.KeyValue, 'key' | 'value'>
  )>> }
);

export const DescriptionListBlockFieldsFragmentDoc = gql`
    fragment descriptionListBlockFields on descriptionListBlock {
  id
  type
  title
  fields {
    key
    value
  }
}
    `;