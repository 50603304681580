import * as Types from '../../@types/graphql.generated';

import { CommonProductFieldsFragment, ProductRangeFieldsFragment } from '../../operations/product.generated';
import { gql } from '@apollo/client';
import { CommonProductFieldsFragmentDoc, ProductRangeFieldsFragmentDoc } from '../../operations/product.generated';
export type ProductOverviewBlockFieldsFragment = (
  { __typename?: 'productOverviewBlock' }
  & Pick<Types.ProductOverviewBlock, 'id' | 'type'>
  & { readMoreAnchor?: Types.Maybe<(
    { __typename?: 'link' }
    & Pick<Types.Link, 'alt' | 'target' | 'title' | 'url'>
  )>, variantsAnchor?: Types.Maybe<(
    { __typename?: 'link' }
    & Pick<Types.Link, 'alt' | 'target' | 'title' | 'url'>
  )>, product: (
    { __typename?: 'product' }
    & { brand?: Types.Maybe<(
      { __typename?: 'brand' }
      & Pick<Types.Brand, 'logosrc' | 'name'>
      & { link: (
        { __typename?: 'link' }
        & Pick<Types.Link, 'alt' | 'target' | 'title' | 'url'>
      ) }
    )>, images?: Types.Maybe<Array<(
      { __typename?: 'image' }
      & Pick<Types.Image, 'alt' | 'height' | 'src' | 'width'>
    )>>, variants?: Types.Maybe<Array<(
      { __typename?: 'product' }
      & Pick<Types.Product, 'id' | 'name'>
      & { link: (
        { __typename?: 'link' }
        & Pick<Types.Link, 'alt' | 'target' | 'title' | 'url'>
      ), mainImage?: Types.Maybe<(
        { __typename?: 'image' }
        & Pick<Types.Image, 'alt' | 'height' | 'src' | 'width'>
      )> }
    )>> }
    & CommonProductFieldsFragment
  ) }
);

export const ProductOverviewBlockFieldsFragmentDoc = gql`
    fragment productOverviewBlockFields on productOverviewBlock {
  id
  type
  readMoreAnchor {
    alt
    target
    title
    url
  }
  variantsAnchor {
    alt
    target
    title
    url
  }
  product {
    ...commonProductFields
    brand {
      link {
        alt
        target
        title
        url
      }
      logosrc
      name
    }
    images {
      alt
      height
      src
      width
    }
    variants {
      id
      name
      link {
        alt
        target
        title
        url
      }
      mainImage {
        alt
        height
        src
        width
      }
    }
  }
}
    ${CommonProductFieldsFragmentDoc}`;