import { IconTypes } from "@focus-nordic/ui-components"
import { Suggestion } from "./types"

type SuggestionTypeName = Suggestion["__typename"]
type IconRecord = Record<NonNullable<SuggestionTypeName>, IconTypes>

const iconRecord: IconRecord = {
	SearchSuggestion: "search",
	ProductHit: "camera"
}

export const getSuggestionIcon = (
	suggestionTypeName: SuggestionTypeName
): IconTypes => iconRecord[suggestionTypeName ?? "SearchSuggestion"]
