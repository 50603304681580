import React from "react"
import { Flex, LayoutProps, Text, TextProps } from "@focus-nordic/ui-components"

interface PanelComponent {
	Title: typeof Title
}

const panelPaddingX = 2

const Title: React.FC<TextProps> = props => (
	<Text
		variant="headline-4"
		mb={1}
		ml={{ _: panelPaddingX, l: 0 }}
		{...props}
	/>
)

const Panel: React.FC<LayoutProps> & PanelComponent = props => (
	<Flex
		flex={1}
		w={1}
		p={{ _: panelPaddingX, l: 3 }}
		mb={{ _: 5, l: 3 }}
		backgroundColor="white"
		{...props}
	/>
)

Panel.Title = Title

export { Panel }
