import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type GalleryBlockFieldsFragment = (
  { __typename?: 'galleryBlock' }
  & Pick<Types.GalleryBlock, 'id' | 'type' | 'alternativeLayout'>
  & { galleryCards?: Types.Maybe<Array<(
    { __typename: 'galleryCard' }
    & Pick<Types.GalleryCard, 'title' | 'preamble' | 'alignmentType' | 'imageOpacity'>
    & { ctaButton?: Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'isExternal' | 'target' | 'title' | 'url'>
    )>, image?: Types.Maybe<(
      { __typename?: 'ImageBase' }
      & Pick<Types.ImageBase, 'fileName'>
      & { meta?: Types.Maybe<(
        { __typename?: 'ImageMeta' }
        & Pick<Types.ImageMeta, 'alt' | 'author'>
      )> }
    )> }
  )>> }
);

export const GalleryBlockFieldsFragmentDoc = gql`
    fragment galleryBlockFields on galleryBlock {
  id
  type
  alternativeLayout
  galleryCards {
    __typename
    title
    preamble
    alignmentType
    ctaButton {
      isExternal
      target
      title
      url
    }
    image {
      fileName
      meta {
        alt
        author
      }
    }
    imageOpacity
  }
}
    `;