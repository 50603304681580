import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type MediaContentBlockFieldsFragment = (
  { __typename?: 'mediaContentBlock' }
  & Pick<Types.MediaContentBlock, 'id' | 'type' | 'title' | 'preamble' | 'isMirrorLayout'>
  & { cards?: Types.Maybe<Array<(
    { __typename?: 'referenceInfo' }
    & Pick<Types.ReferenceInfo, 'title' | 'preamble' | 'isDarkTheme'>
    & { ctaLink?: Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'alt' | 'target' | 'title' | 'url' | 'isExternal'>
    )>, image?: Types.Maybe<(
      { __typename?: 'ImageBase' }
      & Pick<Types.ImageBase, 'fileName'>
      & { meta?: Types.Maybe<(
        { __typename?: 'ImageMeta' }
        & Pick<Types.ImageMeta, 'author' | 'alt'>
      )> }
    )> }
  )>>, blockTheme: (
    { __typename?: 'themeInfo' }
    & Pick<Types.ThemeInfo, 'backgroundColor' | 'textColor'>
  ) }
);

export const MediaContentBlockFieldsFragmentDoc = gql`
    fragment mediaContentBlockFields on mediaContentBlock {
  id
  type
  title
  preamble
  isMirrorLayout
  cards {
    title
    preamble
    isDarkTheme
    ctaLink {
      alt
      target
      title
      url
      isExternal
    }
    image {
      fileName
      meta {
        author
        alt
      }
    }
  }
  blockTheme {
    backgroundColor
    textColor
  }
}
    `;