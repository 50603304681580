import { IncomingDelivery, StockStatus } from "../@types/graphql.generated"
import { StockStatusProps } from "@focus-nordic/ui-components"

interface StockStatusLocales {
	statusOnDemand: string
	statusDate: string
	statusInStock: string
	statusConfirmed: string
	statusPreliminary: string
	statusUnconfirmed: string
	statusOutOfStock: string
	statusIncoming: string
	statusOnDemandMessage: string
	statusExpired: string
	statusExpiredMessage: string
}

type StockStatusVariant = StockStatusProps["status"]

const mapIncomingDelivery = (
	deliveries: IncomingDelivery[],
	locales: StockStatusLocales
) =>
	deliveries.map(
		delivery =>
			`${locales.statusIncoming}: ${delivery.incoming} ${
				// date title for delivery without a date
				!delivery.date
					? `${locales.statusUnconfirmed} ${locales.statusDate}` // date title for confirmed without a date
					: delivery.confirmed
					? locales.statusConfirmed // date title for confirmed with a date
					: locales.statusPreliminary // date title for preliminary with a date
			} ${delivery.date ? `${locales.statusDate} ${delivery.date}` : ""}`
	)

export interface StockStatusAndToolTipText {
	status: StockStatusVariant
	tooltipText: Array<string>
	additionalText: string
}

export const getStockStatusColorAndToolipText = (
	locales: StockStatusLocales,
	stockStatus?: StockStatus,
	onDemand?: boolean,
	isExpired?: boolean
): StockStatusAndToolTipText => {
	// The case !isExpired && !stockStatus is treated as unpublished, so we display the expired status
	if (isExpired || (!isExpired && !stockStatus)) {
		return {
			status: "out-of-stock",
			tooltipText: [locales.statusExpiredMessage],
			additionalText: locales.statusExpired
		}
	}
	if (stockStatus) {
		if (onDemand) {
			return {
				status: "low-stock",
				tooltipText: [locales.statusOnDemandMessage],
				additionalText: locales.statusOnDemand
			}
		} else {
			if (Number(stockStatus.quantity) === 0) {
				if (stockStatus.deliveries && stockStatus.deliveries.length > 0) {
					return {
						status: "low-stock",
						tooltipText: [
							`${locales.statusInStock}: ${stockStatus.quantity}`,
							...mapIncomingDelivery(stockStatus.deliveries, locales)
						],
						additionalText: `${stockStatus.incomingQuantity}`
					}
				} else {
					return {
						status: "out-of-stock",
						tooltipText: [locales.statusOutOfStock],
						additionalText: locales.statusOutOfStock
					}
				}
			} else if (stockStatus.deliveries) {
				let tooltipText = [`${locales.statusInStock}: ${stockStatus.quantity}`]
				let additionalText = `${stockStatus.quantity}`

				if (stockStatus.deliveries.length > 0) {
					additionalText = `${stockStatus.quantity} (${stockStatus.incomingQuantity})`

					tooltipText = [
						...tooltipText,
						...mapIncomingDelivery(stockStatus.deliveries, locales)
					]
				}
				return {
					status: "in-stock",
					tooltipText,
					additionalText
				}
			}
		}
	}
	return {
		status: "in-stock",
		tooltipText: [],
		additionalText: locales.statusInStock
	}
}
