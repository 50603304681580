import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type InspirationHeroBlockFieldsFragment = (
  { __typename?: 'inspirationHeroBlock' }
  & Pick<Types.InspirationHeroBlock, 'id' | 'type' | 'inspirationTitle' | 'imageOpacity' | 'preamble'>
  & { image?: Types.Maybe<(
    { __typename?: 'ImageBase' }
    & Pick<Types.ImageBase, 'fileName'>
    & { meta?: Types.Maybe<(
      { __typename?: 'ImageMeta' }
      & Pick<Types.ImageMeta, 'author' | 'alt'>
    )> }
  )> }
);

export const InspirationHeroBlockFieldsFragmentDoc = gql`
    fragment inspirationHeroBlockFields on inspirationHeroBlock {
  id
  type
  inspirationTitle
  imageOpacity
  preamble
  image {
    fileName
    meta {
      author
      alt
    }
  }
}
    `;