import * as Types from '../../@types/graphql.generated';

import { MenuFieldsFragment } from '../../operations/menu.generated';
import { gql } from '@apollo/client';
import { MenuFieldsFragmentDoc } from '../../operations/menu.generated';
import * as Apollo from '@apollo/client';
export type GetMenuSecondaryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMenuSecondaryQuery = (
  { __typename?: 'Query' }
  & { footerSecondaryMenu?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'menu' }
    & MenuFieldsFragment
  )>>> }
);


export const GetMenuSecondaryDocument = gql`
    query GetMenuSecondary {
  footerSecondaryMenu: menu(type: FOOTER_SECONDARY) {
    ...menuFields
  }
}
    ${MenuFieldsFragmentDoc}`;

/**
 * __useGetMenuSecondaryQuery__
 *
 * To run a query within a React component, call `useGetMenuSecondaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuSecondaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuSecondaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMenuSecondaryQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuSecondaryQuery, GetMenuSecondaryQueryVariables>) {
        return Apollo.useQuery<GetMenuSecondaryQuery, GetMenuSecondaryQueryVariables>(GetMenuSecondaryDocument, baseOptions);
      }
export function useGetMenuSecondaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuSecondaryQuery, GetMenuSecondaryQueryVariables>) {
          return Apollo.useLazyQuery<GetMenuSecondaryQuery, GetMenuSecondaryQueryVariables>(GetMenuSecondaryDocument, baseOptions);
        }
export type GetMenuSecondaryQueryHookResult = ReturnType<typeof useGetMenuSecondaryQuery>;
export type GetMenuSecondaryLazyQueryHookResult = ReturnType<typeof useGetMenuSecondaryLazyQuery>;
export type GetMenuSecondaryQueryResult = Apollo.QueryResult<GetMenuSecondaryQuery, GetMenuSecondaryQueryVariables>;