import React from "react"
import { Flex } from "@focus-nordic/ui-components"
import { SearchSuggestion } from "../SearchSuggestion"

const DesktopSearch: React.FC = () => {
	const [isFocused, setisFocused] = React.useState(false)

	const onFocus = () => {
		setisFocused(true)
	}

	const onBlur = () => {
		setisFocused(false)
	}

	return (
		<>
			<Flex
				alignItems="center"
				right={0}
				flex={1}
				h={1}
				w={isFocused ? 1 : 0.25}
				transition="width .25s"
				position="absolute"
			>
				<SearchSuggestion
					clearOnSubmit
					inputFieldProps={{
						onFocus,
						onBlur
					}}
				/>
			</Flex>
			{/* acts as placeholder to maintain the original layout when the above Flex component is set to postion absolute */}
			<Flex
				alignItems="center"
				flex={1}
				w={1}
				maxw={0.2}
				h={1}
				visibility="hidden"
				position="static"
			></Flex>
		</>
	)
}

export { DesktopSearch }
