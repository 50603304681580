import React from "react"
import {
	CustomerTypeEnum,
	PaymentInput
} from "../../../@types/graphql.generated"
import useSSR from "use-ssr"
import {
	CHECKOUT_SUCCESS_ROUTE,
	CHECKOUT_FAILURE_ROUTE
} from "@focus-nordic/www-common/constants"
import {
	DeliveryOptionFieldsFragment,
	ServicePointFeildsFragment
} from "../operations.generated"

export type StepId = "delivery" | "userInfo" | "summary" | "payment" | "status"
export type StepIds = StepId[]
export type Status = "in-progress" | "success" | "failure"
interface SelectedFreight {
	deliveryOption?: DeliveryOptionFieldsFragment
	servicePoint?: ServicePointFeildsFragment
}
interface CheckoutContextValue extends Partial<PaymentInput> {
	paymentInput: Partial<PaymentInput>
	currentStepIndex: number
	setPaymentInput: React.Dispatch<React.SetStateAction<Partial<PaymentInput>>>
	setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>
	selectedFreight?: SelectedFreight
	setSelectedFreight: React.Dispatch<
		React.SetStateAction<SelectedFreight | undefined>
	>
	customerType: CustomerTypeEnum
	currentStepId: StepId
	stepIds: StepIds
	status: Status
	setCustomerType: (customerType: CustomerTypeEnum) => void
	goToNextStep: () => void
	goToPrevStep: () => void
	goToFirstStep: () => void
	goToStep: (stepId: StepId) => void
}

const defaultCurrentStepIndex = 0
const stepIds: StepIds = [
	"delivery",
	"userInfo",
	"summary",
	"payment",
	"status"
]

const defaultCheckoutContextValue: CheckoutContextValue = {
	paymentInput: {
		paymentSuccessUrl: CHECKOUT_SUCCESS_ROUTE,
		paymentFailureUrl: CHECKOUT_FAILURE_ROUTE,
		cartItems: []
	},
	customerType: CustomerTypeEnum.B2C,
	currentStepId: stepIds[defaultCurrentStepIndex],
	stepIds,
	status: "in-progress",
	currentStepIndex: defaultCurrentStepIndex,
	setCustomerType: () => {},
	setPaymentInput: () => {},
	setCurrentStepIndex: () => {},
	setSelectedFreight: () => {},
	goToNextStep: () => {},
	goToPrevStep: () => {},
	goToFirstStep: () => {},
	goToStep: () => {}
}

const CheckoutContext = React.createContext<CheckoutContextValue>(
	defaultCheckoutContextValue
)

const scrollToTop = () => {
	window.scrollTo({ top: 0, behavior: "smooth" })
}

interface CheckoutProviderProps
	extends Partial<Pick<CheckoutContextValue, "status" | "currentStepId">> {}

export const CheckoutProvider: React.FC<CheckoutProviderProps> = props => {
	const [currentStepIndex, setCurrentStepIndex] = React.useState<
		CheckoutContextValue["currentStepIndex"]
	>(
		props.currentStepId
			? stepIds.indexOf(props.currentStepId)
			: defaultCheckoutContextValue.currentStepIndex
	)

	const [paymentInput, setPaymentInput] = React.useState<
		CheckoutContextValue["paymentInput"]
	>(defaultCheckoutContextValue.paymentInput)

	const [selectedFreight, setSelectedFreight] = React.useState<
		CheckoutContextValue["selectedFreight"]
	>()

	const { isBrowser } = useSSR()

	const currentStepId = stepIds[currentStepIndex]
	const status = props.status || defaultCheckoutContextValue.status

	const goToNextStep = () => {
		scrollToTop()
		setCurrentStepIndex(prev => {
			const newVal = prev + 1
			return newVal > stepIds.length - 1 ? prev : newVal
		})
	}

	const goToPrevStep = () => {
		scrollToTop()
		setCurrentStepIndex(prev => {
			const newVal = prev - 1
			return newVal < 0 ? prev : newVal
		})
	}

	const goToFirstStep = () => {
		scrollToTop()
		setCurrentStepIndex(0)
	}

	const goToStep = (stepId: StepId) => {
		scrollToTop()
		setCurrentStepIndex(stepIds.findIndex(step => step === stepId))
	}

	const [customerType, setCustomerType] = React.useState<
		CheckoutContextValue["customerType"]
	>(defaultCheckoutContextValue.customerType)

	let paymentSuccessUrl =
		defaultCheckoutContextValue.paymentInput.paymentSuccessUrl
	let paymentFailureUrl =
		defaultCheckoutContextValue.paymentInput.paymentFailureUrl
	if (isBrowser) {
		paymentSuccessUrl = `${window.location.origin}${paymentSuccessUrl}`
		paymentFailureUrl = `${window.location.origin}${paymentFailureUrl}`
	}

	return (
		<CheckoutContext.Provider
			value={{
				paymentInput: {
					...paymentInput,
					paymentSuccessUrl,
					paymentFailureUrl
				},
				customerType,
				currentStepIndex,
				currentStepId,
				stepIds,
				status,
				setCustomerType,
				setCurrentStepIndex,
				selectedFreight,
				setSelectedFreight,
				setPaymentInput,
				goToNextStep,
				goToPrevStep,
				goToFirstStep,
				goToStep
			}}
			{...props}
		/>
	)
}

export const useCheckout = () => React.useContext(CheckoutContext)
