import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import {
	PrimaryMenu,
	PrimaryMenuItemContextValue,
	PrimaryMenuContext,
	Flex,
	CollapsibleMenu,
	CollapsibleMenuContext,
	Text,
	Symbol,
	Drawer,
	Icon,
	scale
} from "@focus-nordic/ui-components"
import { GetMenuQuery } from "./operations.generated"
import { GetUserQuery } from "../../operations/user.generated"
import { CustomerTypeEnum, Menu } from "../../@types/graphql.generated"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { trackMainMenuClickEvent, trackMegaMenuClickEvent } from "./utils"
import { CartDropDown } from "./CartDropDown"
import { SITE_MAX_WIDTH, MENU_HEIGHT } from "../../constants"
import { DesktopSearch } from "./DesktopSearch"
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn"
import { useLocation } from "react-router"

export const Logo = styled.div``

interface DesktopPrimaryMenuProps
	extends Pick<GetMenuQuery, "mainPrimaryMenu">,
		Partial<GetUserQuery> {
			isHeaderSticky: boolean
		}

interface MenuLevels {
	0: React.FC
	1: React.FC
	2: React.FC
	3: React.FC
}

type Locales = {
	allIn: string
	logIn: string
}

interface MenuItemAncestors {
	category?: string
	parent?: string
}

interface MenuLinkItemProps {
	menu: Menu
	menuItemAncestors: MenuItemAncestors
	hasParent: boolean
}

const MenuLinkItem: React.FC<MenuLinkItemProps> = props => {
	const isLoggedIn = useIsLoggedIn()
	const { closeAllDropDowns } = React.useContext(PrimaryMenuContext)
	const { isOpened } = React.useContext(CollapsibleMenuContext)
	const location = useLocation()
	const isCurrentOrNestedCategory = props.menu.url && location.pathname.includes(props.menu.url)
	const StyledLink = styled(Link)`
		font-size: ${scale.px(2)};
	`
	const MenuItemHeadingTag = isCurrentOrNestedCategory ? 'a' : StyledLink

	return (
		<Flex h={3} mb={0.5}>
			<CollapsibleMenu.Item pb={0}>
				{props.menu.url && (
					<MenuItemHeadingTag
						to={props.menu.url}
						{...(isCurrentOrNestedCategory ? { href: props.menu.url } : { to: props.menu.url })}
						tabIndex={isOpened || !props.hasParent ? 0 : -1}
						onClick={() => {
							closeAllDropDowns()
							trackMegaMenuClickEvent(
								props.menuItemAncestors.category,
								isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C,
								props.hasParent
									? props.menuItemAncestors.parent
									: props.menu.title,
								props.hasParent ? props.menu.title : "none"
							)
						}}
					>
						{props.menu.title}
					</MenuItemHeadingTag>
				)}
			</CollapsibleMenu.Item>
		</Flex>
	)
}

interface MapMenuLevelsProps
	extends Pick<PrimaryMenuItemContextValue, "closeAllDropDowns" | "openLeveledMenu" | "closeAllLeveledMenus"> {
	locales: Locales
	trackMainMenuClickEvent: (
		linkLabel: string,
		userType: CustomerTypeEnum
	) => void
	trackMegaMenuClickEvent: (
		categoryLabel: string,
		userType: CustomerTypeEnum,
		parentLinkLabel?: string,
		childLinkLabel?: string
	) => void
}

const mapPrimaryMenu = (
	menu: Menu[],
	level: keyof MenuLevels = 0,
	props: MapMenuLevelsProps,
	isLoggedIn: boolean
) =>
	menu.map(item =>
		({
			0: () => (
				<PrimaryMenu.Inner key={item.id}>
					{item.children ? (
						<PrimaryMenu.Item>
							<Text.Button
								fontSize={{ _: 2, xl: 2.25 }}
								mb={0}
								textColor="black"
								onClick={() => props.openLeveledMenu(item.id, 0)}
							>
								{item.title}
							</Text.Button>
						</PrimaryMenu.Item>
					) : item.url ? (
						<PrimaryMenu.Item>
							<Link
								to={item.url}
								onClick={() => {
									props.closeAllDropDowns()
									props.closeAllLeveledMenus()
									props.trackMainMenuClickEvent(
										item.title,
										isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C
									)
								}}
							>
								{item.title}
							</Link>
						</PrimaryMenu.Item>
					) : (
						<PrimaryMenu.Item>{item.title}</PrimaryMenu.Item>
					)}
				</PrimaryMenu.Inner>
			)
		}[level]())
	)
interface MegaMenuLevelProps {
	menu: Menu[]
	isHeaderSticky?: boolean
	// menuItemAncestors: MenuItemAncestors
}

const findMenuItemById = (menu: Menu, id: number, level: number = 1): { item: Menu | null, level: number } => {
    if (menu.id === id) {
        return { item: menu, level };
    }

    if (menu.children && level < 3) { // Stop the recursion if level is 3 or more
        for (let i = 0; i < menu.children.length; i++) {
            const foundItem = findMenuItemById(menu.children[i], id, level + 1);

            if (foundItem.item) {
                return foundItem;
            }
        }
    }

    return { item: null, level };
};

const MegaMenu: React.FC<MegaMenuLevelProps> = ({ 
    menu, 
    isHeaderSticky
}) => {
	const { activeLeveledMenuIds, closeAllLeveledMenus, openLeveledMenu } = React.useContext(PrimaryMenuContext)
    const activeRootMenuItem = menu.find(menuItem => activeLeveledMenuIds?.includes(menuItem.id));
	const isLoggedIn = useIsLoggedIn();

    return (
        <Drawer
            isOpened={activeLeveledMenuIds && activeLeveledMenuIds.length > 0}
            autoWidth
            customTopPosition={parseInt(scale.px(isHeaderSticky ? MENU_HEIGHT.desktop : 18.75))}
            onClose={closeAllLeveledMenus}
        >
            <Drawer.Content display="flex" h={1}>
                {activeRootMenuItem && activeLeveledMenuIds && activeLeveledMenuIds.length > 0 && (
                    <>
                        {activeLeveledMenuIds.map(menuId => {
                            const { item: activeMenuItem, level } = findMenuItemById(menu[0], menuId);

                            return (
                                activeMenuItem ? 
                                    <PrimaryMenu.Level key={activeMenuItem.id}>
										<MenuItemHeading item={activeMenuItem} />
										{activeMenuItem?.children?.map((item, i) => {
											return (
												<PrimaryMenu.LevelItem key={item.id} mb={2}>
													{item.children ? (
														<Flex flexDirection="column" w={1}>
															<Text.Button
																mb={0}
																onClick={() => openLeveledMenu?.(item.id, level)}
															>
																<Flex justifyContent="space-between">
																	<Text as="span" mb={0} fontSize={1.75} lineHeight={2.25}>{item.title}</Text>
																	<Icon color="grey-9" icon="chevron-right" size={2.5} ml={1} />
																</Flex>
															</Text.Button>
														</Flex>
													) : (
														item.url && (
															<Link
																to={item.url}
																onClick={() => {
																	closeAllLeveledMenus()
																	trackMainMenuClickEvent(
																		item.title,
																		isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C
																	);
																}}
																key={i}
															>
																<Text as="span" mb={0} fontSize={1.75} lineHeight={2.25}>{item.title}</Text>
															</Link>
														)
													)}
												</PrimaryMenu.LevelItem>
											)}
										)}
                                    </PrimaryMenu.Level> : 
                                null
                            )
                        })}
                    </>
                )}
            </Drawer.Content>
        </Drawer>
    )
}

const MenuItemHeading: React.FC<{ item: Menu }> = (props) => {
	const isLoggedIn = useIsLoggedIn()
	const { closeAllDropDowns, closeAllLeveledMenus } = React.useContext(PrimaryMenuContext)
	const location = useLocation()

	const isCurrentOrNestedCategory = props.item.url && location.pathname.includes(props.item.url)
	const MenuItemHeadingTag = isCurrentOrNestedCategory ? Text.Anchor : Text.Link

	return (
		props.item.url ? (
			<MenuItemHeadingTag
				to={props.item.url}
				{...(isCurrentOrNestedCategory ? { href: props.item.url } : { to: props.item.url })}
				variant="headline-6"
				fontSize={2.5}
				lineHeight={4}
				mb={1}
				onClick={() => {
					closeAllLeveledMenus()
					closeAllDropDowns()
					trackMegaMenuClickEvent(
						props.item.title,
						isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C,
						"none",
						"none"
					)
				}}
			>
				{props.item.title}
			</MenuItemHeadingTag>
		) : (
			<Text
				variant="headline-6"
				fontSize={2.5}
				lineHeight={4}
				mb={1}
			>
				{props.item.title}
			</Text>
		)
	)
}

export const MegaMenuLevelOne: React.FC<MegaMenuLevelProps> = ({ menu, ...rest }) => {
	return (
		<>
			{menu.map(item => {
				return (
					<Flex
						w={{ _: 1, m: 1 / 3, l: 1 / 4 }}
						mb={{ _: 4, m: 8 }}
						pl={{ m: 2.5 }}
						pr={{ m: 2.5 }}
						borderRight={{ m: true }}
						borderColor="grey-1"
						flexDirection="column"
						key={item.id}
					>
						<MenuItemHeading item={item} />
						{item?.children && (
							<MegaMenuLevelTwo
								category={item.title}
								menu={item.children}
								{...rest}
							/>
						)}
					</Flex>
				)
			})}
		</>
	)
}

interface MegaMenuLevelTwoProps extends MegaMenuLevelProps {
	category: string
}

const MegaMenuLevelTwo: React.FC<MegaMenuLevelTwoProps> = ({
	menu,
	category
}) => {
	const itemLimit = 5
	const [isExpanded, setIsExpanded] = React.useState(false)
	const isExpandable = menu.length > itemLimit
	const locales = useLocales(translationNamespace.shared("Header"), [
		"allIn",
		"seeAll",
		"showLess"
	])

	return (
		<Flex flex={1} flexDirection="column">
			{menu.map((item, index) => {
				const { children, ...currentItem } = item
				return (
					<Flex
						display={index > itemLimit - 1 && !isExpanded ? "none" : "block"}
						key={item.id}
						mb={0.5}
					>
						<CollapsibleMenu key={item.id}>
							{item?.children ? (
								<>
									<CollapsibleMenu.Parent>
										<Text fontSize={2} mb={0}>
											{item.title}
										</Text>
									</CollapsibleMenu.Parent>
									<CollapsibleMenu.Children>
										{[
											{
												// adds the parent link to the link list
												...currentItem,
												title: `${locales.allIn} ${currentItem.title}`
											},
											...item.children
										].map(child => {
											return (
												<MenuLinkItem
													menu={child}
													menuItemAncestors={{ category, parent: item.title }}
													key={child.id}
													hasParent={true}
												/>
											)
										})}
									</CollapsibleMenu.Children>
								</>
							) : (
								<MenuLinkItem
									menu={item}
									menuItemAncestors={{ category }}
									hasParent={false}
								/>
							)}
						</CollapsibleMenu>
					</Flex>
				)
			})}
			{isExpandable && (
				<Text.Button
					fontSize={2}
					textColor="blue-6"
					mb={0}
					onClick={() => setIsExpanded(prev => !prev)}
				>
					{isExpandable && !isExpanded
						? `${locales.seeAll} (${menu.length})`
						: locales.showLess}
				</Text.Button>
			)}
		</Flex>
	)
}

const DesktopPrimaryMenu: React.FC<DesktopPrimaryMenuProps> = React.memo(
	props => {
		const locales: Locales = useLocales(translationNamespace.shared("Header"), [
			"logIn",
			"allIn"
		])

		const isLoggedIn = useIsLoggedIn()

		const mapMenuLevelsProps = {
			locales,
			trackMainMenuClickEvent,
			trackMegaMenuClickEvent
		}

		return (
			<PrimaryMenu position="static">
				<PrimaryMenuContext.Consumer>
					{({ closeAllDropDowns, openLeveledMenu, closeAllLeveledMenus }) => {
						return (
							<Flex w={1} justifyContent="center">
								<Flex
									alignItems="center"
									justifyContent="space-between"
									w={1}
									maxw={SITE_MAX_WIDTH}
									top={0}
									px={2}
								>
									<Flex>
										<Link to="/" onClick={closeAllDropDowns}>
											<Logo>
												<Symbol.Logo />
											</Logo>
										</Link>
									</Flex>
									<Flex
										position="relative"
										alignItems="center"
										flex={1}
										mr={3}
										ml={{ l: 4, xl: 5, xxl: 7.5 }}
									>
										<Flex minw={0.75} justifyContent="flex-start">
											{mapPrimaryMenu(
												props.mainPrimaryMenu as Menu[],
												0,
												{
													closeAllDropDowns,
													closeAllLeveledMenus,
													openLeveledMenu,
													...mapMenuLevelsProps
												},
												isLoggedIn
											)}
										</Flex>
										<DesktopSearch />
									</Flex>
									<Flex flexDirection="column" alignItems="flex-end">
										<CartDropDown initializeCart />
									</Flex>
									<Flex
										flexDirection="column"
										alignItems="flex-end"
										position="absolute"
										left={0}
										top="100%"
										w={1}
									>
										<MegaMenu 
											menu={props.mainPrimaryMenu as Menu[]}
											isHeaderSticky={props.isHeaderSticky}
										 />
									</Flex>
								</Flex>
							</Flex>
						)
					}}
				</PrimaryMenuContext.Consumer>
			</PrimaryMenu>
		)
	}
)
export { DesktopPrimaryMenu }
