import React from "react"
import { Flex } from "@focus-nordic/ui-components"
import env from "@beam-australia/react-env"
import { useHistory } from "react-router"
import { Panel, Spinner } from "../../components"
import {
	useLocales,
	useCart,
	useNetsCheckoutApi,
	usePaymentIdQueryString
} from "../../../../hooks"
import { useCheckout } from "../../context/CheckoutProvider"
import { useGetB2CPaymentQuery } from "../../operations.generated"
import { PaymentInput } from "../../../../@types/graphql.generated"
import {
	cartItemToCartItemInput,
	localeCodeCapitalizesLanguage,
	trackPurchase
} from "../../../../utils"
import { LocalesContext } from "../../../../context"
import {
	CHECKOUT_SUCCESS_ROUTE,
	CHECKOUT_FAILURE_ROUTE
} from "@focus-nordic/www-common/constants"

export interface PaymentStepProps {}

const iFrameContainerId = "checkout-container-div"
const CHECKOUT_ORDER_ID_STORAGE_KEY = "checkoutOrderId"

const getNetsCheckoutKey = (countryCode: string) =>
	({
		// record of checkout keys by market
		"da-dk": env("NETS_CHECKOUT_KEY_DK"),
		"nb-no": env("NETS_CHECKOUT_KEY_NO")
		// returns the swedish checkout key by default
	}[countryCode] || env("NETS_CHECKOUT_KEY_SE"))

interface PaymentConfirmationProps {
	paymentId: string
}

const PaymentConfirmation: React.FC<PaymentConfirmationProps> = props => {
	const netsCheckoutApi = useNetsCheckoutApi()
	const { localeCode } = React.useContext(LocalesContext)
	const history = useHistory()
	const checkout = useCheckout()
	const { cart } = useCart()
	const sentTracking = React.useRef(false)

	React.useEffect(() => {
		if (netsCheckoutApi?.data?.Checkout) {
			const netsCheckout = new netsCheckoutApi.data.Checkout({
				checkoutKey: getNetsCheckoutKey(localeCode),
				paymentId: props.paymentId,
				containerId: iFrameContainerId,
				language: localeCodeCapitalizesLanguage(localeCode)
			})
			netsCheckout.on("payment-completed", (e: any) => {
				const orderId = sessionStorage.getItem(CHECKOUT_ORDER_ID_STORAGE_KEY)

				if (!sentTracking.current) {
					trackPurchase({
						userType: checkout.customerType,
						orderId: props.paymentId,
						revenue:
							(cart.itemsPriceSum?.netPrice ?? 0) +
							(checkout.selectedFreight?.deliveryOption?.price.netPrice ?? 0),
						cartItems: cart.items ?? [],
						tax: cart.vatRate ?? 0,
						shipping:
							checkout.selectedFreight?.deliveryOption?.price.netPrice ?? 0
					})
					sentTracking.current = true
				}

				const queryString = orderId ? `?orderNumber=${orderId}` : '';
				history.push(
					`${CHECKOUT_SUCCESS_ROUTE}${queryString}`
				)
			})
		}
	}, [netsCheckoutApi?.data?.Checkout, localeCode, props.paymentId])

	return (
		<>
			{netsCheckoutApi.isLoading && <Spinner />}
			<div id={iFrameContainerId} />
		</>
	)
}

const Payment: React.FC = () => {
	const checkout = useCheckout()

	const { cart, discountCodeValue } = useCart()
	const [isLoading, setIsLoading] = React.useState(false)
	const netsCheckoutApi = useNetsCheckoutApi()
	const { localeCode } = React.useContext(LocalesContext)
	const history = useHistory()
	const sentTracking = React.useRef(false)

	const paymentIdResult = useGetB2CPaymentQuery({
		variables: {
			paymentInput: {
				...(checkout.paymentInput as PaymentInput),
				// Filter out items with 0 quantity
				cartItems: cart.items?.filter(item => item.quantity !== 0).map(cartItem =>
					cartItemToCartItemInput(cartItem)
				)
			},
			...(env("CURRENT_ENV") === "development" && {
				checkoutUrlOverwrite: "http://localhost:3000/checkout"
			}),
			...(discountCodeValue && {
				discountCode: discountCodeValue
			})
		},
		fetchPolicy: "network-only"
	})

	React.useEffect(() => {
		const orderId = paymentIdResult.data?.b2CPayment?.orderId;
		if (orderId) {
			sessionStorage.setItem(CHECKOUT_ORDER_ID_STORAGE_KEY, orderId);
		}
	}, [paymentIdResult.data?.b2CPayment?.orderId])

	React.useEffect(() => {
		if (paymentIdResult.loading || netsCheckoutApi.isLoading) {
			setIsLoading(true)
		} else if (paymentIdResult.data?.b2CPayment?.id && netsCheckoutApi.data) {
			setIsLoading(false)
			const netsCheckout = new netsCheckoutApi.data.Checkout({
				checkoutKey: getNetsCheckoutKey(localeCode),
				paymentId: paymentIdResult.data.b2CPayment.id,
				containerId: iFrameContainerId,
				language: localeCodeCapitalizesLanguage(localeCode)
			})
			netsCheckout.on("payment-completed", (e: any) => {
				if (!sentTracking.current) {
					trackPurchase({
						userType: checkout.customerType,
						orderId: paymentIdResult.data?.b2CPayment?.orderId ?? "",
						revenue:
							(cart.itemsPriceSum?.netPrice ?? 0) +
							(checkout.selectedFreight?.deliveryOption?.price.netPrice ?? 0),
						cartItems: cart.items ?? [],
						tax: cart.vatRate ?? 0,
						shipping:
							checkout.selectedFreight?.deliveryOption?.price.netPrice ?? 0
					})
					sentTracking.current = true
				}

				history.push(
					`${CHECKOUT_SUCCESS_ROUTE}?orderNumber=${paymentIdResult.data?.b2CPayment?.orderId}`
				)
			})
		} else {
			setIsLoading(false)
			history.push(`${CHECKOUT_FAILURE_ROUTE}`)
		}
	}, [paymentIdResult, netsCheckoutApi, localeCode, history])

	return isLoading ? <Spinner /> : <div id={iFrameContainerId} />
}

const PaymentStep: React.FC<PaymentStepProps> = props => {
	const locales = useLocales("checkout", ["paymentTitle"])
	const checkout = useCheckout()
	const paymentId = usePaymentIdQueryString()

	React.useEffect(() => {
		return () => {
			sessionStorage.removeItem(CHECKOUT_ORDER_ID_STORAGE_KEY);
		}
	}, [])

	return (
		<Flex flexDirection="column" alignItems="center" w={1}>
			<Panel>
				<Panel.Title>{locales.paymentTitle}</Panel.Title>
				<Flex my={2} justifyContent="space-around">
					{checkout.currentStepId === "payment" &&
						(paymentId ? (
							// used when a user gets redirected back to checkout with an existing query string id
							<PaymentConfirmation paymentId={paymentId} />
						) : (
							// inits a payment window based on cart items, and user input from the checkout steps
							<Payment />
						))}
				</Flex>
			</Panel>
		</Flex>
	)
}

export { PaymentStep }
