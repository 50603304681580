import React from "react"
import {
	useCheckout,
	StepId,
	CheckoutProvider
} from "../context/CheckoutProvider"
import { DeliveryStep, UserInfoStep, SummaryStep, PaymentStep } from "../steps"
import { Step } from "../Step"
import { Layout } from "../Layout"
import {
	useCart,
	useCheckoutTracking,
	usePaymentIdQueryString
} from "../../../hooks"

const steps: Record<StepId, React.ReactNode> = {
	payment: PaymentStep,
	delivery: DeliveryStep,
	userInfo: UserInfoStep,
	summary: SummaryStep,
	status: () => <React.Fragment />
}

const View: React.FC = () => {
	const checkout = useCheckout()
	const { cart } = useCart()

	useCheckoutTracking(
		checkout.customerType,
		checkout.currentStepIndex + 1,
		cart.items
	)

	return (
		<Layout>
			{Object.keys(steps).map(key => (
				<Step key={key} isActive={key === checkout.currentStepId}>
					{React.createElement(steps[key])}
				</Step>
			))}
		</Layout>
	)
}

const CheckoutView: React.FC = () => {
	const PaymentId = usePaymentIdQueryString()

	return (
		<CheckoutProvider
			currentStepId={
				// go to payment step directly if paymentId is present i query string
				PaymentId ? "payment" : undefined
			}
		>
			<View />
		</CheckoutProvider>
	)
}

export { CheckoutView }
