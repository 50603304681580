import TagManager from "react-gtm-module"
import { CustomerTypeEnum } from "../../@types/graphql.generated"

interface TrackSearchResultsClickData {
	searchTerm: string
	targetUrl: string
	userType: CustomerTypeEnum
}

interface TrackSearchResultsData {
	searchTerm: string
	searchResultCount: number
	userType: CustomerTypeEnum
}

export const getSearchParam = (locationSearch: string) => {
	const searchParam = new URLSearchParams(locationSearch).get("q")
	return searchParam
}

export const trackSearchResultClick = ({
	searchTerm,
	targetUrl,
	userType
}: TrackSearchResultsClickData) => {
	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			event: "searchResultClick",
			searchTerm,
			targetUrl,
			type: userType
		}
	})
}
export const trackSearchResults = ({
	searchTerm,
	searchResultCount,
	userType
}: TrackSearchResultsData) => {
	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			event: "searchResult",
			searchTerm,
			searchResultCount,
			type: userType
		}
	})
}
