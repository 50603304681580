"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Buyable = void 0;
var OrderStatus_1 = require("../OrderStatus");
var ProductLabel_1 = require("../ProductLabel");
var Buyable;
(function (Buyable) {
    Buyable["Allowed"] = "ALLOWED";
    Buyable["Disabled"] = "DISABLED";
    Buyable["Hidden"] = "HIDDEN";
})(Buyable = exports.Buyable || (exports.Buyable = {}));
