"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scale = void 0;
var scale_1 = require("../constants/scale");
var calc = function (n) { return n * scale_1.base; };
var pxCalc = function (n) { return calc(n); };
var relCalc = function (n) { return calc(n) / scale_1.baseFontSize; };
exports.scale = Object.assign(calc, {
    px: function (n) { return pxCalc(n) + "px"; },
    em: function (n) { return relCalc(n) + "em"; },
    rem: function (n) { return relCalc(n) + "rem"; }
});
