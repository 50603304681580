import React from "react"
import {
	Flex,
	Text,
	ProductCard,
	Icon,
	PrimaryMenuContext,
	ProductCardText,
	ProductCardCartItemProps,
	ProductLabels
} from "@focus-nordic/ui-components"
import { cartItemDataToCartItemProps } from "./utils"
import { useIsLoggedIn, useIsSeller, useLocales } from "../../hooks"
import { AddToCartButton, trackRemoveFromCartClick } from "../AddToCartButton"
import { translationNamespace } from "../../hooks/useLocales"
import { LocalesContext } from "../../context"
import { StockStatusType } from "../StockStatus"
import { useCart } from "../../hooks/useCart"
import { CommonProductFieldsFragment } from "../../operations/product.generated"
import { Loader } from "../Loader"
import { CustomPrice } from "./CustomPrice"
import { CustomerTypeEnum } from "../../@types/graphql.generated"
import { NotifyMe } from "../NotifyMe"

const ProductList: React.FC<Pick<
	ProductCardCartItemProps,
	"columnLayoutBreakpoint"
> & { shouldInitCart?: boolean }> = props => {
	const { cart, updateCartItem, initCart, isInitialized, loading } = useCart()

	const { localeCode } = React.useContext(LocalesContext)
	const isSeller = useIsSeller()
	const isLoggedIn = useIsLoggedIn()
	const { closeAllDropDowns } = React.useContext(PrimaryMenuContext)

	React.useEffect(() => {
		if (props.shouldInitCart) {
			initCart(isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C)
		}
	}, [])

	const cartLocales = useLocales(translationNamespace.shared("Cart"), [
		"cartTitle",
		"delete",
		"addToCart",
		"checkOut",
		"checkOutUrl",
		"netPrice",
		"grossPrice",
		"totalPrice",
		"cartIsEmpty",
		"cartProductAvailabilityMessage",
		"cartProductZeroAvailabilityMessage",
		"cartProductNoMoreItemsMessage"
	])

	const productLocales = useLocales(translationNamespace.shared("Product"), [
		"articleNumber",
		"ean",
		"rsp",
		"margin",
		"fee",
		"price",
		"discountApplied"
	])

	const stockStatusLocales = useLocales(
		translationNamespace.shared("StockStatus"),
		["stockStatusLabel"]
	)

	const productLabelLocales = useLocales(translationNamespace.shared("ProductLabel"), [
		"productLabelCampaign",
		"productLabelComingSoon",
		"productLabelKit",
		"productLabelNew",
		"productLabelOutlet",
		"productLabelCampaignBlackFriday"
    ])

	const locales = {
		...cartLocales,
		...productLocales,
		...{
			stockStatus: stockStatusLocales.stockStatusLabel
		},
		...{
			productLabel: {
				[ProductLabels.CAMPAIGN]: productLabelLocales.productLabelCampaign,
				[ProductLabels.COMINGSOON]: productLabelLocales.productLabelComingSoon,
				[ProductLabels.KIT]: productLabelLocales.productLabelKit,
				[ProductLabels.NEW]: productLabelLocales.productLabelNew,
				[ProductLabels.OUTLET]: productLabelLocales.productLabelOutlet,
				[ProductLabels.CAMPAIGNBLACKFRIDAY]: productLabelLocales.productLabelCampaignBlackFriday,
			}
		}
	}

	const deleteCartItem = (product: CommonProductFieldsFragment) => {
		const quantity =
			product.forcedPackageSize && isLoggedIn ? product.packageSize ?? 1 : 1
		trackRemoveFromCartClick({
			userType: isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C,
			product,
			quantity
		})
		updateCartItem({
			product,
			quantity: 0
		})
	}

	return (
		<>
			{loading && !isInitialized ? (
				<Loader minh={40} />
			) : (
				<Flex flexDirection="column" flex={1}>
					{cart.items && cart.items.length > 0 ? (
						<>
							<Flex
								flex={1}
								display={{ _: "block", l: "flex" }}
								flexWrap="wrap"
								flexDirection={{ _: "column", l: "row" }}
								alignItems="flex-start"
								justifyContent="flex-end"
							>
								{cart?.items?.map(
									item =>
										item.product && (
											<ProductCard.CartItem
												key={item.id}
												{...cartItemDataToCartItemProps(
													item,
													localeCode,
													cart.currency
												)}
												locales={locales}
												stockStatus={
													<StockStatusType
														{...item.product}
														id={item.id}
														textComponent={ProductCardText.Label}
													/>
												}
												customPrice={
													isLoggedIn && isSeller ? (
														<CustomPrice cartItem={item} />
													) : null
												}
												addToCart={
													<Flex minw={18}>
														<AddToCartButton
															parentContext="cart"
															size="small"
															incrementButtonDisabledTooltipText={locales.cartProductNoMoreItemsMessage}
															disableButtonOnMaxAvailabilityReach={!isLoggedIn}
															product={item.product}
														/>
													</Flex>
												}
												notifyMe={
													<NotifyMe 
														variant="transparent"
														p={0}
														ml={1}
														productId={item.product?.id}
														productName={item.product?.name ?? ""}
													/>
												}
												onDelete={() => {
													item.product?.articleNumber &&
														deleteCartItem(item.product)
												}}
												onClick={closeAllDropDowns}
												columnLayoutBreakpoint={props.columnLayoutBreakpoint}
												displayAvailableItemsAlert={!isLoggedIn}
											/>
										)
								)}
							</Flex>
						</>
					) : (
						<Flex
							flex={1}
							justifyContent="center"
							alignItems="center"
							minh={40}
							w={{ _: 1, l: "auto" }}
						>
							<Flex flexDirection="column" alignItems="center">
								<Icon icon="cart" color="grey-3" size={20} />
								<Text>{locales.cartIsEmpty}</Text>
							</Flex>
						</Flex>
					)}
				</Flex>
			)}
		</>
	)
}

export { ProductList }
