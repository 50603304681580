import React from "react"
import { useGetCalculateB2CCartLazyQuery } from "../../operations/cart.generated"
import { CartItemInput, CustomerTypeEnum } from "../../@types/graphql.generated"

import {
	clientCartDefault,
	UpdateCartItemInput,
	CartContext
} from "./CartContextCommon"
import { cartItemsDbConnect } from "../../indexedDb"
import { COOKIE_ACTIVATED_DISCOUNT_CODE } from "../../constants"
import { setYearForward, getTopDomain } from "../../utils"
import Cookies from "js-cookie"

export const CartContextBtcProvider: React.FC = props => {
	// Todo: check and pass cart from previous session stored in indexedDb
	const [getCalculateB2CCart, getCalculateB2CCartResult] = useGetCalculateB2CCartLazyQuery({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "cache-and-network",
		variables: {
			cartItems: [],
			customerType: CustomerTypeEnum.B2C
		}
	})

	const [isInitialized, setIsInitialized] = React.useState(false)
	const [discountCodeValue, setDiscountCodeValue] = React.useState('')

	const cart = React.useRef(getCalculateB2CCartResult.data?.calculateB2CCart)

	if (getCalculateB2CCartResult.data) {
		cart.current = getCalculateB2CCartResult.data.calculateB2CCart
	}

	const initCart = async (customerType: CustomerTypeEnum = CustomerTypeEnum.B2C) => {
		const idb = await cartItemsDbConnect()
		const cartItems = await idb.getCartItems()
		const discountCode = Cookies.get(COOKIE_ACTIVATED_DISCOUNT_CODE)

		if (discountCode) {
			setDiscountCodeValue(discountCode)
		}
		
		getCalculateB2CCart({
			variables: {
				cartItems: cartItems.map(item => ({
					articleNumber: item.id,
					quantity: item.quantity
				})),
				customerType,
				...(discountCode && { discountCode }),
			}
		})
	}

	const updateCartWithDiscountCode = async (customerType: CustomerTypeEnum, discountCode: string) => {
		if (discountCode) {
			Cookies.set(COOKIE_ACTIVATED_DISCOUNT_CODE, discountCode, {
				domain: getTopDomain(window.location.hostname),
				expires: setYearForward()
			})
		} else {
			Cookies.remove(COOKIE_ACTIVATED_DISCOUNT_CODE)
		}

		const cartItems: CartItemInput[] = cart.current?.items ? cart.current.items.map(item => ({
			articleNumber: item.id,
			quantity: item.quantity
		})) : []

		getCalculateB2CCart({
			variables: {
				cartItems,
				customerType,
				...(discountCode && { discountCode }),
			}
		})
	}

	React.useEffect(() => {
		if (getCalculateB2CCartResult.data?.calculateB2CCart) {
			setIsInitialized(true)
		}
	}, [getCalculateB2CCartResult.data])

	const updateCartItem = async (input: UpdateCartItemInput) => {
		const discountCode = Cookies.get(COOKIE_ACTIVATED_DISCOUNT_CODE)
		const inputQuantity = input.quantity ?? 1

		let cartItems: CartItemInput[] = cart.current?.items
			? cart.current.items.reduce<CartItemInput[]>(
				(acc, item) => [
					...acc,
					// if the cart item is already in the cart override the current quantity
					...(item.id === input.product.id
						? inputQuantity === 0
							? [] // If inputQuantity is 0, exclude the item from the array
							: [
								{
									articleNumber: item.id,
									quantity: inputQuantity,
								},
							]
						: [
							// else return it as is
							{
							articleNumber: item.id,
							quantity: item.quantity,
							},
						]),
					],
					[]
				)
			: [];

		// add input item to the end of the cart items array it it's not already add
		if (!cartItems.find(item => item.articleNumber === input.product.id) && inputQuantity > 0) {
			cartItems = [
				...cartItems,
				{
					articleNumber: input.product.articleNumber,
					quantity: inputQuantity
				}
			]
		}

		// // filter out cart items with a quantity of <= 0
		// cartItems = cartItems.filter(item => !(item.quantity <= 0))

		const itemsDiscountData = cartItems.map(cartItem => {
			const currentItem = cart.current?.items && cart.current.items.find(item => item.id === cartItem.articleNumber)
			return {
				acticleNumber: cartItem.articleNumber,
				hasProductDiscount: Boolean(currentItem?.product?.discount)
			}
		})

		const isAllCartItemsHaveDiscount = itemsDiscountData.every(item => item.hasProductDiscount)

		getCalculateB2CCart({
			variables: {
				cartItems,
				customerType: CustomerTypeEnum.B2C,
				...(discountCode && !isAllCartItemsHaveDiscount && { discountCode })
			}
		})
	}

	const checkItemsAvailability = async () => {
		const discountCode = Cookies.get(COOKIE_ACTIVATED_DISCOUNT_CODE)

		const cartItems: CartItemInput[] = cart.current?.items
			? cart.current.items.reduce<CartItemInput[]>(
					(acc, item) => {
						const limitedQuantityItem = item.product?.availableItemsCount !== undefined && item.quantity > item.product?.availableItemsCount

						return [
							...acc,
							// if the quantity of the item is greater than availableItemsCount we limit it to availableItemsCount
							...(limitedQuantityItem
								? [
										{
											articleNumber: item.id,
											quantity: item.product?.availableItemsCount ?? 0,
										}
								  ]
								: [
										// else return it as is
										{
											articleNumber: item.id,
											quantity: item.quantity
										}
								  ])
						]
					} ,
					[]
			  )
			: []

		const itemsDiscountData = cartItems.map(cartItem => {
			const currentItem = cart.current?.items && cart.current.items.find(item => item.id === cartItem.articleNumber)
			return {
				acticleNumber: cartItem.articleNumber,
				hasProductDiscount: Boolean(currentItem?.product?.discount)
			}
		})

		const isAllCartItemsHaveDiscount = itemsDiscountData.every(item => item.hasProductDiscount)

		getCalculateB2CCart({
			variables: {
				cartItems,
				customerType: CustomerTypeEnum.B2C,
				...(discountCode && !isAllCartItemsHaveDiscount && { discountCode })
			}
		})
	}

	const clearCart = async () => {
		getCalculateB2CCart({
			variables: {
				cartItems: [],
				customerType: CustomerTypeEnum.B2C
			}
		})
		Cookies.remove(COOKIE_ACTIVATED_DISCOUNT_CODE)
	}

	return (
		<CartContext.Provider
			value={{
				cart: cart.current ?? clientCartDefault,
				updateCartItem,
				checkItemsAvailability,
				clearCart,
				initCart,
				loading: getCalculateB2CCartResult.loading,
				isInitialized,
				updateCartWithDiscountCode,
				discountCodeValue
			}}
		>
			{props.children}
		</CartContext.Provider>
	)
}
