import * as React from "react"
import {
	Text,
	Flex,
	PromoCard,
	IconLabel,
	Image,
	ImageProps
} from "@focus-nordic/ui-components"
import {
	CardPromoBlock as ICardPromoBlock,
	ImageBase
} from "../../@types/graphql.generated"
import { LinkAnchor } from "../LinkAnchor"
import { imagePath } from "../../utils"

type CardVariant = "normal" | "wide"

const getCardVariantImageProps = (
	cardVariant: CardVariant,
	image: ImageBase
): ImageProps =>
	({
		normal: {
			ratio: 5 / 4,
			src: imagePath(image.fileName, "4:5=384x480"),
			srcSet: [
				imagePath(image.fileName, "4:5=384x480", true),
				imagePath(image.fileName, "4:5=768x960", true)
			],
			responsiveSrcSet: {
				l: [
					`${imagePath(image.fileName, "4:5=384x480")} 1x`,
					`${imagePath(image.fileName, "4:5=768x960")} 2x`
				]
			}
		},
		wide: {
			ratio: 2 / 3,
			src: imagePath(image.fileName, "3:2=384x256"),
			srcSet: [
				imagePath(image.fileName, "3:2=384x256", true),
				imagePath(image.fileName, "3:2=768x512", true)
			],
			responsiveSrcSet: {
				m: [
					`${imagePath(image.fileName, "3:2=384x256")} 1x`,
					`${imagePath(image.fileName, "3:2=768x512")} 2x`
				],
				l: [
					`${imagePath(image.fileName, "3:2=768x512")} 1x`,
					`${imagePath(image.fileName, "3:2=1536x1024")} 2x`
				]
			}
		}
	}[cardVariant])

const CardPromoBlock: React.FC<ICardPromoBlock> = props => {
	const cardVariant: CardVariant =
		props.cards && props.cards.length <= 2 ? "wide" : "normal"

	return (
		<div
			style={{
				width: "100%",
				backgroundColor: props.blockTheme?.backgroundColor
			}}
		>
			<Flex justifyContent="center" pt={{ _: 3, m: 5 }} pb={{ _: 4, m: 6 }}>
				<Flex maxw={180} width={1} flexDirection="column">
					<Flex
						flexDirection="column"
						maxw={80}
						w={1}
						mb={{ _: 1.5, m: 2 }}
						px={{ _: 2, s: 5, l: 8 }}
					>
						{props.cardPromoTitle && (
							<Text variant="headline-4" as="h2" mb={0}>
								<span style={{ color: props.blockTheme?.textColor }}>
									{props.cardPromoTitle}
								</span>
							</Text>
						)}
						{props.preamble && (
							<Text mt={1} mb={0}>
								<span style={{ color: props.blockTheme?.textColor }}>
									{props.preamble}
								</span>
							</Text>
						)}
					</Flex>

					<Flex
						flexWrap={{ _: "nowrap", m: "wrap" }}
						px={{ _: 2, s: 5, l: 8 }}
						w={1}
						overflow={{ _: "scroll", m: "auto" }}
					>
						{props.cards &&
							props.cards.map((card, index) => {
								const isNotLastCard = index !== props.cards!.length - 1
								return (
									<React.Fragment key={index}>
										<Flex
											flex={{ _: "none", m: `1 0 calc(50% - 32px)`, l: "1" }}
											mr={{
												_: isNotLastCard ? 1 : 0,
												m: 0,
												l: isNotLastCard ? 2 : 0
											}}
											mb={{
												_: 0,
												m: isNotLastCard ? 2 : 0,
												l: 0
											}}
											w={{ _: 35, m: "100%" }}
										>
											{(() => {
												const PromoCardComp = () => (
													<PromoCard
														media={
															card.image && (
																<Image
																	{...getCardVariantImageProps(
																		cardVariant,
																		card.image
																	)}
																/>
															)
														}
														variant={cardVariant}
													>
														{card.title && (
															<PromoCard.Title>{card.title}</PromoCard.Title>
														)}
														{card.ctaLink && (
															<IconLabel
																iconColor="blue-6"
																textColor="white"
																circleBg="blue-1"
															>
																{card.ctaLink.title}
															</IconLabel>
														)}
													</PromoCard>
												)
												return card.ctaLink ? (
													<LinkAnchor
														{...card.ctaLink}
														style={{ width: "100%" }}
													>
														<PromoCardComp />
													</LinkAnchor>
												) : (
													<PromoCardComp />
												)
											})()}
										</Flex>
									</React.Fragment>
								)
							})}
					</Flex>
				</Flex>
			</Flex>
		</div>
	)
}

export { CardPromoBlock }
