import * as React from "react"
import {
	SearchInput,
	SearchInputProps,
	SearchSuggestion as SuggestionItem
} from "@focus-nordic/ui-components"
import { useGetSearchSuggestionLazyQuery } from "./operations.generated"
import { QuickLink, LinkTarget } from "../../@types/graphql.generated"
import { useHistory } from "react-router-dom"
import debounce from "lodash/debounce"
import { getSuggestionIcon } from "./utils"
import { Suggestion } from "./types"
import { useLocales } from "../../hooks"
import { translationNamespace } from "../../hooks/useLocales"
import { Z_INDEX } from "../../constants"

interface SearchSuggestionProps
	extends Pick<
		SearchInputProps<QuickLink>,
		"inputFieldProps" | "onSubmit" | "isOpen" | "inputValue"
	> {
	initialValue?: string
	clearOnSubmit?: boolean
}

const SearchSuggestion: React.FC<SearchSuggestionProps> = props => {
	const [
		getSearchSuggestion,
		{ data, loading }
	] = useGetSearchSuggestionLazyQuery({
		fetchPolicy: "cache-first"
	})
	const [internalInputValue, setInternalInputValue] = React.useState(
		props.initialValue!
	)
	const [suggestions, setSuggestions] = React.useState<Suggestion[]>([])
	const inputRef = React.useRef<HTMLInputElement>()
	const history = useHistory()
	const debouncedGetSearchSuggestion = React.useCallback(
		debounce(getSearchSuggestion, 350),
		[getSearchSuggestion]
	)
	const [isFocused, setisFocused] = React.useState(false)

	const inputValue = props.inputValue || internalInputValue

	React.useEffect(() => {
		if (inputValue.length > 0) {
			debouncedGetSearchSuggestion({
				variables: { searchTerm: inputValue }
			})
		}
	}, [internalInputValue, debouncedGetSearchSuggestion])

	React.useEffect(() => {
		if (inputValue.length === 0) {
			setSuggestions([])
		}
		if (data) {
			setSuggestions(data.searchSuggestions?.suggestions ?? [])
		}
	}, [data, inputValue])

	const isOpen = isFocused && inputValue.length > 0

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" })
	}

	const onSelect = (quickLink: QuickLink | null) => {
		if (props.clearOnSubmit) {
			setInternalInputValue("")
		} else {
			setInternalInputValue(quickLink?.link?.title ?? "")
		}
		if (quickLink?.link) {
			if (quickLink?.link?.isExternal) {
				window.open(quickLink.link.url, "_blank")
			} else {
				history.push(quickLink.link.url)
			}
			if (inputRef.current) {
				inputRef.current.blur()
			}
		}
		scrollToTop()

		return quickLink?.link?.title
	}

	const locales = useLocales(translationNamespace.shared("SearchSuggestion"), [
		"route",
		"placeholder"
	])

	const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		setisFocused(false)
		props.inputFieldProps?.onBlur && props.inputFieldProps.onBlur(e)
	}

	const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		setisFocused(true)
		props.inputFieldProps?.onFocus && props.inputFieldProps.onFocus(e)
	}

	const getSearchRoute = (query: string) =>
		`/${locales.route}/?q=${encodeURIComponent(query)}`

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		if (props.clearOnSubmit) {
			setInternalInputValue("")
		}
		e.preventDefault()
		if (inputRef.current) {
			inputRef.current.blur()
		}
		props.onSubmit && props.onSubmit(e)
		scrollToTop()
		history.push(getSearchRoute(internalInputValue))
	}

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		setInternalInputValue(e.target.value)
		props.inputFieldProps?.onChange && props.inputFieldProps.onChange(e)
	}

	React.useEffect(() => {
		const keyListener = (e: KeyboardEvent) => {
			if (e.keyCode === 27 && inputRef.current) {
				inputRef.current.blur()
			}
		}
		document.addEventListener("keydown", keyListener)

		return () => document.removeEventListener("keydown", keyListener)
	}, [inputRef])

	return (
		<SearchInput<Suggestion>
			inputFieldProps={{
				ref: inputRef,
				...props.inputFieldProps,
				onBlur,
				onFocus,
				onChange,
				placeholder: locales.placeholder
			}}
			inputValue={inputValue}
			onSelect={onSelect}
			onSubmit={onSubmit}
			isOpen={isOpen}
			isLoading={loading}
			zIndex={Z_INDEX.searchSuggestion}
		>
			{({ getItemProps, highlightedIndex }) => (
				<>
					{[
						{
							link: {
								url: getSearchRoute(internalInputValue),
								title: inputValue,
								alt: LinkTarget.Self,
								isExternal: false,
								target: LinkTarget.Self
							}
						},
						...suggestions
					].map((item, index) => (
						<SuggestionItem.Page
							{...getItemProps({
								key: `${item.link?.url}-${index}`,
								index,
								item,
								selected: highlightedIndex! === index
							})}
							title={item.link?.title}
							icon={getSuggestionIcon(item.__typename)}
						/>
					))}
				</>
			)}
		</SearchInput>
	)
}

SearchSuggestion.defaultProps = {
	inputValue: "",
	initialValue: "",
	clearOnSubmit: false
}

export { SearchSuggestion }
