import React from "react"
import { useForm } from "react-hook-form"
import {
	Button,
	Flex,
	Radio,
	TextField,
	Text
} from "@focus-nordic/ui-components"
import { useLocales, translationNamespace } from "../../hooks"
import { EMAIL_VALIDATION_PATTERN } from "../../constants/index"
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn"
import {
	NewsletterRecipient,
	CreateNewsletterSubscriberError,
	CustomerTypeEnum
} from "../../@types/graphql.generated"
import {
	useCreateNewsletterSubscriberMutation,
	CreateNewsletterSubscriberMutationVariables
} from "./operations.generated"
import { trackFormSubmit } from "../../utils"

type CreateNewsletterInput = CreateNewsletterSubscriberMutationVariables["input"]
const INVALID_INPUT_CODE = "-2"

export const NewsletterForm = () => {
	const isLoggedIn = useIsLoggedIn()
	const { handleSubmit, register, formState } = useForm<CreateNewsletterInput>({
		defaultValues: {
			newsletterRecipient: NewsletterRecipient.Btc
		}
	})

	const [
		createNewsletterSubscriber,
		newsletterSubscriber
	] = useCreateNewsletterSubscriberMutation()

	const { errors } = formState

	const locales = useLocales(translationNamespace.shared("Footer"), [
		"newsletterBtbRadioLabel",
		"newsletterBtcRadioLabel",
		"newsletterSubmit",
		"newsletterDescription",
		"newsletterEmailPlaceholder",
		"newsLetterEmailError",
		"newsletterTitle",
		"newsletterSuccessTitle"
	])

	const onsubmit = (data: CreateNewsletterInput) => {
		createNewsletterSubscriber({ variables: { input: data } })
		trackFormSubmit(
			"newsletterSubscriber",
			isLoggedIn ? CustomerTypeEnum.B2B : CustomerTypeEnum.B2C
		)
	}

	return (
		<>
			{newsletterSubscriber.data?.subscriber?.__typename !==
			"NewsletterSubscriber" ? (
				(() => {
					const subscriber: CreateNewsletterSubscriberError = newsletterSubscriber
						.data?.subscriber as CreateNewsletterSubscriberError
					const invalidInput =
						Boolean(errors.email) ||
						Boolean(subscriber?.code === INVALID_INPUT_CODE)

					return (
						<Flex
							as="form"
							onSubmit={handleSubmit(onsubmit)}
							flexDirection="column"
							w={1}
							// negates the height of the email error message to avoid uneven footer layout
							mb={invalidInput ? -3 : 0}
						>
							<Text variant="headline-4" textColor="white">
								{locales.newsletterTitle}
							</Text>
							<Text textColor="grey-3">{locales.newsletterDescription}</Text>
							<Flex mb={2}>
								<Flex mr={2}>
									<Radio
										variant="white"
										name="newsletterRecipient"
										disabled={newsletterSubscriber.loading}
										value={NewsletterRecipient.Btc}
										inputRef={register({
											required: true
										})}
										label={locales.newsletterBtcRadioLabel}
									/>
								</Flex>
								<Flex>
									<Radio
										variant="white"
										name="newsletterRecipient"
										disabled={newsletterSubscriber.loading}
										value={NewsletterRecipient.Btb}
										inputRef={register({
											required: true
										})}
										label={locales.newsletterBtbRadioLabel}
									/>
								</Flex>
							</Flex>
							<Flex w={1} alignItems="flex-start">
								<Flex mr={4} w={1}>
									<TextField
										placeholder={locales.newsletterEmailPlaceholder}
										type="email"
										name="email"
										fieldSize="small"
										disabled={newsletterSubscriber.loading}
										helperText={
											invalidInput ? locales.newsLetterEmailError : undefined
										}
										error={invalidInput}
										inputRef={register({
											pattern: EMAIL_VALIDATION_PATTERN,
											required: true
										})}
									/>
								</Flex>
								<Button whiteSpace="nowrap" type="submit" variant="white">
									{locales.newsletterSubmit}
								</Button>
							</Flex>
						</Flex>
					)
				})()
			) : (
				<Text variant="headline-5" textColor="white">
					{locales.newsletterSuccessTitle}
				</Text>
			)}
		</>
	)
}
