import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetCheckoutTranslationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCheckoutTranslationsQuery = (
  { __typename?: 'Query' }
  & { b2BMyPagesTranslations?: Types.Maybe<Array<(
    { __typename?: 'Translation' }
    & Pick<Types.Translation, 'key' | 'value'>
  )>> }
);


export const GetCheckoutTranslationsDocument = gql`
    query GetCheckoutTranslations {
  b2BMyPagesTranslations {
    key
    value
  }
}
    `;

/**
 * __useGetCheckoutTranslationsQuery__
 *
 * To run a query within a React component, call `useGetCheckoutTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckoutTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckoutTranslationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCheckoutTranslationsQuery(baseOptions?: Apollo.QueryHookOptions<GetCheckoutTranslationsQuery, GetCheckoutTranslationsQueryVariables>) {
        return Apollo.useQuery<GetCheckoutTranslationsQuery, GetCheckoutTranslationsQueryVariables>(GetCheckoutTranslationsDocument, baseOptions);
      }
export function useGetCheckoutTranslationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckoutTranslationsQuery, GetCheckoutTranslationsQueryVariables>) {
          return Apollo.useLazyQuery<GetCheckoutTranslationsQuery, GetCheckoutTranslationsQueryVariables>(GetCheckoutTranslationsDocument, baseOptions);
        }
export type GetCheckoutTranslationsQueryHookResult = ReturnType<typeof useGetCheckoutTranslationsQuery>;
export type GetCheckoutTranslationsLazyQueryHookResult = ReturnType<typeof useGetCheckoutTranslationsLazyQuery>;
export type GetCheckoutTranslationsQueryResult = Apollo.QueryResult<GetCheckoutTranslationsQuery, GetCheckoutTranslationsQueryVariables>;