import * as React from "react"
import {
	GalleryBanner,
} from "@focus-nordic/ui-components"
import { galleryBannerDataToProps } from "../../utils"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { GalleryBlock as IGalleryBlock } from "../../@types/graphql.generated"

const GalleryBlock: React.FC<IGalleryBlock> = props => {
	const locales = useLocales(translationNamespace.shared("imageData"), [
		"photographer"
	])

	return (
		<GalleryBanner
            items={galleryBannerDataToProps(props.galleryCards) || []}
            alternativeLayout={props.alternativeLayout}
            photographerLabel={locales.photographer}
        />
	)
}

export { GalleryBlock }
