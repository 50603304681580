"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StackNavigation = exports.StackNavigationContext = void 0;
var React = __importStar(require("react"));
var __1 = require("../");
var react_router_dom_1 = require("react-router-dom");
exports.StackNavigationContext = React.createContext({
    history: [],
    currentView: { id: "", component: function () { return React.createElement(React.Fragment, null); } },
    views: [],
    goToView: function (id) { },
    goBack: function () { }
});
var StackNavigation = function (props) {
    var findView = function (id, views) {
        return views.find(function (view) { return view.id === id; }) || props.views[0];
    };
    var _a = React.useState([
        findView(props.initialViewId, props.views)
    ]), history = _a[0], setHistory = _a[1];
    var _b = React.useState(history[0]), currentView = _b[0], setCurrentView = _b[1];
    var _c = React.useState(), previousView = _c[0], setPreviousView = _c[1];
    React.useEffect(function () {
        setCurrentView(history[history.length - 1]);
        setPreviousView(history[history.length - 2]);
    }, [history]);
    var goToView = function (id) {
        setHistory(function (prev) { return __spreadArray(__spreadArray([], prev), [findView(id, props.views)]); });
    };
    var goBack = function () {
        setHistory(function (prev) { return __spreadArray([], prev).splice(0, prev.length - 1); });
    };
    return (React.createElement(exports.StackNavigationContext.Provider, { value: {
            history: history,
            goToView: goToView,
            goBack: goBack,
            views: props.views,
            currentView: currentView,
            previousView: previousView
        } }, props.children));
};
exports.StackNavigation = StackNavigation;
var NavItem = function (props) { return (React.createElement(__1.Flex, { borderBottom: true, as: "span", w: 1, justifyContent: "space-between", py: 2.5, px: 2 },
    React.createElement(__1.Text, __assign({ fontSize: 2.25, as: "span", mb: 0, fontWeight: "bold" }, props)),
    props.hasChevron && React.createElement(__1.Icon, { color: "black", icon: "chevron-right" }))); };
/**
 *  Nav
 *	Acts as navigation item bewteen views
 */
StackNavigation.Nav = (function (props) {
    var goToView = React.useContext(exports.StackNavigationContext).goToView;
    return (React.createElement("button", { onClick: function () { return goToView(props.to); }, style: { display: "flex", width: "100%" } },
        React.createElement(NavItem, __assign({ textColor: "black", hasChevron: true }, props))));
});
/**
 *  Link
 *	React router link component for linking to other pages
 */
StackNavigation.Link = function (props) {
    return (React.createElement(react_router_dom_1.Link, __assign({ style: { display: "flex", width: "100%" } }, props),
        React.createElement(NavItem, { fontWeight: "normal" }, props.children)));
};
/**
 *  Views
 *	Are where the current view should be rendered
 */
StackNavigation.Views = (function (props) {
    var currentView = React.useContext(exports.StackNavigationContext).currentView;
    var CurrentView = currentView.component;
    return React.createElement(CurrentView, null);
});
