import * as Types from '../../../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetBackordersQueryVariables = Types.Exact<{
  input?: Types.Maybe<Types.GetBackordersInput>;
}>;


export type GetBackordersQuery = (
  { __typename?: 'Query' }
  & { backorders?: Types.Maybe<Array<(
    { __typename?: 'BackorderEntry' }
    & Pick<Types.BackorderEntry, 'orderDate' | 'orderNumber' | 'productName' | 'articleNumber' | 'quantity' | 'onDemand' | 'isExpired'>
    & { pricePerUnit: (
      { __typename?: 'priceInfo' }
      & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
    ), totalPrice: (
      { __typename?: 'priceInfo' }
      & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
    ), totalFees?: Types.Maybe<(
      { __typename?: 'priceInfo' }
      & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
    )>, productPageUrl: (
      { __typename?: 'link' }
      & Pick<Types.Link, 'url'>
    ), stockStatus?: Types.Maybe<(
      { __typename?: 'stockStatus' }
      & Pick<Types.StockStatus, 'quantity' | 'incomingQuantity'>
      & { deliveries?: Types.Maybe<Array<(
        { __typename?: 'incomingDelivery' }
        & Pick<Types.IncomingDelivery, 'confirmed' | 'date' | 'incoming'>
      )>> }
    )> }
  )>> }
);


export const GetBackordersDocument = gql`
    query GetBackorders($input: GetBackordersInput) {
  backorders(input: $input) {
    orderDate
    orderNumber
    productName
    articleNumber
    quantity
    pricePerUnit {
      currency
      netPrice
    }
    totalPrice {
      currency
      netPrice
    }
    totalFees {
      currency
      netPrice
    }
    productPageUrl {
      url
    }
    onDemand
    isExpired
    stockStatus {
      quantity
      incomingQuantity
      deliveries {
        confirmed
        date
        incoming
      }
    }
  }
}
    `;

/**
 * __useGetBackordersQuery__
 *
 * To run a query within a React component, call `useGetBackordersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackordersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackordersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBackordersQuery(baseOptions?: Apollo.QueryHookOptions<GetBackordersQuery, GetBackordersQueryVariables>) {
        return Apollo.useQuery<GetBackordersQuery, GetBackordersQueryVariables>(GetBackordersDocument, baseOptions);
      }
export function useGetBackordersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBackordersQuery, GetBackordersQueryVariables>) {
          return Apollo.useLazyQuery<GetBackordersQuery, GetBackordersQueryVariables>(GetBackordersDocument, baseOptions);
        }
export type GetBackordersQueryHookResult = ReturnType<typeof useGetBackordersQuery>;
export type GetBackordersLazyQueryHookResult = ReturnType<typeof useGetBackordersLazyQuery>;
export type GetBackordersQueryResult = Apollo.QueryResult<GetBackordersQuery, GetBackordersQueryVariables>;