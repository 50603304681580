import * as React from "react"
import { useLocation } from "react-router"
import { ProductsFacetsInput, ProductsInput } from "../@types/graphql.generated"
import {
	facetsStateToFacetsInput,
	productQueryStringToFacetState,
	queryStringToProductRangeArgs
} from "../components/ProductRange/utils"

type BlockQueryParams = {
	facetsInput: ProductsFacetsInput
	productsInput?: ProductsInput
}

export const parseUrlQuery = (url: string): BlockQueryParams => {
	let facetsInput = {}
	const productRangeArgs = queryStringToProductRangeArgs(url)
	try {
		facetsInput =
			facetsStateToFacetsInput(productQueryStringToFacetState(url)) || {}
	} catch (e) {}
	return { facetsInput, ...productRangeArgs }
}

export const useBlockQueryParams = () => {
	const location = useLocation()
	const [blockQueryParams, setBlockQueryParams] = React.useState<
		BlockQueryParams
	>(parseUrlQuery(location.search))

	React.useEffect(() => {
		setBlockQueryParams(parseUrlQuery(location.search))
	}, [location])

	return blockQueryParams
}
