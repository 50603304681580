import { setContext } from "@apollo/client/link/context"
import { onError, ErrorLink } from "@apollo/client/link/error"
import { GRAPHQL_AUTH_ERROR_CODE } from "@focus-nordic/www-common/constants"
import queryString from "query-string"
import env from "@beam-australia/react-env"
import {
	getTokensFromCookies,
	removeTokensFromCookies,
	updateTokenCookies,
} from "../utils"

export interface AccessToken {
	exp?: number
	nbf?: number
}
export interface Tokens {
	refreshToken: string
	accessToken: string
}

const REFRESH_TOKEN_ROUTE = env("REFRESH_TOKEN_ROUTE")

export interface CookieUtils {
	getTokensFromCookies: () => Partial<Tokens>
	removeTokensFromCookies: () => void
	updateTokenCookies: (tokens: Tokens) => void
}

const resetToken = onError((({ operation, graphQLErrors }) => {
	const { refreshToken, accessToken } = getTokensFromCookies()

	if (graphQLErrors) {
		for (let err of graphQLErrors) {
			switch (err?.extensions?.code) {
				case GRAPHQL_AUTH_ERROR_CODE:
					if (refreshToken && accessToken && operation?.operationName === "GetUser") {
						fetch(
							`/${REFRESH_TOKEN_ROUTE}?${queryString.stringify({
								refreshToken
							})}`
						)
							.then(res => {
								if (!res.ok) {
									// clear cookies, and refresh browser if refresh token fails
									removeTokensFromCookies()
									window.location.reload()
								} else {
									return res.json()
								}
							})
							.then((data: Tokens) => {
								// update cookies
								updateTokenCookies(data)
								window.location.reload()
							})
							.catch(err => {
								removeTokensFromCookies()
								window.location.reload()
								throw Error(err)
							})
					}
			}
		}
	}
}) as ErrorLink.ErrorHandler);

export const refreshTokenLink = (cookieUtils: CookieUtils, cookies?: any) => {
	// Used as a reference to in order to use same promise for coccurent requests
	let fetchRefreshToken: Promise<Response> | null = null

	return setContext((request, context) => {
		const { refreshToken, accessToken } = cookieUtils.getTokensFromCookies()

		if (!accessToken && refreshToken) {
			// return promise reference directly if it's already assigned
			if (fetchRefreshToken) {
				return fetchRefreshToken
			}
			// else assign the reference to a new promise and return it
			else {
				return (fetchRefreshToken = fetch(
					`/${REFRESH_TOKEN_ROUTE}?${queryString.stringify({
						refreshToken
					})}`
				)
					.then(res => {
						// reset promise reference
						fetchRefreshToken = null
						if (!res.ok) {
							// clear cookies, and refresh browser if refresh token fails
							cookieUtils.removeTokensFromCookies()
							window.location.reload()
						} else {
							return res.json()
						}
					})
					.then((data: Tokens) => {
						// update cookies
						cookieUtils.updateTokenCookies(data)
						return context
					})
					.catch(err => {
						throw Error(err)
					}))
			}
		} else {
			return context
		}
	}).concat(resetToken)
}
