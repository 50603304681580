import React from "react"
import { Text, Flex, Button, Checkbox, Rte } from "@focus-nordic/ui-components"
import { useLocales } from "../../../../hooks"
import { Summary, Panel, ItemsSummary } from "../../components"
import { useCheckout, StepId } from "../../context/CheckoutProvider"
import { useForm } from "react-hook-form"
import { createMarkup } from "../../../../utils"
import { formatPriceInfo } from "../../../../utils/formatters"
import { LocalesContext } from "../../../../context"

export interface SummaryStepProps {}

interface SummaryItemProps {
	stepId: StepId
}

const SummaryItem: React.FC<SummaryItemProps> = props => {
	const checkout = useCheckout()
	const locales = useLocales("checkout", ["editCtaText"])

	return (
		<Flex mb={2}>
			<Flex flexDirection="column" flex={1} pr={1}>
				{props.children}
			</Flex>
			<Flex alignItems="flex-start">
				<Text.Button
					type="button"
					textDecoration="underline"
					onClick={() => checkout.goToStep(props.stepId)}
				>
					{locales.editCtaText}
				</Text.Button>
			</Flex>
		</Flex>
	)
}

const SummaryStep: React.FC<SummaryStepProps> = props => {
	const locales = useLocales("checkout", [
		"summaryTitle",
		"organizationInformationTitle",
		"paymentCtaText",
		"shippingAdressTitle",
		"deliveryOptionTitle",
		"orderItemsTitle",
		"consentDescription",
		"consentLabel",
		"contactInformationTitle",
		"invoiceAddressTitle"
	])

	const { handleSubmit, register, formState } = useForm({
		mode: "onChange"
	})
	const { isValid } = formState
	const checkout = useCheckout()

	const address = checkout.paymentInput.address
	const companyInfo = checkout.paymentInput.companyInformation
	const invoiceAddress = checkout.paymentInput.invoiceAddress
	const selectedFreight = checkout.selectedFreight
	const { localeCode } = React.useContext(LocalesContext)

	return (
		<Flex
			as="form"
			onSubmit={handleSubmit(checkout.goToNextStep)}
			flexDirection="column"
			alignItems="center"
			w={1}
		>
			<Panel>
				<Panel.Title>{locales.summaryTitle}</Panel.Title>
				{address && (
					<SummaryItem stepId="userInfo">
						<Text variant="headline-6">{locales.contactInformationTitle}</Text>
						<>
							<Text mb={0}>
								{address.firstName} {address.lastName}
							</Text>
							<Text mb={0}>
								+{address.phone?.prefix} {address.phone?.number}
							</Text>
							<Text mb={0}>{address.mail}</Text>
						</>
					</SummaryItem>
				)}

				{companyInfo && (
					<SummaryItem stepId="userInfo">
						<Text variant="headline-6">
							{locales.organizationInformationTitle}
						</Text>
						<>
							<Text mb={0}>{companyInfo.companyName}</Text>
							<Text mb={0}>{companyInfo.orgNumber}</Text>
							<Text mb={0}>{companyInfo.vatNumber}</Text>
							{companyInfo.customerReferenceNumber && (
								<Text mb={0}>{companyInfo.customerReferenceNumber}</Text>
							)}
						</>
					</SummaryItem>
				)}

				{address && (
					<SummaryItem stepId="userInfo">
						<Text variant="headline-6">{locales.shippingAdressTitle}</Text>
						<>
							<Text mb={0}>{address.addressOne}</Text>
							{address.addressTwo && <Text mb={0}>{address.addressTwo}</Text>}
							<Text mb={0}>
								{address.postalCode} {address.city}
							</Text>
						</>
					</SummaryItem>
				)}

				{invoiceAddress && (
					<SummaryItem stepId="userInfo">
						<Text variant="headline-6">{locales.invoiceAddressTitle}</Text>
						<>
							<Text mb={0}>{invoiceAddress.addressOne}</Text>
							{invoiceAddress.addressTwo && (
								<Text mb={0}>{invoiceAddress.addressTwo}</Text>
							)}
							<Text mb={0}>
								{invoiceAddress.postalCode} {invoiceAddress.city}
							</Text>
						</>
					</SummaryItem>
				)}

				{selectedFreight && (
					<SummaryItem stepId="delivery">
						<Text variant="headline-6">{locales.deliveryOptionTitle}</Text>
						{selectedFreight.deliveryOption?.name && (
							<Text fontWeight="bold" mb={0}>
								{selectedFreight.deliveryOption.name} {" – "}
								{formatPriceInfo(
									selectedFreight.deliveryOption.price,
									localeCode
								)}
							</Text>
						)}
						{selectedFreight.servicePoint?.name && (
							<Text>{selectedFreight.servicePoint.name}</Text>
						)}
					</SummaryItem>
				)}
				<ItemsSummary />
			</Panel>
			<Panel>
				<Summary />
				<Flex mb={1}>
					<Rte
						as="div"
						dangerouslySetInnerHTML={createMarkup(locales.consentDescription)}
					/>
				</Flex>
				<Flex justifyContent="flex-start">
					<Checkbox
						id="consent"
						name="consent"
						value="true"
						inputRef={register({
							required: true
						})}
					/>
					<Flex mt={-0.5} ml={1} mb={3}>
						<Rte
							as="label"
							htmlFor="consent"
							dangerouslySetInnerHTML={createMarkup(locales.consentLabel)}
						/>
					</Flex>
				</Flex>

				<Button type="submit" disabled={!isValid}>
					{locales.paymentCtaText}
				</Button>
			</Panel>
		</Flex>
	)
}

export { SummaryStep }
