"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderItem = void 0;
var React = __importStar(require("react"));
var Flex_1 = require("../Flex");
var Label = __importStar(require("./Text"));
var OrderStatus_1 = require("../OrderStatus");
var types_1 = require("../OrderStatus/types");
var Image_1 = require("../Image");
var ProductCardLink_1 = require("./ProductCardLink");
var getOrderStatus = function (orderedQuantity, deliveredQuantity) {
    if (deliveredQuantity && deliveredQuantity < orderedQuantity) {
        return types_1.OrderStatuses.PARTIALLYSHIPPED;
    }
    else if (deliveredQuantity === orderedQuantity) {
        return types_1.OrderStatuses.FULLYSHIPPED;
    }
    return types_1.OrderStatuses.INPROGRESS;
};
var OrderItem = function (props) { return (React.createElement(Flex_1.Flex, { alignItems: "flex-start", w: 1, flex: 1, position: "relative" },
    props.image && (React.createElement(Flex_1.Flex, { flexDirection: "column", justifyContent: "space-between", h: 1 },
        React.createElement(Flex_1.Flex, { mr: 2, w: 16, display: "block", p: 1.5, backgroundColor: "grey-1" },
            React.createElement(Image_1.Image, __assign({ ratio: 1 }, props.image))))),
    React.createElement(Flex_1.Flex, { flexDirection: "column", flex: 1, h: 1 },
        React.createElement(ProductCardLink_1.ProductCardLink, { to: props.to, onClick: props.onClick },
            props.brandName && React.createElement(Label.Brand, null, props.brandName),
            props.name && (React.createElement(Label.Name, { mb: { _: 1, l: 0 } }, props.name))),
        React.createElement(Flex_1.Flex, { flexDirection: { _: "column", l: "row" }, mt: { _: 0, l: 2 } },
            React.createElement(Flex_1.Flex, { flexDirection: "row", flex: 1 },
                React.createElement(Flex_1.Flex, { flex: 1, flexDirection: { _: "column", l: "row" }, justifyContent: "space-between" },
                    React.createElement(Flex_1.Flex, { pr: { _: 0, l: 2 }, flex: "1" },
                        React.createElement(Label.Data, { flexDirection: { _: "row", l: "column" }, title: props.locales.articleNumber, data: props.articleNumber })),
                    React.createElement(Flex_1.Flex, { pr: { _: 0, l: 4 }, flex: "1", flexDirection: "column" },
                        React.createElement(Label.Data, { flexDirection: { _: "row", l: "column" }, title: props.locales.supplierArticleNumber, data: props.supplierArticleNumber }),
                        React.createElement(Label.Data, { flexWrap: "nowrap", flexDirection: "row", title: props.locales.ean + ":", data: props.ean })),
                    React.createElement(Flex_1.Flex, { pr: { _: 0, l: 2 }, flex: "1" },
                        React.createElement(Label.Data, { flexDirection: { _: "row", l: "column" }, title: props.locales.fee, data: props.fee })),
                    React.createElement(Flex_1.Flex, { pr: { _: 0, l: 2 }, flex: "1" },
                        React.createElement(Label.Data, { flexDirection: { _: "row", l: "column" }, title: props.locales.price, data: props.pricePerUnit }))),
                React.createElement(Flex_1.Flex, { flexDirection: { _: "column", l: "row" } },
                    React.createElement(Flex_1.Flex, { pr: { _: 0, l: 2 }, mr: { _: 0, l: 4 } },
                        React.createElement(Label.Data, { flexDirection: { _: "row", l: "column" }, title: props.locales.orderedQuantity, data: props.orderedQuantity })),
                    React.createElement(Flex_1.Flex, { pr: { _: 0, l: 2 } },
                        React.createElement(Label.Data, { flexDirection: { _: "row", l: "column" }, title: props.locales.deliveredQuantity, data: props.deliveredQuantity })),
                    React.createElement(Flex_1.Flex, { display: { _: "flex", l: "none" } }, typeof props.orderedQuantity !== "undefined" && typeof props.deliveredQuantity !== "undefined" && (React.createElement(OrderStatus_1.OrderStatus, { status: getOrderStatus(props.orderedQuantity, props.deliveredQuantity), locales: props.locales.orderStatuses, compactMode: true }))))),
            React.createElement(Flex_1.Flex, { ml: { _: 0, l: 4 }, mt: { _: 1, l: 0 }, alignItems: { l: "flex-end" }, flexDirection: { l: "column" }, flexWrap: "wrap" },
                props.totalPrice && (React.createElement(Label.Price, { minw: { _: 16, l: "auto" }, my: 0.5, price: props.totalPrice })),
                React.createElement(Flex_1.Flex, { display: { _: "none", l: "flex" } }, typeof props.orderedQuantity !== "undefined" && typeof props.deliveredQuantity !== "undefined" && (React.createElement(OrderStatus_1.OrderStatus, { status: getOrderStatus(props.orderedQuantity, props.deliveredQuantity), locales: props.locales.orderStatuses })))))))); };
exports.OrderItem = OrderItem;
