import * as React from "react"
import { Switch, Route } from "react-router-dom"
import { CheckoutPage } from "@focus-nordic/www-client/src/components"
import { PageLayout } from "./components/PageLayout"
import { CHECKOUT_ROUTE } from "./constants"

export const Routes: React.FC = props => (
	<>
		<Switch>
			<Route path={CHECKOUT_ROUTE}>
				<CheckoutPage />
			</Route>
			<Route path="*">
				<PageLayout />
			</Route>
		</Switch>
	</>
)
