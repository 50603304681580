import * as React from "react"
import { Text, Flex, CaptionImage } from "@focus-nordic/ui-components"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { ArticleIntroBlock as IArticleIntroBlock } from "../../@types/graphql.generated"
import { imagePath } from "../../utils"
import { RTE_CONTAINER_MAX_WIDTH, RTE_CONTENT_MAX_WIDTH } from "../../constants"

const ArticleIntroBlock: React.FC<IArticleIntroBlock> = props => {
	const locales = useLocales(translationNamespace.shared("imageData"), [
		"photographer"
	])

	return (
		<Flex
			flexDirection="column"
			maxw={RTE_CONTAINER_MAX_WIDTH}
			w={1}
			mt={{ m: 4 }}
			px={{ _: 2.25, m: 5 }}
		>
			<Flex flexDirection={{ _: "column-reverse", m: "column" }}>
				<Flex w={1} flexDirection="column" maxw={RTE_CONTENT_MAX_WIDTH}>
					{props.articleTitle && (
						<Text variant="headline-1" mb={{ _: 3, m: 5 }}>
							{props.articleTitle}
						</Text>
					)}
					{props.publishDate && (
						<Flex mt={{ _: 0, m: 2 }} mb={{ _: 1, m: 2 }} alignItems="center">
							<Text as="span" fontSize={2} lineHeight={2.5} textColor="grey-4">
								{props.publishDate}
							</Text>
						</Flex>
					)}
				</Flex>
				{props.image && (
					<Flex
						mb={{ _: 5, m: 9 }}
						mx={{ _: -2.25, m: 0 }}
						flexDirection="column"
					>
						<CaptionImage
							caption={
								props.image?.meta?.author
									? `${locales.photographer} ${props.image?.meta?.author}`
									: undefined
							}
							alt={
								props.image.meta && props.image.meta?.alt
									? props.image.meta.alt
									: ""
							}
							description={undefined}
							src={imagePath(props.image.fileName, "original")}
						/>
					</Flex>
				)}
			</Flex>

			{props.preamble && (
				<Flex maxw={RTE_CONTENT_MAX_WIDTH}>
					<Text
						textColor="black"
						fontSize={{ _: 2.5, m: 3 }}
						lineHeight={{ _: 3.5, m: 4 }}
					>
						{props.preamble}
					</Text>
				</Flex>
			)}
		</Flex>
	)
}

export { ArticleIntroBlock }
