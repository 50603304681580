import React from "react"
import {
	AppContextProviderCommon,
	AppContextProviderCommonProps
} from "@focus-nordic/www-common/context/AppContextCommon"
import { CartContextProvider } from "./CartContext"

interface AppContextProviderProps extends AppContextProviderCommonProps {}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
	children,
	...rest
}) => {
	return (
		<AppContextProviderCommon {...rest}>
			<CartContextProvider>{children}</CartContextProvider>
		</AppContextProviderCommon>
	)
}
