import React from "react"
import {
	Pagination as IPagination,
	BlockType
} from "../../@types/graphql.generated"
import { Flex, Button } from "@focus-nordic/ui-components"
import { useLocales } from "../../hooks/useLocales"
import { SpaceProps } from "@focus-nordic/ui-components/dist/styles/styled-system"

export interface PaginationProps extends SpaceProps {
	pagination: Pick<IPagination, "count" | "take" | "skip">
	fetchMore?: () => any
	fetchAll?: () => any
	disabled?: boolean
}

const Pagination: React.FC<PaginationProps> = ({ pagination, disabled, fetchAll, fetchMore, ...rest }) => {
	const locales = useLocales(BlockType.Productlistblock, [
		"loadMore",
		"showAll"
	])

	return pagination?.skip + pagination?.take <
		pagination?.count ? (
		<Flex w={1} flexWrap="wrap" px={2}>
			<Flex w={1} p={2} py={5} justifyContent="center" {...rest}>
				{fetchMore && (
					<Button disabled={disabled} onClick={fetchMore}>
						{locales.loadMore}
					</Button>
				)}
				{fetchAll && (
					<Button
						disabled={disabled}
						onClick={fetchAll}
						variant="grey"
					>
						{locales.showAll} ({pagination.count})
					</Button>
				)}
			</Flex>
		</Flex>
	) : null
}

export { Pagination }
