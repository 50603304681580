import * as Types from '../@types/graphql.generated';

import { ProductListBlockFieldsFragment } from '../components/ProductListBlock/operations.generated';
import { TextEditorBlockFieldsFragment } from '../components/TextEditorBlock/operations.generated';
import { CategoryLinksBlockFieldsFragment } from '../components/CategoryLinksBlock/operations.generated';
import { CategoryLinkListBlockFieldsFragment } from '../components/CategoryLinkListBlock/operations.generated';
import { BrandLinkListBlockFieldsFragment } from '../components/BrandLinkListBlock/operations.generated';
import { HeroBlockFieldsFragment } from '../components/HeroBlock/operations.generated';
import { InspirationHeroBlockFieldsFragment } from '../components/InspirationHeroBlock/operations.generated';
import { ArticleIntroBlockFieldsFragment } from '../components/ArticleIntroBlock/operations.generated';
import { CategoryHeroBlockFieldsFragment } from '../components/CategoryHeroBlock/operations.generated';
import { CardPromoBlockFieldsFragment } from '../components/CardPromoBlock/operations.generated';
import { BreadcrumbsBlockFieldsFragment } from '../components/BreadcrumbsBlock/operations.generated';
import { DescriptionListBlockFieldsFragment } from '../components/DescriptionListBlock/operations.generated';
import { AnchorLinkBlockFieldsFragment } from '../components/AnchorLinkBlock/operations.generated';
import { MediaContentBlockFieldsFragment } from '../components/MediaContentBlock/operations.generated';
import { MediaAssetsBlockFieldsFragment } from '../components/MediaAssetsBlock/operations.generated';
import { MediaGridBlockFieldsFragment } from '../components/MediaGridBlock/operations.generated';
import { ProductOverviewBlockFieldsFragment } from '../components/ProductOverviewBlock/operations.generated';
import { RelatedProductsBlockFieldsFragment } from '../components/RelatedProductsBlock/operations.generated';
import { FullWidthImagePromoBlockFieldsFragment } from '../components/FullWidthImagePromoBlock/operations.generated';
import { SearchBlockFieldsFragment } from '../components/SearchBlock/operations.generated';
import { CustomerApplicationBlockFieldsFragment } from '../components/CustomerApplicationBlock/operations.generated';
import { GalleryBlockFieldsFragment } from '../components/GalleryBlock/operations.generated';
import { FeaturedProductsBlockFieldsFragment } from '../components/FeaturedProductsBlock/operations.generated';
import { ProductCategoriesBlockFieldsFragment } from '../components/ProductCategoriesBlock/operations.generated';
import { VideoBlockFieldsFragment } from '../components/VideoBlock/operations.generated';
import { TextMessagesBlockFieldsFragment } from '../components/TextMessagesBlock/operations.generated';
import { gql } from '@apollo/client';
import { ProductListBlockFieldsFragmentDoc } from '../components/ProductListBlock/operations.generated';
import { TextEditorBlockFieldsFragmentDoc } from '../components/TextEditorBlock/operations.generated';
import { CategoryLinksBlockFieldsFragmentDoc } from '../components/CategoryLinksBlock/operations.generated';
import { CategoryLinkListBlockFieldsFragmentDoc } from '../components/CategoryLinkListBlock/operations.generated';
import { BrandLinkListBlockFieldsFragmentDoc } from '../components/BrandLinkListBlock/operations.generated';
import { HeroBlockFieldsFragmentDoc } from '../components/HeroBlock/operations.generated';
import { InspirationHeroBlockFieldsFragmentDoc } from '../components/InspirationHeroBlock/operations.generated';
import { ArticleIntroBlockFieldsFragmentDoc } from '../components/ArticleIntroBlock/operations.generated';
import { CategoryHeroBlockFieldsFragmentDoc } from '../components/CategoryHeroBlock/operations.generated';
import { CardPromoBlockFieldsFragmentDoc } from '../components/CardPromoBlock/operations.generated';
import { BreadcrumbsBlockFieldsFragmentDoc } from '../components/BreadcrumbsBlock/operations.generated';
import { DescriptionListBlockFieldsFragmentDoc } from '../components/DescriptionListBlock/operations.generated';
import { AnchorLinkBlockFieldsFragmentDoc } from '../components/AnchorLinkBlock/operations.generated';
import { MediaContentBlockFieldsFragmentDoc } from '../components/MediaContentBlock/operations.generated';
import { MediaAssetsBlockFieldsFragmentDoc } from '../components/MediaAssetsBlock/operations.generated';
import { MediaGridBlockFieldsFragmentDoc } from '../components/MediaGridBlock/operations.generated';
import { ProductOverviewBlockFieldsFragmentDoc } from '../components/ProductOverviewBlock/operations.generated';
import { RelatedProductsBlockFieldsFragmentDoc } from '../components/RelatedProductsBlock/operations.generated';
import { FullWidthImagePromoBlockFieldsFragmentDoc } from '../components/FullWidthImagePromoBlock/operations.generated';
import { SearchBlockFieldsFragmentDoc } from '../components/SearchBlock/operations.generated';
import { CustomerApplicationBlockFieldsFragmentDoc } from '../components/CustomerApplicationBlock/operations.generated';
import { GalleryBlockFieldsFragmentDoc } from '../components/GalleryBlock/operations.generated';
import { FeaturedProductsBlockFieldsFragmentDoc } from '../components/FeaturedProductsBlock/operations.generated';
import { ProductCategoriesBlockFieldsFragmentDoc } from '../components/ProductCategoriesBlock/operations.generated';
import { VideoBlockFieldsFragmentDoc } from '../components/VideoBlock/operations.generated';
import { TextMessagesBlockFieldsFragmentDoc } from '../components/TextMessagesBlock/operations.generated';
import * as Apollo from '@apollo/client';
export type GetPageQueryVariables = Types.Exact<{
  path: Types.Scalars['String'];
  productsInput?: Types.Maybe<Types.ProductsInput>;
  facetsInput?: Types.Maybe<Types.ProductsFacetsInput>;
  paginationInput?: Types.Maybe<Types.PaginationInput>;
  sortInput?: Types.Maybe<Types.SortInput>;
}>;


export type GetPageQuery = (
  { __typename?: 'Query' }
  & { page?: Types.Maybe<(
    { __typename?: 'page' }
    & Pick<Types.Page, 'id' | 'httpStatusCode' | 'httpStatus' | 'redirectUrl' | 'canonicalUrl'>
    & { meta?: Types.Maybe<(
      { __typename?: 'meta' }
      & Pick<Types.Meta, 'title' | 'description'>
    )>, externalScripts?: Types.Maybe<Array<(
      { __typename?: 'externalScript' }
      & Pick<Types.ExternalScript, 'scriptUrl' | 'renderInHead' | 'asyncScript'>
    )>>, blocks?: Types.Maybe<Array<(
      { __typename: 'textEditorBlock' }
      & Pick<Types.TextEditorBlock, 'id' | 'type'>
      & TextEditorBlockFieldsFragment
    ) | (
      { __typename: 'productListBlock' }
      & Pick<Types.ProductListBlock, 'id' | 'type'>
      & ProductListBlockFieldsFragment
    ) | (
      { __typename: 'categoryLinksBlock' }
      & Pick<Types.CategoryLinksBlock, 'id' | 'type'>
      & CategoryLinksBlockFieldsFragment
    ) | (
      { __typename: 'categoryLinkListBlock' }
      & Pick<Types.CategoryLinkListBlock, 'id' | 'type'>
      & CategoryLinkListBlockFieldsFragment
    ) | (
      { __typename: 'unknownBlock' }
      & Pick<Types.UnknownBlock, 'id' | 'type'>
    ) | (
      { __typename: 'breadcrumbsBlock' }
      & Pick<Types.BreadcrumbsBlock, 'id' | 'type'>
      & BreadcrumbsBlockFieldsFragment
    ) | (
      { __typename: 'categoryHeroBlock' }
      & Pick<Types.CategoryHeroBlock, 'id' | 'type'>
      & CategoryHeroBlockFieldsFragment
    ) | (
      { __typename: 'productOverviewBlock' }
      & Pick<Types.ProductOverviewBlock, 'id' | 'type'>
      & ProductOverviewBlockFieldsFragment
    ) | (
      { __typename: 'anchorLinkBlock' }
      & Pick<Types.AnchorLinkBlock, 'id' | 'type'>
      & AnchorLinkBlockFieldsFragment
    ) | (
      { __typename: 'descriptionListBlock' }
      & Pick<Types.DescriptionListBlock, 'id' | 'type'>
      & DescriptionListBlockFieldsFragment
    ) | (
      { __typename: 'mediaAssetsBlock' }
      & Pick<Types.MediaAssetsBlock, 'id' | 'type'>
      & MediaAssetsBlockFieldsFragment
    ) | (
      { __typename: 'mediaGridBlock' }
      & Pick<Types.MediaGridBlock, 'id' | 'type'>
      & MediaGridBlockFieldsFragment
    ) | (
      { __typename: 'relatedProductsBlock' }
      & Pick<Types.RelatedProductsBlock, 'id' | 'type'>
      & RelatedProductsBlockFieldsFragment
    ) | (
      { __typename: 'articleIntroBlock' }
      & Pick<Types.ArticleIntroBlock, 'id' | 'type'>
      & ArticleIntroBlockFieldsFragment
    ) | (
      { __typename: 'inspirationHeroBlock' }
      & Pick<Types.InspirationHeroBlock, 'id' | 'type'>
      & InspirationHeroBlockFieldsFragment
    ) | (
      { __typename: 'heroBlock' }
      & Pick<Types.HeroBlock, 'id' | 'type'>
      & HeroBlockFieldsFragment
    ) | (
      { __typename: 'cardPromoBlock' }
      & Pick<Types.CardPromoBlock, 'id' | 'type'>
      & CardPromoBlockFieldsFragment
    ) | (
      { __typename: 'rteBlock' }
      & Pick<Types.RteBlock, 'id' | 'type'>
    ) | (
      { __typename: 'mediaContentBlock' }
      & Pick<Types.MediaContentBlock, 'id' | 'type'>
      & MediaContentBlockFieldsFragment
    ) | (
      { __typename: 'brandLinkListBlock' }
      & Pick<Types.BrandLinkListBlock, 'id' | 'type'>
      & BrandLinkListBlockFieldsFragment
    ) | (
      { __typename: 'fullWidthImagePromoBlock' }
      & Pick<Types.FullWidthImagePromoBlock, 'id' | 'type'>
      & FullWidthImagePromoBlockFieldsFragment
    ) | (
      { __typename: 'searchBlock' }
      & Pick<Types.SearchBlock, 'id' | 'type'>
      & SearchBlockFieldsFragment
    ) | (
      { __typename: 'customerApplicationBlock' }
      & Pick<Types.CustomerApplicationBlock, 'id' | 'type'>
      & CustomerApplicationBlockFieldsFragment
    ) | (
      { __typename: 'featuredProductsBlock' }
      & Pick<Types.FeaturedProductsBlock, 'id' | 'type'>
      & FeaturedProductsBlockFieldsFragment
    ) | (
      { __typename: 'galleryBlock' }
      & Pick<Types.GalleryBlock, 'id' | 'type'>
      & GalleryBlockFieldsFragment
    ) | (
      { __typename: 'productCategoriesBlock' }
      & Pick<Types.ProductCategoriesBlock, 'id' | 'type'>
      & ProductCategoriesBlockFieldsFragment
    ) | (
      { __typename: 'videoBlock' }
      & Pick<Types.VideoBlock, 'id' | 'type'>
      & VideoBlockFieldsFragment
    ) | (
      { __typename: 'textMessagesBlock' }
      & Pick<Types.TextMessagesBlock, 'id' | 'type'>
      & TextMessagesBlockFieldsFragment
    )>> }
  )> }
);

export type GetHttpStatusQueryVariables = Types.Exact<{
  path: Types.Scalars['String'];
}>;


export type GetHttpStatusQuery = (
  { __typename?: 'Query' }
  & { page?: Types.Maybe<(
    { __typename?: 'page' }
    & Pick<Types.Page, 'httpStatusCode' | 'httpStatus' | 'redirectUrl'>
  )> }
);


export const GetPageDocument = gql`
    query GetPage($path: String!, $productsInput: productsInput, $facetsInput: productsFacetsInput, $paginationInput: paginationInput, $sortInput: sortInput) {
  page(path: $path) {
    id
    meta {
      title
      description
    }
    httpStatusCode
    httpStatus
    redirectUrl
    canonicalUrl
    externalScripts {
      scriptUrl
      renderInHead
      asyncScript
    }
    blocks {
      __typename
      ... on Block {
        id
        type
      }
      ... on productListBlock {
        ...productListBlockFields
      }
      ... on textEditorBlock {
        ...textEditorBlockFields
      }
      ... on categoryLinksBlock {
        ...categoryLinksBlockFields
      }
      ... on categoryLinkListBlock {
        ...categoryLinkListBlockFields
      }
      ... on brandLinkListBlock {
        ...brandLinkListBlockFields
      }
      ... on heroBlock {
        ...heroBlockFields
      }
      ... on inspirationHeroBlock {
        ...inspirationHeroBlockFields
      }
      ... on articleIntroBlock {
        ...articleIntroBlockFields
      }
      ... on categoryHeroBlock {
        ...categoryHeroBlockFields
      }
      ... on cardPromoBlock {
        ...cardPromoBlockFields
      }
      ... on breadcrumbsBlock {
        ...breadcrumbsBlockFields
      }
      ... on descriptionListBlock {
        ...descriptionListBlockFields
      }
      ... on anchorLinkBlock {
        ...anchorLinkBlockFields
      }
      ... on mediaContentBlock {
        ...mediaContentBlockFields
      }
      ... on mediaAssetsBlock {
        ...mediaAssetsBlockFields
      }
      ... on mediaGridBlock {
        ...mediaGridBlockFields
      }
      ... on productOverviewBlock {
        ...productOverviewBlockFields
      }
      ... on relatedProductsBlock {
        ...relatedProductsBlockFields
      }
      ... on fullWidthImagePromoBlock {
        ...fullWidthImagePromoBlockFields
      }
      ... on searchBlock {
        ...searchBlockFields
      }
      ... on customerApplicationBlock {
        ...customerApplicationBlockFields
      }
      ... on galleryBlock {
        ...galleryBlockFields
      }
      ... on featuredProductsBlock {
        ...featuredProductsBlockFields
      }
      ... on productCategoriesBlock {
        ...productCategoriesBlockFields
      }
      ... on videoBlock {
        ...videoBlockFields
      }
      ... on textMessagesBlock {
        ...textMessagesBlockFields
      }
      ... on unknownBlock {
        type
      }
    }
  }
}
    ${ProductListBlockFieldsFragmentDoc}
${TextEditorBlockFieldsFragmentDoc}
${CategoryLinksBlockFieldsFragmentDoc}
${CategoryLinkListBlockFieldsFragmentDoc}
${BrandLinkListBlockFieldsFragmentDoc}
${HeroBlockFieldsFragmentDoc}
${InspirationHeroBlockFieldsFragmentDoc}
${ArticleIntroBlockFieldsFragmentDoc}
${CategoryHeroBlockFieldsFragmentDoc}
${CardPromoBlockFieldsFragmentDoc}
${BreadcrumbsBlockFieldsFragmentDoc}
${DescriptionListBlockFieldsFragmentDoc}
${AnchorLinkBlockFieldsFragmentDoc}
${MediaContentBlockFieldsFragmentDoc}
${MediaAssetsBlockFieldsFragmentDoc}
${MediaGridBlockFieldsFragmentDoc}
${ProductOverviewBlockFieldsFragmentDoc}
${RelatedProductsBlockFieldsFragmentDoc}
${FullWidthImagePromoBlockFieldsFragmentDoc}
${SearchBlockFieldsFragmentDoc}
${CustomerApplicationBlockFieldsFragmentDoc}
${GalleryBlockFieldsFragmentDoc}
${FeaturedProductsBlockFieldsFragmentDoc}
${ProductCategoriesBlockFieldsFragmentDoc}
${VideoBlockFieldsFragmentDoc}
${TextMessagesBlockFieldsFragmentDoc}`;

/**
 * __useGetPageQuery__
 *
 * To run a query within a React component, call `useGetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageQuery({
 *   variables: {
 *      path: // value for 'path'
 *      productsInput: // value for 'productsInput'
 *      facetsInput: // value for 'facetsInput'
 *      paginationInput: // value for 'paginationInput'
 *      sortInput: // value for 'sortInput'
 *   },
 * });
 */
export function useGetPageQuery(baseOptions?: Apollo.QueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
        return Apollo.useQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, baseOptions);
      }
export function useGetPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
          return Apollo.useLazyQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, baseOptions);
        }
export type GetPageQueryHookResult = ReturnType<typeof useGetPageQuery>;
export type GetPageLazyQueryHookResult = ReturnType<typeof useGetPageLazyQuery>;
export type GetPageQueryResult = Apollo.QueryResult<GetPageQuery, GetPageQueryVariables>;
export const GetHttpStatusDocument = gql`
    query GetHttpStatus($path: String!) {
  page(path: $path) {
    httpStatusCode
    httpStatus
    redirectUrl
  }
}
    `;

/**
 * __useGetHttpStatusQuery__
 *
 * To run a query within a React component, call `useGetHttpStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHttpStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHttpStatusQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useGetHttpStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetHttpStatusQuery, GetHttpStatusQueryVariables>) {
        return Apollo.useQuery<GetHttpStatusQuery, GetHttpStatusQueryVariables>(GetHttpStatusDocument, baseOptions);
      }
export function useGetHttpStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHttpStatusQuery, GetHttpStatusQueryVariables>) {
          return Apollo.useLazyQuery<GetHttpStatusQuery, GetHttpStatusQueryVariables>(GetHttpStatusDocument, baseOptions);
        }
export type GetHttpStatusQueryHookResult = ReturnType<typeof useGetHttpStatusQuery>;
export type GetHttpStatusLazyQueryHookResult = ReturnType<typeof useGetHttpStatusLazyQuery>;
export type GetHttpStatusQueryResult = Apollo.QueryResult<GetHttpStatusQuery, GetHttpStatusQueryVariables>;