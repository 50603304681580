import * as Types from '../../@types/graphql.generated';

import { AllPaginationFieldsFragment } from '../../operations/pagination.generated';
import { AllImageBaseFieldsFragment } from '../../operations/ImageBase.generated';
import { AllLinkFieldsFragment } from '../../operations/link.generated';
import { ProductRangeFieldsFragment } from '../../operations/product.generated';
import { gql } from '@apollo/client';
import { AllPaginationFieldsFragmentDoc } from '../../operations/pagination.generated';
import { AllImageBaseFieldsFragmentDoc } from '../../operations/ImageBase.generated';
import { AllLinkFieldsFragmentDoc } from '../../operations/link.generated';
import { ProductRangeFieldsFragmentDoc } from '../../operations/product.generated';
import * as Apollo from '@apollo/client';
export type PageRangeFieldsFragment = (
  { __typename?: 'pages' }
  & Pick<Types.Pages, 'id' | 'searchTerm'>
  & { pagination: (
    { __typename?: 'pagination' }
    & AllPaginationFieldsFragment
  ), range?: Types.Maybe<Array<(
    { __typename?: 'referenceInfo' }
    & Pick<Types.ReferenceInfo, 'title' | 'preamble' | 'isDarkTheme'>
    & { image?: Types.Maybe<(
      { __typename?: 'ImageBase' }
      & AllImageBaseFieldsFragment
    )>, ctaLink?: Types.Maybe<(
      { __typename?: 'link' }
      & AllLinkFieldsFragment
    )> }
  )>> }
);

export type GetSearchQueryVariables = Types.Exact<{
  searchInput: Types.SearchInput;
  productsInput?: Types.Maybe<Types.ProductsInput>;
  facetsInput?: Types.Maybe<Types.ProductsFacetsInput>;
  skip?: Types.Maybe<Types.Scalars['Int']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  withPageRange?: Types.Maybe<Types.Scalars['Boolean']>;
  withProductRange?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type GetSearchQuery = (
  { __typename?: 'Query' }
  & { pageRange?: Types.Maybe<(
    { __typename?: 'pages' }
    & PageRangeFieldsFragment
  )>, productRange?: Types.Maybe<(
    { __typename?: 'products' }
    & ProductRangeFieldsFragment
  )> }
);

export type SearchBlockFieldsFragment = (
  { __typename?: 'searchBlock' }
  & Pick<Types.SearchBlock, 'id' | 'type' | 'title'>
);

export const PageRangeFieldsFragmentDoc = gql`
    fragment pageRangeFields on pages {
  id
  searchTerm
  pagination {
    ...allPaginationFields
  }
  range {
    title
    preamble
    isDarkTheme
    image {
      ...allImageBaseFields
    }
    ctaLink {
      ...allLinkFields
    }
  }
}
    ${AllPaginationFieldsFragmentDoc}
${AllImageBaseFieldsFragmentDoc}
${AllLinkFieldsFragmentDoc}`;
export const SearchBlockFieldsFragmentDoc = gql`
    fragment searchBlockFields on searchBlock {
  id
  type
  title
}
    `;
export const GetSearchDocument = gql`
    query getSearch($searchInput: searchInput!, $productsInput: productsInput, $facetsInput: productsFacetsInput, $skip: Int, $take: Int, $withPageRange: Boolean = true, $withProductRange: Boolean = true) {
  pageRange(searchInput: $searchInput, paginationInput: {skip: $skip, take: $take}) @include(if: $withPageRange) {
    ...pageRangeFields
  }
  productRange(searchInput: $searchInput, productsInput: $productsInput, facetsInput: $facetsInput) @include(if: $withProductRange) {
    ...productRangeFields
  }
}
    ${PageRangeFieldsFragmentDoc}
${ProductRangeFieldsFragmentDoc}`;

/**
 * __useGetSearchQuery__
 *
 * To run a query within a React component, call `useGetSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      productsInput: // value for 'productsInput'
 *      facetsInput: // value for 'facetsInput'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      withPageRange: // value for 'withPageRange'
 *      withProductRange: // value for 'withProductRange'
 *   },
 * });
 */
export function useGetSearchQuery(baseOptions?: Apollo.QueryHookOptions<GetSearchQuery, GetSearchQueryVariables>) {
        return Apollo.useQuery<GetSearchQuery, GetSearchQueryVariables>(GetSearchDocument, baseOptions);
      }
export function useGetSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchQuery, GetSearchQueryVariables>) {
          return Apollo.useLazyQuery<GetSearchQuery, GetSearchQueryVariables>(GetSearchDocument, baseOptions);
        }
export type GetSearchQueryHookResult = ReturnType<typeof useGetSearchQuery>;
export type GetSearchLazyQueryHookResult = ReturnType<typeof useGetSearchLazyQuery>;
export type GetSearchQueryResult = Apollo.QueryResult<GetSearchQuery, GetSearchQueryVariables>;