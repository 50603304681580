import * as React from "react"
import {
	Flex,
	Text,
	MediaContent,
	Image,
	IconLabel
} from "@focus-nordic/ui-components"
import { LinkAnchor } from "../LinkAnchor"
import { SITE_MAX_WIDTH } from "../../constants"
import { imagePath } from "../../utils"
import { MediaContentBlock as IMediaContentBlock } from "../../@types/graphql.generated"

const MediaContentBlock: React.FC<IMediaContentBlock> = props => {
	return (
		<Flex
			id={props.id}
			pt={{ _: 3, m: 5 }}
			pb={{ _: 4, m: 6 }}
			px={{ _: 2, m: 8 }}
			w={1}
			style={{
				backgroundColor: props.blockTheme?.backgroundColor
			}}
		>
			<Flex
				w={1}
				flexDirection="column"
				maxw={SITE_MAX_WIDTH}
				style={{ margin: "0 auto" }}
			>
				{(props.title || props.preamble) && <Flex flexDirection="column" maxw={80} w={1} mb={1}>
					{props.title && (
						<Text variant="headline-4" as="h2" mb={{ _: 0.5, m: 1 }}>
							<span style={{ color: props.blockTheme?.textColor }}>
								{props.title}
							</span>
						</Text>
					)}
					{props.preamble && (
						<Text>
							<span style={{ color: props.blockTheme?.textColor }}>
								{props.preamble}
							</span>
						</Text>
					)}
				</Flex>}

				{props.cards &&
					props.cards.map((card, i) => {
						const useMirrorLayout =
							i % 2 === 0 ? props.isMirrorLayout : !props.isMirrorLayout
						const isLastItem = i === props.cards!.length - 1

						return (
							<Flex
								flexDirection="column"
								mb={{ _: isLastItem ? 0 : 2, l: isLastItem ? 0 : 5 }}
								key={i}
							>
								{(() => {
									const MediaContentComp = () => (
										<MediaContent
											media={
												card.image ? (
													<Image
														src={imagePath(card.image.fileName, "original")}
														alt={
															card.image.meta && card.image.meta.alt
																? card.image.meta.alt
																: ""
														}
													/>
												) : null
											}
											mirrorLayout={useMirrorLayout}
										>
											{card.title && (
												<MediaContent.Title>{card.title}</MediaContent.Title>
											)}
											{card.preamble && (
												<MediaContent.Preamble>
													{card.preamble}
												</MediaContent.Preamble>
											)}
											{card.ctaLink && (
												<Flex mt={4}>
													<IconLabel
														iconColor="blue-6"
														textColor="black"
														circleBg="blue-1"
													>
														{card.ctaLink.title}
													</IconLabel>
												</Flex>
											)}
										</MediaContent>
									)
									return card.ctaLink ? (
										<LinkAnchor
											{...card.ctaLink}
											style={{ width: "100%" }}
											title={card.title ? card.title : ''}
										>
											<MediaContentComp />
										</LinkAnchor>
									) : (
										<MediaContentComp />
									)
								})()}
							</Flex>
						)
					})}
			</Flex>
		</Flex>
	)
}

export { MediaContentBlock }
