import * as Types from '../../@types/graphql.generated';

import { MenuFieldsFragment } from '../../operations/menu.generated';
import { gql } from '@apollo/client';
import { MenuFieldsFragmentDoc } from '../../operations/menu.generated';
export type ProductCategoriesBlockFieldsFragment = (
  { __typename?: 'productCategoriesBlock' }
  & Pick<Types.ProductCategoriesBlock, 'id' | 'type'>
  & { categoryList?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'menu' }
    & { children?: Types.Maybe<Array<(
      { __typename?: 'menu' }
      & { children?: Types.Maybe<Array<(
        { __typename?: 'menu' }
        & { children?: Types.Maybe<Array<(
          { __typename?: 'menu' }
          & MenuFieldsFragment
        )>> }
        & MenuFieldsFragment
      )>> }
      & MenuFieldsFragment
    )>> }
    & MenuFieldsFragment
  )>>> }
);

export const ProductCategoriesBlockFieldsFragmentDoc = gql`
    fragment productCategoriesBlockFields on productCategoriesBlock {
  id
  type
  categoryList {
    ...menuFields
    children {
      ...menuFields
      children {
        ...menuFields
        children {
          ...menuFields
        }
      }
    }
  }
}
    ${MenuFieldsFragmentDoc}`;