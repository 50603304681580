import { InMemoryCache } from "@apollo/client"
import { concatPagination } from "@apollo/client/utilities"
const introspectionResult = require("./fragmentTypes.json")
import { QueryOrderHistoryArgs } from "@focus-nordic//www-client/src/@types/graphql.generated"

interface Config {
	graphQlEndpoint: string
}

const apolloClient = {
	credentials: "include",
	cache: () =>
		new InMemoryCache({
			possibleTypes: introspectionResult.possibleTypes,
			typePolicies: {
				pages: {
					fields: {
						range: concatPagination()
					}
				},
				products: {
					fields: {
						// products: {
						// 	merge(existing = [], incoming = [], options) {
						// 		const variables: QueryProductRangeArgs = options.variables || {}
						// 		if (variables.paginationInput) {
						// 			return [...(existing ?? []), ...(incoming ?? [])]
						// 		}
						// 		return incoming
						// 	}
						// },
						facets: {
							merge(existing, incoming) {
								return incoming
							}
						}
					}
				},
				// explicitly define type policy for cartSummary.items to override the previous cache
				cartSummary: {
					fields: {
						items: {
							merge(existing, incoming = []) {
								return incoming
							}
						}
					}
				},
				GetOrderHistoryResult: {
					keyFields: [],
					fields: {
						orders: {
							merge(existing, incoming, options) {
								const variables: QueryOrderHistoryArgs = options.variables || {}
								
								if (variables.input && variables.input.pagination.skip > 0) {
									return [...existing, ...incoming]
								}
								return incoming
							}
						}
					}
				},
        GetDocumentsResult: {
					keyFields: [],
					fields: {
						documents: {
							merge(existing = [], incoming) {
								return [...existing, ...incoming]
							}
						}
					}
				}
			}
		})
}

export const config = {
	apolloClient
}
