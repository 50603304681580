"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.variants = exports.fontWeight = exports.bodyFontFamily = void 0;
exports.bodyFontFamily = "Circular, helvetica, sans-serif";
exports.fontWeight = {
    normal: "400",
    bold: "700"
};
exports.variants = {
    "tag-1": {
        as: "p",
        fontSize: 1.5,
        lineHeight: 2,
        letterSpacing: 0.1,
        fontWeight: "bold",
        textTransform: "uppercase"
    },
    "paragraph-1": {
        as: "p",
        fontSize: { m: 2 },
        lineHeight: { _: 3 },
        textColor: "grey-4",
        mb: 2
    },
    "headline-1": {
        as: "h1",
        fontSize: { _: 4, m: 6, l: 8 },
        lineHeight: { _: 5, m: 7, l: 9 },
        fontWeight: "bold",
        mb: 2
    },
    "headline-2": {
        as: "h2",
        fontSize: { _: 3, m: 4, l: 5 },
        lineHeight: { _: 4, m: 5, l: 6 },
        fontWeight: "bold",
        mb: 1
    },
    "headline-3": {
        as: "h3",
        fontSize: { _: 2.5, m: 3.25, l: 4 },
        lineHeight: { _: 3.75, m: 4.25, l: 5 },
        fontWeight: "bold",
        mb: 1
    },
    "headline-4": {
        as: "h4",
        fontSize: { _: 2.25, l: 3 },
        lineHeight: { _: 3, l: 4 },
        fontWeight: "bold",
        mb: 1
    },
    "headline-5": {
        as: "h5",
        fontSize: { _: 2, l: 2.5 },
        lineHeight: { _: 2.5, l: 4 },
        fontWeight: "bold",
        mb: 1
    },
    "headline-6": {
        as: "h6",
        fontSize: 2,
        lineHeight: { _: 2.5, m: 3 },
        fontWeight: "bold",
        mb: 1
    },
    preamble: {
        as: "p",
        fontSize: { _: 2.5, l: 3 },
        lineHeight: { _: 3.5, l: 4 },
        mb: 1
    }
};
