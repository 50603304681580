import * as Types from '../../../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetDocumentsQueryVariables = Types.Exact<{
  input?: Types.Maybe<Types.GetDocumentsInput>;
}>;


export type GetDocumentsQuery = (
  { __typename?: 'Query' }
  & { documents?: Types.Maybe<(
    { __typename?: 'GetDocumentsResult' }
    & { documents?: Types.Maybe<Array<(
      { __typename?: 'DocumentModel' }
      & Pick<Types.DocumentModel, 'currency' | 'documentNumber' | 'singleOrderNumber' | 'orderNumbers' | 'documentType' | 'invoiceDate' | 'dueDate' | 'amountIncludingFees'>
    )>>, pagination: (
      { __typename?: 'pagination' }
      & Pick<Types.Pagination, 'take' | 'skip' | 'count'>
    ) }
  )> }
);


export const GetDocumentsDocument = gql`
    query GetDocuments($input: GetDocumentsInput) {
  documents(input: $input) {
    documents {
      currency
      documentNumber
      singleOrderNumber
      orderNumbers
      documentType
      invoiceDate
      dueDate
      amountIncludingFees
    }
    pagination {
      take
      skip
      count
    }
  }
}
    `;

/**
 * __useGetDocumentsQuery__
 *
 * To run a query within a React component, call `useGetDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetDocumentsQuery, GetDocumentsQueryVariables>) {
        return Apollo.useQuery<GetDocumentsQuery, GetDocumentsQueryVariables>(GetDocumentsDocument, baseOptions);
      }
export function useGetDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentsQuery, GetDocumentsQueryVariables>) {
          return Apollo.useLazyQuery<GetDocumentsQuery, GetDocumentsQueryVariables>(GetDocumentsDocument, baseOptions);
        }
export type GetDocumentsQueryHookResult = ReturnType<typeof useGetDocumentsQuery>;
export type GetDocumentsLazyQueryHookResult = ReturnType<typeof useGetDocumentsLazyQuery>;
export type GetDocumentsQueryResult = Apollo.QueryResult<GetDocumentsQuery, GetDocumentsQueryVariables>;