import { CartItem, CartItemInput } from "../@types/graphql.generated"

export const cartItemToCartItemInput = (
	cartItem: Pick<CartItem, "id" | "quantity" | "price">,
	shouldAddCustomPrice?: boolean
): CartItemInput => {
	return {
		articleNumber: cartItem.id,
		quantity: cartItem.quantity,
		...(shouldAddCustomPrice && { price: cartItem.price?.netPrice })
	}
}

export const containsOverpricedItems = (items: CartItem[]) => {
	return (
		items?.some(
			item => (item.price?.netPrice ?? 0) > (item.product?.price?.netPrice ?? 0)
		) || false
	)
}
