"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductLabelKeys = exports.ProductLabels = void 0;
var ProductLabels;
(function (ProductLabels) {
    ProductLabels["CAMPAIGN"] = "Campaign";
    ProductLabels["COMINGSOON"] = "Coming soon";
    ProductLabels["KIT"] = "Kit";
    ProductLabels["NEW"] = "New";
    ProductLabels["OUTLET"] = "Outlet";
    ProductLabels["CAMPAIGNBLACKFRIDAY"] = "Black Friday";
})(ProductLabels = exports.ProductLabels || (exports.ProductLabels = {}));
var ProductLabelKeys;
(function (ProductLabelKeys) {
    ProductLabelKeys["CAMPAIGN"] = "CAMPAIGN";
    ProductLabelKeys["COMINGSOON"] = "COMINGSOON";
    ProductLabelKeys["KIT"] = "KIT";
    ProductLabelKeys["NEW"] = "NEW";
    ProductLabelKeys["OUTLET"] = "OUTLET";
    ProductLabelKeys["CAMPAIGNBLACKFRIDAY"] = "CAMPAIGNBLACKFRIDAY";
    ProductLabelKeys["WARRANTY"] = "WARRANTY";
})(ProductLabelKeys = exports.ProductLabelKeys || (exports.ProductLabelKeys = {}));
