import * as React from "react"
import { Link } from "react-router-dom"
import { Link as ILink, LinkTarget } from "../../@types/graphql.generated"
import { linkToLinkProps, getLinkTargetAttribute } from "../../utils"

interface LinkAnchorProps
	extends ILink,
		React.AnchorHTMLAttributes<
			Omit<HTMLAnchorElement, "href" | "target" | "title">
		> {
	target: LinkTarget
	title: string
}

const LinkAnchor: React.FC<LinkAnchorProps> = props => {
	const { isExternal, url, target, children, __typename, alt, ...rest } = props
	return isExternal ? (
		<a
			href={url}
			target={getLinkTargetAttribute(target)}
			rel="noopener noreferrer"
			{...rest}
		>
			{children}
		</a>
	) : (
		<Link {...rest} {...linkToLinkProps(props)}>
			{children}
		</Link>
	)
}

export { LinkAnchor }
