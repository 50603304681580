import * as React from "react"
import { Text, Hero, Image, Flex } from "@focus-nordic/ui-components"
import { imagePath } from "../../utils"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { InspirationHeroBlock as IInspirationHeroBlock } from "../../@types/graphql.generated"

const InspirationHeroBlock: React.FC<IInspirationHeroBlock> = props => {
	const locales = useLocales(translationNamespace.shared("imageData"), [
		"photographer"
	])

	return (
		<Hero
			minh={{ _: 60, m: 54, l: 68, xxl: 90.5 }}
			media={
				props.image ? (
					<Image
						src={imagePath(props.image.fileName, "4:5=384x480")}
						alt={
							props.image.meta && props.image.meta.alt
								? props.image.meta.alt
								: ""
						}
						srcSet={[
							`${imagePath(props.image.fileName, "4:5=384x480", true)}`,
							`${imagePath(props.image.fileName, "4:5=768x960", true)}`
						]}
						responsiveSrcSet={{
							s: [
								`${imagePath(props.image.fileName, "16:9=768x432", true)}`,
								`${imagePath(props.image.fileName, "16:9=1536x864", true)}`
							],
							l: [
								`${imagePath(props.image.fileName, "8:3=1536x576", true)}`,
								`${imagePath(props.image.fileName, "8:3=3072x1152", true)}`
							]
						}}
					/>
				) : (
					undefined
				)
			}
			bgOverlay={
				props.imageOpacity
					? { variant: "solid", opacity: props.imageOpacity }
					: undefined
			}
			px={2}
			hAlign="center"
			caption={
				props.image?.meta?.author
					? `${locales.photographer} ${props.image?.meta?.author}`
					: undefined
			}
		>
			<Flex maxw={{ _: 64, m: 86, l: 108 }} flexDirection="column">
				{props.inspirationTitle && (
					<Text
						as="h1"
						fontWeight="bold"
						fontSize={{ _: 4, m: 6, l: 8 }}
						lineHeight={{ _: 5, m: 7, l: 9 }}
						mb={0}
						textColor="white"
						textAlign="center"
					>
						{props.inspirationTitle}
					</Text>
				)}
				{props.preamble && (
					<Text
						as="p"
						mt={{ _: 0.5, m: 1 }}
						mb={0}
						fontSize={{ _: 2.25, m: 3 }}
						lineHeight={{ _: 3, m: 4 }}
						textAlign="center"
						textColor="white"
					>
						{props.preamble}
					</Text>
				)}
			</Flex>
		</Hero>
	)
}

export { InspirationHeroBlock }
