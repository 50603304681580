import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetCheckoutTranslationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCheckoutTranslationsQuery = (
  { __typename?: 'Query' }
  & { checkoutTranslations?: Types.Maybe<Array<(
    { __typename?: 'Translation' }
    & Pick<Types.Translation, 'key' | 'value'>
  )>> }
);

export type ServicePointFeildsFragment = (
  { __typename?: 'servicePoint' }
  & Pick<Types.ServicePoint, 'id' | 'name'>
);

export type DeliveryOptionFieldsFragment = (
  { __typename?: 'deliveryOption' }
  & Pick<Types.DeliveryOption, 'description' | 'id' | 'logoUrl' | 'name'>
  & { price: (
    { __typename?: 'priceInfo' }
    & Pick<Types.PriceInfo, 'netPrice' | 'currency'>
  ), servicePoints?: Types.Maybe<Array<(
    { __typename?: 'servicePoint' }
    & ServicePointFeildsFragment
  )>> }
);

export type GetDeliveryOptionsQueryVariables = Types.Exact<{
  zipCode: Types.Scalars['String'];
  cartItems?: Types.Maybe<Array<Types.CartItemInput>>;
  customerType?: Types.Maybe<Types.CustomerTypeEnum>;
}>;


export type GetDeliveryOptionsQuery = (
  { __typename?: 'Query' }
  & { deliveryOptions?: Types.Maybe<Array<(
    { __typename?: 'deliveryOption' }
    & DeliveryOptionFieldsFragment
  )>> }
);

export type GetB2CPaymentQueryVariables = Types.Exact<{
  paymentInput: Types.PaymentInput;
  checkoutUrlOverwrite?: Types.Maybe<Types.Scalars['String']>;
  discountCode?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetB2CPaymentQuery = (
  { __typename?: 'Query' }
  & { b2CPayment?: Types.Maybe<(
    { __typename?: 'payment' }
    & Pick<Types.Payment, 'id' | 'orderId'>
  )> }
);

export const ServicePointFeildsFragmentDoc = gql`
    fragment servicePointFeilds on servicePoint {
  id
  name
}
    `;
export const DeliveryOptionFieldsFragmentDoc = gql`
    fragment deliveryOptionFields on deliveryOption {
  description
  id
  logoUrl
  name
  price {
    netPrice
    currency
  }
  servicePoints {
    ...servicePointFeilds
  }
}
    ${ServicePointFeildsFragmentDoc}`;
export const GetCheckoutTranslationsDocument = gql`
    query GetCheckoutTranslations {
  checkoutTranslations {
    key
    value
  }
}
    `;

/**
 * __useGetCheckoutTranslationsQuery__
 *
 * To run a query within a React component, call `useGetCheckoutTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckoutTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckoutTranslationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCheckoutTranslationsQuery(baseOptions?: Apollo.QueryHookOptions<GetCheckoutTranslationsQuery, GetCheckoutTranslationsQueryVariables>) {
        return Apollo.useQuery<GetCheckoutTranslationsQuery, GetCheckoutTranslationsQueryVariables>(GetCheckoutTranslationsDocument, baseOptions);
      }
export function useGetCheckoutTranslationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckoutTranslationsQuery, GetCheckoutTranslationsQueryVariables>) {
          return Apollo.useLazyQuery<GetCheckoutTranslationsQuery, GetCheckoutTranslationsQueryVariables>(GetCheckoutTranslationsDocument, baseOptions);
        }
export type GetCheckoutTranslationsQueryHookResult = ReturnType<typeof useGetCheckoutTranslationsQuery>;
export type GetCheckoutTranslationsLazyQueryHookResult = ReturnType<typeof useGetCheckoutTranslationsLazyQuery>;
export type GetCheckoutTranslationsQueryResult = Apollo.QueryResult<GetCheckoutTranslationsQuery, GetCheckoutTranslationsQueryVariables>;
export const GetDeliveryOptionsDocument = gql`
    query GetDeliveryOptions($zipCode: String!, $cartItems: [cartItemInput!], $customerType: CustomerTypeEnum) {
  deliveryOptions(zipCode: $zipCode, cartItems: $cartItems, customerType: $customerType) {
    ...deliveryOptionFields
  }
}
    ${DeliveryOptionFieldsFragmentDoc}`;

/**
 * __useGetDeliveryOptionsQuery__
 *
 * To run a query within a React component, call `useGetDeliveryOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryOptionsQuery({
 *   variables: {
 *      zipCode: // value for 'zipCode'
 *      cartItems: // value for 'cartItems'
 *      customerType: // value for 'customerType'
 *   },
 * });
 */
export function useGetDeliveryOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetDeliveryOptionsQuery, GetDeliveryOptionsQueryVariables>) {
        return Apollo.useQuery<GetDeliveryOptionsQuery, GetDeliveryOptionsQueryVariables>(GetDeliveryOptionsDocument, baseOptions);
      }
export function useGetDeliveryOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryOptionsQuery, GetDeliveryOptionsQueryVariables>) {
          return Apollo.useLazyQuery<GetDeliveryOptionsQuery, GetDeliveryOptionsQueryVariables>(GetDeliveryOptionsDocument, baseOptions);
        }
export type GetDeliveryOptionsQueryHookResult = ReturnType<typeof useGetDeliveryOptionsQuery>;
export type GetDeliveryOptionsLazyQueryHookResult = ReturnType<typeof useGetDeliveryOptionsLazyQuery>;
export type GetDeliveryOptionsQueryResult = Apollo.QueryResult<GetDeliveryOptionsQuery, GetDeliveryOptionsQueryVariables>;
export const GetB2CPaymentDocument = gql`
    query GetB2CPayment($paymentInput: paymentInput!, $checkoutUrlOverwrite: String, $discountCode: String) {
  b2CPayment(paymentInput: $paymentInput, checkoutUrlOverwrite: $checkoutUrlOverwrite, discountCode: $discountCode) {
    id
    orderId
  }
}
    `;

/**
 * __useGetB2CPaymentQuery__
 *
 * To run a query within a React component, call `useGetB2CPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetB2CPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetB2CPaymentQuery({
 *   variables: {
 *      paymentInput: // value for 'paymentInput'
 *      checkoutUrlOverwrite: // value for 'checkoutUrlOverwrite'
 *      discountCode: // value for 'discountCode'
 *   },
 * });
 */
export function useGetB2CPaymentQuery(baseOptions?: Apollo.QueryHookOptions<GetB2CPaymentQuery, GetB2CPaymentQueryVariables>) {
        return Apollo.useQuery<GetB2CPaymentQuery, GetB2CPaymentQueryVariables>(GetB2CPaymentDocument, baseOptions);
      }
export function useGetB2CPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetB2CPaymentQuery, GetB2CPaymentQueryVariables>) {
          return Apollo.useLazyQuery<GetB2CPaymentQuery, GetB2CPaymentQueryVariables>(GetB2CPaymentDocument, baseOptions);
        }
export type GetB2CPaymentQueryHookResult = ReturnType<typeof useGetB2CPaymentQuery>;
export type GetB2CPaymentLazyQueryHookResult = ReturnType<typeof useGetB2CPaymentLazyQuery>;
export type GetB2CPaymentQueryResult = Apollo.QueryResult<GetB2CPaymentQuery, GetB2CPaymentQueryVariables>;