import { ImageBase } from "../@types/graphql.generated"
import { ImageProps } from "@focus-nordic/ui-components"
import env from "@beam-australia/react-env"

const IMAGE_BASE_URL = env("IMAGE_BASE_URL")

type SizePreset =
	| "8:3=3072x1152"
	| "8:3=1536x576"
	| "16:9=1536x864"
	| "16:9=768x432"
	| "3:4=936x1248"
	| "3:4=468x624"
	| "16:9=1536x1920"
	| "4:5=768x960"
	| "4:5=384x480"
	| "3:2=3072x2048"
	| "3:2=1536x1024"
	| "3:2=768x512"
	| "3:2=384x256"
	| "original"

export type ImagePreset = Record<
	SizePreset,
	{ width?: number; height?: number; format: string }
>

export const IMAGE_PRESETS: ImagePreset = {
	"8:3=3072x1152": {
		width: 3072,
		height: 1152,
		format: "3072x1152"
	},
	"8:3=1536x576": {
		width: 1536,
		height: 576,
		format: "1536x576"
	},
	"16:9=1536x864": {
		width: 1536,
		height: 864,
		format: "1536x864"
	},
	"16:9=768x432": {
		width: 768,
		height: 432,
		format: "768x432"
	},
	"3:4=936x1248": {
		width: 936,
		height: 1248,
		format: "936x1248"
	},
	"3:4=468x624": {
		width: 468,
		height: 624,
		format: "468x624"
	},
	"16:9=1536x1920": {
		width: 1536,
		height: 1920,
		format: "1536x1920"
	},
	"4:5=768x960": {
		width: 768,
		height: 960,
		format: "768x960"
	},
	"4:5=384x480": {
		width: 384,
		height: 480,
		format: "384x480"
	},
	"3:2=3072x2048": {
		width: 3072,
		height: 2048,
		format: "3072x2048"
	},
	"3:2=1536x1024": {
		width: 1536,
		height: 1024,
		format: "1536x1024"
	},
	"3:2=768x512": {
		width: 768,
		height: 512,
		format: "768x512"
	},
	"3:2=384x256": {
		width: 384,
		height: 256,
		format: "384x256"
	},
	original: {
		format: "original"
	}
}

/*
// Returns the full image path for a given sizePreset and fileName
*/
export const imagePath = (
	fileName: string,
	sizePreset: SizePreset,
	srcSet = false
) => {
	// conditioanlly include setSet width
	const includeSrcSet = () => (srcSet ? ` ${selectedImagePreset.width}w` : "")

	const selectedImagePreset = IMAGE_PRESETS[sizePreset]
	return `${IMAGE_BASE_URL}${selectedImagePreset.format}/${encodeURI(
		fileName
	)}${includeSrcSet()}`
}

/*
// Returns an image object based on a preset
*/
export const imageBaseToImage = (
	imageBase: ImageBase,
	sizePreset: SizePreset
): ImageProps => {
	const selectedImagePreset = IMAGE_PRESETS[sizePreset]
	return {
		src: imagePath(imageBase.fileName, sizePreset),
		width: selectedImagePreset.width,
		height: selectedImagePreset.height,
		alt: imageBase.meta?.alt || ""
	}
}
