import * as React from "react"
import { Flex, Text } from "@focus-nordic/ui-components"
import { SITE_MAX_WIDTH } from "../../constants"
import { CategoryLinkListBlock as ICategoryLinkListBlock } from "../../@types/graphql.generated"

const CategoryLinkListBlock: React.FC<ICategoryLinkListBlock> = props => {
	return (
		<Flex
			w={1}
			py={{ _: 4, m: 8 }}
			px={{ _: 2, m: 8 }}
			maxw={SITE_MAX_WIDTH}
			style={{ margin: "0 auto" }}
		>
			{props.categories && (
				<Flex flexWrap="wrap" w={1} mx={{ _: -2, m: -4 }}>
					{props.categories.map(category => (
						<Flex
							as="ul"
							flexDirection="column"
							w={{ _: 1, s: 6 / 12, l: 3 / 12 }}
							mb={{ _: 4, m: 8 }}
							px={{ _: 2, m: 4 }}
							key={`${category.title}-${category.url}`}
						>
							<Flex as="li">
								<Text.Link
									textColor="black"
									to={category.url}
									target={`_${category.target}`}
									variant="headline-5"
								>
									{category.title}
								</Text.Link>
							</Flex>
							{category.subcategories && (
								<Flex as="ul" flexDirection="column">
									{category.subcategories.map(subcategory => (
										<Flex
											as="li"
											key={`${subcategory.title}-${subcategory.url}`}
										>
											<Text.Link
												to={subcategory.url}
												target={`_${subcategory.target}`}
												mb={1}
											>
												{subcategory.title}
											</Text.Link>
										</Flex>
									))}
								</Flex>
							)}
						</Flex>
					))}
				</Flex>
			)}
		</Flex>
	)
}

export { CategoryLinkListBlock }
