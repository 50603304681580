import React from "react"
import {
	Text,
	TextProps,
	Flex,
	LayoutProps,
	Checkbox,
	scale,
	CheckboxProps
} from "@focus-nordic/ui-components"
import { RteContent } from "./.."
import styled from "styled-components"

interface CartSummaryProps extends LayoutProps {}

interface CartSummaryComponent {
	DataTitle: typeof DataTitle
	PriceTitle: typeof PriceTitle
	PriceRow: typeof PriceRow
	SummaryTitle: typeof SummaryTitle
	ConsentLabel: typeof ConsentLabel
	ConsentCheckbox: typeof ConsentCheckbox
}

interface TitleProps extends LayoutProps {
	title?: React.ReactNode
	subtitle?: React.ReactNode
	price?: React.ReactNode
	isDiscountAmount?: Boolean
}

interface ConsentLabelProps extends LayoutProps {
	labelText: string
}

const PriceTitle: React.FC<TextProps> = props => (
	<Text
		as="h3"
		fontSize={3}
		lineHeight={4}
		textColor="black"
		mb={0}
		{...props}
	/>
)

const DataTitle: React.FC<TextProps> = props => (
	<Text mb={0} lineHeight={2} {...props} />
)

const SummaryTitle: React.FC<TextProps> = props => (
	<Text mb={0} lineHeight={2} {...props} />
)

const PriceRow: React.FC<TitleProps> = ({
	title,
	subtitle,
	price,
	isDiscountAmount,
	...rest
}) => (
	<Flex
		justifyContent="space-between"
		alignItems="flex-start"
		py={1.5}
		borderBottom
		{...rest}
	>
		<Flex w={1} mr={1} flex={1} alignItems="flex-end">
			{title && <DataTitle>{title}</DataTitle>}
			{subtitle && (
				<Text mb={0} ml={0.5} fontStyle="italic" fontSize={1.5} lineHeight={1.5}>
					{subtitle}
				</Text>
			)}
		</Flex>
		<Flex alignItems="flex-end">
			{isDiscountAmount && price ? (
				<DataTitle textColor="red-2" textAlign="right">
					- {price}
				</DataTitle>
			) : price && (
				<DataTitle textColor="black" textAlign="right">
					{price}
				</DataTitle>
			)}
		</Flex>
	</Flex>
)

const StyledLabel = styled.label`
	margin-left: ${scale.px(1.5)};
	user-select: none;
`

const CONSENT_ID = "consent"

const ConsentLabel: React.FC<ConsentLabelProps> = props => (
	<StyledLabel htmlFor={CONSENT_ID}>
		<RteContent content={props.labelText} />
	</StyledLabel>
)

const ConsentCheckbox: React.FC<CheckboxProps> = props => (
	<Flex mt={0.75}>
		<Checkbox id={CONSENT_ID} {...props} />
	</Flex>
)

const CartSummary: React.FC<CartSummaryProps> &
	CartSummaryComponent = props => (
	<Flex border flex={1} p={3} flexDirection="column" {...props} />
)

CartSummary.DataTitle = DataTitle
CartSummary.PriceTitle = PriceTitle
CartSummary.PriceRow = PriceRow
CartSummary.SummaryTitle = SummaryTitle
CartSummary.ConsentLabel = ConsentLabel
CartSummary.ConsentCheckbox = ConsentCheckbox

export { CartSummary }
