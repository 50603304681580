import * as React from "react"
import { Flex, IconButton } from "@focus-nordic/ui-components"
import { CardLayout } from "../../@types/graphql.generated"

export interface ProcuctListToggleProps {
	currentListType?: CardLayout
	onToggle?: (cardSize: CardLayout) => any
}

export const useCardLayout = (cardLayout: CardLayout = CardLayout.Large) =>
	React.useState<CardLayout>(cardLayout)

const ProcuctListToggle: React.FC<ProcuctListToggleProps> = props => (
	<Flex>
		<IconButton
			icon="grid"
			active={props.currentListType === CardLayout.Large}
			onClick={() => props.onToggle!(CardLayout.Large)}
		/>
		<IconButton
			icon="list"
			active={props.currentListType === CardLayout.Medium}
			onClick={() => props.onToggle!(CardLayout.Medium)}
		/>
	</Flex>
)

ProcuctListToggle.defaultProps = {
	currentListType: CardLayout.Large,
	onToggle: () => {}
}

export { ProcuctListToggle }
