import * as React from "react"
import { Text, NavigationPanel } from "@focus-nordic/ui-components"
import { MY_PAGES_ROUTE } from "@focus-nordic/www-common/constants"
import { useLocales } from "../../../hooks/useLocales"
import {
	PageRoute,
	PAGE_ROUTES_TITLES,
	getLocaleFilteredRoutes
} from "../routes"
import { LocalesContext } from "../../../context"

export interface NavigationProps {
	customerName?: string
	routes: PageRoute[]
}

const Navigation: React.FC<NavigationProps> = props => {
	const { customerName, routes } = props
	const locales = useLocales("myPages", PAGE_ROUTES_TITLES)
	const { localeCode } = React.useContext(LocalesContext)
	const localeFilteredRoutes = React.useMemo(() => {
		return getLocaleFilteredRoutes(routes, localeCode)
	}, [routes, localeCode])

	return (
		<NavigationPanel>
			<Text mb={0} py={1.25} px={1.5} fontSize={2.25} textColor="black">
				{customerName}
			</Text>
			<ul>
				{localeFilteredRoutes.map(pageRoute =>
					pageRoute.useNavigation ? (
						<NavigationPanel.Link
							to={`${MY_PAGES_ROUTE}/${pageRoute.path}`}
							key={pageRoute.path}
							activeClassName="active"
						>
							<Text as="span" textColor="black">
								{locales[pageRoute.titleTranslationKey]}
							</Text>
						</NavigationPanel.Link>
					) : null
				)}
			</ul>
		</NavigationPanel>
	)
}

export { Navigation }
