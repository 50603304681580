import React from "react"
import { useLocation, useHistory } from "react-router"
import { Flex } from "@focus-nordic/ui-components"
import { BlocksPage } from ".."
import useSSR from "use-ssr"
import { loadCookieControl } from "../../utils/cookiesControl"
import { LocalesContext } from "../../context"
import { Switch, Route } from "react-router-dom"
import { MY_PAGES_ROUTE } from "@focus-nordic/www-common/constants"
import { MyPagesPage } from "../MyPagesPage/MyPagesPage"
import { useAnchorLinkScrolling } from "../../hooks"

const Page: React.FC = () => {
	const location = useLocation()
	const history = useHistory()
	const locales = React.useContext(LocalesContext)

	const { isServer } = useSSR()
	useAnchorLinkScrolling()
	// reset scroll position to 0 after page load
	React.useEffect(() => {
		if (!isServer && history.action !== "POP") {
			let timer = setTimeout(() => window.scrollTo(0, 0), 10)

			return () => {
				clearTimeout(timer)
			}
		}
	}, [location.pathname, isServer])

	// setup cookie control
	React.useEffect(() => {
		if (locales) {
			loadCookieControl(locales.translations)
		}
	}, [locales])

	return (
		<>
			<Flex>
				<Switch>
					<Route path={MY_PAGES_ROUTE}>
						<MyPagesPage />
					</Route>
					<Route path="*">
						<BlocksPage />
					</Route>
				</Switch>
			</Flex>
		</>
	)
}

export { Page }
