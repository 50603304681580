import * as React from "react"
import {
	Text,
	Flex,
	Hero,
	GalleryBanner,
	Image,
	Button,
	OverlayInfo
} from "@focus-nordic/ui-components"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { HeroBlock as IHeroBlock, AlignmentType as HeroBlockItemAlignmentType } from "../../@types/graphql.generated"
import { imagePath, linkToLinkProps, getLinkTargetAttribute, galleryBannerDataToProps } from "../../utils"

const HeroBlock: React.FC<IHeroBlock> = props => {
	const locales = useLocales(translationNamespace.shared("imageData"), [
		"photographer"
	])

	const firstItem = props.heroCards?.[0]

	if (props.heroCards && props.heroCards.length > 0) {
		if (props.galleryMode || props.heroCards.length > 1) {
			return (
				<GalleryBanner
					items={galleryBannerDataToProps(props.heroCards) || []}
					alternativeLayout={props.alternativeLayout}
					photographerLabel={locales.photographer}
				/>
			)
		} else {
			return ( 
				<Hero
					hAlign={firstItem?.alignmentType === HeroBlockItemAlignmentType.Right ? "flex-end" : "flex-start"}
					minh={{ _: 62.5, m: 54, l: 68 }}
					vAlign={{ _: "flex-end", m: "center" }}
					p={{ _: 0, m: 2 }}
					media={
						firstItem?.image ? (
							<Image
								src={imagePath(firstItem.image.fileName, "4:5=384x480")}
								alt={
									firstItem.image.meta && firstItem.image.meta.alt
										? firstItem.image.meta.alt
										: ""
								}
								srcSet={[
									`${imagePath(firstItem.image.fileName, "4:5=384x480", true)}`,
									`${imagePath(firstItem.image.fileName, "4:5=768x960", true)}`
								]}
								responsiveSrcSet={{
									s: [
										`${imagePath(firstItem.image.fileName, "16:9=768x432", true)}`,
										`${imagePath(firstItem.image.fileName, "16:9=1536x864", true)}`
									],
									l: [
										`${imagePath(firstItem.image.fileName, "8:3=1536x576", true)}`,
										`${imagePath(firstItem.image.fileName, "8:3=3072x1152", true)}`
									]
								}}
								loading="eager"
							/>
						) : (
							undefined
						)
					}
					caption={
						firstItem?.image?.meta?.author
							? `${locales.photographer} ${firstItem.image?.meta?.author}`
							: undefined
					}
				>
					<OverlayInfo maxw={{ m: 56 }} darkTheme={props.isDarkTheme}>
						{firstItem?.title && (
							<Text
								as="h1"
								fontSize={{ _: 3, l: 4, xxl: 5 }}
								lineHeight={{ _: 4, l: 5, xxl: 6 }}
								mb={0}
								fontWeight="bold"
								textColor={props.isDarkTheme ? "white" : "black"}
							>
								{firstItem?.title}
							</Text>
						)}
						{firstItem?.preamble && (
							<Flex display={{ _: "none", m: "flex" }}>
								<Text
									as="p"
									mt={{ _: 1, m: 1.75 }}
									fontSize={2.25}
									lineHeight={3}
									truncate="twoLine"
									mb={0}
									textColor={props.isDarkTheme ? "white" : "black"}
								>
									{firstItem?.preamble}
								</Text>
							</Flex>
						)}
						{firstItem?.ctaButton && (
							<Flex mt={4}>
								{firstItem.ctaButton.isExternal ? (
									<Button.Anchor
										href={firstItem.ctaButton.url}
										target={getLinkTargetAttribute(firstItem.ctaButton.target)}
										variant={props.isDarkTheme ? "white" : "blue"}
									>
										{firstItem.ctaButton.title}
									</Button.Anchor>
								) : (
									<Button.Link
										{...linkToLinkProps(firstItem.ctaButton)}
										variant={props.isDarkTheme ? "white" : "blue"}
									>
										{firstItem.ctaButton.title}
									</Button.Link>
								)}
							</Flex>
						)}
					</OverlayInfo>
				</Hero>
			)
		}
	}

	return null
}

export { HeroBlock }
