import * as Types from '../../../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetOrderHistoryQueryVariables = Types.Exact<{
  input?: Types.Maybe<Types.GetOrderHistoryInput>;
}>;


export type GetOrderHistoryQuery = (
  { __typename?: 'Query' }
  & { orderHistory?: Types.Maybe<(
    { __typename?: 'GetOrderHistoryResult' }
    & Pick<Types.GetOrderHistoryResult, 'totalOrders'>
    & { pagination: (
      { __typename?: 'pagination' }
      & Pick<Types.Pagination, 'take' | 'skip' | 'count'>
    ), orders?: Types.Maybe<Array<(
      { __typename?: 'OrderHistoryEntry' }
      & Pick<Types.OrderHistoryEntry, 'id' | 'orderDate' | 'orderStatus' | 'customerReference' | 'customerOrderNumber'>
      & { totalAmount: (
        { __typename?: 'priceInfo' }
        & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
      ) }
    )>> }
  )> }
);


export const GetOrderHistoryDocument = gql`
    query GetOrderHistory($input: GetOrderHistoryInput) {
  orderHistory(input: $input) {
    totalOrders
    pagination {
      take
      skip
      count
    }
    orders {
      id
      orderDate
      orderStatus
      customerReference
      customerOrderNumber
      totalAmount {
        currency
        netPrice
      }
    }
  }
}
    `;

/**
 * __useGetOrderHistoryQuery__
 *
 * To run a query within a React component, call `useGetOrderHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrderHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetOrderHistoryQuery, GetOrderHistoryQueryVariables>) {
        return Apollo.useQuery<GetOrderHistoryQuery, GetOrderHistoryQueryVariables>(GetOrderHistoryDocument, baseOptions);
      }
export function useGetOrderHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderHistoryQuery, GetOrderHistoryQueryVariables>) {
          return Apollo.useLazyQuery<GetOrderHistoryQuery, GetOrderHistoryQueryVariables>(GetOrderHistoryDocument, baseOptions);
        }
export type GetOrderHistoryQueryHookResult = ReturnType<typeof useGetOrderHistoryQuery>;
export type GetOrderHistoryLazyQueryHookResult = ReturnType<typeof useGetOrderHistoryLazyQuery>;
export type GetOrderHistoryQueryResult = Apollo.QueryResult<GetOrderHistoryQuery, GetOrderHistoryQueryVariables>;