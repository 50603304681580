import * as React from "react"
import { Flex, Button, Text } from "@focus-nordic/ui-components"
import { useLocales } from "../../hooks"
import {
	CategoryLinksBlock as ICategoryLinksBlock,
	BlockType
} from "../../@types/graphql.generated"
import { getLinkTargetAttribute } from "../../utils"

const CategoryLinksBlock: React.FC<ICategoryLinksBlock> = props => {
	const locales = useLocales(BlockType.Categorylinksblock, ["title"])

	return props.links ? (
		<Flex justifyContent="center" backgroundColor="blue-1" py={2} w={1} display="none">
			<Flex alignItems="center" flexWrap="wrap" py={2} maxw={1}>
				<Flex justifyContent="flex-start">
					<Text whiteSpace="nowrap" variant="headline-6" ml={2} mb={0}>
						{locales.title}
					</Text>
				</Flex>
				{props.links && (
					<Flex as="ul" maxw={1} overflow="auto" py={1} px={2}>
						{props.links.map(category => (
							<Flex as="li" key={`${category.title}-${category.url}`}>
								<Button.Link
									to={category.url}
									target={getLinkTargetAttribute(category.target)}
									mr={1}
									whiteSpace="nowrap"
									variant="whiteBlueNormal"
								>
									{category.title}
								</Button.Link>
							</Flex>
						))}
					</Flex>
				)}
			</Flex>
		</Flex>
	) : (
		<Flex></Flex>
	)
}

export { CategoryLinksBlock }
