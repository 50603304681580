import React from "react"
import { Flex, Chip, Text } from "@focus-nordic/ui-components"
import { FacetsState, FacetValue } from "./hooks"
import { useLocales } from "../../hooks"
import { BlockType } from "../../@types/graphql.generated"

export interface ChipsProps {
	facetsState: FacetsState
	onRemoveFacet: (facetValue: FacetValue) => any
	onClearFacets: (...args: any) => void
	disabled?: boolean
}

export const Chips: React.FC<ChipsProps> = props => {
	const locales = useLocales(BlockType.Productlistblock, ["clearFilter"])
	return (
		<Flex flexWrap="wrap" mt={2} mb={2}>
			<Flex overflow="auto" maxw={1}>
				<Flex flexWrap="nowrap">
					{Object.keys(props.facetsState).map(key => {
						const facetValue: FacetValue = props.facetsState[key]
						return (
							<Chip
								onClick={() => props.onRemoveFacet(facetValue)}
								disabled={props.disabled}
								key={`${facetValue.key}-${facetValue.value}`}
							>
								{facetValue.value}
							</Chip>
						)
					})}
				</Flex>
			</Flex>
			<Text.Button
				mb={1}
				onClick={props.onClearFacets}
				disabled={props.disabled}
			>
				{locales.clearFilter}
			</Text.Button>
		</Flex>
	)
}

Chips.defaultProps = {
	disabled: false
}
