import React from "react"
import { Rte } from "@focus-nordic/ui-components"
import { useLocales } from "../../../hooks/useLocales"
import { CheckoutProvider } from "../context/CheckoutProvider"
import { Layout } from "../Layout"
import { Panel } from "../components"
import { createMarkup } from "../../../utils"

const View: React.FC = () => {
	const locales = useLocales("checkout", ["failureTitle", "failureDescription"])

	return (
		<Layout hasStepper={false}>
			<Panel>
				<Panel.Title>{locales.failureTitle}</Panel.Title>
				<Rte
					dangerouslySetInnerHTML={createMarkup(locales.failureDescription)}
				/>
			</Panel>
		</Layout>
	)
}

const FailureStatusView: React.FC = () => {
	return (
		<CheckoutProvider>
			<View />
		</CheckoutProvider>
	)
}

export { FailureStatusView }
