import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type ArticleIntroBlockFieldsFragment = (
  { __typename?: 'articleIntroBlock' }
  & Pick<Types.ArticleIntroBlock, 'id' | 'type' | 'articleTitle' | 'publishDate' | 'preamble'>
  & { image?: Types.Maybe<(
    { __typename?: 'ImageBase' }
    & Pick<Types.ImageBase, 'fileName'>
    & { meta?: Types.Maybe<(
      { __typename?: 'ImageMeta' }
      & Pick<Types.ImageMeta, 'author' | 'alt'>
    )> }
  )> }
);

export const ArticleIntroBlockFieldsFragmentDoc = gql`
    fragment articleIntroBlockFields on articleIntroBlock {
  id
  type
  articleTitle
  publishDate
  preamble
  image {
    fileName
    meta {
      author
      alt
    }
  }
}
    `;