import React from "react"
import styled, { css } from "styled-components"
import { Flex } from "@focus-nordic/ui-components"
import useSSR from "use-ssr"
import { Panel, Spinner } from "./components"

export interface StepProps {
	isActive: boolean
}

const StepSyled = styled.div<StepProps>`
	display: flex;
	flex: 1;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	display: none;

	${props =>
		props.isActive &&
		css`
			display: flex;
			transition-delay: 0.4;
		`}
`

const Loader = () => (
	<Panel>
		<Spinner />
	</Panel>
)

export const Step: React.FC<StepProps> = ({ children, ...rest }) => {
	const { isBrowser } = useSSR()
	return (
		<StepSyled {...rest}>
			<Flex flexDirection="column" alignItems="center" w={1}>
				{isBrowser ? (
					<React.Suspense fallback={Loader}>{children}</React.Suspense>
				) : (
					<Loader />
				)}
			</Flex>
		</StepSyled>
	)
}
