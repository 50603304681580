import * as Types from '../../../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetOrderTrackingQueryVariables = Types.Exact<{
  numberOfMonths: Types.Scalars['Int'];
}>;


export type GetOrderTrackingQuery = (
  { __typename?: 'Query' }
  & { orderTracking?: Types.Maybe<Array<(
    { __typename?: 'OrderTrackingEntry' }
    & Pick<Types.OrderTrackingEntry, 'deliveryDate' | 'orderNumber' | 'customerOrderNumber' | 'shipper' | 'trackingNumber' | 'trackingUrl'>
  )>> }
);


export const GetOrderTrackingDocument = gql`
    query GetOrderTracking($numberOfMonths: Int!) {
  orderTracking(numberOfMonths: $numberOfMonths) {
    deliveryDate
    orderNumber
    customerOrderNumber
    shipper
    trackingNumber
    trackingUrl
  }
}
    `;

/**
 * __useGetOrderTrackingQuery__
 *
 * To run a query within a React component, call `useGetOrderTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderTrackingQuery({
 *   variables: {
 *      numberOfMonths: // value for 'numberOfMonths'
 *   },
 * });
 */
export function useGetOrderTrackingQuery(baseOptions?: Apollo.QueryHookOptions<GetOrderTrackingQuery, GetOrderTrackingQueryVariables>) {
        return Apollo.useQuery<GetOrderTrackingQuery, GetOrderTrackingQueryVariables>(GetOrderTrackingDocument, baseOptions);
      }
export function useGetOrderTrackingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderTrackingQuery, GetOrderTrackingQueryVariables>) {
          return Apollo.useLazyQuery<GetOrderTrackingQuery, GetOrderTrackingQueryVariables>(GetOrderTrackingDocument, baseOptions);
        }
export type GetOrderTrackingQueryHookResult = ReturnType<typeof useGetOrderTrackingQuery>;
export type GetOrderTrackingLazyQueryHookResult = ReturnType<typeof useGetOrderTrackingLazyQuery>;
export type GetOrderTrackingQueryResult = Apollo.QueryResult<GetOrderTrackingQuery, GetOrderTrackingQueryVariables>;