import * as Types from '../../@types/graphql.generated';

import { AllLinkFieldsFragment } from '../../operations/link.generated';
import { gql } from '@apollo/client';
import { AllLinkFieldsFragmentDoc } from '../../operations/link.generated';
import * as Apollo from '@apollo/client';
export type GetSearchSuggestionQueryVariables = Types.Exact<{
  searchTerm: Types.Scalars['String'];
}>;


export type GetSearchSuggestionQuery = (
  { __typename?: 'Query' }
  & { searchSuggestions?: Types.Maybe<(
    { __typename?: 'searchSuggestions' }
    & { suggestions?: Types.Maybe<Array<(
      { __typename?: 'ProductHit' }
      & { link?: Types.Maybe<(
        { __typename?: 'link' }
        & AllLinkFieldsFragment
      )> }
    ) | (
      { __typename?: 'SearchSuggestion' }
      & { link?: Types.Maybe<(
        { __typename?: 'link' }
        & AllLinkFieldsFragment
      )> }
    )>> }
  )> }
);


export const GetSearchSuggestionDocument = gql`
    query GetSearchSuggestion($searchTerm: String!) {
  searchSuggestions(searchInput: {searchTerm: $searchTerm}) {
    suggestions {
      link {
        ...allLinkFields
      }
    }
  }
}
    ${AllLinkFieldsFragmentDoc}`;

/**
 * __useGetSearchSuggestionQuery__
 *
 * To run a query within a React component, call `useGetSearchSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchSuggestionQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetSearchSuggestionQuery(baseOptions?: Apollo.QueryHookOptions<GetSearchSuggestionQuery, GetSearchSuggestionQueryVariables>) {
        return Apollo.useQuery<GetSearchSuggestionQuery, GetSearchSuggestionQueryVariables>(GetSearchSuggestionDocument, baseOptions);
      }
export function useGetSearchSuggestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchSuggestionQuery, GetSearchSuggestionQueryVariables>) {
          return Apollo.useLazyQuery<GetSearchSuggestionQuery, GetSearchSuggestionQueryVariables>(GetSearchSuggestionDocument, baseOptions);
        }
export type GetSearchSuggestionQueryHookResult = ReturnType<typeof useGetSearchSuggestionQuery>;
export type GetSearchSuggestionLazyQueryHookResult = ReturnType<typeof useGetSearchSuggestionLazyQuery>;
export type GetSearchSuggestionQueryResult = Apollo.QueryResult<GetSearchSuggestionQuery, GetSearchSuggestionQueryVariables>;