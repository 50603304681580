import { Link } from "react-router-dom"
import { Button, Flex, Symbol, Icon } from "@focus-nordic/ui-components"
import React from "react"
import { SITE_MAX_WIDTH } from "../../../constants"
import { useLocales } from "../../../hooks/useLocales"
import { useCheckout } from ".."
import { usePaymentIdQueryString } from "../../../hooks"

export const Header: React.FC = () => {
	const locales = useLocales("checkout", [
		"backToShoppingCtaText",
		"previousStepCtaText"
	])
	const checkout = useCheckout()
	const firstOrLastStep =
		checkout.currentStepIndex === 0 ||
		checkout.currentStepIndex === checkout.stepIds.length - 1

	const paymentId = usePaymentIdQueryString()

	return (
		<Flex
			justifyContent="center"
			w={1}
			backgroundColor="white"
			py={{ _: 1.5, l: 3 }}
		>
			<Flex px={{ _: 2, s: 5, l: 8 }} w={1} maxw={SITE_MAX_WIDTH}>
				<Flex flex={1}>
					<Flex display={{ _: "flex", l: "none" }}>
						{/* hide back buttons if paymentId query string is present */}
						{!paymentId &&
							(firstOrLastStep ? (
								<Link to="/">
									<Icon color="blue-6" icon="arrow-left" />
								</Link>
							) : (
								<button onClick={checkout.goToPrevStep}>
									<Icon color="blue-6" icon="arrow-left" />
								</button>
							))}
					</Flex>
					<Flex display={{ _: "none", l: "flex" }}>
						{/* hide back buttons if paymentId query string is present */}
						{!paymentId &&
							(firstOrLastStep ? (
								<Button.Link variant="transparent" icon="arrow-left-big" to="/">
									{locales.backToShoppingCtaText}
								</Button.Link>
							) : (
								<Button
									variant="transparent"
									icon="arrow-left-big"
									onClick={checkout.goToPrevStep}
								>
									{locales.previousStepCtaText}
								</Button>
							))}
					</Flex>
				</Flex>
				<Flex flex={1} px={3} justifyContent="center">
					<Link to="/">
						<Symbol.Logo h={{ _: 2.5, l: 5 }} />
					</Link>
				</Flex>
				<Flex flex={1} />
			</Flex>
		</Flex>
	)
}
