import { CustomerTypeEnum, Menu } from "../../@types/graphql.generated"
import { SelectOption } from "@focus-nordic/ui-components"
import TagManager from "react-gtm-module"

export const dataToProps = (markets: Menu[]) => {
	return markets.map((market: Menu) => {
		const { title, url } = market

		return {
			label: title,
			value: url
		}
	}) as SelectOption[]
}

export const detectSelectedMarket = (marketOptions: SelectOption[]) =>
	marketOptions.find((item: SelectOption) =>
		item.value.includes(window.location.origin)
	)

export const trackFooterLinkClick = (
	footerClickCategory: string,
	footerLinkClick: string,
	userType: CustomerTypeEnum
) => {
	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			event: "footerClick",
			footerClickCategory,
			footerLinkClick,
			type: userType
		}
	})
}
