import React from "react"
import { Flex, LayoutProps, scale } from "@focus-nordic/ui-components"
import { SITE_MAX_WIDTH } from "../../constants/index"

const DropDownContainer: React.FC<LayoutProps> = ({ children, ...rest }) => (
	<Flex
		w={1}
		overflow="auto"
		maxh={`calc(100vh - ${scale.px(20)})`}
		justifyContent="center"
		{...rest}
	>
		<Flex
			w={1}
			h={1}
			maxw={SITE_MAX_WIDTH}
			py={{ _: 3, m: 5 }}
			mx={{ _: 2, s: 3 }}
		>
			{children}
		</Flex>
	</Flex>
)

export { DropDownContainer }
