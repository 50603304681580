import env from "@beam-australia/react-env"
import TagManager from "react-gtm-module"
import { colors } from "@focus-nordic/ui-components"
import {
	consentChange,
	COOKIE_CONTROL_MARKETING_CONSENT,
	CUSTOMER_IDENTIFIER_DISPLAYED,
	COOKIE_CUSTOMER_NUMBER,
	COOKIE_ACTIVATED_DISCOUNT_CODE,
	NOTIFY_ME_PRODUCTS_DATA
} from "../constants"
import { TranslationsRecord } from "../context/LocalesContext"

export interface OptionalCookies {
	name?: string
	label?: string
	description?: string
	cookies?: string[]
	onAccept?: () => void
	onRevoke?: () => void
}

export interface CookieControlConfig {
	apiKey?: string
	product: "COMMUNITY" | "PRO" | "PRO_MULTISITE"
	position: "LEFT" | "RIGHT"
	optionalCookies: OptionalCookies[]
	accessibility: { [key: string]: string | boolean }
	theme: "LIGHT" | "DARK"
	locale?: string
	locales: { [key: string]: any }[]
	statement: { [key: string]: any }
	branding: { [key: string]: any }
	necessaryCookies: string[]
}

export type OptionalCookieCategories = "analytics" | "marketing"

export const getCookieConsentByCategory = (
	category: OptionalCookieCategories
) =>
	Boolean(
		window?.CookieControl?.getCategoryConsent?.(CookieCategoryMap[category])
	)

export const CookieCategoryMap: Record<OptionalCookieCategories, number> = {
	analytics: 0,
	marketing: 1
}

export const getConfig = (translations: object): CookieControlConfig => {
	const TEXT_OBJECT_PREFIX = "Website_CookieControl_textObject"
	const STATEMENT_OBJECT_PREFIX = "Website_CookieControl_statementObject"
	const ANALYTICS_OBJECT_PREFIX = "Website_CookieControl_analyticsObject"
	const MARKETING_OBJECT_PREFIX = "Website_CookieControl_marketingObject"

	return {
		apiKey: translations["Website_CookieControl_apiKey"],
		product: "PRO_MULTISITE",
		position: "LEFT",
		theme: "LIGHT",
		locale: window.__HEADERS__.xSite,
		statement: {
			description:
				translations[`${STATEMENT_OBJECT_PREFIX}_statementObjectDescription`],
			name: translations[`${STATEMENT_OBJECT_PREFIX}_statementObjectName`],
			url: `${window.location.origin}/cookies/`,
			updated: translations[`${STATEMENT_OBJECT_PREFIX}_statementObjectUpdated`]
		},
		necessaryCookies: [
			"access_token",
			"refresh_token",
			COOKIE_CUSTOMER_NUMBER,
			CUSTOMER_IDENTIFIER_DISPLAYED,
			COOKIE_ACTIVATED_DISCOUNT_CODE,
			NOTIFY_ME_PRODUCTS_DATA,
			"express:sess",
			"express:sess.sig"
		],
		optionalCookies: [
			{
				name: translations[`${ANALYTICS_OBJECT_PREFIX}_analyticsName`],
				label: translations[`${ANALYTICS_OBJECT_PREFIX}_analyticsLabel`],
				description:
					translations[`${ANALYTICS_OBJECT_PREFIX}_analyticsDescription`],
				cookies: ["_ga", "_gid", "_gat"],
				onAccept: function() {
					window[`ga-disable-${env("TAG_MANAGER_PROPERTY_ID")}`] = false

					TagManager.dataLayer({
						dataLayerName: "PageDataLayer",
						dataLayer: {
							event: "virtualPageview",
							page: {
								path: window.location.pathname,
								country: window.__HEADERS__.xSite
							}
						}
					})

					TagManager.dataLayer({
						dataLayerName: "PageDataLayer",
						dataLayer: {
							event: "analyticsConsent",
							analyticsConsentState: true
						}
					})
				},
				onRevoke: function() {
					window[`ga-disable-${env("TAG_MANAGER_PROPERTY_ID")}`] = true
					TagManager.dataLayer({
						dataLayerName: "PageDataLayer",
						dataLayer: {
							event: "analyticsConsent",
							analyticsConsentState: false
						}
					})
				}
			},
			{
				name: "marketing",
				label: "Marketing",
				description: "Marketing description",
				cookies: [
					"YSC",
					"IDE",
					"VISITOR_INFO1_LIVE",
					"CONSENT",
					"PREF",
					"fr",
					"_fbp"
				],
				onAccept: () => {
					const event = consentChange(COOKIE_CONTROL_MARKETING_CONSENT, true)
					document.dispatchEvent(event)

					// Adding FB-pixels to dataLayer
					TagManager.dataLayer({
						dataLayerName: "PageDataLayer",
						dataLayer: {
							event: "fbpConsent",
							consentState: true
						}
					})
				},
				onRevoke: () => {
					const event = consentChange(COOKIE_CONTROL_MARKETING_CONSENT, false)
					document.dispatchEvent(event)

					// Adding FB-pixels to dataLayer
					TagManager.dataLayer({
						dataLayerName: "PageDataLayer",
						dataLayer: {
							event: "fbpConsent",
							consentState: false
						}
					})
				}
			}
		],
		locales: [
			{
				locale: window.__HEADERS__.xSite.split("-")[0],
				text: {
					title: translations[`${TEXT_OBJECT_PREFIX}_textObjectTitle`],
					intro: translations[`${TEXT_OBJECT_PREFIX}_textObjectIntro`],
					acceptSettings:
						translations[`${TEXT_OBJECT_PREFIX}_textObjectAcceptSettings`],
					rejectSettings:
						translations[`${TEXT_OBJECT_PREFIX}_textObjectRejectSettings`],
					necessaryTitle:
						translations[`${TEXT_OBJECT_PREFIX}_textObjectNecessaryTitle`],
					necessaryDescription:
						translations[
							`${TEXT_OBJECT_PREFIX}_textObjectNecessaryDescription`
						],
					on: translations[`${TEXT_OBJECT_PREFIX}_textObjectOn`],
					off: translations[`${TEXT_OBJECT_PREFIX}_textObjectOff`],
					cornerButton:
						translations[`${TEXT_OBJECT_PREFIX}_textObjectCornerButton`], // used by screen readers
					landmark: translations[`${TEXT_OBJECT_PREFIX}_textObjectLandmark`], // used by screen readers
					removeAbout: true
				},
				statement: {
					description:
						translations[
							`${STATEMENT_OBJECT_PREFIX}_statementObjectDescription`
						],
					name: translations[`${STATEMENT_OBJECT_PREFIX}_statementObjectName`],
					url: `${window.location.origin}/cookies/`,
					updated:
						translations[`${STATEMENT_OBJECT_PREFIX}_statementObjectUpdated`]
				},
				optionalCookies: [
					{
						name: translations[`${ANALYTICS_OBJECT_PREFIX}_analyticsName`],
						label: translations[`${ANALYTICS_OBJECT_PREFIX}_analyticsLabel`],
						description:
							translations[`${ANALYTICS_OBJECT_PREFIX}_analyticsDescription`]
					},
					{
						name: translations[`${MARKETING_OBJECT_PREFIX}_marketingName`],
						label: translations[`${MARKETING_OBJECT_PREFIX}_marketingLabel`],
						description:
							translations[`${MARKETING_OBJECT_PREFIX}_marketingDescription`]
					}
				]
			}
		],
		branding: {
			fontFamily: "Circular",
			backgroundColor: colors["blue-1"],
			toggleText: colors["white"],
			toggleColor: colors["white"],
			toggleBackground: colors["blue-6"],
			removeAbout: true
		},
		accessibility: {
			highlightFocus: true
		}
	}
}

const loadCookieControl = (translations: TranslationsRecord) => {
	const script = document.createElement("script")
	script.type = "text/javascript"
	script.src = "https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js"
	document.body.appendChild(script)

	script.addEventListener("load", e => {
		if (window.CookieControl) {
			window.CookieControl.load(getConfig(translations))
		}
	})
}

export { loadCookieControl }
