import { Translation } from "../@types/graphql.generated"

function translationsArrayToRecord<T extends string>(
	translations: Translation[]
): Record<T, string> {
	return translations.reduce(
		(acc: Record<T, string>, translation) => ({
			...acc,
			[translation.key!]: translation.value
		}),
		{} as any
	)
}

/**
 * Returns the last letters of a locale/language code.
 * I.e. us for en-us
 */
const localeCodeToCountryCode = (localeCode: string) => localeCode.split("-")[1]

/**
 * Capitalizes the last two language characters of a locale/language code.
 * I.e. se-sv for se-SV
 */
const localeCodeCapitalizesLanguage = (localeCode: string) => {
	const [country, language] = localeCode.split("-")
	return `${country}-${language.toUpperCase()}`
}

export {
	translationsArrayToRecord,
	localeCodeToCountryCode,
	localeCodeCapitalizesLanguage
}
