import React from "react"
import TagManager from "react-gtm-module"
import { ListTypes } from "./types"
import {
	CustomerTypeEnum,
	Pagination,
	Product
} from "../../@types/graphql.generated"

interface TrackData {
	userType: CustomerTypeEnum
	products: Product[]
	listType: ListTypes
	pagination?: Pick<Pagination, "skip" | "take" | "count">
}

export const useTrackProductListImpressions = ({
	userType,
	products,
	listType,
	pagination
}: TrackData) => {
	React.useEffect(() => {
		if (!products.length) return

		const _products = products.map((item, index) => ({
			name: item.name,
			id: item.id,
			price: item.price?.netPrice,
			brand: item.brandName,
			category: item.categories?.join("/"),
			list: listType,
			position: index + 1
		}))

		const impressions = pagination?.take
			? _products.slice(-pagination.take)
			: _products

		TagManager.dataLayer({
			dataLayerName: "PageDataLayer",
			dataLayer: {
				ecommerce: null
			}
		})

		TagManager.dataLayer({
			dataLayerName: "PageDataLayer",
			dataLayer: {
				event: "productListImpressions",
				type: userType,
				ecommerce: {
					currencyCode: products[0].price?.currency,
					impressions
				}
			}
		})
	}, [products, userType, pagination, listType])
}
