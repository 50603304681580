import React from "react"
import TagManager from "react-gtm-module"
import { CustomerTypeEnum, Product } from "../../../@types/graphql.generated"

export const useProductViewTracking = (
	product: Product,
	userType: CustomerTypeEnum
) => {
	React.useEffect(() => {
		TagManager.dataLayer({
			dataLayerName: "PageDataLayer",
			dataLayer: {
				ecommerce: null
			}
		})

		TagManager.dataLayer({
			dataLayerName: "PageDataLayer",
			dataLayer: {
				event: "productViewTracking",
				type: userType,
				ecommerce: {
					detail: {
						products: [
							{
								name: product.name,
								id: product.id,
								price: product.price?.netPrice,
								brand: product.brandName,
								category: product.categories?.join("/")
							}
						]
					}
				}
			}
		})
	}, [product, userType])
}
