import React from "react"
import { Flex, Text } from "@focus-nordic/ui-components"
import { translationNamespace } from "../../hooks/useLocales"
import { SITE_MAX_WIDTH } from "../../constants"
import { useLocales } from "../../hooks"
import { useGetMenuSecondaryQuery } from "./operations.generated"

export const FooterSecondary = () => {
	const locales = useLocales(translationNamespace.shared("Footer"), ["orgName"])
	const query = useGetMenuSecondaryQuery()
	return (
		<Flex backgroundColor="grey-6" py={{ _: 2, m: 5.5 }} flexWrap="wrap">
			<Flex
				w={1}
				maxw={SITE_MAX_WIDTH}
				px={{ _: 2, s: 5, l: 8 }}
				justifyContent="space-between"
				flexDirection={{ _: "column-reverse", l: "row" }}
				style={{ margin: "0 auto" }}
			>
				<Flex mr={{ _: 0, m: 8 }} mb={1} mt={{ _: 2.5, l: 1 }} flexShrink={0}>
					<Text textColor="white" as="span" mb={0}>
						&copy;{new Date().getFullYear()} {locales.orgName}
					</Text>
				</Flex>
				{query.data?.footerSecondaryMenu && (
					<Flex as="ul" w={{ _: 1, l: "auto" }} flexWrap="wrap">
						{query.data?.footerSecondaryMenu.map((item, i) => (
							<Flex
								as="li"
								my={1}
								pr={{ _: 2, l: 0 }}
								w={{ _: 0.5, l: "auto" }}
								mr={{
									_: 0,
									l:
										i !==
										(query &&
											query.data &&
											query.data.footerSecondaryMenu &&
											query.data.footerSecondaryMenu.length - 1)
											? 10
											: 0
								}}
								key={i}
							>
								<Text.Link
									to={item?.url || ""}
									target={item?.isExternalLink ? "_blank" : "_self"}
									textColor="grey-3"
									mb={0}
								>
									{item?.title}
								</Text.Link>
							</Flex>
						))}
					</Flex>
				)}
			</Flex>
		</Flex>
	)
}
