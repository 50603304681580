import { Translation } from "../@types/graphql.generated"
import { TranslationsRecord } from "../context"

export const translationsArrayToTranslationsRecord = (
	prefix: string,
	translations: Translation[]
): TranslationsRecord => {
	return translations.reduce(
		(acc, item) => ({
			...acc,
			[`Website_${prefix}_${item?.key}`]: item?.value
		}),
		{}
	)
}
