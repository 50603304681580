import * as React from "react"
import { Flex, Text, MasonryGrid, Image } from "@focus-nordic/ui-components"
import { SITE_MAX_WIDTH } from "../../constants"
import { MediaGridBlock as IMediaGridBlock } from "../../@types/graphql.generated"
import { YouTubeCookieConsentNotice } from "../YouTubeCookieNotice"

const MediaGridBlock: React.FC<IMediaGridBlock> = props => {
	return (
		<Flex id={props.id} as="section" backgroundColor="blue-1" w={1}>
			<Flex
				w={1}
				py={{ _: 4, m: 8 }}
				px={{ _: 2, m: 8 }}
				maxw={SITE_MAX_WIDTH}
				style={{ margin: "0 auto" }}
				flexDirection="column"
			>
				{props.title && (
					<Text variant="headline-4" as="h2" mb={{ _: 3, m: 4 }}>
						{props.title}
					</Text>
				)}
				{props.items && (
					<MasonryGrid>
						{props.items.map(
							(item, i) =>
								item &&
								(item.__typename === "youtubeEmbed" ? (
									<YouTubeCookieConsentNotice ytId={item.ytId} key={i} />
								) : item.__typename === "image" ? (
									<Image
										key={i}
										src={item.src}
										width={item.width}
										height={item.height}
									/>
								) : null)
						)}
					</MasonryGrid>
				)}
			</Flex>
		</Flex>
	)
}

export { MediaGridBlock }
