import * as React from "react"
import { AnchorMenu, Flex } from "@focus-nordic/ui-components"
import { AnchorLinkBlock as IAnchorLinkBlock } from "../../@types/graphql.generated"
import { dataToProps } from "./utils"
import { SITE_MAX_WIDTH } from "../../constants"
import { Z_INDEX } from "../../constants"
import { MENU_HEIGHT } from "../../constants"

const AnchorLinkBlock: React.FC<IAnchorLinkBlock> = props => (
	<Flex
		flexDirection="column"
		style={{ margin: "0 auto" }}
		w={1}
		zIndex={Z_INDEX.anchorLinkListBlock}
		alignItems="center"
	>
		<Flex w={1} maxw={SITE_MAX_WIDTH}>
			{props.links && (
				<AnchorMenu
					links={dataToProps(props.links)}
					offset={MENU_HEIGHT.mobile}
					responsiveOffset={{ m: MENU_HEIGHT.desktop }}
				/>
			)}
		</Flex>
	</Flex>
)

export { AnchorLinkBlock }
