"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Medium = void 0;
var React = __importStar(require("react"));
var Flex_1 = require("../Flex");
var Label = __importStar(require("./Text"));
var Image_1 = require("../Image");
var types_1 = require("./types");
var ProductCardLink_1 = require("./ProductCardLink");
var ProductLabel_1 = require("../ProductLabel");
var Medium = function (props) {
    var _a;
    return (React.createElement(Flex_1.Flex, { flexDirection: "row", w: 1, position: "relative" },
        React.createElement(Flex_1.Flex, { py: 2, alignItems: "flex-start", borderBottom: true, flex: 1 },
            props.image && (React.createElement(Flex_1.Flex, { flexDirection: "column", justifyContent: "space-between", h: 1 },
                React.createElement(Flex_1.Flex, { mr: 2, w: 16, display: "block", p: 1.5, backgroundColor: "grey-1", position: "relative" },
                    ((props.label && props.label !== "NONE") || (props.warranty && props.warranty > 0)) && (React.createElement(Flex_1.Flex, { position: "absolute", flexDirection: "column", alignItems: "flex-start", top: 0, left: 0, zIndex: 1, style: { gap: "2px 0" } },
                        props.label && props.label !== "NONE" && React.createElement(ProductLabel_1.ProductLabel, { label: props.label, locales: props.locales.productLabel }),
                        props.warranty && props.warranty > 0 && (React.createElement(ProductLabel_1.ProductLabel, { label: ProductLabel_1.ProductLabelKeys.WARRANTY, customLabelContent: (_a = props.locales.productLabel.productLabelWarrantyPlural) === null || _a === void 0 ? void 0 : _a.replace("{0}", props.warranty.toString()), locales: props.locales.productLabel })))),
                    React.createElement(Image_1.Image, __assign({ ratio: 1 }, props.image))))),
            React.createElement(Flex_1.Flex, { flexDirection: "column", flex: 1, h: 1 },
                React.createElement(Flex_1.Flex, { justifyContent: "space-between" },
                    React.createElement(ProductCardLink_1.ProductCardLink, { to: props.to, onClick: props.onClick },
                        props.brandName && React.createElement(Label.Brand, null, props.brandName),
                        props.name && (React.createElement(Label.Name, { mb: { _: 1, l: 0 } }, props.name))),
                    React.createElement(Flex_1.Flex, { display: { _: "none", l: "flex" } }, props.productDiscountedPrice && props.productComparisonPrice && !props.isLoggedIn && props.buyable !== types_1.Buyable.Hidden ? (React.createElement(Flex_1.Flex, { flexDirection: "column", alignItems: "flex-end" },
                        React.createElement(Label.Price, { minw: { _: 16, l: "auto" }, price: props.productComparisonPrice, my: 0, textColor: "grey-3", textDecoration: "line-through", fontSize: 1.75, lineHeight: 1.75 }),
                        React.createElement(Label.Price, { minw: { _: 16, l: "auto" }, price: props.productDiscountedPrice, textColor: "red-1" }))) : props.price && React.createElement(Label.Price, { minw: { _: 16, l: "auto" }, price: props.price, alignItems: "flex-start", textColor: props.buyable === types_1.Buyable.Disabled || (props.isLoggedIn && !props.rsp) ? "grey-3" : "black" }))),
                React.createElement(Flex_1.Flex, { flex: 1, flexDirection: { _: "column", l: "row" }, alignItems: { l: "flex-end" }, justifyContent: "space-between" },
                    props.articleNumber && (React.createElement(Flex_1.Flex, { pr: { _: 0, l: 2 }, flex: "1" },
                        React.createElement(Label.Data, { flexDirection: { _: "row", l: "column" }, title: props.locales.articleNumber, data: props.articleNumber }))),
                    props.ean && (React.createElement(Flex_1.Flex, { pr: { _: 0, l: 2 }, flex: "1", maxw: 35 },
                        React.createElement(Label.Data, { flexDirection: { _: "row", l: "column" }, title: props.locales.ean, data: props.ean }))),
                    props.stockStatus && (React.createElement(Flex_1.Flex, { pr: { _: 0, l: 2 }, flex: "1" }, props.stockStatus)),
                    props.fee && (React.createElement(Flex_1.Flex, { pr: { _: 0, l: 2 }, flex: "1" },
                        React.createElement(Label.Data, { flexDirection: { _: "row", l: "column" }, title: props.locales.fee, data: props.fee }))),
                    props.margin && (React.createElement(Flex_1.Flex, { pr: { _: 0, l: 2 }, flex: "1", display: { _: "none", l: "flex" } },
                        React.createElement(Label.Data, { flexDirection: { _: "row", l: "column" }, title: props.locales.margin, data: props.margin }))),
                    props.productDiscountedPrice && props.isLoggedIn && props.rsp ? (React.createElement(Flex_1.Flex, { pr: { _: 0, l: 2 }, flex: "1", display: { _: "none", l: "flex" } },
                        React.createElement(Label.Discount, { flexDirection: "row", title: props.locales.rsp, data: {
                                discountPrice: props.productDiscountedPrice,
                                comparisonPrice: props.productComparisonPrice,
                                startDate: props.startDate,
                                endDate: props.endDate,
                                tooltipId: props.articleNumber,
                                locales: {
                                    campaignMessage: props.locales.campaignMessage,
                                }
                            } }))) : props.rsp && (React.createElement(Flex_1.Flex, { pr: { _: 0, l: 2 }, flex: "1", display: { _: "none", l: "flex" } },
                        React.createElement(Label.Data, { flexDirection: { _: "row", l: "column" }, title: props.locales.rsp, data: props.rsp }))),
                    React.createElement(Flex_1.Flex, { mt: { _: 2, l: 0 }, justifyContent: "space-between", alignItems: "flex-end", flexDirection: { l: "column" }, flexWrap: "wrap" },
                        React.createElement(Flex_1.Flex, { display: { _: "flex", l: "none" } }, props.productDiscountedPrice && props.productComparisonPrice && !props.isLoggedIn && props.buyable !== types_1.Buyable.Hidden ? (React.createElement(Flex_1.Flex, { flexDirection: "column", alignItems: "flex-end" },
                            React.createElement(Label.Price, { minw: { _: 16, l: "auto" }, price: props.productComparisonPrice, my: 0, textColor: "grey-3", textDecoration: "line-through", fontSize: 1.75, lineHeight: 1.75 }),
                            React.createElement(Label.Price, { minw: { _: 16, l: "auto" }, price: props.productDiscountedPrice, textColor: "red-1" }))) : props.price && React.createElement(Label.Price, { minw: { _: 16, l: "auto" }, price: props.price, alignItems: "flex-start", textColor: props.buyable === types_1.Buyable.Disabled || (props.isLoggedIn && !props.rsp) ? "grey-3" : "black" })),
                        props.notifyMe ?
                            React.createElement(Flex_1.Flex, { mt: { _: 1, s: 0 } }, props.notifyMe)
                            : (props.addToCart ?
                                React.createElement(Flex_1.Flex, { mt: { _: 1, s: 0 } }, props.addToCart) :
                                null)),
                    React.createElement(Flex_1.Flex, { mt: 1, justifyContent: "flex-start", display: { _: "flex", l: "none" } },
                        props.margin && (React.createElement(Flex_1.Flex, { pr: 1 },
                            React.createElement(Label.Data, { flexDirection: { _: "row", l: "column" }, title: props.locales.margin, data: props.margin }))),
                        props.productDiscountedPrice && props.isLoggedIn && props.rsp ? (React.createElement(Label.Discount, { flexDirection: "row", title: props.locales.rsp, data: {
                                discountPrice: props.productDiscountedPrice,
                                comparisonPrice: props.productComparisonPrice,
                                startDate: props.startDate,
                                endDate: props.endDate,
                                tooltipId: props.articleNumber,
                                locales: {
                                    campaignMessage: props.locales.campaignMessage,
                                }
                            } })) : props.rsp && (React.createElement(Flex_1.Flex, null,
                            React.createElement(Label.Data, { flexDirection: { _: "row", l: "column" }, title: props.locales.rsp, data: props.rsp })))))))));
};
exports.Medium = Medium;
