import React from "react"
import {
	TextField,
	Flex,
	Button,
	Text,
	Checkbox
} from "@focus-nordic/ui-components"
import { Panel, Summary } from "../../components"
import { useLocales, translationNamespace } from "../../../../hooks"
import { useForm } from "react-hook-form"
import {
	CustomerTypeEnum,
	PaymentaddressInput
} from "../../../../@types/graphql.generated"
import { useCheckout } from "../../context/CheckoutProvider"
import { LocalesContext } from "../../../../context"
import { EMAIL_VALIDATION_PATTERN } from "../../../../constants"
import { localeCodeToCountryCode } from "../../../../utils"
import { PhoneNumberFieldFormValues } from "../../../PhoneNumberField/index"
import { getFieldRulesConfig } from "../../../../utils/fieldsConfig"
import VatNumberField from "../../../VatNumberField"

const PhoneNumberField = React.lazy(() =>
	import(/* webpackChunkName: 'PhoneNumberField' */ "../../../PhoneNumberField")
)

export interface UserInfoStepProps {}

interface FormValues extends PaymentaddressInput, PhoneNumberFieldFormValues {
	invoiceAddressOne: string
	invoiceAddressTwo: string
	invoiceAddressPostalCode: string
	invoiceAddressCity: string
	companyName: string
	orgNumber: string
	vatNumber: string
	customerReferenceNumber: string
}

const UserInfoStep: React.FC<UserInfoStepProps> = props => {
	const locales = useLocales("checkout", [
		"userInfoTitle",
		"companyNamePlaceholder",
		"organizationNumberPlaceholder",
		"vatNumberPlaceholder",
		"firstNamePlaceholder",
		"lastNamePlaceholder",
		"clientReferenceFirstNamePlaceholder",
		"clientReferenceLastNamePlaceholder",
		"mobileNumberPlaceholder",
		"emailAddressPlaceholder",
		"streetAddressPlaceholder",
		"streetAddressTwoPlaceholder",
		"changePostalCode",
		"postalCodePlaceholder",
		"cityPlaceholder",
		"nextCtaText",
		"mobileNumberError",
		"shoppingAsConsumerText",
		"shoppingAsCompanyText",
		"contactInformationTitle",
		"organizationInformationTitle",
		"shippingAdressTitle",
		"invoiceAddressCheckboxLabel",
		"invoiceAddressTitle",
		"clientReferenceNumberPlaceholder"
	])
	const commonLocales = useLocales(translationNamespace.shared("Common"), [
		"exactFieldFormatText",
		"digitsOnlyFieldFormatText",
		"exactFieldLengthRuleText",
		"minLengthRuleText",
		"vatValidationErrorText"
	])
	const checkout = useCheckout()
	const isB2E = checkout.customerType === CustomerTypeEnum.B2E

	const { localeCode } = React.useContext(LocalesContext)

	const { handleSubmit, register, formState, getValues, trigger } = useForm<
		FormValues
	>({
		defaultValues: {
			countryCode: localeCodeToCountryCode(
				localeCode
			).toUpperCase() as FormValues["countryCode"]
		},
		mode: "onChange"
	})
	const { errors } = formState

	const [useDeliveryAsInvoice, setUseDeliveryAsInvoice] = React.useState(true)

	const onSubmit = (data: FormValues) => {
		checkout.setPaymentInput(prev => ({
			...prev,
			address: {
				firstName: data.firstName,
				lastName: data.lastName,
				addressOne: data.addressOne,
				addressTwo: data.addressTwo,
				postalCode: checkout.paymentInput.zipCode!,
				city: data.city,
				mail: data.mail,
				phone: data.phone
			},
			...(data.invoiceAddressOne &&
				data.invoiceAddressPostalCode &&
				data.invoiceAddressCity && {
					invoiceAddress: {
						firstName: data.firstName,
						lastName: data.lastName,
						addressOne: data.invoiceAddressOne,
						addressTwo: data.invoiceAddressTwo,
						postalCode: data.invoiceAddressPostalCode,
						city: data.invoiceAddressCity,
						mail: data.mail
					}
				}),
			...(data.companyName &&
				data.orgNumber &&
				data.vatNumber && {
					companyInformation: {
						companyName: data.companyName,
						customerReferenceNumber: data.customerReferenceNumber,
						orgNumber: data.orgNumber,
						vatNumber: `${fieldRulesConfig?.vatNumber?.props?.prefix ?? ""}${data.vatNumber}${fieldRulesConfig?.vatNumber?.props?.suffix ?? ""}`
					}
				})
		}))

		checkout.goToNextStep()
	}

	const translations = React.useMemo(() => {
		return {
			...locales,
			...commonLocales,
		}
	}, [locales, commonLocales])

	const fieldRulesConfig = React.useMemo(() => {
		return getFieldRulesConfig(localeCode, translations);
	}, [localeCode, translations]);

	return (
		<Flex
			flexDirection="column"
			alignItems="center"
			w={1}
			as="form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<Panel>
				<Panel.Title>{locales.userInfoTitle}</Panel.Title>
				<Text>
					{isB2E
						? locales.shoppingAsCompanyText
						: locales.shoppingAsConsumerText}
				</Text>

				<Text variant="headline-6" mb={2}>
					{locales.contactInformationTitle}
				</Text>
				<Flex
					borderBottom
					borderColor="grey-1"
					pb={2}
					mb={4}
					flexDirection="column"
				>
					<Flex mb={2}>
						<TextField
							name="firstName"
							error={!!errors.firstName}
							inputRef={register({ required: true })}
							label={
								isB2E
									? locales.clientReferenceFirstNamePlaceholder
									: locales.firstNamePlaceholder
							}
						/>
					</Flex>
					<Flex mb={2}>
						<TextField
							name="lastName"
							error={!!errors.lastName}
							inputRef={register({ required: true })}
							label={
								isB2E
									? locales.clientReferenceLastNamePlaceholder
									: locales.lastNamePlaceholder
							}
						/>
					</Flex>
					<Flex flexDirection="column" mb={2}>
						<PhoneNumberField
							name="phone"
							register={register}
							trigger={trigger}
							getValues={getValues}
							error={!!errors.phone}
							helperText={errors.phone && locales.mobileNumberError}
							label={locales.mobileNumberPlaceholder}
						/>
					</Flex>
					<Flex mb={2}>
						<TextField
							name="mail"
							error={!!errors.mail}
							type="email"
							inputRef={register({
								required: true,
								pattern: EMAIL_VALIDATION_PATTERN
							})}
							label={locales.emailAddressPlaceholder}
						/>
					</Flex>
				</Flex>

				{isB2E && (
					<>
						<Text variant="headline-6" mb={2}>
							{locales.organizationInformationTitle}
						</Text>
						<Flex
							borderBottom
							borderColor="grey-1"
							pb={2}
							mb={4}
							flexDirection="column"
						>
							<Flex mb={2}>
								<TextField
									name="companyName"
									error={!!errors.companyName}
									inputRef={register({ required: true })}
									label={locales.companyNamePlaceholder}
								/>
							</Flex>
							<Flex mb={2}>
								<TextField
									name="orgNumber"
									error={!!errors.orgNumber}
									{...(fieldRulesConfig.orgNumber.props && fieldRulesConfig.orgNumber.props)}
									inputRef={register(fieldRulesConfig.orgNumber.registerOptions)}
									helperText={errors.orgNumber && errors.orgNumber.message}
									label={locales.organizationNumberPlaceholder}
								/>
							</Flex>
							<Flex mb={2}>
								<VatNumberField
									name="vatNumber"
									register={register}
									trigger={trigger}
									getValues={getValues}
									vatNumberProps={fieldRulesConfig.vatNumber}
									error={!!errors.vatNumber}
									helperText={errors.vatNumber && errors.vatNumber.message}
									label={locales.vatNumberPlaceholder}
								/>
							</Flex>
							<Flex mb={2}>
								<TextField
									name="customerReferenceNumber"
									error={!!errors.customerReferenceNumber}
									inputRef={register()}
									label={locales.clientReferenceNumberPlaceholder}
								/>
							</Flex>
						</Flex>
					</>
				)}

				<Text variant="headline-6" mb={2}>
					{locales.shippingAdressTitle}
				</Text>
				<Flex
					borderBottom={isB2E}
					borderColor="grey-1"
					pb={isB2E ? 2 : 0}
					mb={isB2E ? 4 : 2}
					flexDirection="column"
				>
					<Flex mb={2}>
						<TextField
							name="addressOne"
							error={!!errors.addressOne}
							inputRef={register({ required: true })}
							label={locales.streetAddressPlaceholder}
						/>
					</Flex>
					{isB2E && (
						<Flex mb={2}>
							<TextField
								name="addressTwo"
								error={!!errors.addressTwo}
								inputRef={register()}
								label={locales.streetAddressTwoPlaceholder}
							/>
						</Flex>
					)}
					<Flex flex={1} w={1} flexDirection={{ _: "column", s: "row" }}>
						<Flex pr={{ s: 2 }} mb={2}>
							<TextField
								name="postalCode"
								error={!!errors.postalCode}
								inputRef={register({ required: true })}
								label={locales.postalCodePlaceholder}
								value={checkout.paymentInput.zipCode}
								disabled
							/>
						</Flex>
						<Flex flex={2} mb={2}>
							<TextField
								name="city"
								error={!!errors.city}
								inputRef={register({ required: true })}
								label={locales.cityPlaceholder}
							/>
						</Flex>
					</Flex>
				</Flex>
				{isB2E && (
					<>
						<Text variant="headline-6" mb={2}>
							{locales.invoiceAddressTitle}
						</Text>
						<Flex flexDirection="column">
							<Flex mb={2}>
								<Checkbox
									label={locales.invoiceAddressCheckboxLabel}
									name="sameAsDeliveryAddress"
									onChange={() =>
										setUseDeliveryAsInvoice(!useDeliveryAsInvoice)
									}
									checked={useDeliveryAsInvoice}
								/>
							</Flex>
							{!useDeliveryAsInvoice && (
								<>
									<Flex mb={2}>
										<TextField
											name="invoiceAddressOne"
											error={!!errors.invoiceAddressOne}
											inputRef={register({ required: true })}
											label={locales.streetAddressPlaceholder}
										/>
									</Flex>
									<Flex mb={2}>
										<TextField
											name="invoiceAddressTwo"
											error={!!errors.invoiceAddressTwo}
											inputRef={register()}
											label={locales.streetAddressTwoPlaceholder}
										/>
									</Flex>
									<Flex
										flex={1}
										w={1}
										flexDirection={{ _: "column", s: "row" }}
									>
										<Flex pr={{ s: 2 }} mb={2}>
											<TextField
												name="invoiceAddressPostalCode"
												error={!!errors.invoiceAddressPostalCode}
												inputRef={register({ required: true })}
												label={locales.postalCodePlaceholder}
											/>
										</Flex>
										<Flex flex={2} mb={2}>
											<TextField
												name="invoiceAddressCity"
												error={!!errors.invoiceAddressCity}
												inputRef={register({ required: true })}
												label={locales.cityPlaceholder}
											/>
										</Flex>
									</Flex>
								</>
							)}
						</Flex>
					</>
				)}
			</Panel>
			<Panel>
				<Summary />
				<Button type="submit">{locales.nextCtaText}</Button>
			</Panel>
		</Flex>
	)
}

export { UserInfoStep }
