import { setContext } from "@apollo/client/link/context"
import { CookieUtils } from "@focus-nordic/www-client/src/links"

export const accessTokenLink = (
	cookieUtils: Pick<CookieUtils, "getTokensFromCookies">
) =>
	setContext((request, context) => {
		const { accessToken } = cookieUtils.getTokensFromCookies()
		return {
			...(accessToken !== "undefined" && {
				headers: {
					...context.headers,
					...(accessToken && {
						authorization: `Bearer ${accessToken}`
					})
				}
			})
		}
	})
