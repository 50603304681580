import React from "react"
import queryString from "query-string"
import { useLocation } from "react-router"
import { Text, Rte } from "@focus-nordic/ui-components"
import { useLocales } from "../../../hooks/useLocales"
import { CheckoutProvider } from "../context/CheckoutProvider"
import { Layout } from "../Layout"
import { Panel } from "../components"
import { useCart } from "../../../hooks/useCart"
import { createMarkup } from "../../../utils"

const View: React.FC = () => {
	const locales = useLocales("checkout", [
		"confirmationTitle",
		"orderNumberTitle",
		"confirmationDescription"
	])
	const location = useLocation()
	const cart = useCart()
	const { orderNumber } = queryString.parse(location.search)

	React.useEffect(() => {
		cart.clearCart && cart.clearCart()
	}, [])

	return (
		<>
			<Panel>
				<Text variant="headline-4">{locales.confirmationTitle}</Text>
				{orderNumber && (
					<Text variant="headline-5">
						{locales.orderNumberTitle}: {orderNumber}
					</Text>
				)}
				<Rte
					dangerouslySetInnerHTML={createMarkup(
						locales.confirmationDescription
					)}
				/>
			</Panel>
		</>
	)
}

const SuccessStatusView: React.FC = () => {
	return (
		<CheckoutProvider currentStepId="status">
			<Layout>
				<View />
			</Layout>
		</CheckoutProvider>
	)
}

export { SuccessStatusView }
