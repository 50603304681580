import React from "react"
import {
	CustomerTypeEnum,
	Pages,
	ReferenceInfo
} from "../../@types/graphql.generated"
import { SearchResult, Text, Image } from "@focus-nordic/ui-components"
import { Pagination, PaginationProps } from "../Pagination"
import { imagePath } from "../../utils"
import { Link, useLocation } from "react-router-dom"
import { linkToLinkProps } from "../../utils"
import { useIsLoggedIn } from "../../hooks"
import { getSearchParam, trackSearchResultClick } from "../SearchBlock"

interface PageRangeProps extends Omit<PaginationProps, "pagination"> {
	pages: Pages
}

interface ResultItemProps {
	page: ReferenceInfo
}

const ResultItem: React.FC<ResultItemProps> = ({ page }) => (
	<SearchResult
		media={
			<Image
				src={imagePath(page.image?.fileName ?? "fallback.png", "16:9=768x432")}
				ratio={5 / 8}
				cover
			/>
		}
	>
		<>
			{page.title ? (
				<Text as="h3" variant="headline-6" textColor="blue-6">
					{page.title}
				</Text>
			) : null}
			{page.preamble ? (
				<Text truncate={{ _: "threeLine", s: "twoLine" }}>{page.preamble}</Text>
			) : null}
		</>
	</SearchResult>
)

const PageRange: React.FC<PageRangeProps> = ({ pages, ...rest }) => {
	const location = useLocation()
	const isLoggedIn = useIsLoggedIn()
	return (
		<>
			{pages.range?.map(page =>
				page.ctaLink ? (
					<Link
						key={page.title}
						style={{ width: "100%" }}
						{...linkToLinkProps(page.ctaLink)}
						onClick={() => {
							if (!location.search) return

							trackSearchResultClick({
								searchTerm: getSearchParam(location.search) ?? "",
								targetUrl: page.ctaLink?.url ?? "",
								userType: isLoggedIn
									? CustomerTypeEnum.B2B
									: CustomerTypeEnum.B2C
							})
						}}
					>
						<ResultItem page={page} />
					</Link>
				) : (
					<ResultItem key={page.title} page={page} />
				)
			)}
			<Pagination pagination={pages.pagination} {...rest} />
		</>
	)
}

export { PageRange }
