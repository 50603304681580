import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type VideoBlockFieldsFragment = (
  { __typename?: 'videoBlock' }
  & Pick<Types.VideoBlock, 'id' | 'type' | 'title' | 'localVideoUrl' | 'externalVideoUrl' | 'thumbnailImage'>
  & { ctaButton?: Types.Maybe<(
    { __typename?: 'link' }
    & Pick<Types.Link, 'isExternal' | 'target' | 'title' | 'url'>
  )> }
);

export const VideoBlockFieldsFragmentDoc = gql`
    fragment videoBlockFields on videoBlock {
  id
  type
  title
  localVideoUrl
  externalVideoUrl
  ctaButton {
    isExternal
    target
    title
    url
  }
  thumbnailImage
}
    `;