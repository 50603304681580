"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Summary = void 0;
var React = __importStar(require("react"));
var Flex_1 = require("../Flex");
var Label = __importStar(require("./Text"));
var Image_1 = require("../Image");
var Text_1 = require("../Text");
var Icon_1 = require("../Icon");
var Summary = function (props) {
    var _a, _b;
    return (React.createElement(Flex_1.Flex, { flexDirection: "row", w: 1, position: "relative" },
        React.createElement(Flex_1.Flex, { py: 2, alignItems: "flex-start", borderBottom: true, borderColor: "grey-3", flex: 1 },
            props.image && (React.createElement(Flex_1.Flex, { flexDirection: "column", justifyContent: "space-between", h: 1 },
                React.createElement(Flex_1.Flex, { mr: 2, mb: 1, w: { _: 10, xs: 12 }, display: "block", p: 1.5, backgroundColor: "grey-1" },
                    React.createElement(Image_1.Image, __assign({ ratio: 1 }, props.image))))),
            React.createElement(Flex_1.Flex, { flexDirection: "column", flex: 1, h: 1 },
                props.brandName && React.createElement(Label.Brand, null, props.brandName),
                props.name && (React.createElement(Label.Name, { mb: { _: 1, xs: 0 } }, props.name)),
                React.createElement(Flex_1.Flex, { flex: 1, flexDirection: { _: "column", xs: "row" }, justifyContent: "space-between", mt: 1 },
                    props.articleNumber && (React.createElement(Flex_1.Flex, { pr: { _: 0, xs: 2 }, flex: "1" },
                        React.createElement(Label.Data, { flexDirection: { _: "row", xs: "column" }, title: props.locales.articleNumber, data: props.articleNumber }))),
                    React.createElement(Flex_1.Flex, { mt: { _: 2, xs: 0 }, flexDirection: { _: "row-reverse", xs: "unset" }, justifyContent: "space-between", flexShrink: 2, pt: 3 },
                        React.createElement(Flex_1.Flex, { w: { _: "auto", xs: 17.5 }, justifyContent: "flex-end", flexWrap: "wrap" }, props.cartItemDiscountedPrice || props.productDiscountedPrice ? (React.createElement(Flex_1.Flex, { flexDirection: "column", alignItems: "flex-end" },
                            React.createElement(Label.Discount, { flexDirection: "row-reverse", data: {
                                    discountPrice: (_a = props.cartItemDiscountedPriceTotal) !== null && _a !== void 0 ? _a : props.productDiscountedPriceTotal,
                                    price: (_b = props.cartItemComparisonPriceTotal) !== null && _b !== void 0 ? _b : props.productComparisonPriceTotal,
                                    isTotalPrice: true
                                } }),
                            props.cartItemDiscountedPrice && (React.createElement(Flex_1.Flex, { alignItems: "center", zIndex: 2 },
                                React.createElement(Icon_1.Icon, { icon: "sell", color: "grey-4", size: 1.5, viewBox: "0 0 12 12" }),
                                React.createElement(Text_1.Text, { as: "span", fontSize: 1.5, ml: 0.5, mb: 0 }, props.locales.discountApplied))))) : props.priceTotal && React.createElement(Label.Price, { price: props.priceTotal }))),
                    React.createElement(Flex_1.Flex, { mt: 1, justifyContent: "flex-end", display: { _: "flex", xs: "none" } }))))));
};
exports.Summary = Summary;
