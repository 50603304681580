"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.size = exports.layoutSize = void 0;
var styled_system_1 = require("styled-system");
var calc_1 = require("../utils/calc");
var calc = function (val) {
    return typeof val === "number" ? (val > 1 ? calc_1.scale.px(val) : val * 100 + "%") : val;
};
var configs = {};
configs.layoutSize = {
    w: {
        property: "width",
        transform: calc
    },
    h: {
        property: "height",
        transform: calc
    },
    maxw: {
        property: "maxWidth",
        transform: calc
    },
    minw: {
        property: "minWidth",
        transform: calc
    },
    minh: {
        property: "minHeight",
        transform: calc
    },
    maxh: {
        property: "maxHeight",
        transform: calc
    },
    size: {
        properties: ["width", "height"],
        transform: calc
    }
};
configs.size = {
    w: {
        property: "width",
        transform: calc_1.scale.px
    },
    h: {
        property: "height",
        transform: calc_1.scale.px
    },
    maxw: {
        property: "maxWidth",
        transform: calc_1.scale.px
    },
    minw: {
        property: "minWidth",
        transform: calc_1.scale.px
    },
    minh: {
        property: "minHeight",
        transform: calc_1.scale.px
    },
    maxh: {
        property: "maxHeight",
        transform: calc_1.scale.px
    },
    size: {
        properties: ["width", "height"],
        transform: calc_1.scale.px
    }
};
exports.layoutSize = styled_system_1.system(configs.layoutSize);
exports.size = styled_system_1.system(configs.size);
