import { openDB, DBSchema, IDBPDatabase } from "idb"
import { CartItem } from "../@types/graphql.generated"
import { cartItemsDbConnect } from "./cartItems"

interface IndexedDB extends DBSchema {
	cartItems: {
		value: CartItem
		key: string
		indexes: { "by-id": CartItem["id"] }
	}
}

export let idb: Promise<IDBPDatabase<IndexedDB>>

// To avoid indexedDb related code from being called server side,
// this initializer function is called in src/App.tsx
// client side code can then interact with the indexedDb instance defined above
export const idbConnect = () => {
	idb = openDB<IndexedDB>("indexed-db", 2, {
		upgrade(db, oldVersion) {
			switch (oldVersion) {
				case 0:
					const productStore = db.createObjectStore("cartItems", {
						keyPath: "id"
					})
					productStore.createIndex("by-id", "id")
					break
			}
		}
	})
}
export { cartItemsDbConnect }
