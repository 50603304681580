"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resize = void 0;
var styled_system_1 = require("styled-system");
var configs = {
    resize: {
        property: "resize"
    }
};
exports.resize = styled_system_1.system(configs);
