import TagManager from "react-gtm-module"
import { CustomerTypeEnum } from "../../@types/graphql.generated"
import { CommonProductFieldsFragment } from "../../operations/product.generated"

export interface TrackData {
	userType: CustomerTypeEnum
	product: CommonProductFieldsFragment
	quantity: number
}

export const trackAddToCartClick = ({
	userType,
	product,
	quantity
}: TrackData) => {
	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			ecommerce: null
		}
	})

	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			type: userType,
			event: "addToCart",
			ecommerce: {
				currencyCode: product.price?.currency,
				add: {
					products: [
						{
							name: product.name,
							id: product.id,
							price: product.price?.netPrice,
							brand: product.brandName,
							category: product.categories?.join("/"),
							variant: product.name,
							quantity
						}
					]
				}
			}
		}
	})
}

export const trackRemoveFromCartClick = ({
	userType,
	product,
	quantity
}: TrackData) => {
	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			ecommerce: null
		}
	})

	TagManager.dataLayer({
		dataLayerName: "PageDataLayer",
		dataLayer: {
			type: userType,
			event: "removeFromCart",
			ecommerce: {
				currencyCode: product.price?.currency,
				remove: {
					products: [
						{
							name: product.name,
							id: product.id,
							price: product.price?.netPrice,
							brand: product.brandName,
							category: product.categories?.join("/"),
							variant: product.name,
							quantity
						}
					]
				}
			}
		}
	})
}
