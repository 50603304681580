import { SelectOption } from "../../../../ui-components/dist/components/Select/Select"
export const countries: SelectOption[] = [
	{ value: "AL", label: "Albania (Shqipëria)" },
	{ value: "AD", label: "Andorra (Andorra)" },
	{ value: "AM", label: "Armenia, (Hayastán)" },
	{ value: "AT", label: "Austria (Österreich)" },
	{ value: "AZ", label: "Azerbaijan (Azərbaycan)" },
	{ value: "BY", label: "Belarus (Bielaruś, Беларусь)" },
	{ value: "BE", label: "Belgium" },
	{
		value: "BA",
		label: "Bosnia and Herzegovina (Bosnia I Hercegovína, Босна и Херцеговина)"
	},
	{ value: "BG", label: "Bulgaria (Bulgariya, България)" },
	{ value: "CZ", label: "Czech Republic (Česká republika)" },
	{ value: "HR", label: "Croatia (Hrvatska)" },
	{ value: "CY", label: "Cyprus (Κύπρος Kıbrıs)" },
	{ value: "DK", label: "Denmark (Danmark)" },
	{ value: "EE", label: "Estonia (Eesti)" },
	{ value: "FO", label: "Faroe Islands (Føroyar)" },
	{ value: "FI", label: "Finland (Suomi)" },
	{ value: "FR", label: "France (France)" },
	{ value: "GR", label: "Greece (Hellas Ελλάς)" },
	{ value: "GL", label: "Greenland" },
	{ value: "GE", label: "Georgia (Sak'art'velo საქართველო)" },
	{ value: "DE", label: "Germany (Deutschland)" },
	{ value: "HU", label: "Hungary (Magyarország)" },
	{ value: "IS", label: "Iceland (Ísland)" },
	{ value: "IE", label: "Ireland (Ireland, Éire)" },
	{ value: "IT", label: "Italy (Italia)" },
	{ value: "XK", label: "Kosovo (Kosova, Косово)" },
	{ value: "LV", label: "Latvia (Latvija)" },
	{ value: "LI", label: "Liechtenstein (Liechtenstein)" },
	{ value: "LT", label: "Lithuania (Lietuva)" },
	{
		value: "LU",
		label: "Luxemburg (Luxembourg, Luxemburg, Lëtezebuerg)"
	},
	{ value: "MT", label: "Malta (Malta)" },
	{ value: "MD", label: "Moldova (Moldova)" },
	{ value: "ME", label: "Montenegro (Crna Gora Црна Гора)" },
	{ value: "NL", label: "Netherlands (Nederland, Nederlân)" },
	{
		value: "MK",
		label: "North Macedonia (Severna Makedonija Северна Македонија)"
	},
	{ value: "NO", label: "Norway, (Norge)" },
	{ value: "PL", label: "Poland (Polska)" },
	{ value: "PT", label: "Portugal (Portugal)" },
	{ value: "RO", label: "Romania (România)" },
	{ value: "RU", label: "Russian (Rossiâ Россия)" },
	{ value: "RS", label: "Serbia (Srbija Србија)" },
	{ value: "ES", label: "Spain (España, Espanya, Espainia)" },
	{ value: "SK", label: "Slovakia (Slovensko)" },
	{ value: "SI", label: "Slovenia (Slovenija)" },
	{ value: "SE", label: "Sweden (Sverige)" },
	{
		value: "CH",
		label: "Switzerland (Suisse, Schweiz, Svizzera, Svizra)"
	},
	{ value: "TR", label: "Turkey (Türkiye)" },
	{ value: "UA", label: "Ukraine (Ukraїna Україна)" },
	{ value: "GB", label: "United Kingdom" },
	{ value: "AX", label: "Åland Island (Ahvenanmaa)" }
]
