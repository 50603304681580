import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type BrandLinkListBlockFieldsFragment = (
  { __typename?: 'brandLinkListBlock' }
  & Pick<Types.BrandLinkListBlock, 'id' | 'type'>
  & { brandItems?: Types.Maybe<Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'title'>
    & { children?: Types.Maybe<Array<(
      { __typename?: 'menu' }
      & Pick<Types.Menu, 'id' | 'isExternalLink' | 'pageType' | 'title' | 'url'>
    )>> }
  )>> }
);

export const BrandLinkListBlockFieldsFragmentDoc = gql`
    fragment brandLinkListBlockFields on brandLinkListBlock {
  id
  type
  brandItems {
    id
    title
    children {
      id
      isExternalLink
      pageType
      title
      url
    }
  }
}
    `;