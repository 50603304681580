import * as Types from '../@types/graphql.generated';

import { AllImageFieldsFragment } from './image.generated';
import { AllLinkFieldsFragment } from './link.generated';
import { AllStockStatusFieldsFragment } from './stockStatus.generated';
import { AllPriceInfoFieldsFragment } from './priceInfo.generated';
import { gql } from '@apollo/client';
import { AllImageFieldsFragmentDoc } from './image.generated';
import { AllLinkFieldsFragmentDoc } from './link.generated';
import { AllStockStatusFieldsFragmentDoc } from './stockStatus.generated';
import { AllPriceInfoFieldsFragmentDoc } from './priceInfo.generated';
import * as Apollo from '@apollo/client';
export type AllCartItemFieldsFragment = (
  { __typename?: 'cartItem' }
  & Pick<Types.CartItem, 'id' | 'fee' | 'margin' | 'currency' | 'quantity'>
  & { rsp?: Types.Maybe<(
    { __typename?: 'priceInfo' }
    & Pick<Types.PriceInfo, 'netPrice' | 'currency'>
  )>, price?: Types.Maybe<(
    { __typename?: 'priceInfo' }
    & Pick<Types.PriceInfo, 'netPrice' | 'currency'>
  )>, discount?: Types.Maybe<(
    { __typename?: 'DiscountModel' }
    & Pick<Types.DiscountModel, 'startDate' | 'endDate'>
    & { comparisonPrice: (
      { __typename?: 'priceInfo' }
      & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
    ), discountedPrice: (
      { __typename?: 'priceInfo' }
      & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
    ) }
  )>, product?: Types.Maybe<(
    { __typename?: 'product' }
    & Pick<Types.Product, 'id' | 'articleNumber' | 'description' | 'ean' | 'name' | 'brandName' | 'packageSize' | 'forcedPackageSize' | 'onDemand' | 'categories' | 'availableItemsCount'>
    & { fee?: Types.Maybe<(
      { __typename?: 'priceInfo' }
      & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
    )>, price?: Types.Maybe<(
      { __typename?: 'priceInfo' }
      & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
    )>, productPreviousPrice?: Types.Maybe<(
      { __typename?: 'priceInfo' }
      & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
    )>, discount?: Types.Maybe<(
      { __typename?: 'product_DiscountModel' }
      & Pick<Types.Product_DiscountModel, 'startDate' | 'endDate'>
      & { comparisonPrice: (
        { __typename?: 'priceInfo' }
        & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
      ), discountedPrice: (
        { __typename?: 'priceInfo' }
        & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
      ) }
    )>, mainImageSmall?: Types.Maybe<(
      { __typename?: 'image' }
      & AllImageFieldsFragment
    )>, link: (
      { __typename?: 'link' }
      & AllLinkFieldsFragment
    ), stockStatus?: Types.Maybe<(
      { __typename?: 'stockStatus' }
      & AllStockStatusFieldsFragment
    )> }
  )> }
);

export type AllProductFieldsFragment = (
  { __typename?: 'product' }
  & Pick<Types.Product, 'id' | 'articleNumber' | 'description' | 'ean' | 'name' | 'brandName' | 'packageSize' | 'forcedPackageSize' | 'onDemand' | 'categories'>
  & { fee?: Types.Maybe<(
    { __typename?: 'priceInfo' }
    & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
  )>, price?: Types.Maybe<(
    { __typename?: 'priceInfo' }
    & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
  )>, mainImageSmall?: Types.Maybe<(
    { __typename?: 'image' }
    & AllImageFieldsFragment
  )>, link: (
    { __typename?: 'link' }
    & AllLinkFieldsFragment
  ), stockStatus?: Types.Maybe<(
    { __typename?: 'stockStatus' }
    & AllStockStatusFieldsFragment
  )> }
);

export type AllCartFieldsFragment = (
  { __typename?: 'cart' }
  & Pick<Types.Cart, 'id' | 'currency' | 'customerNumber' | 'userId' | 'freightCost'>
  & { items?: Types.Maybe<Array<(
    { __typename?: 'cartItem' }
    & Pick<Types.CartItem, 'priceHasChanged'>
    & { lastActiveProductPrice?: Types.Maybe<(
      { __typename?: 'priceInfo' }
      & Pick<Types.PriceInfo, 'netPrice' | 'currency'>
    )> }
    & AllCartItemFieldsFragment
  )>> }
);

export type GetCartQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCartQuery = (
  { __typename?: 'Query' }
  & { cart?: Types.Maybe<(
    { __typename?: 'cart' }
    & AllCartFieldsFragment
  )> }
);

export type UpdateCartItemMutationVariables = Types.Exact<{
  articleNumber: Types.Scalars['String'];
  quantity?: Types.Maybe<Types.Scalars['Int']>;
}>;


export type UpdateCartItemMutation = (
  { __typename?: 'Mutation' }
  & { updateCartItem?: Types.Maybe<(
    { __typename?: 'cart' }
    & AllCartFieldsFragment
  )> }
);

export type UpdateCartItemCustomPriceMutationVariables = Types.Exact<{
  items: Array<Types.CartItemCustomPrice>;
}>;


export type UpdateCartItemCustomPriceMutation = (
  { __typename?: 'Mutation' }
  & { updateCartItemCustomPrice?: Types.Maybe<(
    { __typename?: 'cart' }
    & AllCartFieldsFragment
  )> }
);

export type GetCartSummaryQueryVariables = Types.Exact<{
  cartItems: Array<Types.Maybe<Types.CartItemInput>>;
  customerType?: Types.Maybe<Types.CustomerTypeEnum>;
}>;


export type GetCartSummaryQuery = (
  { __typename?: 'Query' }
  & { cartSummary?: Types.Maybe<(
    { __typename?: 'cartSummary' }
    & Pick<Types.CartSummary, 'id' | 'currency' | 'vatRate'>
    & { itemsPriceSum?: Types.Maybe<(
      { __typename?: 'priceInfo' }
      & AllPriceInfoFieldsFragment
    )>, items?: Types.Maybe<Array<(
      { __typename?: 'cartItem' }
      & AllCartItemFieldsFragment
    )>> }
  )> }
);

export type GetCalculateB2CCartQueryVariables = Types.Exact<{
  cartItems: Array<Types.Maybe<Types.CartItemInput>>;
  customerType: Types.CustomerTypeEnum;
  discountCode?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetCalculateB2CCartQuery = (
  { __typename?: 'Query' }
  & { calculateB2CCart?: Types.Maybe<(
    { __typename?: 'cartSummary' }
    & Pick<Types.CartSummary, 'currency' | 'fullOrderDiscountApplied' | 'id' | 'vatRate'>
    & { fullOrderDiscountAmount?: Types.Maybe<(
      { __typename?: 'priceInfo' }
      & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
    )>, items?: Types.Maybe<Array<(
      { __typename?: 'cartItem' }
      & AllCartItemFieldsFragment
    )>>, itemsPriceSum?: Types.Maybe<(
      { __typename?: 'priceInfo' }
      & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
    )> }
  )> }
);

export const AllProductFieldsFragmentDoc = gql`
    fragment allProductFields on product {
  id
  articleNumber
  description
  ean
  name
  brandName
  packageSize
  forcedPackageSize
  onDemand
  categories
  fee {
    currency
    netPrice
  }
  price {
    currency
    netPrice
  }
  mainImageSmall {
    ...allImageFields
  }
  link {
    ...allLinkFields
  }
  stockStatus {
    ...allStockStatusFields
  }
}
    ${AllImageFieldsFragmentDoc}
${AllLinkFieldsFragmentDoc}
${AllStockStatusFieldsFragmentDoc}`;
export const AllCartItemFieldsFragmentDoc = gql`
    fragment allCartItemFields on cartItem {
  id
  fee
  rsp {
    netPrice
    currency
  }
  price {
    netPrice
    currency
  }
  discount {
    comparisonPrice {
      currency
      netPrice
    }
    discountedPrice {
      currency
      netPrice
    }
    startDate
    endDate
  }
  margin
  currency
  quantity
  product {
    id
    articleNumber
    description
    ean
    name
    brandName
    packageSize
    forcedPackageSize
    onDemand
    categories
    fee {
      currency
      netPrice
    }
    price {
      currency
      netPrice
    }
    productPreviousPrice {
      currency
      netPrice
    }
    discount {
      comparisonPrice {
        currency
        netPrice
      }
      discountedPrice {
        currency
        netPrice
      }
      startDate
      endDate
    }
    mainImageSmall {
      ...allImageFields
    }
    link {
      ...allLinkFields
    }
    stockStatus {
      ...allStockStatusFields
    }
    availableItemsCount
  }
}
    ${AllImageFieldsFragmentDoc}
${AllLinkFieldsFragmentDoc}
${AllStockStatusFieldsFragmentDoc}`;
export const AllCartFieldsFragmentDoc = gql`
    fragment allCartFields on cart {
  id
  currency
  customerNumber
  userId
  freightCost
  items {
    ...allCartItemFields
    lastActiveProductPrice {
      netPrice
      currency
    }
    priceHasChanged
  }
}
    ${AllCartItemFieldsFragmentDoc}`;
export const GetCartDocument = gql`
    query GetCart {
  cart {
    ...allCartFields
  }
}
    ${AllCartFieldsFragmentDoc}`;

/**
 * __useGetCartQuery__
 *
 * To run a query within a React component, call `useGetCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCartQuery(baseOptions?: Apollo.QueryHookOptions<GetCartQuery, GetCartQueryVariables>) {
        return Apollo.useQuery<GetCartQuery, GetCartQueryVariables>(GetCartDocument, baseOptions);
      }
export function useGetCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCartQuery, GetCartQueryVariables>) {
          return Apollo.useLazyQuery<GetCartQuery, GetCartQueryVariables>(GetCartDocument, baseOptions);
        }
export type GetCartQueryHookResult = ReturnType<typeof useGetCartQuery>;
export type GetCartLazyQueryHookResult = ReturnType<typeof useGetCartLazyQuery>;
export type GetCartQueryResult = Apollo.QueryResult<GetCartQuery, GetCartQueryVariables>;
export const UpdateCartItemDocument = gql`
    mutation UpdateCartItem($articleNumber: String!, $quantity: Int) {
  updateCartItem(articleNumber: $articleNumber, quantity: $quantity) {
    ...allCartFields
  }
}
    ${AllCartFieldsFragmentDoc}`;
export type UpdateCartItemMutationFn = Apollo.MutationFunction<UpdateCartItemMutation, UpdateCartItemMutationVariables>;

/**
 * __useUpdateCartItemMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemMutation, { data, loading, error }] = useUpdateCartItemMutation({
 *   variables: {
 *      articleNumber: // value for 'articleNumber'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateCartItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>) {
        return Apollo.useMutation<UpdateCartItemMutation, UpdateCartItemMutationVariables>(UpdateCartItemDocument, baseOptions);
      }
export type UpdateCartItemMutationHookResult = ReturnType<typeof useUpdateCartItemMutation>;
export type UpdateCartItemMutationResult = Apollo.MutationResult<UpdateCartItemMutation>;
export type UpdateCartItemMutationOptions = Apollo.BaseMutationOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>;
export const UpdateCartItemCustomPriceDocument = gql`
    mutation UpdateCartItemCustomPrice($items: [cartItemCustomPrice!]!) {
  updateCartItemCustomPrice(items: $items) {
    ...allCartFields
  }
}
    ${AllCartFieldsFragmentDoc}`;
export type UpdateCartItemCustomPriceMutationFn = Apollo.MutationFunction<UpdateCartItemCustomPriceMutation, UpdateCartItemCustomPriceMutationVariables>;

/**
 * __useUpdateCartItemCustomPriceMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemCustomPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemCustomPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemCustomPriceMutation, { data, loading, error }] = useUpdateCartItemCustomPriceMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useUpdateCartItemCustomPriceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCartItemCustomPriceMutation, UpdateCartItemCustomPriceMutationVariables>) {
        return Apollo.useMutation<UpdateCartItemCustomPriceMutation, UpdateCartItemCustomPriceMutationVariables>(UpdateCartItemCustomPriceDocument, baseOptions);
      }
export type UpdateCartItemCustomPriceMutationHookResult = ReturnType<typeof useUpdateCartItemCustomPriceMutation>;
export type UpdateCartItemCustomPriceMutationResult = Apollo.MutationResult<UpdateCartItemCustomPriceMutation>;
export type UpdateCartItemCustomPriceMutationOptions = Apollo.BaseMutationOptions<UpdateCartItemCustomPriceMutation, UpdateCartItemCustomPriceMutationVariables>;
export const GetCartSummaryDocument = gql`
    query GetCartSummary($cartItems: [cartItemInput]!, $customerType: CustomerTypeEnum) {
  cartSummary(cartItems: $cartItems, customerType: $customerType) {
    id
    currency
    itemsPriceSum {
      ...allPriceInfoFields
    }
    vatRate
    items {
      ...allCartItemFields
    }
  }
}
    ${AllPriceInfoFieldsFragmentDoc}
${AllCartItemFieldsFragmentDoc}`;

/**
 * __useGetCartSummaryQuery__
 *
 * To run a query within a React component, call `useGetCartSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartSummaryQuery({
 *   variables: {
 *      cartItems: // value for 'cartItems'
 *      customerType: // value for 'customerType'
 *   },
 * });
 */
export function useGetCartSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetCartSummaryQuery, GetCartSummaryQueryVariables>) {
        return Apollo.useQuery<GetCartSummaryQuery, GetCartSummaryQueryVariables>(GetCartSummaryDocument, baseOptions);
      }
export function useGetCartSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCartSummaryQuery, GetCartSummaryQueryVariables>) {
          return Apollo.useLazyQuery<GetCartSummaryQuery, GetCartSummaryQueryVariables>(GetCartSummaryDocument, baseOptions);
        }
export type GetCartSummaryQueryHookResult = ReturnType<typeof useGetCartSummaryQuery>;
export type GetCartSummaryLazyQueryHookResult = ReturnType<typeof useGetCartSummaryLazyQuery>;
export type GetCartSummaryQueryResult = Apollo.QueryResult<GetCartSummaryQuery, GetCartSummaryQueryVariables>;
export const GetCalculateB2CCartDocument = gql`
    query GetCalculateB2CCart($cartItems: [cartItemInput]!, $customerType: CustomerTypeEnum!, $discountCode: String) {
  calculateB2CCart(cartItems: $cartItems, customerType: $customerType, discountCode: $discountCode) {
    currency
    fullOrderDiscountAmount {
      currency
      netPrice
    }
    fullOrderDiscountApplied
    id
    items {
      ...allCartItemFields
    }
    itemsPriceSum {
      currency
      netPrice
    }
    vatRate
  }
}
    ${AllCartItemFieldsFragmentDoc}`;

/**
 * __useGetCalculateB2CCartQuery__
 *
 * To run a query within a React component, call `useGetCalculateB2CCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalculateB2CCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalculateB2CCartQuery({
 *   variables: {
 *      cartItems: // value for 'cartItems'
 *      customerType: // value for 'customerType'
 *      discountCode: // value for 'discountCode'
 *   },
 * });
 */
export function useGetCalculateB2CCartQuery(baseOptions?: Apollo.QueryHookOptions<GetCalculateB2CCartQuery, GetCalculateB2CCartQueryVariables>) {
        return Apollo.useQuery<GetCalculateB2CCartQuery, GetCalculateB2CCartQueryVariables>(GetCalculateB2CCartDocument, baseOptions);
      }
export function useGetCalculateB2CCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCalculateB2CCartQuery, GetCalculateB2CCartQueryVariables>) {
          return Apollo.useLazyQuery<GetCalculateB2CCartQuery, GetCalculateB2CCartQueryVariables>(GetCalculateB2CCartDocument, baseOptions);
        }
export type GetCalculateB2CCartQueryHookResult = ReturnType<typeof useGetCalculateB2CCartQuery>;
export type GetCalculateB2CCartLazyQueryHookResult = ReturnType<typeof useGetCalculateB2CCartLazyQuery>;
export type GetCalculateB2CCartQueryResult = Apollo.QueryResult<GetCalculateB2CCartQuery, GetCalculateB2CCartQueryVariables>;