import * as React from "react"
import { Place } from "react-tooltip"
import {
	Flex,
	StockStatus as StockStatusBase,
	Tooltip
} from "@focus-nordic/ui-components"
import { Product, Buyable } from "../../@types/graphql.generated"
import { getStockStatusColorAndToolipText } from "../../utils"
import { translationNamespace, useLocales, useIsLoggedIn } from "../../hooks"
import { useFeatureFlags } from "../../hooks/useFeatureFlags"

interface StockStatusProps
	extends Pick<Product, "id" | "stockStatus" | "onDemand" | "buyable"> {
	textComponent: React.ComponentType
	compactMode?: boolean
	place?: Place
	isExpired?: boolean
}

interface MappedContentProps {
	tooltipText: string[]
}

const MappedContent: React.FC<MappedContentProps> = props => (
	<React.Fragment>
		{props.tooltipText.map((row, index) => (
			<p key={index}>{row}</p>
		))}
	</React.Fragment>
)

const StockStatus: React.FC<StockStatusProps> = props => {
	const locales = useLocales(translationNamespace.shared("StockStatus"), [
		"statusInStock",
		"statusOutOfStock",
		"statusOnDemand",
		"statusConfirmed",
		"statusUnconfirmed",
		"statusPreliminary",
		"statusDate",
		"statusIncoming",
		"statusOnDemandMessage",
		"statusExpired",
		"statusExpiredMessage"
	])
	const {
		status,
		tooltipText,
		additionalText
	} = getStockStatusColorAndToolipText(
		locales,
		props.stockStatus,
		props.onDemand,
		props.isExpired
	)
	const { textComponent: TextComponent } = props

	return (
		<Flex>
			<Flex
				zIndex={2}
				data-tip
				data-for={`stockStatus-${props.id}`}
				alignItems="center"
			>
				<Flex mr={0.75}>
					<StockStatusBase status={status} compactMode={props.compactMode} />
				</Flex>
				<Flex>
					<TextComponent>{additionalText}</TextComponent>
				</Flex>
			</Flex>
			{tooltipText.length > 0 && (
				<Tooltip id={`stockStatus-${props.id}`} place={props.place}>
					<MappedContent tooltipText={tooltipText} />
				</Tooltip>
			)}
		</Flex>
	)
}

/**
 * Stock status based on BTC or BTB
 */
const StockStatusType: React.FC<StockStatusProps> = props => {
	const isLoggedIn = useIsLoggedIn()
	const { features } = useFeatureFlags()

	if (!props.stockStatus) return null

	return (
		<>
			{isLoggedIn ? (
				<>
					<StockStatus
						stockStatus={props.stockStatus}
						onDemand={props.onDemand}
						id={props.id}
						textComponent={props.textComponent}
					/>
				</>
			) : (
				features.isBtcEnabled && (
					<StockStatus
						stockStatus={{
							// will display stock status as out of stock if Buyable === Buyable.Disabled or available items amount is 0
							quantity: (props.buyable === Buyable.Disabled || props.stockStatus?.quantity === "0") ? "0" : "1"
						}}
						onDemand={false}
						id={props.id}
						textComponent={props.textComponent}
					/>
				)
			)}
		</>
	)
}

export { StockStatus, StockStatusType }
