"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.strokeColor = void 0;
var styled_system_1 = require("styled-system");
exports.strokeColor = styled_system_1.system({
    strokeColor: {
        property: "stroke"
    },
    fillColor: {
        property: "fill"
    }
});
