import { ApolloLink, Observable } from "@apollo/client"

// Prevents auth restricted operations from running for unauthorized users
export const authOperationsLink = new ApolloLink((operation, forward) => {
	const isAuthorized =
		typeof operation.getContext().headers?.authorization !== "undefined"

	// black listed auth operations
	const authRequiredOperations = ["GetUser", "GetCart"]

	if (
		authRequiredOperations.includes(operation.operationName) &&
		!isAuthorized
	) {
		// skips auth required operation if the user is not authenticated
		return new Observable(subsriber => {
			subsriber.next({})
		})
	}
	// passes the operation along to the next link
	return forward(operation)
})
