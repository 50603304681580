import React from "react"
import env from "@beam-australia/react-env"
import { Text, Icon } from "@focus-nordic/ui-components"
import { useLocales, translationNamespace } from "../../hooks"
import { removeTokensFromCookies } from "../../utils"
import { useIsLoggedIn } from "../../hooks/useIsLoggedIn"

const Login: React.FC = () => {
	const locales = useLocales(translationNamespace.shared("User"), [
		"logIn",
		"logOut",
		"allIn"
	])

	const isLoggedIn = useIsLoggedIn()

	const handleLogout = (
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) => {
		event.preventDefault()
		removeTokensFromCookies()
		window.location.href = getLogoutUrl()
	}

	const getLogoutUrl = () => {
		return `${env("AUTH_URL")}/account/logout?returnUrl=${encodeURIComponent(env("HOST_URL") + "/" + env("LOGOUT_ROUTE"))}`;
	}

	return (
		<Text.Anchor
			display="flex"
			alignItems="center"
			fontSize={1.75}
			onClick={isLoggedIn ? handleLogout : () => {}}
			href={isLoggedIn ? getLogoutUrl() : `/${env("LOGIN_ROUTE")}`}
			mb={0}
			textColor="blue-6"
		>
			<Icon
				mr={0.5}
				mb={0}
				size={2}
				color="blue-6"
				icon={isLoggedIn ? "logout" : "login"}
			/>
			{isLoggedIn ? locales.logOut : locales.logIn}
		</Text.Anchor>
	)
}

export { Login }
