import React from "react"
import { Text, Flex, LayoutProps } from "@focus-nordic/ui-components"

interface PanelComponent {
	Title: typeof Title
}
export interface PanelProps extends LayoutProps {}

const Panel: React.FC<PanelProps> & PanelComponent = props => (
	<Flex
		backgroundColor="white"
		pt={{ _: 3, l: 6 }}
		pr={{ _: 3, l: 6 }}
		pb={{ _: 3, l: 6 }}
		pl={{ _: 3, l: 6 }}
		w={1}
		maxw={80}
		mt={3}
		flexDirection="column"
		{...props}
	/>
)

const Title: React.FC = props => (
	<Flex borderBottom mb={2}>
		<Text variant="headline-4">{props.children}</Text>
	</Flex>
)

Panel.Title = Title

export { Panel }
