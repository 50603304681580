import * as React from "react"
import { PrimaryMenu } from "@focus-nordic/ui-components"
import env from "@beam-australia/react-env"
import { Cart } from "../Cart"
import { DropDownContainer } from "./DropDownContainer"
import { useFeatureFlags, useIsLoggedIn } from "../../hooks"

const CART_DROPDOWN_ID = "cart"

interface CartDropDownProps {
	initializeCart?: boolean
}

const CartDropDown = (props: CartDropDownProps) => {
	const { features } = useFeatureFlags()
	const isLoggedIn = useIsLoggedIn()

	return (
		<>
			{features.isBtcEnabled || isLoggedIn ? (
				<>
					<PrimaryMenu.ItemDropDown
						dropDownId={CART_DROPDOWN_ID}
						component={<Cart.Indicator />}
					/>
					<PrimaryMenu.DropDown id={CART_DROPDOWN_ID}>
						<DropDownContainer>
							<Cart.MiniCart containerDropdownId={CART_DROPDOWN_ID} initializeCart={props.initializeCart} />
						</DropDownContainer>
					</PrimaryMenu.DropDown>
				</>
			) : (
				<a href={`/${env("LOGIN_ROUTE")}`}>
					<Cart.Indicator />
				</a>
			)}
		</>
	)
}

export { CartDropDown }
