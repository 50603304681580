import React from 'react'
import { Link} from 'react-router-dom' 
import { TableColumn } from "react-data-table-component"
import { Flex, Table, Icon, Text } from "@focus-nordic/ui-components"
import { Panel } from "../../components"
import { Layout, LayoutProps } from "../../Layout"
import { useGetOrderTrackingQuery } from './operations.generated'
import { OrderTrackingEntry } from '../../../../@types/graphql.generated'
import { useLocales } from '../../../../hooks'
import { MY_PAGES_ROUTE } from '@focus-nordic/www-common/constants'
import { ORDER_HISTORY_PAGE_ROUTE } from '../../routes'
import { Loader } from '../../../Loader'
import { cellStyled, cellWithLinkStyled } from '../../styles'

interface OrderTrackingViewProps extends LayoutProps { }

type DataRow = Required<OrderTrackingEntry>

type ColumnsProps = Omit<DataRow, "__typename">

const INITIAL_NUMBER_OF_MONTHS = 6

export const OrderTrackingView: React.FC<OrderTrackingViewProps> = props => {
    const orderTrackingData = useGetOrderTrackingQuery({
        variables: {
            numberOfMonths: INITIAL_NUMBER_OF_MONTHS
        }
    })
    const trackingList = orderTrackingData?.data?.orderTracking as ColumnsProps []
    const locales = useLocales("myPages_orderTracking", [
        "orderTrackingPageTitle",
        "orderTrackingDeliveryDate",
        "orderTrackingOrderNumber",
        "orderTrackingCustomerOrderNumber",
        "orderTrackingShipper",
        "orderTrackingTrackingNumber",
        "orderTrackingTrackOrder",
        "orderTrackingSubtitle",
        "orderTrackingNoRecordsMessage"
    ])

    const columns: TableColumn<ColumnsProps>[] = [
        {
            name: locales.orderTrackingDeliveryDate,
            selector: row => row.deliveryDate?.slice(0, row.deliveryDate.indexOf("T")),
            maxWidth: "150px",
        },
        {
            name: locales.orderTrackingOrderNumber,
            cell: row => (
                <Link to={`${MY_PAGES_ROUTE}/${ORDER_HISTORY_PAGE_ROUTE.path}/${row.orderNumber}`}>{row.orderNumber}</Link>
            ),
            style: cellStyled,
            maxWidth: "165px"
        },
        {
            name: locales.orderTrackingCustomerOrderNumber,
            selector: row => row.customerOrderNumber,
            maxWidth: "225px",
        },
        {
            name: locales.orderTrackingShipper,
            selector: row => row.shipper,
            maxWidth: "165px",
        },
        {
            name: locales.orderTrackingTrackingNumber,
            cell: row => {
                const url = row.trackingUrl?.replace("%1", row.trackingNumber) || "#"

                return (<Text textColor="blue-6" mb={0} fontSize={1.5}>
                    <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {row.trackingNumber}
                    </a>
                </Text>)
            },
            style: cellWithLinkStyled,
            minWidth: "115px",
            maxWidth: "130px",
        },
        {
            cell: row => {
                const url = row.trackingUrl?.replace("%1", row.trackingNumber) || "#"
                return (
                    <Flex alignItems="center">
                        <Icon color="blue-6" icon="link-external" viewBox="0 0 16 16" size={2}/>
                        <Text textColor="blue-6" mb={0} ml={1} fontSize={1.5}>
                            <a
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {locales.orderTrackingTrackOrder}
                            </a>
						</Text>
                    </Flex>
                )
            },
            style: cellWithLinkStyled,
            right: true,
            maxWidth: "150px",
        }
    ]

    return (
        <Layout {...props}>
            <Panel.Title>{locales.orderTrackingPageTitle}</Panel.Title>
            <Panel.Title variant="headline-6" textColor="grey-4" fontWeight="normal" mb={2}>{locales.orderTrackingSubtitle.replace("{number}", `${INITIAL_NUMBER_OF_MONTHS}`)}</Panel.Title>
            <Flex flexDirection="column" py={1} px={2} mx={{_: 1.25, s: 0}} backgroundColor="white">
                 <Table
                    columns={columns}
                    data={trackingList}
                    noRecordsMessage={locales.orderTrackingNoRecordsMessage.replace("{number}", `${INITIAL_NUMBER_OF_MONTHS}`)}
                    progressPending={orderTrackingData.loading}
                    progressComponent={<Loader minh={40}/>}
                />
			</Flex>
        </Layout> 
    )
}