import * as React from "react"
import TagManager from "react-gtm-module"
import env from "@beam-australia/react-env"
import { useLocation } from "react-router"
import { QueryResult } from "@apollo/client"
import { LocalesContext } from "../../../context/LocalesContext"
import {
	GetPageQuery,
	GetPageQueryVariables
} from "../../../operations/page.generated"
import { getCookieConsentByCategory } from "../../../utils"

/**
 *   Hook for pushing analytics data based on PageQueryResult
 */
export const usePageQueryTracking = (
	pageQueryResult: QueryResult<GetPageQuery, GetPageQueryVariables>
) => {
	const location = useLocation()
	const context = React.useContext(LocalesContext)
	React.useEffect(() => {
		if (!pageQueryResult.loading) {
			const path =
				location.search !== ""
					? location.pathname + location.search
					: location.pathname

			const marketingCookieConsent = getCookieConsentByCategory("marketing")

			if (window[`ga-disable-${env("TAG_MANAGER_PROPERTY_ID")}`] !== true) {
				TagManager.dataLayer({
					dataLayerName: "PageDataLayer",
					dataLayer: {
						event: "virtualPageview",
						page: {
							path: path,
							title: pageQueryResult.data?.page?.meta?.title ?? "no title",
							country: context.localeCode
						}
					}
				})

				TagManager.dataLayer({
					dataLayerName: "PageDataLayer",
					dataLayer: {
						event: "analyticsConsent",
						analyticsConsent: true
					}
				})
			}

			if (marketingCookieConsent) {
				TagManager.dataLayer({
					dataLayerName: "PageDataLayer",
					dataLayer: {
						event: "fbpConsent",
						consentState: true
					}
				})
			}
		}
	}, [pageQueryResult, location, context])
}
