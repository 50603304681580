import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import "./vendors.css"
import "./styles.module.css"
import TagManager from "react-gtm-module"
import env from "@beam-australia/react-env"

TagManager.initialize({
	gtmId: env("GOOGLE_TAG_MANAGER_ID"),
	dataLayerName: "PageDataLayer"
})

TagManager.dataLayer({
	dataLayerName: "PageDataLayer",
	dataLayer: {
		originalLocation: window.location.href
	}
})

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate

renderMethod(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
