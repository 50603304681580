import * as React from "react"
import { useGetCartLazyQuery } from "../../operations/cart.generated"
import {
	useUpdateCartItemMutation,
	useUpdateCartItemCustomPriceMutation
} from "../../operations/cart.generated"

import {
	clientCartDefault,
	UpdateCartItemInput,
	CartContext,
	UpdateCartItemCustomPriceInput
} from "./CartContextCommon"

export const CartContextBtbProvider: React.FC = props => {
	const [
		updateCartItemMutation,
		updateCartItemMutationResult
	] = useUpdateCartItemMutation()

	const [
		updateCartItemCustomPriceMutation
	] = useUpdateCartItemCustomPriceMutation()

	// initial BTC cart request
	// Todo: check and pass cart from previous session stored in indexedDb
	const [getCart, getCartResult] = useGetCartLazyQuery()
	const [isInitialized, setIsInitialized] = React.useState(false)

	const cart = getCartResult.data?.cart ?? clientCartDefault

	const updateCartItem = (input: UpdateCartItemInput) => {
		updateCartItemMutation({
			variables: {
				articleNumber: input.product.articleNumber,
				quantity: input.quantity
			},
			optimisticResponse: {}
		})
	}

	const updateCartItemCustomPrice = (input: UpdateCartItemCustomPriceInput) => {
		updateCartItemCustomPriceMutation({
			variables: {
				items: input.map(item => ({
					productIdentifier: item.product.articleNumber,
					customPrice: item.customPrice,
					priceHasChanged: item.priceHasChanged
				}))
			}
		})
	}

	React.useEffect(() => {
		if (getCartResult.data?.cart?.id) {
			setIsInitialized(true)
		}
	}, [getCartResult.data])

	return (
		<CartContext.Provider
			value={{
				cart,
				updateCartItem,
				updateCartItemCustomPrice,
				initCart: () => getCart(),
				loading: updateCartItemMutationResult.loading || getCartResult.loading,
				isInitialized
			}}
		>
			{props.children}
		</CartContext.Provider>
	)
}
