import React from "react"

interface FeatureFlagsContextValue {
	features: {
		isBtcEnabled: boolean
		isMyPagesCheckoutEnabled: boolean
	}
}

const defaultFeatures = {
	isBtcEnabled: false,
	isMyPagesCheckoutEnabled: false
}

export const FeatureFlagsContext = React.createContext<
	FeatureFlagsContextValue
>({
	features: {
		...defaultFeatures
	}
})

export interface FeatureFlagsProviderProps extends FeatureFlagsContextValue {}

export const FeatureFlagsProvider: React.FC<FeatureFlagsProviderProps> = ({
	features,
	...rest
}) => {
	return (
		<FeatureFlagsContext.Provider
			value={{ features: { ...defaultFeatures, ...features } }}
			{...rest}
		/>
	)
}
