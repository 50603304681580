import { LinkProps } from "react-router-dom"
import { Link, LinkTarget } from "../@types/graphql.generated"

export const getLinkTargetAttribute = (target: LinkTarget) =>
	({
		[LinkTarget.Blank]: "_blank",
		[LinkTarget.Self]: "_self",
		[LinkTarget.NoLink]: "_self"
	}[target] || "_self")

export const linkToLinkProps = (link: Link): LinkProps => ({
	to: link.url || "",
	target: getLinkTargetAttribute(link.target)
})
