import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CustomerApplicationBlockFieldsFragment = (
  { __typename?: 'customerApplicationBlock' }
  & Pick<Types.CustomerApplicationBlock, 'id' | 'type'>
  & { countries?: Types.Maybe<Array<(
    { __typename?: 'country' }
    & Pick<Types.Country, 'label' | 'value'>
  )>> }
);

export type CustomerApplicationMutationVariables = Types.Exact<{
  input: Types.CustomerApplicationInput;
}>;


export type CustomerApplicationMutation = (
  { __typename?: 'Mutation' }
  & { customerApplication?: Types.Maybe<(
    { __typename?: 'customerApplicationResult' }
    & Pick<Types.CustomerApplicationResult, 'successDescription' | 'successTitle'>
  )> }
);

export const CustomerApplicationBlockFieldsFragmentDoc = gql`
    fragment customerApplicationBlockFields on customerApplicationBlock {
  id
  type
  countries {
    label
    value
  }
}
    `;
export const CustomerApplicationDocument = gql`
    mutation CustomerApplication($input: customerApplicationInput!) {
  customerApplication(applicationForm: $input) {
    successDescription
    successTitle
  }
}
    `;
export type CustomerApplicationMutationFn = Apollo.MutationFunction<CustomerApplicationMutation, CustomerApplicationMutationVariables>;

/**
 * __useCustomerApplicationMutation__
 *
 * To run a mutation, you first call `useCustomerApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerApplicationMutation, { data, loading, error }] = useCustomerApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerApplicationMutation(baseOptions?: Apollo.MutationHookOptions<CustomerApplicationMutation, CustomerApplicationMutationVariables>) {
        return Apollo.useMutation<CustomerApplicationMutation, CustomerApplicationMutationVariables>(CustomerApplicationDocument, baseOptions);
      }
export type CustomerApplicationMutationHookResult = ReturnType<typeof useCustomerApplicationMutation>;
export type CustomerApplicationMutationResult = Apollo.MutationResult<CustomerApplicationMutation>;
export type CustomerApplicationMutationOptions = Apollo.BaseMutationOptions<CustomerApplicationMutation, CustomerApplicationMutationVariables>;