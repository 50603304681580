import React from "react"
import { LocalesContext } from "../context"
import { localeCodeToCountryCode } from "../utils"
import { getPageTitle } from "../utils/getPageTitle"

export const usePageTitle = (pageTitle: string) => {
	const { localeCode } = React.useContext(LocalesContext)

	return getPageTitle(
		localeCodeToCountryCode(localeCode).toUpperCase(),
		pageTitle
	)
}
