import * as React from "react"
import { VideoBlock as IVideoBlock } from "../../@types/graphql.generated"
import { VideoBanner } from "@focus-nordic/ui-components"
import env from "@beam-australia/react-env"
import { imagePath } from "../../utils"

export interface VideoBlockProps extends IVideoBlock {}

const VideoBlock: React.FC<VideoBlockProps> = ({
	localVideoUrl,
	thumbnailImage,
	...rest
}) => {
	const getFullLocalVideoUrl = (url: string | undefined) => {
		if (url) {
			return url.startsWith("http")
				? localVideoUrl
				: `${env("BLOB_STORAGE_BASE_URL")}${url}`
		}

		return url
	}

	const fullLocalVideoUrl = getFullLocalVideoUrl(localVideoUrl)
	const thumbnailImageUrl =
		thumbnailImage && imagePath(thumbnailImage, "8:3=1536x576")

	return (
		<VideoBanner
			localVideoUrl={fullLocalVideoUrl}
			thumbnailImage={thumbnailImageUrl}
			{...rest}
		/>
	)
}

export { VideoBlock }
