import { colors } from "@focus-nordic/ui-components/dist"

export const cellStyled = {
    color: colors["blue-6"],
    "&:hover": {
        cursor: "pointer",
        textDecoration: "underline"
    }
}

export const cellWithLinkStyled = {
    color: colors["blue-6"],
    "& a:hover": {
        cursor: "pointer",
        textDecoration: "underline"
    }
}