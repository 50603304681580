import * as Types from '../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { user?: Types.Maybe<(
    { __typename?: 'User' }
    & Pick<Types.User, 'email' | 'name' | 'isAdmin' | 'isSeller'>
    & { currentCustomer?: Types.Maybe<(
      { __typename?: 'Customer' }
      & Pick<Types.Customer, 'usingDropship' | 'identifier' | 'name' | 'registrationNumber' | 'email' | 'contact' | 'phoneNumber'>
      & { invoiceAddress?: Types.Maybe<(
        { __typename?: 'CustomerAddress' }
        & Pick<Types.CustomerAddress, 'name' | 'addressOne' | 'addressTwo' | 'postalCode' | 'city' | 'country'>
      )>, deliveryAddresses?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'CustomerAddress' }
        & Pick<Types.CustomerAddress, 'addressOne' | 'addressTwo' | 'city' | 'country' | 'name' | 'postalCode'>
      )>>> }
    )>, availableCustomers?: Types.Maybe<Array<(
      { __typename?: 'ChooseCustomer' }
      & Pick<Types.ChooseCustomer, 'identifier' | 'name'>
    )>> }
  )> }
);


export const GetUserDocument = gql`
    query GetUser {
  user {
    email
    name
    isAdmin
    isSeller
    currentCustomer {
      usingDropship
      identifier
      name
      registrationNumber
      email
      contact
      phoneNumber
      invoiceAddress {
        name
        addressOne
        addressTwo
        postalCode
        city
        country
      }
      deliveryAddresses {
        addressOne
        addressTwo
        city
        country
        name
        postalCode
      }
    }
    availableCustomers {
      identifier
      name
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;