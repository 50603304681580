import React from "react"
import { Flex, Text, Spinner, colors } from "@focus-nordic/ui-components"
import {} from "@focus-nordic//www-common/constants"
import {
	useLocales,
	useCart,
	translationNamespace,
	useIsLoggedIn
} from "../../hooks"
import { cartTotalPrice } from "./utils"
import { LocalesContext } from "../../context"
import { CartSummary } from "../CartSummary"
import { formatPriceInfo } from "../../utils"

const Summary: React.FC = props => {
	const { cart, loading } = useCart()
	const isLoggedIn = useIsLoggedIn()
	const { localeCode } = React.useContext(LocalesContext)

	const cartLocales = useLocales(translationNamespace.shared("Cart"), [
		"checkOut",
		"netPrice",
		"grossPrice",
		"totalPrice",
		"freightCost"
	])

	const productLocales = useLocales(translationNamespace.shared("Product"), [
		"discountApplied"
	])

	const locales = {
		...cartLocales,
		...productLocales
	}

	return (
		<>
			{loading && cart.items?.length === 0 ? (
				<Flex flex={1} justifyContent="center" alignItems="center" minh={40}>
					<Spinner strokeColor={colors["blue-6"]} />
				</Flex>
			) : (
				<Flex flex={1} flexDirection="column">
					{cart.fullOrderDiscountAmount && (
						<Flex borderBottom justifyContent="space-between" mb={2} flexWrap="wrap">
							<Text wordBreak="break-word">{locales.discountApplied}</Text>
							<Text textColor="red-1">- {formatPriceInfo(cart.fullOrderDiscountAmount, localeCode)}</Text>
						</Flex>
					)}
					<Flex pb={2} borderBottom flexDirection="column">
						<Flex justifyContent="space-between" flexWrap="wrap">
							<CartSummary.PriceTitle mr={1}>
								{locales.totalPrice}
							</CartSummary.PriceTitle>
							<CartSummary.PriceTitle>
								{cart?.items && (
									isLoggedIn ?
									cartTotalPrice(cart.items, cart.currency, localeCode) :
									cart.itemsPriceSum && formatPriceInfo(cart.itemsPriceSum, localeCode)
								)}
							</CartSummary.PriceTitle>
						</Flex>
						<Flex justifyContent="flex-end">
							<Text as="span" fontSize={1.5} lineHeight={2} mb={0}>
								{isLoggedIn ? locales.netPrice : locales.grossPrice}
							</Text>
						</Flex>
					</Flex>
					{isLoggedIn && cart.freightCost && (
						<CartSummary.PriceRow
							title={locales.freightCost}
							price={cart.freightCost}
						/>
					)}
				</Flex>
			)}
		</>
	)
}

export { Summary }
