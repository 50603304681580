import {
	Button,
	Text,
	YoutubeEmbed,
	YoutubeEmbedProps,
	Flex
} from "@focus-nordic/ui-components"
import * as React from "react"

import { useLocales, translationNamespace } from "../../hooks/useLocales"
import {
	CookieControlConsentEvent,
	COOKIE_CONTROL_MARKETING_CONSENT
} from "../../constants"
import { getCookieConsentByCategory } from "../../utils"

export interface IYouTubeCookieConsentNotice extends YoutubeEmbedProps {}

export const YouTubeCookieConsentNotice: React.FC<IYouTubeCookieConsentNotice> = props => {
	const locales = useLocales(
		translationNamespace.cookieControl("marketingObject"),
		["marketingNoticeButton", "marketingNoticeMessage"]
	)

	const [marketCookieConsent, setMarketCookieConsent] = React.useState(false)

	React.useEffect(() => {
		setMarketCookieConsent(getCookieConsentByCategory("marketing"))
		function acceptYoutube(e: CookieControlConsentEvent) {
			setMarketCookieConsent(e.detail?.consent ?? false)
		}
		document.addEventListener(COOKIE_CONTROL_MARKETING_CONSENT, acceptYoutube)

		return () => {
			document.removeEventListener(
				COOKIE_CONTROL_MARKETING_CONSENT,
				acceptYoutube
			)
		}
	}, [])

	return (
		<YoutubeEmbed
			ytId={props.ytId}
			placeholder={
				!marketCookieConsent && (
					<YoutubeEmbed.Placeholder>
						<Flex
							alignItems="center"
							justifyContent="center"
							flexDirection="column"
							px={{ _: 4, m: 8 }}
							py={2}
						>
							<Text
								textColor="white"
								textAlign="center"
								mb={{ _: 2, l: 4 }}
								wordBreak="break-word"
							>
								{locales.marketingNoticeMessage}
							</Text>
							<Button
								size={{ _: "small", l: "normal" }}
								variant="white"
								onClick={() => {
									window?.CookieControl?.open()
								}}
								wordBreak="break-word"
							>
								{locales.marketingNoticeButton}
							</Button>
						</Flex>
					</YoutubeEmbed.Placeholder>
				)
			}
		/>
	)
}
