import * as React from "react"
import { useHistory, useLocation } from "react-router"
import TagManager from "react-gtm-module"
import sha256 from "crypto-js/sha256"
import { useGetUserQuery } from "../../../operations/user.generated"
import { CustomerTypeEnum } from "../../../@types/graphql.generated"

export const useUserLoginTracking = () => {
	const location = useLocation()
	const history = useHistory()
	const queryParams = new URLSearchParams(location.search)
	const getUser = useGetUserQuery()
	const userEmail = getUser.data?.user?.email || ""

	React.useEffect(() => {
		const LOGIN = "login"
		if (queryParams.has(LOGIN)) {
			TagManager.dataLayer({
				dataLayerName: "PageDataLayer",
				dataLayer: {
					event: LOGIN,
					userId: sha256(userEmail).toString(),
					type: CustomerTypeEnum.B2B
				}
			})
			queryParams.delete(LOGIN)
			history.replace({
				search: queryParams.toString()
			})
		}
	}, [history, location, queryParams, userEmail])
}
