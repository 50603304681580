"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logo = exports.logoPalettes = void 0;
var React = __importStar(require("react"));
var __1 = require("../");
var styles_1 = require("../../styles");
exports.logoPalettes = {
    color: {
        shutterOne: "#3A91B1",
        shutterTwo: "#A1C2CB",
        shutterThree: "#005487",
        shutterFour: "#C1C3C5",
        titleOne: "#005487",
        titleTwo: "#A6A8AB"
    },
    white: {
        shutterOne: styles_1.colors["white"],
        shutterTwo: styles_1.colors["white"],
        shutterThree: styles_1.colors["white"],
        shutterFour: styles_1.colors["white"],
        titleOne: styles_1.colors["white"],
        titleTwo: styles_1.colors["white"]
    },
    black: {
        shutterOne: styles_1.colors["black"],
        shutterTwo: styles_1.colors["black"],
        shutterThree: styles_1.colors["black"],
        shutterFour: styles_1.colors["black"],
        titleOne: styles_1.colors["black"],
        titleTwo: styles_1.colors["black"]
    }
};
var Logo = function (_a) {
    var palette = _a.palette, rest = __rest(_a, ["palette"]);
    var selectedPalette = exports.logoPalettes[palette];
    return (React.createElement(__1.Svg, __assign({ viewBox: "0 0 135 40" }, rest),
        React.createElement("path", { d: "M15.3821307,0.642915254 C14.3231608,0.954779661 13.2825075,1.28494915 12.2737387,1.64088136 C3.83522111,5.12833898 -1.1848794,13.9893559 0.241100503,22.987322 C0.336075377,23.4802034 0.547055276,24.4754576 0.688160804,25.0944407 L8.00733166,23.1100339 C7.48293467,21.0578305 7.37235678,19.0761356 7.50260804,17.1127458 C7.52024623,13.4707119 8.94419095,9.03071186 10.1971809,7.03749153 C11.6000955,4.41986441 13.4826332,2.19816949 15.7613518,0.555457627 C15.6351709,0.581898305 15.5089899,0.615118644 15.3821307,0.642915254", fill: selectedPalette.shutterOne }),
        React.createElement("path", { d: "M21.7129523,0.200271186 C21.6044095,0.230101695 21.497902,0.25179661 21.3880025,0.284338983 C12.9752638,2.80094915 8.4205402,12.5989153 9.89468593,22.5792542 L11.0547362,22.2653559 C13.2086307,13.7453559 19.576696,6.67620339 28.6549372,4.21586441 C29.513103,3.9819661 30.3760176,3.79891525 31.2328266,3.66332203 C28.4412437,1.73044068 25.1632538,0.516881356 21.7129523,0.200271186", fill: selectedPalette.shutterTwo }),
        React.createElement("path", { d: "M33.8054246,5.89213559 C32.7308518,5.98433898 31.087108,6.17755932 29.2520578,6.57213559 C27.3701985,6.97755932 22.9219824,8.79044068 19.2437412,12.0338305 C15.631304,15.0568814 13.5384648,21.1877288 13.3837915,22.2345085 C16.2153995,19.9124746 21.3813543,17.2968814 26.1321332,16.7667119 C30.9290427,16.2304407 34.6228869,17.0521356 39.6741935,19.5341695 C39.6463794,17.9470508 39.4272588,16.3409492 38.9930879,14.743661 C38.0480879,11.263661 36.2123593,8.25959322 33.8054246,5.89213559", fill: selectedPalette.shutterThree }),
        React.createElement("path", { d: "M25.0340201,38.9297627 C33.0078392,36.7670508 38.5401256,30.0429831 39.551608,22.3575593 C34.5667839,19.6531525 31.9237688,18.5623051 25.7809296,19.2334915 C21.5002764,19.6992542 16.5697236,22.1785763 16.0324372,22.9134915 C19.7839447,24.0029831 22.984598,27.3853559 24.114799,31.5507797 C24.8386432,34.2185763 24.6113819,36.9080678 23.6541709,39.2463729 C24.1154774,39.1548475 24.5747487,39.0538305 25.0340201,38.9297627", fill: selectedPalette.shutterFour }),
        React.createElement("path", { d: "M46.2105678,26.6210847 L46.2105678,9.99871186 L42.6096633,9.99871186 L42.6096633,7.6000678 L46.2105678,7.6000678 L46.2105678,6.45701695 C46.2105678,4.356 46.9547638,2.70108475 48.4431558,1.49227119 C49.7354925,0.497694915 51.9456935,6.77966102e-05 55.0710452,6.77966102e-05 L55.0710452,3.1519322 C53.8153417,3.1519322 52.8981558,3.41633898 52.322201,3.94650847 C51.7550653,4.55125424 51.4714975,5.38786441 51.4714975,6.45701695 L51.4714975,7.6000678 L55.0710452,7.6000678 L55.0710452,9.99871186 L51.4714975,9.99871186 L51.4714975,26.6210847 L46.2105678,26.6210847 Z", fill: selectedPalette.titleOne }),
        React.createElement("path", { d: "M62.7874146,17.0825763 C62.8518618,18.728 62.9475151,19.9761356 63.0730176,20.8269831 C63.1985201,21.6778305 63.5356809,22.4466441 64.0845,23.1347797 C64.6339975,23.8229153 65.4799523,24.1666441 66.6243995,24.1666441 C68.2247261,24.1666441 69.2341734,23.5598644 69.6520628,22.3469831 C70.0706307,21.1334237 70.2802538,19.4161356 70.2802538,17.1944407 C70.2802538,15.0093559 70.0679171,13.2825763 69.6452789,12.0134237 C69.2212839,10.7442712 68.2145503,10.1103729 66.6243995,10.1103729 C64.9867613,10.1571525 63.9379673,10.7910508 63.4780176,12.0134237 C63.0180678,13.2364746 62.7874146,14.9259661 62.7874146,17.0825763 M66.568093,7.48867797 C68.7830427,7.48867797 70.5522889,7.84664407 71.8778668,8.56257627 C73.2034447,9.27850847 74.1545503,10.3381695 74.7311834,11.7415593 C75.3078166,13.1456271 75.5968116,14.9259661 75.5968116,17.0825763 C75.5968116,20.4574915 74.8797513,22.928 73.4483442,24.4941017 C72.0155804,26.0608814 69.6798769,26.8439322 66.4432688,26.8439322 C63.3728668,26.8161356 61.1097513,26.0303729 59.6546005,24.487322 C58.198093,22.9442712 57.4701784,20.4947797 57.4701784,17.1381695 C57.5264849,10.7049492 60.558897,7.48867797 66.568093,7.48867797", fill: selectedPalette.titleOne }),
        React.createElement("path", { d: "M93.9457538,25.8263051 C93.9457538,25.9008814 93.4084673,26.0493559 92.3338945,26.2724068 C91.2593216,26.4954576 89.8896482,26.6303729 88.224196,26.6764746 C85.8993467,26.6764746 84.0568342,26.3517288 82.6986935,25.7008814 C81.3405528,25.0500339 80.3616332,23.987661 79.7619347,22.5144407 C79.1615578,21.0405424 78.8623869,19.0208814 78.8623869,16.4554576 C78.9356533,14.4100339 79.263995,12.7368136 79.8446985,11.4351186 C80.4267588,10.1334237 81.3527638,9.15986441 82.6227136,8.51376271 C83.8926633,7.86766102 85.5879648,7.54427119 87.7086181,7.54427119 C89.0565829,7.5720678 90.2688693,7.68867797 91.3427638,7.89274576 C92.4173367,8.09749153 93.1866332,8.26494915 93.6526884,8.39511864 L94.2245729,11.1422373 C93.8806281,10.9659661 93.2476884,10.7469831 92.3271106,10.4866441 C91.4058543,10.2263051 90.2478392,10.0825763 88.8523869,10.0547797 C87.0193719,10.0547797 85.8009799,10.6588475 85.1972111,11.867661 C84.591407,13.0764746 84.290201,14.6052881 84.290201,16.4554576 C84.290201,18.6866441 84.5378141,20.5015593 85.0357538,21.9008814 C85.5343719,23.3002034 86.5594221,24.0554576 88.1129397,24.1666441 C89.351005,24.1666441 90.415402,24.0602034 91.3081658,23.8459661 C92.2009296,23.6324068 92.9200251,23.3971525 93.4647739,23.1415593 C94.0088442,22.8866441 94.2808794,22.7307119 94.2808794,22.6744407 L93.9457538,25.8263051 Z", fill: selectedPalette.titleOne }),
        React.createElement("path", { d: "M103.085525,21.252339 C103.085525,22.0428475 103.375877,22.6984407 103.957259,23.2184407 C104.538641,23.7391186 105.257058,23.9994576 106.113188,23.9994576 C107.527636,23.9628475 108.535726,23.6272542 109.14153,22.9953898 C109.745299,22.3628475 110.108917,21.8381017 110.228992,21.4197966 L110.228992,7.60013559 L114.234219,7.60013559 L114.234219,26.6211525 L110.228992,26.6211525 L110.228992,24.8503051 C109.866053,25.1574237 109.080475,25.5384407 107.87158,25.9933559 C106.661329,26.4489492 104.992485,26.6767458 102.862334,26.6767458 C101.280324,26.6211525 100.025977,26.1425085 99.1013291,25.2408136 C98.1760025,24.3391186 97.7126608,23.0652203 97.7126608,21.4197966 L97.7126608,7.60013559 L103.085525,7.60013559 L103.085525,21.252339 Z", fill: selectedPalette.titleOne }),
        React.createElement("path", { d: "M123.261716,12.2298983 C123.261716,12.9641356 123.915008,13.515322 125.222269,13.8821017 C126.528852,14.2495593 127.870711,14.5932881 129.247847,14.9139661 C130.624982,15.2346441 131.817595,15.7736271 132.827043,16.5315932 C133.83649,17.2895593 134.363601,18.4631186 134.411088,20.0529492 C134.411088,22.3770169 133.784932,24.0461695 132.534656,25.0590508 C131.283023,26.0726102 129.466289,26.6671864 127.085133,26.8434576 C126.787319,26.8807458 126.33144,26.8997288 125.717495,26.8997288 C124.098852,26.8997288 122.703399,26.7722712 121.531817,26.516 C120.359555,26.2604068 119.545485,26.0488814 119.089606,25.8814237 L118.350158,22.5627797 C118.768726,22.8509153 119.536666,23.2136271 120.652621,23.6502373 C121.768575,24.0875254 123.113148,24.315322 124.684982,24.3343051 C126.359932,24.3343051 127.496917,24.0807458 128.096616,23.5743051 C128.696992,23.0671864 129.01041,22.2746441 129.038902,21.196678 C129.038902,19.9505763 128.618299,19.1275254 127.775736,18.7282034 C126.933852,18.3282034 125.570962,17.9424407 123.687068,17.5709153 C121.803852,17.1987119 120.384656,16.6644746 119.431515,15.9668475 C118.477696,15.2698983 118.001465,14.0421017 118.001465,12.2854915 C118.141214,9.22718644 120.559681,7.59057627 125.256867,7.37701695 C125.414932,7.37701695 125.634053,7.36752542 125.912872,7.34922034 C126.192369,7.33023729 126.391817,7.32074576 126.51257,7.32074576 C128.215334,7.32074576 129.587043,7.44210169 130.629053,7.68345763 C131.671063,7.92549153 132.377947,8.12549153 132.750384,8.28345763 L133.656716,11.3648136 C133.294455,11.1329492 132.582822,10.8468475 131.522495,10.5078644 C130.462168,10.1682034 129.024656,9.99871186 127.210636,9.99871186 C125.982746,9.99871186 125.024857,10.1404068 124.336289,10.4237966 C123.647721,10.7071864 123.28953,11.3092203 123.261716,12.2298983", fill: selectedPalette.titleOne }),
        React.createElement("polygon", { fill: selectedPalette.titleTwo, points: "45.9913116 31.9888814 48.7442261 31.9888814 51.1898291 36.0831186 51.2162864 36.0831186 51.2162864 31.9888814 53.5241759 31.9888814 53.5241759 39.3692203 50.8825176 39.3692203 48.3168392 35.235661 48.2998794 35.235661 48.2998794 39.3692203 45.9913116 39.3692203" }),
        React.createElement("path", { d: "M67.025397,37.7677966 C68.0090653,37.7677966 68.6318291,37.179322 68.6318291,35.6789831 C68.6318291,34.179322 68.0090653,33.5908475 67.025397,33.5908475 C66.042407,33.5908475 65.4182864,34.179322 65.4182864,35.6789831 C65.4182864,37.179322 66.042407,37.7677966 67.025397,37.7677966 M67.025397,31.7305085 C68.4873317,31.7305085 71.1975075,31.8545763 71.1975075,35.6789831 C71.1975075,39.5047458 68.4873317,39.6274576 67.025397,39.6274576 C65.5634623,39.6274576 62.8519296,39.5047458 62.8519296,35.6789831 C62.8519296,31.8545763 65.5634623,31.7305085 67.025397,31.7305085", fill: selectedPalette.titleTwo }),
        React.createElement("path", { d: "M82.9371482,35.2353898 L84.6480528,35.2353898 C85.1175,35.2353898 85.3142337,35.0069153 85.3142337,34.5411525 C85.3142337,33.9838644 85.0150628,33.8489492 84.6141332,33.8489492 L82.9371482,33.8489492 L82.9371482,35.2353898 Z M80.5261432,31.9886102 L85.6066206,31.9886102 C86.297902,31.9886102 86.7171482,32.0604746 87.0502387,32.2570847 C87.5034045,32.5255593 87.8799121,33.2401356 87.8799121,34.0455593 C87.8799121,34.7384407 87.6404397,35.4828475 86.9396608,35.7309831 L86.9396608,35.751322 C87.2985302,35.8767458 87.5895603,36.2475932 87.6309422,36.8882712 L87.7340578,38.5011525 C87.7605151,38.832678 87.8799121,39.1425085 88.033907,39.3696271 L85.4010678,39.3696271 C85.3142337,39.152678 85.3142337,38.832678 85.2721734,37.9743729 C85.2463945,37.4272542 85.0930779,37.0957288 84.6480528,37.0957288 L82.9371482,37.0957288 L82.9371482,39.3696271 L80.5261432,39.3696271 L80.5261432,31.9886102 Z", fill: selectedPalette.titleTwo }),
        React.createElement("path", { d: "M99.6372588,37.5086102 L101.021178,37.5086102 C102.124922,37.5086102 102.569947,36.8475932 102.569947,35.6787797 C102.569947,34.512 102.124922,33.8489492 101.021178,33.8489492 L99.6372588,33.8489492 L99.6372588,37.5086102 Z M97.224897,31.9886102 L101.920048,31.9886102 C103.989143,31.9886102 105.082033,32.9594576 105.082033,35.6787797 C105.082033,38.272678 103.997284,39.3689492 101.970927,39.3689492 L97.224897,39.3689492 L97.224897,31.9886102 Z", fill: selectedPalette.titleTwo }),
        React.createElement("polygon", { fill: selectedPalette.titleTwo, points: "114.437261 39.3691525 116.848266 39.3691525 116.848266 31.9888136 114.437261 31.9888136" }),
        React.createElement("path", { d: "M134.385648,36.496 C134.231653,37.4675254 133.958261,38.2539661 133.360598,38.7909153 C132.769719,39.3285424 131.846427,39.6275254 130.375673,39.6275254 C128.913739,39.6275254 126.203563,39.5048136 126.203563,35.6790508 C126.203563,31.8546441 128.913739,31.7305763 130.375673,31.7305763 C131.828111,31.7305763 134.069518,31.9570169 134.385648,34.8831186 L131.666653,34.8831186 C131.598136,34.355661 131.306427,33.5909153 130.375673,33.5909153 C129.392683,33.5909153 128.76992,34.1793898 128.76992,35.6790508 C128.76992,37.1793898 129.375724,37.7678644 130.256276,37.7678644 C131.016075,37.7678644 131.470598,37.3854915 131.666653,36.496 L134.385648,36.496 Z", fill: selectedPalette.titleTwo })));
};
exports.Logo = Logo;
exports.Logo.defaultProps = {
    palette: "color",
    w: 16,
    h: 5
};
