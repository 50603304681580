import * as Types from '../../../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetCustomerUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomerUsersQuery = (
  { __typename?: 'Query' }
  & { customerUsers?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'UserRoleModel' }
    & Pick<Types.UserRoleModel, 'name' | 'email'>
  )>>> }
);


export const GetCustomerUsersDocument = gql`
    query GetCustomerUsers {
  customerUsers {
    name
    email
  }
}
    `;

/**
 * __useGetCustomerUsersQuery__
 *
 * To run a query within a React component, call `useGetCustomerUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerUsersQuery, GetCustomerUsersQueryVariables>) {
        return Apollo.useQuery<GetCustomerUsersQuery, GetCustomerUsersQueryVariables>(GetCustomerUsersDocument, baseOptions);
      }
export function useGetCustomerUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerUsersQuery, GetCustomerUsersQueryVariables>) {
          return Apollo.useLazyQuery<GetCustomerUsersQuery, GetCustomerUsersQueryVariables>(GetCustomerUsersDocument, baseOptions);
        }
export type GetCustomerUsersQueryHookResult = ReturnType<typeof useGetCustomerUsersQuery>;
export type GetCustomerUsersLazyQueryHookResult = ReturnType<typeof useGetCustomerUsersLazyQuery>;
export type GetCustomerUsersQueryResult = Apollo.QueryResult<GetCustomerUsersQuery, GetCustomerUsersQueryVariables>;