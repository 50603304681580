import * as React from "react"
import { Flex } from "@focus-nordic/ui-components"
import { ProductListBlockFieldsFragment } from "./operations.generated"
import { ProductRange } from "../ProductRange"

export interface ProductListBlockProps extends ProductListBlockFieldsFragment {}

const ProductListBlock: React.FC<ProductListBlockProps> = props => (
	<Flex w={1} justifyContent="center" mb={{ _: 1, m: 8 }} mt={{ _: 0, xl: 8}}>
		<ProductRange {...props} />
	</Flex>
)

export { ProductListBlock }
