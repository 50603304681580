export const API_BASE_URL = "api"
export const PRICE_EXPORT_ENDPOINT = "priceExport"

export const SITEMAP_ENDPOINT = "sitemap"
export const SITEMAP_FILENAME = "sitemap.xml"

export const API_ENDPOINT = {
	priceExport: `${API_BASE_URL}/${PRICE_EXPORT_ENDPOINT}`
}

export const CUSTOMER_ENDPOINT = "Customer"
export const INVOICE_DOCUMENT_ROUTE = "Documents/Get"

export const CHECKOUT_ROUTE = "/checkout"
export const CHECKOUT_SUCCESS_ROUTE = `${CHECKOUT_ROUTE}/success`
export const CHECKOUT_FAILURE_ROUTE = `${CHECKOUT_ROUTE}/failure`

export const MY_PAGES_ROUTE = "/my-pages"

export const GRAPHQL_AUTH_ERROR_CODE = "AUTH_NOT_AUTHENTICATED"
