import React from "react"
import { GetMenuQuery } from "./operations.generated"
import { GetUserQuery } from "../../operations/user.generated"
import { DesktopPrimaryMenu, Logo } from "./DektopPrimaryMenu"
import { DesktopSecondaryMenu } from "./DesktopSecondaryMenu"
import { User } from "./User"
import {
	Flex,
	LayoutProps,
	colors,
	PrimaryMenu,
	breakpoint,
	Svg
} from "@focus-nordic/ui-components"
import { SITE_MAX_WIDTH, Z_INDEX, MENU_HEIGHT } from "../../constants"
import styled, { css } from "styled-components"
import useSSR from "use-ssr"
import { scale } from "@focus-nordic/ui-components"

interface StickyHeaderProps extends LayoutProps {
	sticky: boolean
	visible: boolean
}

// Open issue regarding this error:
// Type of property 'defaultProps' circularly references itself in mapped type
// https://github.com/microsoft/TypeScript/issues/37597
const StickyHeader = styled(Flex)<StickyHeaderProps>`
	position: relative;
	border-bottom: 1px solid transparent;
	transition: 0.25s transform;
	padding: 0 ${scale.px(3)};
	${Logo} {
		${Svg} {
			width: 100%;
		}
		transform-origin: left center;
		${breakpoint("_")`
			width: ${scale.px(14)};
		`}
		${breakpoint("xl")`
			width: ${scale.px(16.75)};
		`}
	}
	${props =>
		props.sticky &&
		css`
			padding: 0;
			border-color: ${colors["grey-3"]};
			transform: translateY(-100%);
			${Logo} {
				width: ${scale.px(14)};
			}
		`}
	${props =>
		props.visible &&
		css`
			position: fixed;
			transform: translateY(0);
		`}
`

interface DesktopHeaderProps extends GetMenuQuery, Partial<GetUserQuery> {}

const DesktopHeader: React.FC<DesktopHeaderProps> = props => {
	const menuWrapperRef = React.useRef<HTMLDivElement>(null)
	const menuRef = React.useRef<HTMLDivElement>(null)
	const [menuWrapperSize, setMenuWrapperSize] = React.useState<
		DOMRect | undefined
	>()
	const { isBrowser } = useSSR()
	const [menuSize, setMenuSize] = React.useState<DOMRect | undefined>()
	const menuVisible = menuWrapperSize
		? Math.abs(menuWrapperSize.top) >= menuWrapperSize.height
		: false
	const menuStikcy = menuSize
		? Math.abs(menuSize.top) >= menuSize.height
		: false

	const scrollHandler = (event: Event) => {
		if (menuWrapperRef.current && menuRef.current) {
			setMenuWrapperSize(menuWrapperRef.current.getBoundingClientRect())
			setMenuSize(menuRef.current.getBoundingClientRect())
		}
	}

	React.useEffect(() => {
		if (isBrowser) {
			window.addEventListener("scroll", scrollHandler)
			return () => {
				window.removeEventListener("scroll", scrollHandler)
			}
		}
	}, [])

	return (
		<PrimaryMenu>
			<Flex
				ref={menuWrapperRef}
				w={1}
				flexDirection="column"
				position="relative"
				display={{ _: "none", l: "flex" }}
			>
				<Flex w={1} flexDirection="column" alignItems="center">
					<DesktopSecondaryMenu mainSecondaryMenu={props.mainSecondaryMenu} />
				</Flex>
				<Flex ref={menuRef} w={1} h={7.5}>
					<StickyHeader
						w={1}
						h={MENU_HEIGHT.desktop}
						backgroundColor="white"
						flexDirection="column"
						alignItems="center"
						top={0}
						zIndex={Z_INDEX.primaryMenu}
						sticky={menuStikcy}
						visible={menuVisible}
					>
						<DesktopPrimaryMenu mainPrimaryMenu={props.mainPrimaryMenu} isHeaderSticky={menuStikcy} />
					</StickyHeader>
				</Flex>
				<Flex w={1} justifyContent="center" borderBottom>
					<Flex
						w={1}
						maxw={SITE_MAX_WIDTH}
						px={2}
						my={1.25}
						h={3}
						justifyContent="flex-end"
					>
						<User user={props.user} />
					</Flex>
				</Flex>
			</Flex>
		</PrimaryMenu>
	)
}

export { DesktopHeader }
