import React from "react"
import { Flex } from "@focus-nordic/ui-components"
import { useLocales } from "../../../hooks"
import { useCart } from "../../../hooks/useCart"
import { formatPriceInfo } from "../../../utils/formatters"
import { useCheckout } from "../context/CheckoutProvider"
import { LocalesContext } from "../../../context/LocalesContext"
import { vatOfGrossPrice } from "../../../utils"
import { CartSummary } from "../../CartSummary"

const Summary: React.FC = () => {
	const locales = useLocales("checkout", [
		"includeVatTitle",
		"freightCostTitle",
		"subtotalTitle",
		"costTitle",
		"vatTitle",
		"excludeVatTitle"
	])

	const { localeCode } = React.useContext(LocalesContext)

	const { cart } = useCart()
	const checkout = useCheckout()

	return (
		<Flex flexDirection="column" w={1}>
			<Flex flexDirection="column" mb={1.5}>
				{cart.itemsPriceSum && (
					<CartSummary.PriceRow
						title={locales.subtotalTitle}
						subtitle={!!cart.vatRate ? locales.includeVatTitle : locales.excludeVatTitle}
						price={formatPriceInfo({
							currency: cart.itemsPriceSum.currency,
							netPrice: cart.fullOrderDiscountAmount ? 
								cart.itemsPriceSum.netPrice + cart.fullOrderDiscountAmount.netPrice : 
								cart.itemsPriceSum.netPrice
						}, localeCode)}
					/>
				)}
				{cart.fullOrderDiscountAmount && (
					<CartSummary.PriceRow
						title="Discounted amount"
						price={formatPriceInfo(cart.fullOrderDiscountAmount, localeCode)}
						isDiscountAmount
					/>
				)}
				{checkout.selectedFreight?.deliveryOption && (
					<CartSummary.PriceRow
						title={locales.freightCostTitle}
						subtitle={locales.includeVatTitle}
						price={formatPriceInfo(
							checkout.selectedFreight.deliveryOption.price,
							localeCode
						)}
					/>
				)}
				{!!cart.vatRate && cart.itemsPriceSum && (
					<CartSummary.PriceRow
						title={locales.vatTitle}
						price={formatPriceInfo(
							{
								netPrice: vatOfGrossPrice(
									cart.itemsPriceSum.netPrice +
										(checkout.selectedFreight?.deliveryOption?.price.netPrice ??
											0),
									cart.vatRate
								),
								currency: cart.itemsPriceSum.currency
							},
							localeCode
						)}
					/>
				)}
			</Flex>
			<Flex justifyContent="space-between" mb={3} pb={1.5} borderBottom>
				<CartSummary.DataTitle textColor="black" fontWeight="bold">
					{locales.costTitle}
				</CartSummary.DataTitle>
				{cart.itemsPriceSum && (
					<CartSummary.SummaryTitle textColor="black" fontWeight="bold">
						{formatPriceInfo(
							{
								netPrice:
									(cart.itemsPriceSum.netPrice ?? 0) +
									(checkout.selectedFreight?.deliveryOption?.price.netPrice ??
										0),
								currency: cart.itemsPriceSum.currency
							},
							localeCode
						)}
					</CartSummary.SummaryTitle>
				)}
			</Flex>
		</Flex>
	)
}

export { Summary }
