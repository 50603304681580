"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GalleryBannerItemSize = exports.AlignmentType = void 0;
var AlignmentType;
(function (AlignmentType) {
    AlignmentType["Center"] = "CENTER";
    AlignmentType["Right"] = "RIGHT";
    AlignmentType["Left"] = "LEFT";
})(AlignmentType = exports.AlignmentType || (exports.AlignmentType = {}));
var GalleryBannerItemSize;
(function (GalleryBannerItemSize) {
    GalleryBannerItemSize["Large"] = "LARGE";
    GalleryBannerItemSize["Medium"] = "MEDIUM";
    GalleryBannerItemSize["Small"] = "SMALL";
})(GalleryBannerItemSize = exports.GalleryBannerItemSize || (exports.GalleryBannerItemSize = {}));
