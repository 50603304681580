import React from "react"
import env from "@beam-australia/react-env"
import { getLoadScriptHook } from "./getLoadScriptHook"

const useApi = getLoadScriptHook<typeof google>({
	scriptUrl: `https://maps.googleapis.com/maps/api/js?key=${env(
		"GOOGLE_API_KEY"
	)}&libraries=places`,
	windowNamespace: "google"
})

export const useGoogleMapsApi = () => {
	const api = useApi()
	const mapsApi = api.data?.maps
	const [placesService, setPlaceService] = React.useState<
		google.maps.places.PlacesService
	>()
	const [autoCompleteService, setAutoCompleteService] = React.useState<
		google.maps.places.AutocompleteService
	>()

	React.useEffect(() => {
		if (mapsApi) {
			setPlaceService(
				new mapsApi.places.PlacesService(document.createElement("div"))
			)
			setAutoCompleteService(new mapsApi.places.AutocompleteService())
		}
	}, [mapsApi])

	return {
		...api,
		placesService,
		autoCompleteService
	}
}
