import React from "react"
import { loadeScript, LoadScriptArgs } from "../utils"

export function getLoadScriptHook<PromiseResolveValue>(args: LoadScriptArgs) {
	let promise: Promise<PromiseResolveValue> | undefined
	const callContext = { promise }

	return function useLoadScript() {
		const loadeScriptPromise = loadeScript<PromiseResolveValue>(
			callContext,
			args
		)
		const [isLoading, setIsLoading] = React.useState(true)
		const [data, setData] = React.useState<PromiseResolveValue>()

		const [error, setError] = React.useState()

		React.useEffect(() => {
			loadeScriptPromise
				.then(data => {
					setData(data)
					setIsLoading(false)
				})
				.catch(e => {
					setError(e)
					setIsLoading(false)
				})
		}, [])

		return {
			isLoading,
			error,
			data
		}
	}
}
