"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockStatus = void 0;
var React = __importStar(require("react"));
var Icon_1 = require("../Icon");
var StockStatus = function (_a) {
    var status = _a.status, compactMode = _a.compactMode, rest = __rest(_a, ["status", "compactMode"]);
    var commonProps = {
        marginRight: 0.75,
        size: compactMode ? 1.5 : 2
    };
    return ({
        "in-stock": (React.createElement(Icon_1.Icon, __assign({ icon: "check-circle", color: "green-1" }, commonProps, rest))),
        "low-stock": (React.createElement(Icon_1.Icon, __assign({ icon: "info-circle", color: "orange" }, commonProps, rest))),
        "out-of-stock": (React.createElement(Icon_1.Icon, __assign({ icon: "close-circle", color: "red-1" }, commonProps, rest)))
    }[status] || null);
};
exports.StockStatus = StockStatus;
