"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
var constants_1 = require("../constants");
var breakpointList = Object.keys(constants_1.breakpoints).reduce(function (acc, key) { return __spreadArray(__spreadArray([], acc), [constants_1.breakpoints[key] + "px"]); }, []);
var breakpoints = Object.assign(breakpointList);
breakpoints.xxs = breakpoints[0];
breakpoints.xs = breakpoints[1];
breakpoints.s = breakpoints[2];
breakpoints.m = breakpoints[3];
breakpoints.l = breakpoints[4];
breakpoints.xl = breakpoints[5];
breakpoints.xxl = breakpoints[6];
exports.theme = {
    breakpoints: breakpoints,
    colors: constants_1.colors,
    borderColors: constants_1.borderColors,
    darkMode: false
};
