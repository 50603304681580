import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type MediaAssetsBlockFieldsFragment = (
  { __typename?: 'mediaAssetsBlock' }
  & Pick<Types.MediaAssetsBlock, 'id' | 'type' | 'title'>
  & { assets?: Types.Maybe<Array<(
    { __typename?: 'file' }
    & Pick<Types.File, 'label' | 'src' | 'type'>
  )>> }
);

export const MediaAssetsBlockFieldsFragmentDoc = gql`
    fragment mediaAssetsBlockFields on mediaAssetsBlock {
  id
  type
  title
  assets {
    label
    src
    type
  }
}
    `;