import React from 'react'
import { TableColumn } from "react-data-table-component"
import { Flex, Table } from "@focus-nordic/ui-components"
import { Panel } from "../../components"
import { Layout, LayoutProps } from "../../Layout"
import { useGetCustomerUsersQuery } from './operations.generated'
import { UserRoleModel } from '../../../../@types/graphql.generated'
import { useLocales, translationNamespace } from '../../../../hooks'
import { Loader } from '../../../Loader'

interface UsersOverviewViewProps extends LayoutProps { }

type DataRow = Required<UserRoleModel>

type ColumnsProps = Omit<DataRow, "__typename">

export const UsersOverviewView: React.FC<UsersOverviewViewProps> = props => {
    const usersData = useGetCustomerUsersQuery()
    const users = usersData?.data?.customerUsers as ColumnsProps []
    const locales = useLocales("myPages_usersOverview", [
        "usersOverviewPageTitle",
        "usersOverviewFullName",
        "usersOverviewEmail",
    ])

    const sharedLocales = useLocales(translationNamespace.shared("Common"), ["noRecordsMessage"])

    const columns: TableColumn<ColumnsProps>[] = [
        {
            name: locales.usersOverviewFullName,
            selector: row => row.name,
            minWidth: "200px",
            maxWidth: "250px",
        },
        {
            name: locales.usersOverviewEmail,
            selector: row => row.email,
            minWidth: "300px",
            maxWidth: "350px",
            grow: 2
        },
    ]

    return (
        <Layout {...props}>
            <Panel.Title>{locales.usersOverviewPageTitle}</Panel.Title>
            <Flex flexDirection="column" py={1} px={2} mx={{_: 1.25, s: 0}} backgroundColor="white">
                <Table
                    columns={columns}
                    data={users}
                    noRecordsMessage={sharedLocales.noRecordsMessage}
                    progressPending={usersData.loading}
                    progressComponent={<Loader minh={40}/>}
                />
			</Flex>
        </Layout> 
    )
}