import * as Types from '../@types/graphql.generated';

import { gql } from '@apollo/client';
export type AllPriceInfoFieldsFragment = (
  { __typename?: 'priceInfo' }
  & Pick<Types.PriceInfo, 'currency' | 'netPrice'>
);

export const AllPriceInfoFieldsFragmentDoc = gql`
    fragment allPriceInfoFields on priceInfo {
  currency
  netPrice
}
    `;