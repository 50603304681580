import * as Types from '../@types/graphql.generated';

import { gql } from '@apollo/client';
export type AllImageFieldsFragment = (
  { __typename?: 'image' }
  & Pick<Types.Image, 'alt' | 'height' | 'src' | 'width'>
);

export const AllImageFieldsFragmentDoc = gql`
    fragment allImageFields on image {
  alt
  height
  src
  width
}
    `;