import React from "react"
import TagManager from "react-gtm-module"
import { CartItem, CustomerTypeEnum } from "../@types/graphql.generated"

export const useCheckoutTracking = (
	userType: CustomerTypeEnum,
	step: number,
	items?: CartItem[]
) => {
	React.useEffect(() => {
		const products =
			step === 1
				? items?.map(item => ({
						name: item.product?.name,
						id: item.product?.id,
						price: item.product?.price?.netPrice,
						brand: item.product?.brandName,
						category: item.product?.categories?.join("/"),
						variant: item.product?.name,
						quantity: item.quantity
				  }))
				: null

		TagManager.dataLayer({
			dataLayerName: "PageDataLayer",
			dataLayer: {
				ecommerce: null
			}
		})

		TagManager.dataLayer({
			dataLayerName: "PageDataLayer",
			dataLayer: {
				event: "checkout",
				type: userType,
				ecommerce: {
					checkout: {
						actionField: {
							step
						},
						...(products && { products })
					}
				}
			}
		})
	}, [userType, step])
}
