import * as Types from '../../../../@types/graphql.generated';

import { AllPriceInfoFieldsFragment } from '../../../../operations/priceInfo.generated';
import { gql } from '@apollo/client';
import { AllPriceInfoFieldsFragmentDoc } from '../../../../operations/priceInfo.generated';
import * as Apollo from '@apollo/client';
export type GetOrderOverviewQueryVariables = Types.Exact<{
  orderNumber: Types.Scalars['String'];
}>;


export type GetOrderOverviewQuery = (
  { __typename?: 'Query' }
  & { orderOverview?: Types.Maybe<(
    { __typename?: 'OrderOvervewModel' }
    & Pick<Types.OrderOvervewModel, 'orderNumber' | 'orderDate' | 'paymentMethod'>
    & { billingAddress: (
      { __typename?: 'OrderAddressNullableModel' }
      & Pick<Types.OrderAddressNullableModel, 'name' | 'addressOne' | 'postalCode' | 'city' | 'country'>
    ), deliveryAddress: (
      { __typename?: 'OrderAddressNullableModel' }
      & Pick<Types.OrderAddressNullableModel, 'name' | 'addressOne' | 'postalCode' | 'city' | 'country'>
    ), totalPrice: (
      { __typename?: 'priceInfo' }
      & AllPriceInfoFieldsFragment
    ), productsPrice?: Types.Maybe<(
      { __typename?: 'priceInfo' }
      & AllPriceInfoFieldsFragment
    )>, copyFee?: Types.Maybe<(
      { __typename?: 'priceInfo' }
      & AllPriceInfoFieldsFragment
    )>, recycleFee?: Types.Maybe<(
      { __typename?: 'priceInfo' }
      & AllPriceInfoFieldsFragment
    )>, chemicalFee?: Types.Maybe<(
      { __typename?: 'priceInfo' }
      & AllPriceInfoFieldsFragment
    )>, products?: Types.Maybe<Array<(
      { __typename?: 'OrderProductOverview' }
      & Pick<Types.OrderProductOverview, 'articleNumber' | 'vendorItemNumber' | 'ean' | 'brandName' | 'productName' | 'orderedQuantity' | 'deliveredQuantity'>
      & { feePerUnit?: Types.Maybe<(
        { __typename?: 'priceInfo' }
        & AllPriceInfoFieldsFragment
      )>, pricePerUnit: (
        { __typename?: 'priceInfo' }
        & AllPriceInfoFieldsFragment
      ), totalPrice: (
        { __typename?: 'priceInfo' }
        & AllPriceInfoFieldsFragment
      ), productPageUrl: (
        { __typename?: 'link' }
        & Pick<Types.Link, 'url'>
      ), productImage?: Types.Maybe<(
        { __typename?: 'image' }
        & Pick<Types.Image, 'src'>
      )> }
    )>> }
  )> }
);


export const GetOrderOverviewDocument = gql`
    query GetOrderOverview($orderNumber: String!) {
  orderOverview(orderNumber: $orderNumber) {
    orderNumber
    orderDate
    paymentMethod
    billingAddress {
      name
      addressOne
      postalCode
      city
      country
    }
    deliveryAddress {
      name
      addressOne
      postalCode
      city
      country
    }
    totalPrice {
      ...allPriceInfoFields
    }
    productsPrice {
      ...allPriceInfoFields
    }
    copyFee {
      ...allPriceInfoFields
    }
    recycleFee {
      ...allPriceInfoFields
    }
    chemicalFee {
      ...allPriceInfoFields
    }
    products {
      articleNumber
      vendorItemNumber
      ean
      brandName
      productName
      feePerUnit {
        ...allPriceInfoFields
      }
      pricePerUnit {
        ...allPriceInfoFields
      }
      orderedQuantity
      deliveredQuantity
      totalPrice {
        ...allPriceInfoFields
      }
      productPageUrl {
        url
      }
      productImage {
        src
      }
    }
  }
}
    ${AllPriceInfoFieldsFragmentDoc}`;

/**
 * __useGetOrderOverviewQuery__
 *
 * To run a query within a React component, call `useGetOrderOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderOverviewQuery({
 *   variables: {
 *      orderNumber: // value for 'orderNumber'
 *   },
 * });
 */
export function useGetOrderOverviewQuery(baseOptions?: Apollo.QueryHookOptions<GetOrderOverviewQuery, GetOrderOverviewQueryVariables>) {
        return Apollo.useQuery<GetOrderOverviewQuery, GetOrderOverviewQueryVariables>(GetOrderOverviewDocument, baseOptions);
      }
export function useGetOrderOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderOverviewQuery, GetOrderOverviewQueryVariables>) {
          return Apollo.useLazyQuery<GetOrderOverviewQuery, GetOrderOverviewQueryVariables>(GetOrderOverviewDocument, baseOptions);
        }
export type GetOrderOverviewQueryHookResult = ReturnType<typeof useGetOrderOverviewQuery>;
export type GetOrderOverviewLazyQueryHookResult = ReturnType<typeof useGetOrderOverviewLazyQuery>;
export type GetOrderOverviewQueryResult = Apollo.QueryResult<GetOrderOverviewQuery, GetOrderOverviewQueryVariables>;