import * as React from "react"
import { Flex, Text, Hero, Image } from "@focus-nordic/ui-components"
import { imagePath } from "../../utils"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { CategoryHeroBlock as ICategoryHeroBlock } from "../../@types/graphql.generated"

const CategoryHeroBlock: React.FC<ICategoryHeroBlock> = props => {
	const locales = useLocales(translationNamespace.shared("imageData"), [
		"photographer"
	])
	const sharedLocales = useLocales(translationNamespace.shared("Common"), [
		"readMore"
	])

	return (
		<Hero
			minh={props.image ? { _: 14, m: 30, l: 40.5 } : { _: 14, m: 20, l: 25 }}
			media={
				props.image ? (
					<Image
						src={imagePath(props.image.fileName, "3:2=384x256")}
						alt={
							props.image.meta && props.image.meta.alt
								? props.image.meta.alt
								: ""
						}
						srcSet={[
							`${imagePath(props.image.fileName, "3:2=384x256", true)}`,
							`${imagePath(props.image.fileName, "3:2=768x512", true)}`
						]}
						responsiveSrcSet={{
							s: [
								`${imagePath(props.image.fileName, "16:9=768x432", true)}`,
								`${imagePath(props.image.fileName, "16:9=1536x864", true)}`
							],
							l: [
								`${imagePath(props.image.fileName, "8:3=1536x576", true)}`,
								`${imagePath(props.image.fileName, "8:3=3072x1152", true)}`
							]
						}}
					/>
				) : (
					undefined
				)
			}
			px={2}
			bgOverlay={{ variant: "gradient", opacity: 0.35 }}
			caption={
				props.image?.meta?.author
					? `${locales.photographer} ${props.image?.meta?.author}`
					: undefined
			}
		>
			<Flex flexDirection="column">
				<Text variant="headline-2" as="h1" mb={0.5} textColor="white">
					{props.title}
				</Text>
				{props.preamble && (
					<Flex maxw={{ _: 1, m: 60 }} flexDirection="column">
						<Text textColor="white" truncate="twoLine" mb={0}>
							{props.preamble}
						</Text>
					</Flex>
				)}
				{props.readMoreAnchor && (
					<Text.Anchor
						href={props.readMoreAnchor.url}
						textColor="white"
						mt={2}
						style={{ textDecoration: "underline" }}
					>
						{props.readMoreAnchor.title || sharedLocales.readMore}
					</Text.Anchor>
				)}
			</Flex>
		</Hero>
	)
}

export { CategoryHeroBlock }
