import {
	Flex,
	Button,
	Radio,
	Select,
	Text,
	colors,
	scale,
	Collapse,
	Spinner
} from "@focus-nordic/ui-components"
import React, { useState } from "react"
import styled from "styled-components"
import { GetDeliveryOptionsQuery } from "../../operations.generated"
import { useForm, Resolver } from "react-hook-form"
import { PaymentInput } from "../../../../@types/graphql.generated"
import { useLocales } from "../../../../hooks"
import { Summary, Panel } from "../../components"
import { useCheckout } from "../../context/CheckoutProvider"
import { formatPriceInfo } from "../../../../utils"
interface DeliveryOptionsProps {
	loading: boolean
	deliveryOptions: GetDeliveryOptionsQuery["deliveryOptions"]
}

const containerActiveColor = colors["grey-3"]

interface ContainerProps {
	active?: boolean
}

const Container = styled.div<ContainerProps>`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: ${scale.px(2)};
	margin-bottom: ${scale.px(1)};
	border: 1px ${colors["grey-1"]} solid;
	border-color: ${props => props.active && containerActiveColor};
	transition: border-color 0.2s;
	cursor: pointer;

	&:hover {
		border-color: ${containerActiveColor};
	}
`

type DeliveryOptionInput = Pick<
	PaymentInput,
	"deliveryOptionId" | "servicePointId"
>

const resolver: Resolver<DeliveryOptionInput> = async values => {
	return {
		values: values.deliveryOptionId ? values : {},
		errors: !values.deliveryOptionId
			? {
					deliveryOptionId: {
						type: "required"
					}
			  }
			: {}
	}
}

const DeliveryOptions: React.FC<DeliveryOptionsProps> = props => {
	const { handleSubmit, register, formState, setValue } = useForm<
		DeliveryOptionInput
	>({
		shouldUnregister: true,
		mode: "onChange",
		resolver
	})

	const checkout = useCheckout()
	const locales = useLocales("checkout", ["nextCtaText"])
	const [activeDeliveryId, setActiveDeliveryId] = useState("")
	const { isValid } = formState

	const onSubmit = (data: DeliveryOptionInput) => {
		const deliveryOption = props.deliveryOptions?.find(
			item => item.id === data.deliveryOptionId
		)
		const servicePoint = deliveryOption?.servicePoints?.find(
			item => item.id === data.servicePointId
		)

		checkout.setSelectedFreight({
			deliveryOption,
			servicePoint
		})

		checkout.setPaymentInput(prev => ({
			...prev,
			deliveryOptionId: data.deliveryOptionId,
			servicePointId: data.servicePointId
		}))
		checkout.goToNextStep()
	}

	return (
		<Flex
			flex={1}
			flexDirection="column"
			as="form"
			onSubmit={handleSubmit(onSubmit)}
			w={1}
			alignItems="center"
		>
			{props.loading ? (
				<Panel mt={0} pt={0} alignItems="center">
					<Spinner size={4} strokeColor={colors["grey-3"]} />
				</Panel>
			) : (
				(props.deliveryOptions?.length ?? 0) > 0 && (
					<Panel mt={0} pt={0}>
						{props.deliveryOptions?.map(deliveryOption => {
							const active = activeDeliveryId === deliveryOption.id
							return (
								<Container
									key={deliveryOption.id}
									onClick={() => {
										checkout.setSelectedFreight({
											deliveryOption
										})
										setValue("deliveryOptionId", deliveryOption.id, {
											shouldValidate: true
										})
										setActiveDeliveryId(deliveryOption.id)
									}}
									active={active}
								>
									<Flex w={1}>
										<Flex mr={2} mt={0.5}>
											<Radio
												id={deliveryOption.id}
												value={deliveryOption.id}
												name="deliveryOptionId"
												inputRef={register()}
											/>
										</Flex>
										<Flex flexDirection="column" flex={1}>
											<Flex>
												<Text.Label
													htmlFor={deliveryOption.id}
													fontWeight="bold"
													mb={0}
												>
													{deliveryOption.name}
												</Text.Label>
											</Flex>
											{deliveryOption.description && (
												<Flex>
													<Text mb={0} fontSize={1.5} lineHeight={2}>
														{deliveryOption.description}
													</Text>
												</Flex>
											)}
										</Flex>
										<Flex flexDirection="column" alignItems="flex-end" flex={1}>
											<Flex>
												<Text whiteSpace="normal" mb={0}>
													{formatPriceInfo(
														deliveryOption.price,
														deliveryOption.price.currency
													)}
												</Text>
											</Flex>
											{deliveryOption.logoUrl && (
												<img
													width={scale(11)}
													src={deliveryOption.logoUrl}
													alt={`${deliveryOption.name} logo`}
												/>
											)}
										</Flex>
									</Flex>

									<Collapse isOpened={active}>
										<Flex flex={1}>
											{active &&
												deliveryOption.servicePoints &&
												deliveryOption.servicePoints.length > 0 && (
													<Flex mt={1} flex={1} width={1}>
														<Select
															name="servicePointId"
															inputRef={register()}
															options={deliveryOption.servicePoints.map(
																servicePoint => ({
																	label: servicePoint.name,
																	value: servicePoint.id
																})
															)}
														/>
													</Flex>
												)}
										</Flex>
									</Collapse>
								</Container>
							)
						})}
					</Panel>
				)
			)}
			<Panel>
				<Summary />
				<Button type="submit" disabled={!isValid} mt={1}>
					{locales.nextCtaText}
				</Button>
			</Panel>
		</Flex>
	)
}
export { DeliveryOptions }
