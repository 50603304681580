import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type CategoryLinkListBlockFieldsFragment = (
  { __typename?: 'categoryLinkListBlock' }
  & Pick<Types.CategoryLinkListBlock, 'id' | 'type'>
  & { categories?: Types.Maybe<Array<(
    { __typename?: 'categoryTree' }
    & Pick<Types.CategoryTree, 'alt' | 'url' | 'title' | 'target'>
    & { subcategories?: Types.Maybe<Array<(
      { __typename?: 'categoryTree' }
      & Pick<Types.CategoryTree, 'alt' | 'url' | 'title' | 'target'>
    )>> }
  )>> }
);

export const CategoryLinkListBlockFieldsFragmentDoc = gql`
    fragment categoryLinkListBlockFields on categoryLinkListBlock {
  id
  type
  categories {
    alt
    url
    title
    target
    subcategories {
      alt
      url
      title
      target
    }
  }
}
    `;