import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type NotifyMeWhenProductBackInStockQueryVariables = Types.Exact<{
  productId: Types.Scalars['String'];
  emailAddress: Types.Scalars['String'];
  productName: Types.Scalars['String'];
}>;


export type NotifyMeWhenProductBackInStockQuery = (
  { __typename?: 'Query' }
  & { notifyMeWhenProductBackInStock: (
    { __typename?: 'NotifyMeResult' }
    & Pick<Types.NotifyMeResult, 'success'>
  ) }
);

export type UnsubscribeFromNotifyMeWhenProductBackInStockQueryVariables = Types.Exact<{
  productId: Types.Scalars['String'];
  emailAddress: Types.Scalars['String'];
  productName: Types.Scalars['String'];
}>;


export type UnsubscribeFromNotifyMeWhenProductBackInStockQuery = (
  { __typename?: 'Query' }
  & { unsubscribeFromNotifyMeWhenProductBackInStock: (
    { __typename?: 'UnsubscribeResult' }
    & Pick<Types.UnsubscribeResult, 'success'>
  ) }
);


export const NotifyMeWhenProductBackInStockDocument = gql`
    query NotifyMeWhenProductBackInStock($productId: String!, $emailAddress: String!, $productName: String!) {
  notifyMeWhenProductBackInStock(productId: $productId, emailAddress: $emailAddress, productName: $productName) {
    success
  }
}
    `;

/**
 * __useNotifyMeWhenProductBackInStockQuery__
 *
 * To run a query within a React component, call `useNotifyMeWhenProductBackInStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotifyMeWhenProductBackInStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotifyMeWhenProductBackInStockQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      emailAddress: // value for 'emailAddress'
 *      productName: // value for 'productName'
 *   },
 * });
 */
export function useNotifyMeWhenProductBackInStockQuery(baseOptions?: Apollo.QueryHookOptions<NotifyMeWhenProductBackInStockQuery, NotifyMeWhenProductBackInStockQueryVariables>) {
        return Apollo.useQuery<NotifyMeWhenProductBackInStockQuery, NotifyMeWhenProductBackInStockQueryVariables>(NotifyMeWhenProductBackInStockDocument, baseOptions);
      }
export function useNotifyMeWhenProductBackInStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotifyMeWhenProductBackInStockQuery, NotifyMeWhenProductBackInStockQueryVariables>) {
          return Apollo.useLazyQuery<NotifyMeWhenProductBackInStockQuery, NotifyMeWhenProductBackInStockQueryVariables>(NotifyMeWhenProductBackInStockDocument, baseOptions);
        }
export type NotifyMeWhenProductBackInStockQueryHookResult = ReturnType<typeof useNotifyMeWhenProductBackInStockQuery>;
export type NotifyMeWhenProductBackInStockLazyQueryHookResult = ReturnType<typeof useNotifyMeWhenProductBackInStockLazyQuery>;
export type NotifyMeWhenProductBackInStockQueryResult = Apollo.QueryResult<NotifyMeWhenProductBackInStockQuery, NotifyMeWhenProductBackInStockQueryVariables>;
export const UnsubscribeFromNotifyMeWhenProductBackInStockDocument = gql`
    query UnsubscribeFromNotifyMeWhenProductBackInStock($productId: String!, $emailAddress: String!, $productName: String!) {
  unsubscribeFromNotifyMeWhenProductBackInStock(productId: $productId, emailAddress: $emailAddress, productName: $productName) {
    success
  }
}
    `;

/**
 * __useUnsubscribeFromNotifyMeWhenProductBackInStockQuery__
 *
 * To run a query within a React component, call `useUnsubscribeFromNotifyMeWhenProductBackInStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeFromNotifyMeWhenProductBackInStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnsubscribeFromNotifyMeWhenProductBackInStockQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      emailAddress: // value for 'emailAddress'
 *      productName: // value for 'productName'
 *   },
 * });
 */
export function useUnsubscribeFromNotifyMeWhenProductBackInStockQuery(baseOptions?: Apollo.QueryHookOptions<UnsubscribeFromNotifyMeWhenProductBackInStockQuery, UnsubscribeFromNotifyMeWhenProductBackInStockQueryVariables>) {
        return Apollo.useQuery<UnsubscribeFromNotifyMeWhenProductBackInStockQuery, UnsubscribeFromNotifyMeWhenProductBackInStockQueryVariables>(UnsubscribeFromNotifyMeWhenProductBackInStockDocument, baseOptions);
      }
export function useUnsubscribeFromNotifyMeWhenProductBackInStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnsubscribeFromNotifyMeWhenProductBackInStockQuery, UnsubscribeFromNotifyMeWhenProductBackInStockQueryVariables>) {
          return Apollo.useLazyQuery<UnsubscribeFromNotifyMeWhenProductBackInStockQuery, UnsubscribeFromNotifyMeWhenProductBackInStockQueryVariables>(UnsubscribeFromNotifyMeWhenProductBackInStockDocument, baseOptions);
        }
export type UnsubscribeFromNotifyMeWhenProductBackInStockQueryHookResult = ReturnType<typeof useUnsubscribeFromNotifyMeWhenProductBackInStockQuery>;
export type UnsubscribeFromNotifyMeWhenProductBackInStockLazyQueryHookResult = ReturnType<typeof useUnsubscribeFromNotifyMeWhenProductBackInStockLazyQuery>;
export type UnsubscribeFromNotifyMeWhenProductBackInStockQueryResult = Apollo.QueryResult<UnsubscribeFromNotifyMeWhenProductBackInStockQuery, UnsubscribeFromNotifyMeWhenProductBackInStockQueryVariables>;